import React from 'react';
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter';
import { Dropdown, Button } from "react-bootstrap";
import { format } from 'date-fns';
import { Add, More, FilterSquare, UserCirlceAdd, UserOctagon, ProfileCircle } from 'iconsax-react';
import TabellaLista from '../../../../components/Tabella/TabellaLista';
export const JuniorTable = () => {

	const data_column = [

		{
			Header: 'Prodotto',
			accessor: 'lista_prodotto',
			Filter: ColumnFilter
		},
		{
			Header: 'Nome utente',
			accessor: 'lista_utente',
			Filter: ColumnFilter,
			Cell: ({ value }) => {
				return (
					<Button variant="dark light" size="xs" >
						<span className="me-2"><ProfileCircle size="24" variant="Bulk" /></span>
						{value}
					</Button>
				)
			},
			disableFilters: true,
		},
        {
			Header: 'Data',
			accessor: 'lista_data',
			Filter: ColumnFilter
		},
        {
			Header: 'Ora',
			accessor: 'lista_ora',
			Filter: ColumnFilter
		},
		{
			Header: 'Azione',
			Footer: 'Azione',
			accessor: 'azione',
			Cell: ({ value }) => {
				return (
					<Dropdown>
						<Dropdown.Toggle
							className="btn-primary light sharp i-false btn-action"
						>
							<More />
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item>Modifica</Dropdown.Item>

						</Dropdown.Menu>
					</Dropdown>
				)
			},
			disableFilters: true,
		},
	];
	const data_json = [
		{
			"id": 1,
			"lista_prodotto": "Affitto campo da tennis",
			"lista_utente": "Giorgio Giorgi",
			"lista_data": "28 maggio 2024",
			"lista_ora": "11:;30",
		},
		{
			"id": 2,
			"lista_prodotto": "Lezione di tennis",
			"lista_utente": "Maria Mercurio",
			"lista_data": "30 maggio 2024",
			"lista_ora": "17:;30",
		}
	];

	return (
		<>
			<TabellaLista
				cardTitle={'Lista di attesa'}
				hasSearch={true}
				hasFilter={true}
				hasAddMore={false}
				data_column={data_column}
				data_json={data_json}
			/>
		</>
	)

}
export default JuniorTable;