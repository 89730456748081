import React from "react";
import { Dropdown, Button } from "react-bootstrap";
import { ColumnFilter } from "../../../components/table/FilteringTable/ColumnFilter";
import { More, DocumentText, DocumentDownload, Verify, Information } from "iconsax-react";
import TabellaLista from "../../../components/Tabella/TabellaLista";

const data_column = [

    {
        Header: 'Data caricamento',
        Footer: 'Data caricamento',
        accessor: 'data-caricamento',
        Filter: ColumnFilter
    },
    {
        Header: 'Nome documento',
        Footer: 'Nome documento',
        accessor: 'nome-documento',
        Cell: ({ value }) => {
            return (
                <Button variant="dark light" size="xs" >
                    <span className="me-2"><DocumentText size="24" variant="Bulk" /></span>
                    {value}
                </Button>
            );
        },
        Filter: ColumnFilter
    },
    {
        Header: 'Tipologia',
        Footer: 'Tipologia',
        accessor: 'tipologia',
        Filter: ColumnFilter
    },

    {
        Header: 'Caricato da',
        Footer: 'Caricato da',
        accessor: 'caricato-da',
        Filter: ColumnFilter
    },
    {
        Header: 'Azione',
        Footer: 'Azione',
        accessor: 'azione',
        Cell: ({ value }) => {
            return (
                <Dropdown>
                    <Dropdown.Toggle
                        className="btn-primary light sharp i-false btn-action"
                    >
                        <More />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item><span className="me-2"><DocumentDownload size="20"/></span>Scarica</Dropdown.Item>
                        <br/>
                        <Dropdown.Item><span className="me-2 text-success"><Verify size="20"/></span>Convalida</Dropdown.Item>                        
                        <Dropdown.Item><span className="me-2 text-danger"><Information size="20"/></span>Respingi</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )
        },
        disableFilters: true,
    },
];
const data_json = [

    {
        "data-caricamento": "10 Marzo 2024",
        "nome-documento": "Lorem Ipsum",
        "tipologia": "Lorem Ipsum",
        "caricato-da": "Nome Cognome"
    },
    {
        "data-caricamento": "10 Marzo 2024",
        "nome-documento": "Lorem Ipsum",
        "tipologia": "Lorem Ipsum",
        "caricato-da": "Nome Cognome"
    }
];

const ListaDocumenti = () => {
    return (
        <TabellaLista
            cardTitle={'Documenti'}
            hasSearch={true}
            hasFilter={true}
            hasAddMore={false}
            linkAddMore={'#'}
            data_column={data_column}
            data_json={data_json}
        />
    )
}

export default ListaDocumenti
