/// Menu
//import Metismenu from "metismenujs";
import React, { useContext, useEffect, useReducer, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import Collapse from 'react-bootstrap/Collapse';
/// Link
import { Link } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

import {ReactComponent as LogoPoweredBySportId} from "../../../images/powered-by-SportID.svg";

import { MenuMaestroList } from './MenuMaestro';

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active : "",
  activeSubmenu : "",
}


const SideBar = () => {
	const {
		iconHover,
		sidebarposition,
		headerposition,
		sidebarLayout,
	} = useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);	
  
  // For scroll
	const [hideOnScroll, setHideOnScroll] = useState(true)
	useScrollPosition(
		({ prevPos, currPos }) => {
		  const isShow = currPos.y > prevPos.y
		  if (isShow !== hideOnScroll) setHideOnScroll(isShow)
		},
		[hideOnScroll]
	)
  
  //let scrollPosition = useScrollPosition();
  
  
  const handleMenuActive = status => {		
    setState({active : status});		
		if(state.active === status){			
      setState({active : ""});
    }
   
	}
	const handleSubmenuActive = (status) => {		
    setState({activeSubmenu : status})
		if(state.activeSubmenu === status){
      setState({activeSubmenu : ""})
			
		}
    
	}

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll d-flex flex-column justify-content-between">
             <ul className="metismenu" id="menu" style={{ overflow: 'hidden' }}>
                {MenuMaestroList.map((data, index)=>{
                  let menuClass = data.classsChange;
                    if(menuClass === "menu-title"){
                      return(
                          <li className={menuClass}  key={index} >{data.title}</li>
                      )
                    }else{
                      return(				
                        <li className={` ${ state.active === data.title ? 'mm-active' : ''}`}
                          key={index} 
                        >
                          
                          {data.content && data.content.length > 0 ?
                              <Link to={"#"} 
                                className="has-arrow"
                                onClick={() => {handleMenuActive(data.title)}}
                              >								
                                  {data.iconStyle}
                                  <span className="nav-text">{data.title}</span>
                              </Link>
                          :
                            <Link  to={data.to} >
                                {data.iconStyle}
                                <span className="nav-text">{data.title}</span>
                            </Link>
                          }
                          <Collapse in={state.active === data.title ? true :false}>
                            <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                              {data.content && data.content.map((data,index) => {									
                                return(	
                                    <li key={index}
                                      className={`${ state.activeSubmenu === data.title ? "mm-active" : ""}`}                                    
                                    >
                                      {data.content && data.content.length > 0 ?
                                          <>
                                            <Link to={data.to} className={data.hasMenu ? 'has-arrow' : ''}
                                              onClick={() => { handleSubmenuActive(data.title)}}
                                            >
                                              {data.title}
                                            </Link>
                                            <Collapse in={state.activeSubmenu === data.title ? true : false}>
                                                <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                                  {data.content && data.content.map((data,index) => {
                                                    return(	
                                                      <>
                                                        <li key={index}>
                                                          <Link to={data.to} className={`${path === data.to ? "mm-active" : ""}`} >{data.title}</Link>
                                                        </li>
                                                      </>
                                                    )
                                                  })}
                                                </ul>
                                            </Collapse>
                                          </>
                                        :
                                        <Link to={data.hasMenu ? '#' : data.to}>
                                          {data.title}
                                        </Link>
                                      }
                                      
                                    </li>
                                  
                                )
                              })}
                            </ul>
                          </Collapse>
                        </li>	
                      )
                  }
                })}          
            </ul>

            <div className="d-flex mb-5">
              <LogoPoweredBySportId />
            </div>  
            
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
