import React, { useEffect, useState } from "react";
import { Row, Col, Card, Tab, Nav, Form, Button } from "react-bootstrap";
import TabellaLista from "../../Tabella/TabellaLista";
import { Dropdown } from "react-bootstrap";
import { More, TableDocument } from 'iconsax-react';
import { ColumnFilter } from "../../table/FilteringTable/ColumnFilter";
import { format } from 'date-fns';

const SportMaestro = (props) => {

    const [formData, setFormData] = useState({
        taughtSports: []
    });
    const [data_json, setDataJson] = useState(null);

    useEffect(() => {
        if (props.profile && props.profile.idsGuru) {
            let playerData = props.profile;

            if(playerData && playerData.idsGuru) {
                let formData = {};

                // console.log(playerData.idsGuru);

                formData.taughtSports = playerData.idsGuru.taughtSports ? playerData.idsGuru.taughtSports : [];

                // TODO RIMUOVERE
                // testerdata:
                /*
                formData.taughtSports = [{
                        "sportType": "RUNNING",
                        "license": {
                            "document": {
                                "id": "string",
                                "name": "string",
                                "uri": "string",
                                "uploadedAt": 0
                            },
                            "verificationStatus": "PENDING"
                        }
                    }];
                */

                let data_json = [];
                formData.taughtSports.forEach(element => {
                    let curr_obj = {};
                    curr_obj.sport = element.sportType;
                    if(element.license.document) {
                        curr_obj.date = format(element.license.document.uploadedAt, 'dd/MM/yyyy HH:mm:ss');
                        curr_obj.state = element.license.verificationStatus;
                        curr_obj.document = {
                            'name': element.license.document.name,
                            'uri': element.license.document.uri
                        };
                    }
                    data_json.push(curr_obj);
                });
                
                setFormData(formData);
                setDataJson(data_json);
            }
        }
		
    }, [props]);

    const data_column = [
        {
            Header: 'Data',
            accessor: 'date',
            Filter: ColumnFilter
        },
        {
            Header: 'Sport',
            accessor: 'sport',
            Filter: ColumnFilter
        },
        {
            Header: 'Tesserino',
            accessor: 'document',
            Cell: ({ value }) => {
				return (
					<Button onClick={() => downloadDoc(value.uri)} variant="dark light" size="xs" >
						<span className="me-2"><TableDocument size="24" variant="Bulk" /></span>
						{value.name}
					</Button>
				)
			},
			disableFilters: true,
        },
        {
            Header: 'Stato tesserino',
            accessor: 'state',
            Filter: ColumnFilter,
			Cell: ({ value }) => {
                if(value == 'PENDING') {
                    return <span><i className="fa fa-circle text-warning me-1"></i> In approvazione</span>
                } else if(value == 'APPROVED') {
                    return <span><i className="fa fa-circle text-success me-1"></i> Approvato</span>
                } else if(value == 'REJECTED') {
                    return <span><i className="fa fa-circle text-danger me-1"></i> Rifiutato</span>
                }
			},
			disableFilters: true,
        }
    ];

    const downloadDoc = (uri) => {
        window.open(uri, '_blank');
    };

    return (
        <>
            <Row>
                <TabellaLista
                    cardTitle={'Sport praticati'}
                    hasSearch={true}
                    hasFilter={false}
                    hasAddMore={false}
                    data_column={data_column}
                    data_json={data_json}
                />
            </Row>
        </>
    )
}
export default SportMaestro;