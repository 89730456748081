import React, { Fragment } from "react";
import TabellaLista from "../../../../components/Tabella/TabellaLista";
import { Button, Alert } from "react-bootstrap";
import { ColumnFilter } from "../../../../components/table/FilteringTable/ColumnFilter";
import { ProfileCircle, InfoCircle } from "iconsax-react";

const ListaPromozioni = () => {
    const data_column = [

        {
            Header: 'Data inizio',
            Footer: 'Data inizio',
            accessor: 'data-inizio',
            Filter: ColumnFilter
        },
        {
            Header: 'Data fine',
            Footer: 'Data fine',
            accessor: 'data-fine',
            Filter: ColumnFilter
        },
        {
            Header: 'Nome Promozione',
            Footer: 'Nome Promozione',
            accessor: 'nome-promozione',
            Filter: ColumnFilter
        },
        {
            Header: 'Nome Struttura',
            Footer: 'Nome Struttura',
            accessor: 'nome-struttura',
            Cell: ({ value }) => {
                return (
                    <Button variant="dark light" size="xs" >
                        <span className="me-2"><ProfileCircle size="24" variant="Bulk" /></span>
                        {value}
                    </Button>
                );
            },
            Filter: ColumnFilter
        },
        {
            Header: 'Prodotti',
            Footer: 'Prodotti',
            accessor: 'prodotti',
            Filter: ColumnFilter
        },
        {
            Header: 'Stato',
            Footer: 'Stato',
            accessor: 'stato',
            Cell: ({ value }) => {
                return (
                    <div className="d-flex align-items-center">
                        <i className={`fa fa-circle me-1 ${value.class}`}></i>
                        {value.label}
                    </div>
                );
            },
            disableFilters: true,
        }
    ];

    const data_json = [
        {
            "data-inizio": "10 Marzo 2024",
            "data-fine": "10 Marzo 2024",
            "nome-promozione": "Lorem Ipsum",
            "nome-struttura": "Lorem Ipsum",
            "prodotti": "Lorem Ipsum (+19)",
            "stato": {
                "label": "Inactive",
                "class": "text-warning"
            },
            "vsibilita": {
                "label": "Inactive",
                "class": "text-warning"
            },
        }
    ];

    return (
        <Fragment>
            <TabellaLista
                cardTitle={'Lista Promozioni'}
                hasSearch={true}
                hasFilter={true}
                hasAddMore={false}
                data_column={data_column}
                data_json={data_json}
            />
        </Fragment>
    )
}

export default ListaPromozioni