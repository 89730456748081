import { More } from "iconsax-react";
import { Dropdown, Alert } from "react-bootstrap";
import { ColumnFilter } from "../../../components/table/FilteringTable/ColumnFilter";
import TabellaLista from "../../../components/Tabella/TabellaLista";
import { Fragment } from "react";

export const ListaMieiAllievi = () => {

    const data_column = [

        {
            Header: 'Nome e Cognome',
            Footer: 'Nome e Cognome',
            accessor: 'nome-e-cognome',
            Filter: ColumnFilter
        },
        {
            Header: 'Email',
            Footer: 'Email',
            accessor: 'email',
            Filter: ColumnFilter
        },
        {
            Header: 'Data registrazione',
            Footer: 'Data registrazione',
            accessor: 'data-registrazione',
            Filter: ColumnFilter
        },

        {
            Header: 'Stato',
            Footer: 'Stato',
            accessor: 'stato',
            Cell: ({ value }) => {
                return (
                    <div className="d-flex align-items-center">
                        <i className={`fa fa-circle me-1 ${value.class}`}></i>
                        {value.label}
                    </div>
                );
            },
            disableFilters: true,
        },
        {
            Header: 'Abbonamento',
            Footer: 'Abbonamento',
            accessor: 'abbonamento',
            Cell: ({ value }) => {
                return (
                    <div className="d-flex align-items-center">
                        <i className={`fa fa-star me-1 ${value.class}`}></i>
                        {value.label}
                    </div>
                )
            },
            disableFilters: true,
        },

        {
            Header: 'Azione',
            Footer: 'Azione',
            accessor: 'azione',
            Cell: ({ value }) => {
                return (
                    <Dropdown>
                        <Dropdown.Toggle
                            className="btn-primary light sharp i-false btn-action"
                        >
                            <More />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item>Edit</Dropdown.Item>
                            <Dropdown.Item>Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            },
            disableFilters: true,
        },
    ];

    const data_json = [
        {
            "nome-e-cognome": "Loredana Bassi",
            "email": "loredana@mail.com",
            "membri": 12,
            "data-registrazione": "12 Marzo 2024",
            "stato": {
                "label": "Inactive",
                "class": "text-warning"
            },
            "abbonamento": {
                "label": "Smart",
                "class": "text-dark"
            },
        },
        {
            "nome-e-cognome": "Loredana Bassi",
            "email": "loredana@mail.com",
            "membri": 12,
            "data-registrazione": "12 Marzo 2024",
            "stato": {
                "label": "Completed",
                "class": "text-blue"
            },
            "abbonamento": {
                "label": "Premium",
                "class": "text-warning"
            },
        },
        {
            "nome-e-cognome": "Loredana Bassi",
            "email": "loredana@mail.com",
            "membri": 12,
            "data-registrazione": "12 Marzo 2024",
            "stato": {
                "label": "Active",
                "class": "text-success"
            },
            "abbonamento": {
                "label": "Premium",
                "class": "text-warning"
            },
        },
    ];

    return (
        <Fragment>
            <TabellaLista
                cardTitle={'Lista Allievi'}
                hasSearch={true}
                hasFilter={true}
                hasAddMore={false}
                data_column={data_column}
                data_json={data_json}
            />
            
            <Alert variant="danger">
                <Alert.Heading>Attenzione</Alert.Heading>
                <p>
                    La lista è in fase di creazione.<br/>
                    Le "Azioni" non sono definitive
                </p>
            </Alert>
        </Fragment>
    )
}

export default ListaMieiAllievi