import React from "react";
import { Dropdown } from "react-bootstrap";
import { ColumnFilter } from "../../../../components/table/FilteringTable/ColumnFilter";
import { More } from "iconsax-react";
import TabellaLista from "../../../../components/Tabella/TabellaLista";

const data_column = [

	{
		Header: 'Nome prodotto',
		accessor: 'prodotto_nome',
		Filter: ColumnFilter
	},
	{
		Header: 'Prezzo',
		accessor: 'prodotto_prezzo',
		Filter: ColumnFilter
	},
	{
		Header: 'Prezzo ai soci',
		accessor: 'prodotto_soci',
		Filter: ColumnFilter
	},

	{
		Header: 'Stato',
		accessor: 'prodotto_status',
		Filter: ColumnFilter,
		Cell: ({ value }) => {
			return (
				<div className="d-flex align-items-center">
					<i className={`fa fa-circle me-1 ${value.class}`}></i>
					{value.label}
				</div>
			)
		},
		disableFilters: true,
	},
    
	{
		Header: 'Visibilità',
		accessor: 'prodotto_vis',
		Filter: ColumnFilter,
		Cell: ({ value }) => {
			return (
				<div className="d-flex align-items-center">
					<i className={`fa fa-circle me-1 ${value.class}`}></i>
					{value.label}
				</div>
			)
		},
		disableFilters: true,
	},
	{
		Header: 'Azione',
		Footer: 'Azione',
		accessor: 'azione',
		Cell: ({ value }) => {
			return (
				<Dropdown>
					<Dropdown.Toggle
						className="btn-primary light sharp i-false btn-action"
					>
						<More />
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item>Modifica</Dropdown.Item>
						<Dropdown.Item>Cancella</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			)
		},
		disableFilters: true,
	},
];
const data_json = [

	{
		"prodotto_nome": "Abbonamento mensile Tennis",
		"prodotto_prezzo": "€ 99,00/Mese",
		"prodotto_soci": "€ 89,00/Mese",
		"prodotto_status": {
			"label": "Attivo",
			"class": "text-success"
		},
        "prodotto_vis": {
			"label": "Pubblico",
			"class": "text-success"
		},
	},
    {
		"prodotto_nome": "Affitto campo tennis",
		"prodotto_prezzo": "€ 9,00/Ora",
		"prodotto_soci": "€ 8,00/Ora",
		"prodotto_status": {
			"label": "Bozza",
			"class": "text-primary"
		},
        "prodotto_vis": {
			"label": "Privato",
			"class": "text-danger"
		},
	}
	
];

const ListaProdotti = () => {
	return (
		<TabellaLista
			cardTitle={'Lista Prodotti '}
			hasSearch={true}
			hasFilter={true}
			hasAddMore={true}
			labelAddMore={'Nuovo prodotto'}
			linkAddMore={'/struttura/prodotti/lista/aggiungi'}
			data_column={data_column}
			data_json={data_json}
		/>
	)
}

export default ListaProdotti
