import React, { Fragment } from "react";
import TabellaLista from "../../../../components/Tabella/TabellaLista";
import { Dropdown } from "react-bootstrap";
import { ColumnFilter } from "../../../../components/table/FilteringTable/ColumnFilter";
import { More } from "iconsax-react";

const ListaPromozioni = () => {
    const data_column = [

        {
            Header: 'Data inizio',
            Footer: 'Data inizio',
            accessor: 'data-inizio',
            Filter: ColumnFilter
        },
        {
            Header: 'Data fine',
            Footer: 'Data fine',
            accessor: 'data-fine',
            Filter: ColumnFilter
        },
        {
            Header: 'Nome Promozione',
            Footer: 'Nome Promozione',
            accessor: 'nome-promozione',
            Filter: ColumnFilter
        },
        {
            Header: 'Prodotti',
            Footer: 'Prodotti',
            accessor: 'prodotti',
            Filter: ColumnFilter
        },
        {
            Header: 'Stato',
            Footer: 'Stato',
            accessor: 'stato',
            Cell: ({ value }) => {
                return (
                    <div className="d-flex align-items-center">
                        <i className={`fa fa-circle me-1 ${value.class}`}></i>
                        {value.label}
                    </div>
                );
            },
            disableFilters: true,
        },
        {
            Header: 'Azione',
            Footer: 'Azione',
            accessor: 'azione',
            Cell: ({ value }) => {
                return (
                    <Dropdown>
                        <Dropdown.Toggle
                            className="btn-primary light sharp i-false btn-action"
                        >
                            <More />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item>Scarica</Dropdown.Item>
                            <Dropdown.Item>Modifica</Dropdown.Item>
                            <Dropdown.Item>Cancella</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            },
            disableFilters: true,
        },
    ];

    const data_json = [
        {
            "data-inizio": "10 Marzo 2024",
            "data-fine": "10 Marzo 2024",
            "nome-promozione": "Lorem Ipsum",
            "prodotti": "Lorem Ipsum (+19)",
            "stato": {
                "label": "Inactive",
                "class": "text-warning"
            },
            "vsibilita": {
                "label": "Inactive",
                "class": "text-warning"
            },
        }
    ];

    return (
        <Fragment>
            <TabellaLista
                cardTitle={'Lista Promozioni'}
                hasSearch={true}
                hasFilter={true}
                hasAddMore={true}
                labelAddMore={'Nuova promozione'}
                linkAddMore={'/maestro/prodotti/promozioni/aggiungi-promozione'}
                data_column={data_column}
                data_json={data_json}
            />
        </Fragment>
    )
}

export default ListaPromozioni