import React, { Fragment, useState } from 'react';
import { Card, Row, Col, Form, Button, FormControl } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Select from "react-select";
import { InfoCircle, Trash, AddSquare } from "iconsax-react";
import "react-widgets/styles.css";

const AggiungiImpianto = () => {

	const sportOptions = [
		{ label: "Calcio", value: "calcio" },
		{ label: "Basket", value: "basket" },
		{ label: "Pallavolo", value: "pallavolo" },
		{ label: "Tennis", value: "tennis" },
	]

	const statoOptions = [
		{ label: "Disponibile", value: "disponibile" },
		{ label: "Indisponibile", value: "indisponibile" },
		{ label: "In manutenzione", value: "in manutenzione" },
	]

	const [disponibilitàMensile, setDisponibilitàMensile] = useState([0]);

	const handleAggiungiRigaMese = () => {
		const nextNumber = Math.max(...disponibilitàMensile) + 1;
		setDisponibilitàMensile([...disponibilitàMensile, nextNumber]);
	}
	const handleRimuoviRigaMese = (i) => {
		const deleteVal = [...disponibilitàMensile]
		deleteVal.splice(i, 1)
		setDisponibilitàMensile(deleteVal)
	}

	/**
	 * Lunedì
	 */

	const [disponilitàLunedì, setDisponilitàLunedì] = useState([]);

	const handleAggiungiRigaLunedì = () => {
		const nextNumber = Math.max(...disponilitàLunedì) + 1;
		setDisponilitàLunedì([...disponilitàLunedì, nextNumber]);
	}
	const handleRimuoviRigaLunedì = (i) => {
		const deleteVal = [...disponilitàLunedì]
		deleteVal.splice(i, 1)
		setDisponilitàLunedì(deleteVal)
	}

	const [chiusoLunedì, setChiusoLunedì] = useState(false);

	const handleCheckboxChiusoLunedì = () => {
		setChiusoLunedì(!chiusoLunedì);
		setDisponilitàLunedì([]);
	};

	/**
	 * Martedì
	 */

	const [disponilitàMartedì, setDisponilitàMartedì] = useState([]);

	const handleAggiungiRigaMartedì = () => {
		const nextNumber = Math.max(...disponilitàMartedì) + 1;
		setDisponilitàMartedì([...disponilitàMartedì, nextNumber]);
	}
	const handleRimuoviRigaMartedì = (i) => {
		const deleteVal = [...disponilitàMartedì]
		deleteVal.splice(i, 1)
		setDisponilitàMartedì(deleteVal)
	}

	const [chiusoMartedì, setChiusoMartedì] = useState(false);

	const handleCheckboxChiusoMartedì = () => {
		setChiusoMartedì(!chiusoMartedì);
		setDisponilitàMartedì([]);
	};


	/**
	 * Mercoledì
	 */

	const [disponilitàMercoledì, setDisponilitàMercoledì] = useState([]);

	const handleAggiungiRigaMercoledì = () => {
		const nextNumber = Math.max(...disponilitàMercoledì) + 1;
		setDisponilitàMercoledì([...disponilitàMercoledì, nextNumber]);
	}
	const handleRimuoviRigaMercoledì = (i) => {
		const deleteVal = [...disponilitàMercoledì]
		deleteVal.splice(i, 1)
		setDisponilitàMercoledì(deleteVal)
	}

	const [chiusoMercoledì, setChiusoMercoledì] = useState(false);

	const handleCheckboxChiusoMercoledì = () => {
		setChiusoMercoledì(!chiusoMercoledì);
		setDisponilitàMercoledì([]);
	};

	/**
	 * Giovedì
	 */

	const [disponilitàGiovedì, setDisponilitàGiovedì] = useState([]);

	const handleAggiungiRigaGiovedì = () => {
		const nextNumber = Math.max(...disponilitàGiovedì) + 1;
		setDisponilitàGiovedì([...disponilitàGiovedì, nextNumber]);
	}
	const handleRimuoviRigaGiovedì = (i) => {
		const deleteVal = [...disponilitàGiovedì]
		deleteVal.splice(i, 1)
		setDisponilitàGiovedì(deleteVal)
	}

	const [chiusoGiovedì, setChiusoGiovedì] = useState(false);

	const handleCheckboxChiusoGiovedì = () => {
		setChiusoGiovedì(!chiusoGiovedì);
		setDisponilitàGiovedì([]);
	};

	/**
	 * Venerdì
	 */

	const [disponilitàVenerdì, setDisponilitàVenerdì] = useState([]);

	const handleAggiungiRigaVenerdì = () => {
		const nextNumber = Math.max(...disponilitàVenerdì) + 1;
		setDisponilitàVenerdì([...disponilitàVenerdì, nextNumber]);
	}
	const handleRimuoviRigaVenerdì = (i) => {
		const deleteVal = [...disponilitàVenerdì]
		deleteVal.splice(i, 1)
		setDisponilitàVenerdì(deleteVal)
	}

	const [chiusoVenerdì, setChiusoVenerdì] = useState(false);

	const handleCheckboxChiusoVenerdì = () => {
		setChiusoVenerdì(!chiusoVenerdì);
		setDisponilitàVenerdì([]);
	};

	/**
	 * Sabato
	 */

	const [disponilitàSabato, setDisponilitàSabato] = useState([]);

	const handleAggiungiRigaSabato = () => {
		const nextNumber = Math.max(...disponilitàSabato) + 1;
		setDisponilitàSabato([...disponilitàSabato, nextNumber]);
	}
	const handleRimuoviRigaSabato = (i) => {
		const deleteVal = [...disponilitàSabato]
		deleteVal.splice(i, 1)
		setDisponilitàSabato(deleteVal)
	}

	const [chiusoSabato, setChiusoSabato] = useState(false);

	const handleCheckboxChiusoSabato = () => {
		setChiusoSabato(!chiusoSabato);
		setDisponilitàSabato([]);
	};

	/**
	 * Domenica
	 */

	const [disponilitàDomenica, setDisponilitàDomenica] = useState([]);

	const handleAggiungiRigaDomenica = () => {
		const nextNumber = Math.max(...disponilitàDomenica) + 1;
		setDisponilitàDomenica([...disponilitàDomenica, nextNumber]);
	}
	const handleRimuoviRigaDomenica = (i) => {
		const deleteVal = [...disponilitàDomenica]
		deleteVal.splice(i, 1)
		setDisponilitàDomenica(deleteVal)
	}

	const [chiusoDomenica, setChiusoDomenica] = useState(false);

	const handleCheckboxChiusoDomenica = () => {
		setChiusoDomenica(!chiusoDomenica);
		setDisponilitàDomenica([]);
	};

	return (
		<Fragment>
			<Card>
				<Card.Header as="h4" className="card-title">
					Aggiungi Impianto
				</Card.Header>

				<Card.Body>
					< Form>
						<Row>
							<Col xxl="4" xl="4" lg="12">
								<Form.Label>Nome impianto</Form.Label>
								<Form.Control type="text" placeholder="Nome impianto"></Form.Control>
								<br />
							</Col>
							<Col xxl="4" xl="4" lg="12">
								<Form.Label>Sport</Form.Label>
								<Select
									defaultValue={""}
									options={sportOptions}
								/>
								<br />
							</Col>
							<Col xxl="4" xl="4" lg="12">
								<Form.Label>Stato</Form.Label>
								<Select
									defaultValue={""}
									options={statoOptions}
								/>
								<br />
							</Col>

							<Col xxl="4" xl="4" lg="12">
								<Form.Label>Prezzo</Form.Label>
								<Form.Control type="number" placeholder="Es. 20"></Form.Control>
								<br />
							</Col>
							<Col xxl="4" xl="4" lg="12">
								<Form.Label>Slot (in ore)</Form.Label>
								<Form.Control type="number" min="0.5" max="2.5" step="0.5" placeholder="Es. 2.5"></Form.Control>
								<br />
							</Col>
							<Col xxl="4" xl="4" lg="12">
								<Form.Label>Percentuale massima pagabile in FitCoin <InfoCircle size="20" variant="Bulk" /></Form.Label>
								<Form.Control type="number" min="5" max="100" placeholder="Es. 10"></Form.Control>
								<br />
							</Col>

							<Col xxl="6" xl="6" lg="12">
								<Form.Label>Prezzo soci</Form.Label>
								<Form.Control type="number" placeholder="Es. 20"></Form.Control>
								<br />
							</Col>
							<Col xxl="6" xl="6" lg="12">
								<Form.Label>Percentuale massima pagabile in FitCoin per soci</Form.Label>
								<Form.Control type="number" min="5" max="100" placeholder="Es. 10"></Form.Control>
								<br />
							</Col>
							<Col>
								<Form.Label>Descrizione impianto</Form.Label>
								<Form.Control as="textarea" rows="5" placeholder="Descrizione impianto"></Form.Control>
								<br />
							</Col>
						</Row>
					</Form>
				</Card.Body>
			</Card>

			<Card>
				<Card.Header as="h4" className="card-title">
					Disponibilità mensile
				</Card.Header>

				<Card.Body>
					<Row className="pb-4">
						<Col xxl="5" xl="5" lg="5">
							<Form.Label>Data inizio disponibilità</Form.Label>
							<FormControl type="date" placeholder="Ora fine" ></FormControl>
						</Col>

						<Col xxl="5" xl="5" lg="5">
							<Form.Label>Data fine disponibilità</Form.Label>
							<FormControl type="date" placeholder="Ora fine" ></FormControl>
						</Col>
						<Col className="d-flex flex-column justify-content-around">
							<Form.Label className='text-white'> Aggiungi </Form.Label>
							<Button className='btn btn-dark light btn-sm rounded-3 d-flex align-items-center justify-content-center' onClick={() => handleAggiungiRigaMese(1)}>Aggiungi <span className='ms-2'><AddSquare size="22" variant="Bulk" /></span></Button>
						</Col>
					</Row>
					{disponibilitàMensile.map((valore, index) => (
						<Fragment key={index}>
							<Row className="pb-4">
								<Col xxl="5" xl="5" lg="5">
									<Form.Label>Data inizio disponibilità</Form.Label>
									<FormControl type="date" placeholder="Ora fine"></FormControl>
								</Col>

								<Col xxl="5" xl="5" lg="5">
									<Form.Label>Data fine disponibilità</Form.Label>
									<FormControl type="date" placeholder="Ora fine"></FormControl>
								</Col>
								<Col className="d-flex flex-column justify-content-around">
									<Form.Label> </Form.Label>
									<div onClick={() => handleRimuoviRigaMese(index)}><Trash size="24" color="red" variant="Bulk" /></div>
								</Col>
							</Row>
						</Fragment>
					))}
				</Card.Body>
			</Card>

			<Card>
				<Card.Header as="h4" className="card-title">
					Disponibilità settimanale
				</Card.Header>
				<Card.Body>

					<Row className="align-items-end">
						<Col className="m-3">
							Lunedì
						</Col>
						<Col>
							<div className="btn-group btn-group-toggle" data-toggle="buttons">
								<label className="btn btn-sm rounded-3 m-0 btn-dark light active d-flex align-items-center">
									<Form.Check
										className="me-2 light"
										type="checkbox"
										checked={chiusoLunedì}
										onChange={handleCheckboxChiusoLunedì}
									/>
									Chiuso
								</label>
							</div>
						</Col>
						<Col xxl="3" xl="3">
							<Form.Label>Ora inizio</Form.Label>
							<FormControl {...chiusoLunedì ? { disabled: true } : {}} type="time" placeholder="Ora fine"></FormControl>
						</Col>
						<Col xxl="3" xl="3">
							<Form.Label>Ora fine</Form.Label>
							<FormControl {...chiusoLunedì ? { disabled: true } : {}} type="time" placeholder="Ora fine"></FormControl>
						</Col>
						<Col xxl="2" xl="2" className="d-flex flex-column justify-content-around">
							<Form.Label className='text-white'> Aggiungi </Form.Label>
							<Button {...chiusoLunedì ? { disabled: true } : {}} className='btn btn-dark light btn-sm rounded-3 d-flex align-items-center justify-content-center' onClick={() => handleAggiungiRigaLunedì(1)}>Aggiungi <span className='ms-2'><AddSquare size="22" variant="Bulk" /></span></Button>
						</Col>
					</Row>
					<br />

					{disponilitàLunedì.map((valore, index) => (

						<Fragment key={index}>
							<Row className="justify-content-end">
								<Col xxl="3" xl="3" className="align-self-end">
									<Form.Label>Ora inizio</Form.Label>
									<FormControl type="time" placeholder="Ora fine"></FormControl>
								</Col>
								<Col xxl="3" xl="3" className="align-self-end">
									<Form.Label>Ora fine</Form.Label>
									<FormControl type="time" placeholder="Ora fine"></FormControl>
								</Col>
								<Col xxl="2" xl="2" className="d-flex flex-column justify-content-around col">
									<Form.Label> </Form.Label>
									<div onClick={() => handleRimuoviRigaLunedì(index)}><Trash size="24" color="red" variant="Bulk" /></div>
								</Col>
							</Row>
							<br />
						</Fragment>
					))}

					<Row className="align-items-end">
						<Col className="m-3">
							Martedì
						</Col>
						<Col>
							<div className="btn-group btn-group-toggle" data-toggle="buttons">
								<label className="btn btn-sm rounded-3 m-0 btn-dark light active d-flex align-items-center">
									<Form.Check
										className="me-2 light"
										type="checkbox"
										checked={chiusoMartedì}
										onChange={handleCheckboxChiusoMartedì}
									/>
									Chiuso
								</label>
							</div>
						</Col>
						<Col xxl="3" xl="3">
							<Form.Label>Ora inizio</Form.Label>
							<FormControl {...chiusoMartedì ? { disabled: true } : {}} type="time" placeholder="Ora fine"></FormControl>
						</Col>
						<Col xxl="3" xl="3">
							<Form.Label>Ora fine</Form.Label>
							<FormControl {...chiusoMartedì ? { disabled: true } : {}} type="time" placeholder="Ora fine"></FormControl>
						</Col>
						<Col xxl="2" xl="2" className="d-flex flex-column justify-content-around">
							<Form.Label className='text-white'> Aggiungi </Form.Label>
							<Button {...chiusoMartedì ? { disabled: true } : {}} className='btn btn-dark light btn-sm rounded-3 d-flex align-items-center justify-content-center' onClick={() => handleAggiungiRigaMartedì(1)}>Aggiungi <span className='ms-2'><AddSquare size="22" variant="Bulk" /></span></Button>
						</Col>
					</Row>
					<br />

					{disponilitàMartedì.map((valore, index) => (

						<Fragment key={index}>
							<Row className="justify-content-end">
								<Col xxl="3" xl="3" className="align-self-end">
									<Form.Label>Ora inizio</Form.Label>
									<FormControl type="time" placeholder="Ora fine"></FormControl>
								</Col>
								<Col xxl="3" xl="3" className="align-self-end">
									<Form.Label>Ora fine</Form.Label>
									<FormControl type="time" placeholder="Ora fine"></FormControl>
								</Col>
								<Col xxl="2" xl="2" className="d-flex flex-column justify-content-around col">
									<Form.Label> </Form.Label>
									<div onClick={() => handleRimuoviRigaMartedì(index)}><Trash size="24" color="red" variant="Bulk" /></div>
								</Col>
							</Row>
							<br />
						</Fragment>
					))}

					<Row className="align-items-end">
						<Col className="m-3">
							Mercoledì
						</Col>
						<Col>
							<div className="btn-group btn-group-toggle" data-toggle="buttons">
								<label className="btn btn-sm rounded-3 m-0 btn-dark light active d-flex align-items-center">
									<Form.Check
										className="me-2 light"
										type="checkbox"
										checked={chiusoMercoledì}
										onChange={handleCheckboxChiusoMercoledì}
									/>
									Chiuso
								</label>
							</div>
						</Col>
						<Col xxl="3" xl="3">
							<Form.Label>Ora inizio</Form.Label>
							<FormControl {...chiusoMercoledì ? { disabled: true } : {}} type="time" placeholder="Ora fine"></FormControl>
						</Col>
						<Col xxl="3" xl="3">
							<Form.Label>Ora fine</Form.Label>
							<FormControl {...chiusoMercoledì ? { disabled: true } : {}} type="time" placeholder="Ora fine"></FormControl>
						</Col>
						<Col xxl="2" xl="2" className="d-flex flex-column justify-content-around">
							<Form.Label className='text-white'> Aggiungi </Form.Label>
							<Button {...chiusoMercoledì ? { disabled: true } : {}} className='btn btn-dark light btn-sm rounded-3 d-flex align-items-center justify-content-center' onClick={() => handleAggiungiRigaMercoledì(1)}>Aggiungi <span className='ms-2'><AddSquare size="22" variant="Bulk" /></span></Button>
						</Col>
					</Row>
					<br />

					{disponilitàMercoledì.map((valore, index) => (

						<Fragment key={index}>
							<Row className="justify-content-end">
								<Col xxl="3" xl="3" className="align-self-end">
									<Form.Label>Ora inizio</Form.Label>
									<FormControl type="time" placeholder="Ora fine"></FormControl>
								</Col>
								<Col xxl="3" xl="3" className="align-self-end">
									<Form.Label>Ora fine</Form.Label>
									<FormControl type="time" placeholder="Ora fine"></FormControl>
								</Col>
								<Col xxl="2" xl="2" className="d-flex flex-column justify-content-around col">
									<Form.Label> </Form.Label>
									<div onClick={() => handleRimuoviRigaMercoledì(index)}><Trash size="24" color="red" variant="Bulk" /></div>
								</Col>
							</Row>
							<br />
						</Fragment>
					))}

					<Row className="align-items-end">
						<Col className="m-3">
							Giovedì
						</Col>
						<Col>
							<div className="btn-group btn-group-toggle" data-toggle="buttons">
								<label className="btn btn-sm rounded-3 m-0 btn-dark light active d-flex align-items-center">
									<Form.Check
										className="me-2 light"
										type="checkbox"
										checked={chiusoGiovedì}
										onChange={handleCheckboxChiusoGiovedì}
									/>
									Chiuso
								</label>
							</div>
						</Col>
						<Col xxl="3" xl="3">
							<Form.Label>Ora inizio</Form.Label>
							<FormControl {...chiusoGiovedì ? { disabled: true } : {}} type="time" placeholder="Ora fine"></FormControl>
						</Col>
						<Col xxl="3" xl="3">
							<Form.Label>Ora fine</Form.Label>
							<FormControl {...chiusoGiovedì ? { disabled: true } : {}} type="time" placeholder="Ora fine"></FormControl>
						</Col>
						<Col xxl="2" xl="2" className="d-flex flex-column justify-content-around">
							<Form.Label className='text-white'> Aggiungi </Form.Label>
							<Button {...chiusoGiovedì ? { disabled: true } : {}} className='btn btn-dark light btn-sm rounded-3 d-flex align-items-center justify-content-center' onClick={() => handleAggiungiRigaGiovedì(1)}>Aggiungi <span className='ms-2'><AddSquare size="22" variant="Bulk" /></span></Button>
						</Col>
					</Row>
					<br />

					{disponilitàGiovedì.map((valore, index) => (

						<Fragment key={index}>
							<Row className="justify-content-end">
								<Col xxl="3" xl="3" className="align-self-end">
									<Form.Label>Ora inizio</Form.Label>
									<FormControl type="time" placeholder="Ora fine"></FormControl>
								</Col>
								<Col xxl="3" xl="3" className="align-self-end">
									<Form.Label>Ora fine</Form.Label>
									<FormControl type="time" placeholder="Ora fine"></FormControl>
								</Col>
								<Col xxl="2" xl="2" className="d-flex flex-column justify-content-around col">
									<Form.Label> </Form.Label>
									<div onClick={() => handleRimuoviRigaGiovedì(index)}><Trash size="24" color="red" variant="Bulk" /></div>
								</Col>
							</Row>
							<br />
						</Fragment>
					))}

					<Row className="align-items-end">
						<Col className="m-3">
							Venerdì
						</Col>
						<Col>
							<div className="btn-group btn-group-toggle" data-toggle="buttons">
								<label className="btn btn-sm rounded-3 m-0 btn-dark light active d-flex align-items-center">
									<Form.Check
										className="me-2 light"
										type="checkbox"
										checked={chiusoVenerdì}
										onChange={handleCheckboxChiusoVenerdì}
									/>
									Chiuso
								</label>
							</div>
						</Col>
						<Col xxl="3" xl="3">
							<Form.Label>Ora inizio</Form.Label>
							<FormControl {...chiusoVenerdì ? { disabled: true } : {}} type="time" placeholder="Ora fine"></FormControl>
						</Col>
						<Col xxl="3" xl="3">
							<Form.Label>Ora fine</Form.Label>
							<FormControl {...chiusoVenerdì ? { disabled: true } : {}} type="time" placeholder="Ora fine"></FormControl>
						</Col>
						<Col xxl="2" xl="2" className="d-flex flex-column justify-content-around">
							<Form.Label className='text-white'> Aggiungi </Form.Label>
							<Button {...chiusoVenerdì ? { disabled: true } : {}} className='btn btn-dark light btn-sm rounded-3 d-flex align-items-center justify-content-center' onClick={() => handleAggiungiRigaVenerdì(1)}>Aggiungi <span className='ms-2'><AddSquare size="22" variant="Bulk" /></span></Button>
						</Col>
					</Row>
					<br />

					{disponilitàVenerdì.map((valore, index) => (

						<Fragment key={index}>
							<Row className="justify-content-end">
								<Col xxl="3" xl="3" className="align-self-end">
									<Form.Label>Ora inizio</Form.Label>
									<FormControl type="time" placeholder="Ora fine"></FormControl>
								</Col>
								<Col xxl="3" xl="3" className="align-self-end">
									<Form.Label>Ora fine</Form.Label>
									<FormControl type="time" placeholder="Ora fine"></FormControl>
								</Col>
								<Col xxl="2" xl="2" className="d-flex flex-column justify-content-around col">
									<Form.Label> </Form.Label>
									<div onClick={() => handleRimuoviRigaVenerdì(index)}><Trash size="24" color="red" variant="Bulk" /></div>
								</Col>
							</Row>
							<br />
						</Fragment>
					))}

					<Row className="align-items-end">
						<Col className="m-3">
							Sabato
						</Col>
						<Col>
							<div className="btn-group btn-group-toggle" data-toggle="buttons">
								<label className="btn btn-sm rounded-3 m-0 btn-dark light active d-flex align-items-center">
									<Form.Check
										className="me-2 light"
										type="checkbox"
										checked={chiusoSabato}
										onChange={handleCheckboxChiusoSabato}
									/>
									Chiuso
								</label>
							</div>
						</Col>
						<Col xxl="3" xl="3">
							<Form.Label>Ora inizio</Form.Label>
							<FormControl {...chiusoSabato ? { disabled: true } : {}} type="time" placeholder="Ora fine"></FormControl>
						</Col>
						<Col xxl="3" xl="3">
							<Form.Label>Ora fine</Form.Label>
							<FormControl {...chiusoSabato ? { disabled: true } : {}} type="time" placeholder="Ora fine"></FormControl>
						</Col>
						<Col xxl="2" xl="2" className="d-flex flex-column justify-content-around">
							<Form.Label className='text-white'> Aggiungi </Form.Label>
							<Button {...chiusoSabato ? { disabled: true } : {}} className='btn btn-dark light btn-sm rounded-3 d-flex align-items-center justify-content-center' onClick={() => handleAggiungiRigaSabato(1)}>Aggiungi <span className='ms-2'><AddSquare size="22" variant="Bulk" /></span></Button>
						</Col>
					</Row>
					<br />

					{disponilitàSabato.map((valore, index) => (

						<Fragment key={index}>
							<Row className="justify-content-end">
								<Col xxl="3" xl="3" className="align-self-end">
									<Form.Label>Ora inizio</Form.Label>
									<FormControl type="time" placeholder="Ora fine"></FormControl>
								</Col>
								<Col xxl="3" xl="3" className="align-self-end">
									<Form.Label>Ora fine</Form.Label>
									<FormControl type="time" placeholder="Ora fine"></FormControl>
								</Col>
								<Col xxl="2" xl="2" className="d-flex flex-column justify-content-around col">
									<Form.Label> </Form.Label>
									<div onClick={() => handleRimuoviRigaSabato(index)}><Trash size="24" color="red" variant="Bulk" /></div>
								</Col>
							</Row>
							<br />
						</Fragment>
					))}

					<Row className="align-items-end">
						<Col className="m-3">
							Domenica
						</Col>
						<Col>
							<div className="btn-group btn-group-toggle" data-toggle="buttons">
								<label className="btn btn-sm rounded-3 m-0 btn-dark light active d-flex align-items-center">
									<Form.Check
										className="me-2 light"
										type="checkbox"
										checked={chiusoDomenica}
										onChange={handleCheckboxChiusoDomenica}
									/>
									Chiuso
								</label>
							</div>
						</Col>
						<Col xxl="3" xl="3">
							<Form.Label>Ora inizio</Form.Label>
							<FormControl {...chiusoDomenica ? { disabled: true } : {}} type="time" placeholder="Ora fine"></FormControl>
						</Col>
						<Col xxl="3" xl="3">
							<Form.Label>Ora fine</Form.Label>
							<FormControl {...chiusoDomenica ? { disabled: true } : {}} type="time" placeholder="Ora fine"></FormControl>
						</Col>
						<Col xxl="2" xl="2" className="d-flex flex-column justify-content-around">
							<Form.Label className='text-white'> Aggiungi </Form.Label>
							<Button {...chiusoDomenica ? { disabled: true } : {}} className='btn btn-dark light btn-sm rounded-3 d-flex align-items-center justify-content-center' onClick={() => handleAggiungiRigaDomenica(1)}>Aggiungi <span className='ms-2'><AddSquare size="22" variant="Bulk" /></span></Button>
						</Col>
					</Row>
					<br />

					{disponilitàDomenica.map((valore, index) => (

						<Fragment key={index}>
							<Row className="justify-content-end">
								<Col xxl="3" xl="3" className="align-self-end">
									<Form.Label>Ora inizio</Form.Label>
									<FormControl type="time" placeholder="Ora fine"></FormControl>
								</Col>
								<Col xxl="3" xl="3" className="align-self-end">
									<Form.Label>Ora fine</Form.Label>
									<FormControl type="time" placeholder="Ora fine"></FormControl>
								</Col>
								<Col xxl="2" xl="2" className="d-flex flex-column justify-content-around col">
									<Form.Label> </Form.Label>
									<div onClick={() => handleRimuoviRigaDomenica(index)}><Trash size="24" color="red" variant="Bulk" /></div>
								</Col>
							</Row>
							<br />
						</Fragment>
					))}


				</Card.Body>
			</Card>

			<div className="d-flex justify-content-end gap-4 mt-4">
				<Link to={"#"} className="btn btn-dark light mr-4">Annulla</Link> <Link to="#" className="btn btn-primary ">Aggiungi</Link>
			</div>
		</Fragment>
	)
}

export default AggiungiImpianto