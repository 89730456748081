import React from 'react';
import { SearchNormal } from 'iconsax-react';

export const GlobalFilter = ( {filter, setFilter} ) =>{
	return(
		<div className='d-flex align-items-center me-3 p-2 ps-3 pe-3' style={{background:'#eeeeee',borderRadius:'12px',height:'40px'}}>
			<input placeholder='Cerca...'
				value={filter || ''}  onChange={e => setFilter(e.target.value)} 
				style={{all:'unset', width:'120px'}}
            /> <SearchNormal size={20}/>
		</div>
	)
} 