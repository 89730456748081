import React, { useEffect, useState } from "react";
import { Row, Col, Card, Tab, Nav, Form, Button } from "react-bootstrap";
import TabellaLista from "../Tabella/TabellaLista";
import { Dropdown } from "react-bootstrap";
import { Link, useLocation } from 'react-router-dom';
import { More, User } from 'iconsax-react';
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import { format } from 'date-fns';

const ParentiJunior = (props) => {

    const location = useLocation();

    const [data_json, setDataJson] = useState(null);

    useEffect(() => {
        if (props.profile && props.profile.junior) {
            let playerData = props.profile;

            // console.log(playerData.junior);

            if (playerData.junior.parent) {
                let element = playerData.junior.parent;
                let new_data_json = [];
                
                new_data_json.push({
                    "userId": element.userId,
                    "data": format(element.updatedAt, 'dd/MM/yyyy HH:mm:ss'),
                    "nome": element.givenName,
                    "cognome": element.familyName,
                    "doc_status": 1
                });

                setDataJson(new_data_json);
            } else {
                setDataJson([]);
            }
        }
		
    }, [props]);

    const data_column = [
        {
            Header: 'Ultima modifica',
            accessor: 'data',
            Filter: ColumnFilter
        },
        {
            Header: 'Nome',
            accessor: 'nome',
            Filter: ColumnFilter,
        },
        {
            Header: 'Cognome',
            accessor: 'cognome',
            Filter: ColumnFilter,
        },
        {
            Header: 'Stato',
            accessor: 'doc_status',
            Filter: ColumnFilter,
            Cell: ({ value }) => {
                return value === 1 ? <span><i className="fa fa-circle text-success me-1"></i> Attivo</span> : <span><i className="fa fa-circle text-warning me-1"></i> Non attivo</span>
            },
            disableFilters: true,
        },
        {
            Header: 'Azione',
            accessor: 'azione',
            Cell: ({ row }) => {

                let link_to_open = '/superadmin/anagrafiche/player/'+row.original.userId;
                if(location.pathname.indexOf('struttura') > -1) {
                    link_to_open = '/struttura/clienti/player_profile/'+row.original.userId;
                }

                return (
                    <Dropdown>
                        <Dropdown.Toggle
                            className="btn-primary light sharp i-false btn-action">
                            <More />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => openDetail(link_to_open)}><span className="me-2"><User size="20" /></span> Visualizza</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            },
            disableFilters: true,
        },
    ];

    function openDetail(link_to_open) {
		window.location.href = link_to_open;
	}

    return (
        <>
            <Row>
                <Form>
                    <TabellaLista
                        cardTitle={'Lista Junior'}
                        hasSearch={true}
                        hasFilter={false}
                        hasAddMore={false}
                        data_column={data_column}
                        data_json={data_json}
                    />
                </Form>

            </Row>
        </>
    )
}
export default ParentiJunior;