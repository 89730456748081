import React, { Fragment, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Row, Col, Card, Tab, Nav, Form, Dropdown } from "react-bootstrap";
import platino from "../../../images/platino.png";
import oro from "../../../images/oro.png";
import argento from "../../../images/argento.png";
import bronzo from "../../../images/bronzo.png";
import { More, User } from 'iconsax-react';
import TabellaLista from "../Tabella/TabellaLista";
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import MyApiClient from "../../../api-client/MyApiClient";
import { format } from 'date-fns';

const TraguardiJunior = (props) => {

    const [formData, setFormData] = useState({
        season: '',
        rewards_trophies_bronze: '-',
        rewards_trophies_silver: '-',
        rewards_trophies_gold: '-',
        rewards_trophies_platinum: '-',
        rewards_points: '-',
        last_update: '-',
        my_last_goal: [],
        skills: []
    });

    const data_column = [
		{
			Header: 'Sport',
			accessor: 'sport',
			Filter: ColumnFilter
		},
		{
			Header: 'Sessione',
			accessor: 'sessione',
			Filter: ColumnFilter
		},
		{
			Header: 'Tipo trofeo',
			accessor: 'tipo',
			Cell: ({ value }) => {
				return <span><img src={bronzo} style={{ width: '10%' }} className='bd-tondo'/> {value}</span> 
			},
			Filter: ColumnFilter
		},
	];

    useEffect(() => {

        if (props.profile && props.profile.junior) {
            let playerData = props.profile;

            const fetchData = async () => {
                try {
                    const tokenDetailsString = sessionStorage.getItem('userDetails');
                    if (tokenDetailsString) {
                        const tokenDetails = JSON.parse(tokenDetailsString);
                        const token = tokenDetails.idToken;
                        const apiClient = new MyApiClient();

                        const [seasonCurrentRewards,sportsSkills] = await Promise.all([
                            apiClient.getSeasonCurrentRewards(token, playerData.junior.userId),
                            apiClient.getSportsSkills(token, playerData.junior.userId)
                        ]);

                        let my_formData = {};

                        if(seasonCurrentRewards) {
                            my_formData.rewards_points = seasonCurrentRewards.rewards.points;
                            my_formData.last_update = format(new Date(), 'dd/MM/yyyy HH:mm:ss');
                            my_formData.rewards_trophies_bronze = seasonCurrentRewards.rewards.trophies.bronze;
                            my_formData.rewards_trophies_silver = seasonCurrentRewards.rewards.trophies.silver;
                            my_formData.rewards_trophies_gold = seasonCurrentRewards.rewards.trophies.gold;
                            my_formData.rewards_trophies_platinum = seasonCurrentRewards.rewards.trophies.platinum;

                            // TODO CAPIRE
                            my_formData.my_last_goal = [];
                        }

                        if(sportsSkills && sportsSkills.length>0) {
                            let my_skills = [];
                            sportsSkills.forEach(element => {
                                my_skills.push({
                                    'percentage': element.percentageOfCompletion,
                                    'name': element.sportType
                                });
                            });
                            my_formData.skills = my_skills;
                        } else {
                            my_formData.skills = [];
                        }

                        setFormData(my_formData);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };

            fetchData();
        }

    }, [props]);
	
    return (
        <>
            <Form>
                <Row>
                <Card className="cardAuto col-xl-12 me-4">
                    <Card.Header><h4 className="card-title">Trofei Move</h4></Card.Header>
                    <Card.Body>
                        <Row className="mt-3">
                            <Col className="col-xl-6">
                                <Row>
                                <Col className="col-xl-3 text-center">
                                <img src={platino} style={{ width: '100%' }} />
                                <h4>{formData.rewards_trophies_platinum}</h4>
                                </Col>
                                <Col className="col-xl-3 text-center">
                                <img src={oro} style={{ width: '100%' }} />
                                <h4>{formData.rewards_trophies_gold}</h4>
                                </Col>
                                <Col className="col-xl-3 text-center">
                                <img src={argento} style={{ width: '100%' }} />
                                <h4>{formData.rewards_trophies_silver}</h4>
                                </Col>
                                <Col className="col-xl-3 text-center">
                                <img src={bronzo} style={{ width: '100%' }} />
                                <h4>{formData.rewards_trophies_bronze}</h4>
                                </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                
                </Row>
                <Row>
                <Card className="cardAuto col me-4">

                    <Card.Header><h4 className="card-title">Traguardi Skill</h4></Card.Header>
                    <Card.Body>
                        <Row className="mt-3">
                            {formData.skills.length==0 && <div className="mt-5 mb-5 text-center">Nessuna skill presente</div>}
                            {formData.skills.length>0 && formData.skills.map((skill, index) => (
                                <Col key={index} className="col text-center">
                                <div className="skill">{skill.percentage}</div>
                                <h5 className="text-center">{skill.name}</h5>
                                </Col>
                            ))}
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="cardAuto col">

                    <Card.Header><h4 className="card-title">Livello Move</h4></Card.Header>
                    <Card.Body>
                        <Row>
                            <Col className="col">
                                <h3 className="text-primary livelloMove">{formData.rewards_points}</h3>
                                Ultimo aggiornamento: {formData.last_update}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                </Row>
            </Form>
        </>
    )
}
export default TraguardiJunior;