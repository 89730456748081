import React, { useEffect, useState } from 'react';
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter';
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import { More, User } from 'iconsax-react';
import TabellaLista from '../../../../components/Tabella/TabellaLista';
import MyApiClient from '../../../../../api-client/MyApiClient';

export const JuniorTable = () => {

	const [loadingShowed, setLoadingShowed] = useState(false);

	const data_column = [
		{
			Header: 'Nome e cognome',
			accessor: 'name',
			Filter: ColumnFilter
		},
		{
			Header: 'Parente',
			accessor: 'family_name',
			Filter: ColumnFilter
		},
		{
			Header: 'Data di registrazione',
			accessor: 'registration_date',
			Filter: ColumnFilter
		},
		{
			Header: 'Stato',
			accessor: 'registration_state',
			Filter: ColumnFilter,
			Cell: ({ value }) => {
				return value === 1 ? <span><i className="fa fa-circle text-success me-1"></i> Attivo</span> : <span><i className="fa fa-circle text-danger me-1"></i> Disattivo</span>
			},
			disableFilters: true,
		},
		{
			Header: 'Azione',
			Footer: 'Azione',
			accessor: 'azione',
			Cell: ({ row }) => {
				return (
					<Dropdown>
						<Dropdown.Toggle className="btn-primary light sharp i-false btn-action">
							<More/>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item onClick={() => openDetail(row.original)}><span className="me-2"><User size="20" /></span> Visualizza</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				)
			},
			disableFilters: true,
		},
	];

	const [data_json, setDataJson] = useState(null);

	useEffect(() => {
        const fetchData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    let token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

					setLoadingShowed(true);
					const [customersData] = await Promise.all([
                        apiClient.getJuniors(token)
                    ]);
					setLoadingShowed(false);

					let data_customers = [];
					if(customersData) {
						customersData.forEach(element => {
							let customers_row = {
								"id": element.userId,
								"name": element.givenName
							};
							data_customers.push(customers_row);
						});
					}
					setDataJson(data_customers);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

    }, []);

	function openDetail(row) {
		window.location.href = '/struttura/junior/junior_profile/'+row.id;
	}

	return (
		<>
			<Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

			<TabellaLista
				cardTitle={'Lista Junior'}
				hasSearch={true}
				hasFilter={true}
				hasAddMore={false}
				data_column={data_column}
				data_json={data_json}
			/>
		</>
	)
}
export default JuniorTable;