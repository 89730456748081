import React, { useEffect, useState } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination, useSortBy } from 'react-table';
import { GlobalFilter } from '../table/FilteringTable/GlobalFilter';
import { Button, Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { Add, FilterSquare } from 'iconsax-react';
import './filtering.css';

export const TabellaLista = (props) => {

	const [data, setDataJson] = useState([]);
	const [columns, setColumns] = useState([]);
	const [message, setMessage] = useState('Caricamento in corso...');

	const tableInstance = useTable({
		columns,
		data,
		initialState: { pageIndex: 0 }
	}, useFilters, useGlobalFilter, useSortBy, usePagination)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance

	const { globalFilter, pageIndex } = state

	const [show, setShow] = useState(false);
	const [modalStyle, setModalStyle] = useState({
		position: 'fixed',
		margin: 0,
		top: 0,
		right: '-100%',
		height: '100%',
		transition: 'right 0.5s ease-in-out',
		visibility: 'hidden' // Inizialmente nascondi la modale
	});

	const handleShow = () => {
		setShow(true);
	};

	const handleClose = () => {
		setModalStyle(prevStyle => ({
			...prevStyle,
			right: '-100%',
			visibility: 'hidden' // Nascondi la modale
		}));
		setShow(false);
	};

	useEffect(() => {
		if (show) {
			setTimeout(() => {
				setModalStyle(prevStyle => ({
					...prevStyle,
					right: 0,
					visibility: 'visible' // Rendi visibile la modale
				}));
			}, 300);
		}
	}, [show]);

	useEffect(() => {
		setColumns(props.data_column);

		// console.log(props.cardTitle+' - '+props.data_json);

		if(props.data_json) {
			setDataJson(props.data_json);
			setMessage('Nessun dato presente');
		} else {
			setMessage('Caricamento in corso');
		}
	}, [props]);

	return (
		<>
			<div className="card">
				<div className="card-header">
					<h4 className="card-title">{props.cardTitle}</h4>
					<div className="ml-auto d-flex align-items-center">
						{props.hasSearch && <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />}
						{props.hasFilter && <Button onClick={handleShow} className="btn btn-dark light btn-sm btn-add me-3"><span className='me-2'>Filtri</span><FilterSquare variant="Bulk" /></Button>}
						{props.hasAddMore && <Link to={props.linkAddMore} className="btn btn-primary btn-sm btn-add"> <Add variant="Bulk" /><span className='ms-2'>{props.labelAddMore}</span></Link>}
						{props.hasAddMoreAsModal && <Button onClick={props.modalCallBack} className="btn btn-primary btn-sm btn-add" ><Add variant="Bulk" /><span className='ms-2'>Aggiungi</span></Button>}</div>
				</div>
				<div className="card-body">
					<div className="table-responsive" style={{ overflowX: 'hidden' }}>
						<table {...getTableProps()} className="table dataTable display">
							<thead>
								{headerGroups.map(headerGroup => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map((column, index, array) => (
											<th key={column.id} {...column.getHeaderProps(column.getSortByToggleProps())}>
												{column.render('Header')}
												{column.isSorted && index !== array.length - 1 && (
													<span className="ml-1">
														{column.isSortedDesc ? <i className="fa fa-arrow-down ms-2 fs-14" /> : <i className="fa fa-arrow-up ms-2 fs-14" />}
													</span>
												)}
											</th>
										))}
									</tr>
								))}
							</thead>
							<tbody {...getTableBodyProps()} className="">
								{page.length === 0 ? (
									<tr>
										<td colSpan={props.data_column.length} style={{ textAlign: 'center' }}>
											{message}
										</td>
									</tr>
								) : (
									page.map((row) => {
										prepareRow(row);
										return (
											<tr {...row.getRowProps()}>
												{row.cells.map((cell) => {
													return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>;
												})}
											</tr>
										);
									})
								)}
							</tbody>
						</table>

						<div className="d-flex align-items-center justify-content-between footTab">
							{!props.notPagination &&
								<>
									<span>
										Pagina {' '}
										<strong>
											{pageIndex + 1} di {pageOptions.length}
										</strong>{''}
									</span>
									<div className="text-end mb-3">
										<div className="filter-pagination  mt-3">
											<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

											<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
												Indietro
											</button>
											<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
												Avanti
											</button>
											<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
										</div>
									</div>
								</>
							}
						</div>
					</div>
				</div>
			</div>
			{show && (
				<Modal show={show} onHide={handleClose}>
					<div className="modal-dialog" style={modalStyle}>
						<div className="modal-content" style={{ height: '100%', border: 'none', borderRadius: 0 }}>
							<Modal.Header closeButton>
								<Modal.Title>Filtri</Modal.Title>
							</Modal.Header>
							<Modal.Body style={{ overflowY: 'auto' }}>
								{headerGroups.map(headerGroup => (
									<div {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map(column => (
											column.canFilter && (
												<span key={column.id} {...column.getHeaderProps()}>
													{column.render('Header')}
													{column.canFilter && column.render('Filter')}
												</span>
											)
										))}
									</div>
								))}
							</Modal.Body>
							<Modal.Footer>
								<Button variant="secondary" onClick={handleClose}>
									Chiudi
								</Button>
							</Modal.Footer>
						</div>
					</div>
				</Modal>
			)}

			{props.modalContent && props.modalContent}
		</>
	)

}
export default TabellaLista;