import React, { useEffect, useState } from 'react';
import {  Row, Col, Card, Modal, Spinner } from 'react-bootstrap';
import MyApiClient from '../../../api-client/MyApiClient';

const HomeSuperAdmin = () => {

	const [loadingShowed, setLoadingShowed] = useState(false);

	const [formData, setFormData] = useState({
        totalSalesSum: '',
        totalUsers: '',
        premiumUsers: '',
        clubs: '',
        players: '',
        juniors: '',
        gurus: ''
    });

	useEffect(() => {
        const fetchData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

					setLoadingShowed(true);
                    const [overviewData] = await Promise.all([
                        apiClient.getAdministrationSuperAdmin(token)
                    ]);
					setLoadingShowed(false);

					if(overviewData) {
						let myFormData = {
							totalSalesSum: overviewData.totalSalesSum,
							totalUsers: overviewData.totalUsers,
							premiumUsers: overviewData.premiumUsers,
							clubs: overviewData.clubs,
							players: overviewData.players,
							juniors: overviewData.juniors,
							gurus: overviewData.gurus
						};

						setFormData(myFormData);
					}
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

	return (
		<>
			<Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

			<Row>
				{/*
				<Col xl="3" xxl="4" lg="6" sm="6">
					<Card className="bg-green">
						<Card.Body>
							<h4 className="mb-1 text-white font-w300">Vendite da Strutture e Maestri</h4>
							<h2 className="text-white mb-0 ">€ 175.123,25</h2>
						</Card.Body>
					</Card>
				</Col>
				*/}

				<Col xl="4" xxl="4" lg="6" sm="6">
					<Card className="bg-green">
						<Card.Body>
							<h4 className="mb-1 text-white font-w300">Fatturato abbonamenti SportID</h4>
							<h2 className="text-white mb-0 ">€ {formData.totalSalesSum}</h2>
						</Card.Body>
					</Card>

				</Col>

				<Col xl="4" xxl="4" lg="6" sm="6">
					<Card className="bg-blue">
						<Card.Body>
							<h4 className="mb-1 text-white font-w300">Abbonamenti SMART</h4>
							<h2 className="text-white mb-0 ">{formData.totalUsers - formData.premiumUsers}</h2>
						</Card.Body>
					</Card>
				</Col>

				<Col xl="4" xxl="4" lg="6" sm="6">
					<Card className="bg-orange">
						<Card.Body>
							<h4 className="mb-1 text-white font-w300">Abbonamenti PREMIUM</h4>
							<h2 className="text-white mb-0 ">{formData.premiumUsers}</h2>
						</Card.Body>
					</Card>
				</Col>

				<Col xl="3" xxl="4" lg="6" sm="6">
					<Card>
						<Card.Body className='border-star-5-blue'>
							<h4 className="mb-1 font-w300">Strutture Sportive</h4>
							<h2 className=" mb-0 ">{formData.clubs}</h2>
						</Card.Body>
					</Card>
				</Col>
				<Col xl="3" xxl="4" lg="6" sm="6">
					<Card>
						<Card.Body className='border-star-5-blue'>
							<h4 className="mb-1 font-w300">Player</h4>
							<h2 className=" mb-0 ">{formData.players}</h2>
						</Card.Body>
					</Card>
				</Col>
				<Col xl="3" xxl="4" lg="6" sm="6">
					<Card>
						<Card.Body className='border-star-5-blue'>
							<h4 className="mb-1 font-w300">Junior</h4>
							<h2 className=" mb-0 ">{formData.juniors}</h2>
						</Card.Body>
					</Card>
				</Col>
				<Col xl="3" xxl="4" lg="6" sm="6">
					<Card>
						<Card.Body className='border-star-5-blue'>
							<h4 className="mb-1 font-w300">Maestri</h4>
							<h2 className=" mb-0 ">{formData.gurus}</h2>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			{/*
			<Row>
				<Col xxl="6" xl="6" lg="12" sm="12">
					<Card className='widget-stat'>
						<Card.Body>
							Tabella Maestri da convalidare
						</Card.Body>
					</Card>
				</Col>
			</Row>
			*/}
		</>
	)
}
export default HomeSuperAdmin;