import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, Modal, Row, Spinner } from "react-bootstrap";
import { ColumnFilter } from "../../../components/table/FilteringTable/ColumnFilter";
import { More, DocumentDownload, Edit2, Trash } from "iconsax-react";
import TabellaLista from "../../../components/Tabella/TabellaLista";
import MyApiClient from "../../../../api-client/MyApiClient";
import { format } from 'date-fns';

export const DocumentsTable = () => {

	const MAX_FILE_SIZE = 5 * 1024 * 1024;
	const ALLOWED_FILE_TYPES = ['application/pdf'];

	const [newDocName, setNewDocName] = useState('');
  	const [newDocFile, setNewDocFile] = useState(null);

	const [data_json, setDataJson] = useState(null);
	const [modalCentered, setModalCentered] = useState(false);

	const [modalShowed, setModalShowed] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalText, setModalText] = useState('');

	const [confirmShowed, setConfirmShowed] = useState(false);
	const [onContinue, setOnContinue] = useState(null);

	const [loadingShowed, setLoadingShowed] = useState(false);

	const handleTextChange = (event) => {
		setNewDocName(event.target.value);
	};

	const handleFileChange = (event) => {
		setNewDocFile(event.target.files[0]);
	};

	const downloadDoc = (row) => {
        window.open(row.uri, '_blank');
    };

	const deleteDoc = (row) => {
		setOnContinue(() => () => continueDeleteDoc(row));
		setConfirmShowed(true);
    };

	const continueDeleteDoc = (row) => {

		setConfirmShowed(false);

		const deleteData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

					setLoadingShowed(true);
                    const saveResult = await apiClient.deleteDoc(token, row.id);
					setLoadingShowed(false);

                    if(!saveResult) {
                        setModalTitle("Errore durante l'operazione");
                        setModalText("Si è verificato un errore durante la cancellazione");
                        setModalShowed(true);
                    } else {
						window.location.href = '/struttura/documenti';
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        deleteData();
	};

	const addDoc = (e) => {
        e.preventDefault();

		setModalCentered(false);

		let file = newDocFile;

		if (file) {

			if (file.size > MAX_FILE_SIZE) {
				setModalTitle("Errore durante l'operazione");
				setModalText('Il file selezionato supera la dimensione massima consentita.');
				setModalShowed(true);
				return;
			}

			if (!ALLOWED_FILE_TYPES.includes(file.type)) {
				setModalTitle("Errore durante l'operazione");
				setModalText('Il file selezionato non è di tipo PDF.');
				setModalShowed(true);
				return;
			}

			const reader = new FileReader();
			reader.onloadend = () => {
				const base64String = reader.result.split(',')[1];
				// console.log(base64String);
				continueAddDoc(newDocName,base64String);
			};
			reader.readAsDataURL(file);
		}
    };

	function continueAddDoc(newDocName,base64String) {
		//console.log(newDocName);
		//console.log(base64String);

		const addData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

					let data_insert = {
						'content': base64String,
						'name': newDocName
					}
					
					setLoadingShowed(true);
                    const saveResult = await apiClient.addDocument(token, data_insert);
					setLoadingShowed(false);

					if (saveResult && saveResult.error) {
						setModalTitle("Errore durante l'operazione");
						setModalText("Si è verificato un errore durante il salvataggio (" + saveResult.error + ")");
						setModalShowed(true);
					} else {
						window.location.href = '/struttura/documenti';
					}
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        addData();
	}

	const data_column = [
		{
			Header: 'Data caricamento',
			Footer: 'Data caricamento',
			accessor: 'data-caricamento',
			Filter: ColumnFilter
		},
		{
			Header: 'Nome documento',
			Footer: 'Nome documento',
			accessor: 'nome-documento',
			Filter: ColumnFilter
		},
		{
			Header: 'Azione',
			Footer: 'Azione',
			accessor: 'azione',
			Cell: ({ row }) => {
				return (
					<Dropdown>
						<Dropdown.Toggle className="btn-primary light sharp i-false btn-action">
							<More />
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item onClick={() => downloadDoc(row.original)} className="text-danger"><span className="me-2"><DocumentDownload size="20" /></span>Scarica</Dropdown.Item>
							<Dropdown.Item onClick={() => deleteDoc(row.original)} className="text-danger"><span className="me-2"><Trash size="20" /></span>Cancella</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				)
			},
			disableFilters: true,
		},
	];

	useEffect(() => {
        const fetchData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    let token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

					setLoadingShowed(true);
					const [documentsData] = await Promise.all([
                        apiClient.getDocuments(token)
                    ]);
					setLoadingShowed(false);

					if(documentsData) {
						let data_requests = [];
						documentsData.forEach(element => {
							let member_row = {
								"id": element.id,
								"data-caricamento": format(element.uploadedAt, 'dd/MM/yyyy HH:mm:ss'),
								"nome-documento": element.name,
								"uri": element.uri
							};
							data_requests.push(member_row);
						});
						setDataJson(data_requests);
					} else {
						setDataJson([]);
					}
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

    }, []);

	return (

		<>
			<Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

			<Modal className="fade" show={confirmShowed} centered>
                <Modal.Header>
                    <Modal.Title>Sei sicuro?</Modal.Title>
                    <Button onClick={() => setConfirmShowed(false)} variant="" className="btn-close">
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Row><Col className="col-xl-64">Sei sicuro di volere continuare?</Col></Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button className="btn btn-light btn-add" onClick={() => setConfirmShowed(false)}>
                        Chiudi
                    </Button>
					<Button className="btn btn-primary btn-add" onClick={onContinue}>
                        Continua
                    </Button>
                </Modal.Footer>
            </Modal>

			<Modal className="fade" show={modalShowed} centered>
                <Modal.Header>
                    <Modal.Title>{modalTitle}</Modal.Title>
                    <Button onClick={() => setModalShowed(false)} variant="" className="btn-close">
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Row><Col className="col-xl-64">{modalText}</Col></Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button className="btn btn-primary btn-add" onClick={() => setModalShowed(false)}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>

			<TabellaLista
				cardTitle={'Documenti'}
				hasSearch={true}
				hasFilter={true}
				hasAddMoreAsModal={true}
				modalCallBack={() => setModalCentered(true)}
				modalContent={
					<Modal className="fade" show={modalCentered} centered>
						<Modal.Header>
							<Modal.Title>Aggiungi documento</Modal.Title>
							<Button
								onClick={() => setModalCentered(false)}
								variant=""
								className="btn-close"
							>
							</Button>
						</Modal.Header>
						<Modal.Body>
							<Form>
								<Row>
									<Col className="col-lg-12 pb-4">
										<Form.Label>Nome del documento</Form.Label>
										<Form.Control type="text" placeholder="Inserisci il nome del documento" onChange={handleTextChange} />
									</Col>
								</Row>
								<Row>
									<Col className="col-lg-12 pb-4">
										<Form.Label>Seleziona file</Form.Label>
										<Form.Control type="file" placeholder="Carica il file" onChange={handleFileChange} />
									</Col>
								</Row>
							</Form>
						</Modal.Body>
						<Modal.Footer className="justify-content-between">
							<Button className="btn btn-light btn-add" onClick={() => setModalCentered(false)}>
								Annulla
							</Button>
							<Button onClick={addDoc} className="btn btn-primary btn-add">Aggiungi</Button>
						</Modal.Footer>
					</Modal>
				}
				data_column={data_column}
				data_json={data_json}
			/>
		</>
	)
}

export default DocumentsTable;
