import React from 'react';
import { Link } from 'react-router-dom';
import { ColumnFilter } from '../../../components/table/FilteringTable/ColumnFilter';
import { Dropdown } from "react-bootstrap";
import { More, Edit2, Trash } from 'iconsax-react';
import TabellaLista from '../../../components/Tabella/TabellaLista';

const StrutturaRuoli = () => {

    const data_column = [

        {
            Header: 'Ruolo',
            accessor: 'role_name',
            Filter: ColumnFilter
        },
        {
            Header: 'Permessi',
            accessor: 'role_permission',
            Filter: ColumnFilter
        },

        {
            Header: 'Azione',
            accessor: 'azione',
            Cell: ({ value }) => {
                return (
                    <Dropdown>
                        <Dropdown.Toggle
                            className="btn-primary light sharp i-false btn-action"
                        >
                            <More />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item><Link to='#'><span className="me-2"><Edit2 size="20" /></span> Modifica</Link></Dropdown.Item>
                            <br />
                            <Dropdown.Item className="text-danger"><span className="me-2"><Trash size="20" /></span>Cancella</Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                )
            },
            disableFilters: true,
        },
    ];

    const data_json = [
        {
            "id": 1,
            "role_name": "Admin",
            "role_permission": "tutti",

        },
        {
            "id": 2,
            "role_name": "Segreteria",
            "role_permission": "17",

        },
        {
            "id": 3,
            "role_name": "MKT gestore",
            "role_permission": "24",

        }
    ];
    return (
        <>
            <TabellaLista
                cardTitle={'Ruoli Gestionale'}
                hasSearch={true}
                hasFilter={true}
                hasAddMore={true}
                labelAddMore={'Nuovo ruolo'}
                linkAddMore={'/struttura/strumenti/aggiungi_ruolo'}
                data_column={data_column}
                data_json={data_json}
            />
        </>

    )
}

export default StrutturaRuoli