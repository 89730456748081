import React, { useEffect, useState } from 'react';
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter';
import { Dropdown, Modal, Spinner} from "react-bootstrap";
import { format } from 'date-fns';
import { Add, More, Edit2, Trash, User } from 'iconsax-react';
import TabellaLista from '../../../../components/Tabella/TabellaLista';
import MyApiClient from '../../../../../api-client/MyApiClient';

const sports = [
	{ value: "RUNNING", label: "Corsa" },
	{ value: "BIKING", label: "Ciclismo" },
	{ value: "TENNIS", label: "Tennis" },
	{ value: "PADEL", label: "Padel" },
	{ value: "SKIING", label: "Sci" },
	{ value: "CROSS_COUNTRY_SKIING", label: "Fondo" },
	{ value: "SNOWBOARD", label: "Snowboard" },
	{ value: "SWIMMING", label: "Nuoto" },
	{ value: "GOLF", label: "Golf" },
	{ value: "FOOTBALL", label: "Calcio" },
	{ value: "FIVE_A_SIDE_FOOTBALL", label: "Calcio a 5" },
	{ value: "VOLLEYBALL", label: "Pallavolo" },
	{ value: "BASKETBALL", label: "Basket" },
	{ value: "ICE_HOCKEY", label: "Hockey su ghiaccio" },
	{ value: "RUGBY", label: "Rugby" }
];

export const TeamsTable = () => {

	const [data_json, setDataJson] = useState(null);

	const [loadingShowed, setLoadingShowed] = useState(false);

	const data_column = [
		{
			Header : 'Team',
			Footer : 'Team',
			accessor: 'name',
			Filter: ColumnFilter,
			Cell: ({ row }) => (
				<div style={{ display: 'flex', alignItems: 'center' }}>
				  {row.original.imageUrl && <img
					src={row.original.imageUrl}
					alt={row.original.name}
					style={{
					  width: '40px',
					  height: '40px',
					  borderRadius: '50%',
					  marginRight: '10px'
					}}
				  />}
				  {row.original.name}
				</div>
			)
		},
		{
			Header : 'Staff tecnico',
			accessor: 'coach',
			Filter: ColumnFilter
		},
		{
			Header : 'Membri',
			Footer : 'Membri',
			accessor: 'members',
			Filter: ColumnFilter
		},
		{
			Header : 'Sport',
			Footer : 'Sport',
			accessor: 'sport',
			Filter: ColumnFilter
		},
		{
			Header : 'Azione',
			Footer : 'Azione',
			accessor: 'azione',
			Cell: ({ row }) => {
				return (
					<Dropdown>
					  <Dropdown.Toggle
						className="btn-primary light sharp i-false btn-action">
						<More />
					  </Dropdown.Toggle>
					  <Dropdown.Menu>
					  	<Dropdown.Item onClick={() => openDetail(row.original)}><span className="me-2"><User size="20" /></span> Visualizza</Dropdown.Item>
						{/* <Dropdown.Item className="text-danger"><span className="me-2"><Trash size="20" /></span>Cancella</Dropdown.Item> */}
					  </Dropdown.Menu>
					</Dropdown>
				)
			},
			disableFilters: true,
		},
	];

	function openDetail(row) {
		window.location.href = '/struttura/teams/edit/'+row.id;
	}

	useEffect(() => {
        const fetchData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    let token = tokenDetails.idToken;
					const clubId = tokenDetails.localId;
                    const apiClient = new MyApiClient();

					setLoadingShowed(true);
					const [teamsData] = await Promise.all([
                        apiClient.getTeams(token, clubId)
                    ]);
					setLoadingShowed(false);

					let data_teams = [];
					if(teamsData && teamsData.length > 0) {
						teamsData.forEach(element => {

							let image = null;
							if(element.logo) {
								image = element.logo;
							}

							let coach = null;
							if(element.captain && element.captain.fullName) {
								coach = element.captain.fullName;
							}

							let sport_decode = '';
							sports.forEach(element2 => {
								if(element2.value == element.sport) {
									sport_decode = element2.label;
								}
							});

							let teams_row = {
								"id": element.teamId,
								"name": element.name,
								"imageUrl": image,
								"coach": coach,
								"members": element.members?element.members.length:0,
								"sport": sport_decode
							};
							data_teams.push(teams_row);
						});
					}
					setDataJson(data_teams);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

    }, []);
	
	return(
		<>
			<Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

			<TabellaLista
				cardTitle={'Squadre'}
				hasSearch={true}
				hasFilter={true}
				hasAddMore={true}
				linkAddMore={'/struttura/teams/add'}
				labelAddMore={'Aggiungi'}
				data_column={data_column} 
				data_json={data_json} 
            />
		</>
	)
	
}
export default TeamsTable;