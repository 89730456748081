import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Tab, Nav, Form } from "react-bootstrap";

const bloodTypes = [
	{ value: "A_POSITIVE", label: "A positivo" },
	{ value: "A_NEGATIVE", label: "A negativo" },
	{ value: "B_POSITIVE", label: "B positivo" },
	{ value: "B_NEGATIVE", label: "B negativo" },
	{ value: "AB_POSITIVE", label: "AB positivo" },
	{ value: "AB_NEGATIVE", label: "AB negativo" },
	{ value: "O_POSITIVE", label: "0 positivo" },
	{ value: "O_NEGATIVE", label: "0 negativo" }
];

const foodIntollerances = [
	{ value: "GLUTEN", label: "Glutine" },
	{ value: "LACTOSE", label: "Lattosio" },
	{ value: "NUTS", label: "Noccioline" },
	{ value: "SHELLFISH", label: "Molluschi" },
	{ value: "EGGS", label: "Uova" },
	{ value: "FISH", label: "Pesce" },
	{ value: "SOY", label: "Soia" },
	{ value: "CELERY", label: "Sedano" },
    { value: "MUSTARD", label: "Mostarda" },
    { value: "SESAME", label: "Sesamo" },
    { value: "SULPHITES", label: "Solfiti" },
    { value: "LUPIN", label: "Lupini" },
    { value: "MOLLUSCS", label: "Molluschi" }
];

const DatiSanPlayer = (props) => {

    const [formData, setFormData] = useState({
        assicurato: '',
        gruppoSanguigno: '',
        intolleranzeAlimentari: '',
        traumi: '',
        farmaci: '',
        interventiMedici: '',
        malattieEreditarie: ''
    });

    useEffect(() => {
        if (props.profile && props.profile.idsPlay) {
            let playerData = props.profile;

            let formData = {};

            // console.log(playerData.idsPlay);

            formData.assicurato = playerData.idsPlay.insured ? 'Si' : 'No';
            formData.gruppoSanguigno = playerData.idsPlay.bloodType ? convertBloodType(playerData.idsPlay.bloodType) : '-';
            formData.intolleranzeAlimentari = playerData.idsPlay.foodIntollerances ? convertFoodIntollerances(playerData.idsPlay.foodIntollerances) : '-';
            formData.traumi = playerData.idsPlay.traumas ? convertTraumas(playerData.idsPlay.traumas) : '-';
            formData.farmaci = playerData.idsPlay.medicines ? convertMedicines(playerData.idsPlay.medicines) : '-';
            formData.interventiMedici = playerData.idsPlay.medicalOperations ? convertMedicalOperations(playerData.idsPlay.medicalOperations) : '-';
            formData.malattieEreditarie = playerData.idsPlay.hereditaryDiseases ? convertHereditaryDiseases(playerData.idsPlay.hereditaryDiseases) : '-';

            setFormData(formData);
        }
		
    }, [props]);

    function convertBloodType(key) {
        bloodTypes.forEach(element => {
            if(element.value==key) {
                return element.label;
            }
        });
    }

    function convertFoodIntollerances(keys) {
        let intollerances_string = '';
        keys.forEach(key => {
            foodIntollerances.forEach(element => {
                if(element.value==key) {
                    intollerances_string += element.label+'. ';
                }
            });
        });
        return intollerances_string;
    }

    function convertTraumas(keys) {
        let traumas_string = '';
        keys.forEach(element => {
            traumas_string += element+'. ';
        });
        return traumas_string;
    }

    function convertMedicines(keys) {
        let medicines_string = '';
        keys.forEach(element => {
            medicines_string += element+'. ';
        });
        return medicines_string;
    }

    function convertMedicalOperations(keys) {
        let medicalOp_string = '';
        keys.forEach(element => {
            medicalOp_string += element+'. ';
        });
        return medicalOp_string;
    }

    function convertHereditaryDiseases(keys) {
        let herDiseases_string = '';
        keys.forEach(element => {
            herDiseases_string += element+'. ';
        });
        return herDiseases_string;
    }

    return (
        <>
            <Row>

                <Form>
                    <Card className="cardAuto">

                        <Card.Header><h4 className="card-title">Informazioni generiche</h4></Card.Header>
                        <Card.Body>
                            <Row className="mt-3">
                                <Col className="col-xl-3">
                                    <Form.Label>Assicurato</Form.Label>
                                    <Form.Control type="text" value={formData.assicurato} disabled />
                                </Col>
                                <Col className="col-xl-3">
                                    <Form.Label>Gruppo sanguigno</Form.Label>
                                    <Form.Control type="text" value={formData.gruppoSanguigno} disabled />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className="cardAuto">

                        <Card.Header><h4 className="card-title">Intolleranze alimentari</h4></Card.Header>
                        <Card.Body>
                            <Row className="mt-3">
                                <Col className="col">
                                    <Form.Control type="text" value={formData.intolleranzeAlimentari} disabled />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className="cardAuto">

                        <Card.Header><h4 className="card-title">Traumi</h4></Card.Header>
                        <Card.Body>
                            <Row className="mt-3">
                                <Col className="col">
                                    <Form.Control type="text" value={formData.traumi} disabled />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className="cardAuto">

                        <Card.Header><h4 className="card-title">Farmaci</h4></Card.Header>
                        <Card.Body>
                            <Row className="mt-3">
                                <Col className="col">
                                    <Form.Control type="text" value={formData.farmaci} disabled />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className="cardAuto">

                        <Card.Header><h4 className="card-title">Interventi medici</h4></Card.Header>
                        <Card.Body>
                            <Row className="mt-3">
                                <Col className="col">
                                    <Form.Control type="text" value={formData.interventiMedici} disabled />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className="cardAuto">

                        <Card.Header><h4 className="card-title">Malattie eriditarie</h4></Card.Header>
                        <Card.Body>
                            <Row className="mt-3">
                                <Col className="col">
                                    <Form.Control type="text" value={formData.malattieEreditarie} disabled />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Form>

            </Row>
        </>
    )
}
export default DatiSanPlayer;