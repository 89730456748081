import React, { Fragment, useState } from 'react'
import { Row, Col, Card, Collapse, Form, FormCheck, Button } from 'react-bootstrap'
import Select from 'react-select'
import { ArrowDown2, FilterSquare } from 'iconsax-react'
import GraficoAbbonamentiAttivi from '../../../components/SuperAdmin/Dashboard/graficoAbbonamentiAttivi'


const ReportSportID = () => {

    const options = [
        { value: "lorem-ipsum", label: "Lorem Ipsum" },
        { value: "lorem-ipsum", label: "Lorem Ipsum" },
        { value: "lorem-ipsum", label: "Lorem Ipsum" },
    ];

    const [selectedOption, setSelectedOption] = useState({ label: "Seleziona...", value: null });

    const [isConfronto, setisConfronto] = useState(false);

    const handleCheckboxConfronto = () => {
        setisConfronto(!isConfronto);
    };

    const [isOpen, setIsOpen] = React.useState(false);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Fragment>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <div className='d-flex align-items-center justify-content-between' onClick={toggleCollapse}>
                                <Card.Title as="h4">Filtri</Card.Title>
                                <ArrowDown2 size="32" variant="Bulk" />
                            </div>
                            <Collapse in={isOpen}>
                                <Form>
                                    <Row>
                                        <Col xxl="4" xl="4" lg="4">
                                            <Form.Label>Periodo</Form.Label>
                                            <Select
                                                defaultValue={selectedOption}
                                                onChange={setSelectedOption}
                                                options={options}
                                            />
                                            <br />
                                        </Col>

                                        <Col xxl="4" xl="4" lg="4">
                                            <Form.Label>Dalla data</Form.Label>
                                            <Form.Control type="date" />
                                            <br />
                                        </Col>

                                        <Col xxl="4" xl="4" lg="4">
                                            <Form.Label>Alla data</Form.Label>
                                            <Form.Control type="date" />
                                            <br />
                                        </Col>

                                        <Col xxl="12">
                                            <Form.Group controlId="confronto">
                                                <FormCheck
                                                    className="d-flex"
                                                    type="checkbox"
                                                    label="Questo prodotto ha una scadenza."
                                                    checked={isConfronto}
                                                    onChange={handleCheckboxConfronto} />
                                            </Form.Group>
                                            <br/>
                                        </Col>

                                        {isConfronto &&

                                            <Fragment>

                                                <Col xxl="4" xl="4" lg="4">
                                                    <Form.Label>Periodo</Form.Label>
                                                    <Select
                                                        defaultValue={selectedOption}
                                                        onChange={setSelectedOption}
                                                        options={options}
                                                    />
                                                    <br />
                                                </Col>

                                                <Col xxl="4" xl="4" lg="4">
                                                    <Form.Label>Dalla data</Form.Label>
                                                    <Form.Control type="date" />
                                                    <br />
                                                </Col>

                                                <Col xxl="4" xl="4" lg="4">
                                                    <Form.Label>Alla data</Form.Label>
                                                    <Form.Control type="date" />
                                                    <br />
                                                </Col>

                                            </Fragment>
                                        }

                                        <Col xxl="12">
                                            <Button variant="primary btn-block rounded-3" size="sm"> <FilterSquare size="20" variant="Bulk"/> Applica filtro </Button>
                                        </Col>

                                    </Row>
                                </Form>
                            </Collapse>
                        </Card.Body>

                    </Card>
                </Col>
            </Row>

            <Row>
                <Col xl="3" xxl="4" lg="6" sm="6">
                    <Card>
                        <Card.Body className='border-start-5-success'>
                            <h4 className="mb-1">Strutture Sportive</h4>
                            <h2 className=" mb-0 ">3043</h2>
                            <small><b className='text-danger'>-25</b> dal periodo precendete</small>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl="3" xxl="4" lg="6" sm="6">
                    <Card>
                        <Card.Body className='border-start-5-cyan'>
                            <h4 className="mb-1">Player</h4>
                            <h2 className=" mb-0 ">3043</h2>
                            <small><b className='text-success'>+25</b> dal periodo precendete</small>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl="3" xxl="4" lg="6" sm="6">
                    <Card>
                        <Card.Body className='border-start-5-gray'>
                            <h4 className="mb-1">Junior</h4>
                            <h2 className=" mb-0 ">3043</h2>
                            <small><b className='text-success'>+25</b> dal periodo precendete</small>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl="3" xxl="4" lg="6" sm="6">
                    <Card>
                        <Card.Body className='border-start-5-orange'>
                            <h4 className="mb-1">Maestri</h4>
                            <h2 className=" mb-0 ">3043</h2>
                            <small><b className='text-success'>+25</b> dal periodo precendete</small>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col xxl="6" xl="6" lg="12" sm="12">
                    <Card>
                        <Card.Header>
                            <Card.Title as="h4">Iscritti Smart</Card.Title>
                        </Card.Header>
                        <Card.Body>

                            <Row>
                                <Col xxl="12" xl="12" lg="12" sm="12">
                                    <h6 className='mb-0'>Nr totale</h6>
                                    <h2 className='mb-0'>3043</h2>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>
                                    <hr />
                                </Col>
                                <Col xxl="4" xl="4" lg="4" md="4" sm="4">
                                    <h5 className='mb-0'>Player</h5>
                                    <span className='d-flex align-items-center'><h4 className='mb-0 font-w300'>3043</h4><small className='text-danger font-w700 ms-1'>+25</small></span>

                                </Col>
                                <Col xxl="4" xl="4" lg="4" md="4" sm="4">
                                    <h5 className='mb-0'>Junior</h5>
                                    <span className='d-flex align-items-center'><h4 className='mb-0 font-w300'>3043</h4><small className='text-danger font-w700 ms-1'>+25</small></span>

                                </Col>
                                <Col xxl="4" xl="4" lg="4" md="4" sm="4">
                                    <h5 className='mb-0'>Maestri</h5>
                                    <span className='d-flex align-items-center'><h4 className='mb-0 font-w300'>3043</h4><small className='text-danger font-w700 ms-1'>+25</small></span>

                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
                <Col xxl="6" xl="6" lg="12" sm="12">
                    <Card>
                        <Card.Header>
                            <Card.Title as="h4">Iscritti Smart</Card.Title>
                        </Card.Header>
                        <Card.Body>

                            <Row>
                                <Col xxl="6" xl="6" lg="6" md="6" sm="12">
                                    <h6 className='mb-0'>Nr totale</h6>
                                    <h2 className='mb-0'>3043</h2>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>
                                    <hr />
                                </Col>
                                <Col xxl="6" xl="6" lg="6" md="6" sm="12" className='text-md-end'>
                                    <h6 className='mb-0'>Fatturato</h6>
                                    <h2 className='mb-0'>€ 30.043,00</h2>
                                    <small><b className='text-danger'>-2.542,00</b> dal periodo precendete</small>
                                    <hr />
                                </Col>

                                <Col xxl="6" xl="6" lg="6" md="6" sm="6">
                                    <h5 className='mb-0'>Player</h5>
                                    <span className='d-flex align-items-center'><h4 className='mb-0 font-w300'>3043</h4><small className='text-danger font-w700 ms-1'>+25</small></span>
                                    <span className='d-flex align-items-center'><h4 className='mb-0 font-w300'>€ 9.043,00</h4><small className='text-danger font-w700 ms-1'>+25</small></span>

                                </Col>
                                <Col xxl="6" xl="6" lg="6" md="6" sm="6">
                                    <h5 className='mb-0'>Junior</h5>
                                    <span className='d-flex align-items-center'><h4 className='mb-0 font-w300'>3043</h4><small className='text-danger font-w700 ms-1'>+25</small></span>
                                    <span className='d-flex align-items-center'><h4 className='mb-0 font-w300'>€ 9.043,00</h4><small className='text-danger font-w700 ms-1'>+25</small></span>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h4">Abbonamenti attivati nel periodo selezionato</Card.Title>
                        </Card.Header>

                        <Card.Body>
                            <GraficoAbbonamentiAttivi />
                        </Card.Body>

                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h4">Conversioni da Iscritti SMART a abbonamenti PREMIUM</Card.Title>
                        </Card.Header>

                        <Card.Body>
                            <GraficoAbbonamentiAttivi />
                        </Card.Body>

                    </Card>
                </Col>
            </Row>

        </Fragment>
    );
};

export default ReportSportID