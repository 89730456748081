import React, { Fragment, useState } from "react";
import { Form, Row, Col, InputGroup, FormCheck } from "react-bootstrap";
import Select from "react-select";
import { Trash, AddSquare } from "iconsax-react";

const FormProdottoBundle = () => {

    const options = [
        { value: "lorem-ipsum", label: "Lorem Ipsum" },
        { value: "lorem-ipsum", label: "Lorem Ipsum" },
        { value: "lorem-ipsum", label: "Lorem Ipsum" },
    ];

    const [selectedOption, setSelectedOption] = useState({ label: "Seleziona...", value: null });

    const [data, setData] = useState([]);

    const handleAggiungiRiga = () => {
        const nextNumber = Math.max(...data) + 1;
        setData([...data, nextNumber]);
    }
    const handleRimuoviRiga = (i) => {
        const deleteVal = [...data]
        deleteVal.splice(i, 1)
        setData(deleteVal)
    }

    return (
        <Fragment>
            <Row>
                <Col>
                    <Form.Label>Strutture Prenotabili</Form.Label>
                    <div className="bg-body p-4">
                        {data.map((val, i) => (
                            <Row className="pb-4">
                                <Col>
                                    <Row>
                                        <Col xxl="3" xl="3" lg="3" md="3">
                                            <Form.Label>Struttura</Form.Label>
                                            <Select
                                                defaultValue={selectedOption}
                                                onChange={setSelectedOption}
                                                options={options}
                                                style={{
                                                    lineHeight: "40px",
                                                    color: "#7e7e7e",
                                                    paddingLeft: " 15px",
                                                }}
                                            />
                                        </Col>
                                        <Col xxl="3" xl="3" lg="3" md="3">
                                            <Form.Label>Impianto</Form.Label>
                                            <Select
                                                defaultValue={selectedOption}
                                                onChange={setSelectedOption}
                                                options={options}
                                                style={{
                                                    lineHeight: "40px",
                                                    color: "#7e7e7e",
                                                    paddingLeft: " 15px",
                                                }}
                                            />
                                        </Col>
                                        <Col xxl="3" xl="3" lg="3" md="3">
                                            <Form.Label>Prezzo</Form.Label>
                                            <InputGroup>
                                                <Form.Control disabled="true" type="number" min="5" defaultValue={9} placeholder="Inserisci la percentuale"></Form.Control>
                                                <span className="input-group-text">€</span>
                                            </InputGroup>
                                        </Col>
                                        <Col xxl="3" xl="3" lg="3" md="3">
                                            <Form.Label>Slot (in ore)</Form.Label>
                                            <Form.Control disabled="true" defaultValue="1" />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="d-flex flex-column justify-content-around col-auto ">
                                    <Form.Label></Form.Label>
                                    <div onClick={() => handleRimuoviRiga(i)}><Trash size="32" color="red" variant="Bulk" /></div>
                                </Col>
                            </Row>
                        ))}

                        <div className="bg-white text-center p-1" onClick={handleAggiungiRiga}>Aggiungi  <AddSquare size="24" color="#737B8B" variant="Bulk" /></div>

                    </div>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Form.Label>Dettagli tua prestazione</Form.Label>
                    <div className="bg-body p-4">
                        <Row>
                            <Col className="col-lg-4 col-12">
                                <Form.Label>Prezzo</Form.Label>
                                <InputGroup>
                                    <Form.Control type="number" min="1" placeholder="Inserisci il tuo prezzo"></Form.Control>
                                    <span className="input-group-text">€</span>
                                </InputGroup>
                            </Col>
                            <Col className="col-lg-4 col-12">
                                <Form.Label>Slot (in ore)</Form.Label>
                                <Form.Control type="number" min="1" placeholder="Inserisci il slot"></Form.Control>

                            </Col>
                            <Col className="col-lg-4 col-12">
                                <Form.Label>Percentuale pagamento in Fitcoin</Form.Label>
                                <InputGroup>
                                    <Form.Control type="number" min="5" placeholder="Inserisci la percentuale"></Form.Control>
                                    <span className="input-group-text">%</span>
                                </InputGroup>

                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <br />

            <Row>
                <Col>
                    <Form.Group controlId="prodottoConListaAttesa">
                        <FormCheck className="d-flex" type="checkbox" label="Prodotto con lista di attesa aperta" />
                    </Form.Group>
                </Col>
            </Row>
        </Fragment>
    )

}

export default FormProdottoBundle