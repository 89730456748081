import { Link } from 'react-router-dom';
import React from 'react';
import { Add } from 'iconsax-react';
import EventCalendar from "./../../../../components/AppsMenu/Calendar/EventCalendar";
export const StrutturaCalendarioEventi = () => {


	
	return(
		<>

        <div className="card">
				<div className="card-header">
					<h4 className="card-title">Calendario Eventi</h4>
                    
				</div>
			<div className="card-body">
            <EventCalendar />
            </div>
        </div>
		
		</>
	)
	
}
export default StrutturaCalendarioEventi;