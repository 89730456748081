import React, { Fragment, useState } from 'react';
import { Form, FormCheck, Row, Col, Card } from "react-bootstrap";
import Select from "react-select";
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "react-widgets/styles.css";

export const AggiungiComunicazioneGenerica = () => {

    const options = [
        { value: "Strutture", label: "Strutture" },
        { value: "Maestri", label: "Mestri" },
        { value: "Player", label: "Player" },
        { value: "CDA", label: "CdA" },
        { value: "Soci", label: "Soci" },
        { value: "Teams", label: "Teams" },
    ];
    
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    return (
        <Fragment>
            <Card>
                <Card.Header as="h4" className="card-title">Crea una nuova comunicazione</Card.Header>
                <Card.Body>
                    <Form>
                        <Row>
                            <Col xxl="6" xl="6" lg="6">
                                <Form.Label>Titolo della comunicazione</Form.Label>
                                <Form.Control type="text" placeholder="Inserisci titolo" />
                                <br/>
                            </Col>
                            <Col xxl="6" xl="6" lg="6">
                                <Form.Label>Seleziona tipologia destinatario</Form.Label>
                                <Select
                                    defaultValue={""}
                                    options={options}
                                    isMulti
                                    style={{
                                        lineHeight: "40px",
                                        color: "#fd7e14",
                                        paddingLeft: " 15px",
                                    }
                                    }
                                />
                                <br/>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Group controlId="prodottoConListaAttesa">
                                <FormCheck
                                    className="d-flex"
                                    type="checkbox"
                                    label="Programma l'invio"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                    value="" />
                            </Form.Group>
                        </Row>
                        {isChecked &&
                            <Row>
                                <Col>
                                    <Form.Label>Seleziona data</Form.Label>
                                    <Form.Control type="date"></Form.Control>
                                </Col>
                                <Col>
                                    <Form.Label>Seleziona ora</Form.Label>
                                    <Form.Control type="time"></Form.Control>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Form.Label className="mt-4">Contenuto comunicazione</Form.Label>
                            <div className="custom-ekeditor">
                                {/* <h2>Using CKEditor 5 build in React</h2> */}
                                <CKEditor
                                    editor={ClassicEditor}
                                    // data="<p>Hello from CKEditor 5!</p>"
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        console.log({ event, editor, data });
                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        console.log('Focus.', editor);
                                    }}
                                />
                            </div>
                        </Row>
                        <div className="d-flex justify-content-end gap-4 mt-4">
                            <Link to={"#"} className="btn btn-dark light mr-4">Annulla</Link> <Link to={"#"} className="btn btn-dark light mr-4">Salva bozza</Link>	<Link to="#" className="btn btn-primary ">Invia</Link>

                        </div>

                    </Form>
                </Card.Body>
            </Card>

        </Fragment>
    )

}
export default AggiungiComunicazioneGenerica;