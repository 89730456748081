import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Tab, Nav, Modal, Spinner } from "react-bootstrap";
import { Edit, Profile, TableDocument, DocumentText, Video, Star, Camera, Note, ArrangeHorizontal, Add, Calendar, Information} from "iconsax-react";
import { Link, useLocation, useParams } from 'react-router-dom';
import FormIDSMaestro from "../../../../components/Maestri/profilo/formIDSMaestro";
import VideoMaestro from "../../../../components/Maestri/profilo/videoMaestro";
import SportMaestro from "../../../../components/Maestri/profilo/sportMaestro";
import NoteMaestro from "../../../../components/Maestri/profilo/noteMaestro";
import FeedbackMaestro from "../../../../components/Maestri/profilo/feedbackMaestro";
import MyApiClient from "../../../../../api-client/MyApiClient";
import DynamicLogo from "../../../DynamicLogoLoader";
import DEFAULT_USER from "../../../../../images/DEFAULT_USER.png";

const MaestroEdit = () => {

	const { id } = useParams();

	const [loadingShowed, setLoadingShowed] = useState(false);

	const [profile, setProfile] = useState(null);
	const [labelNome, setLabelNome] = useState('');
	
    const tabData = [
		{
			name: "Informazioni",
			icon: <TableDocument size='18' variant='Bulk' />,
			content: <FormIDSMaestro isDisabled={true} profile={profile} />,
		},
		{
			name: "Video di presentazione",
			icon: <Video size='18'  />,
			content: <VideoMaestro profile={profile}/>,
		},
		{
			name: "Sport",
			icon: <DocumentText size='18' />,
			content:<SportMaestro profile={profile}/>,
		},
		{
			name: "Feedback",
			icon: <Star size='18' />,
			content:<FeedbackMaestro profile={profile}/>,
		}/*,
		{
			name: "Note private",
			icon: <Note size='18' variant="Bulk" />,
			content:<NoteMaestro/>,
		},*/
	];

	const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [defaultTab, setDefaultTab] = useState(query.get('tab') || tabData[0].name.toLowerCase());

    useEffect(() => {
        const tab = query.get('tab');
		if (tab && tab !== defaultTab) {
            setDefaultTab(tab);
        }
    }, [location.search, defaultTab]);

	useEffect(() => {
        const fetchData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

					setLoadingShowed(true);
                    const [profileData] = await Promise.all([
                        apiClient.getProfiles(token, id)
                    ]);
					setLoadingShowed(false);

					let labelNomeTemp = '';
					if(profileData.idsGuru) {
						labelNomeTemp = profileData.idsGuru.givenName+' '+profileData.idsGuru.familyName;
					}
					setLabelNome(labelNomeTemp);

					setProfile(profileData);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

	const isNotCompleted = profile && profile.idsGuru.profileStatus != 'COMPLETED' && profile.idsGuru.profileStatus != 'ACTIVE';

	return (
		<>
			<Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

			<div className="custom-tab-1 mtnegativo2">
				<Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
					<Nav as="ul" className="nav-tabs justify-content-center" style={{ border: 'none' }}>
						{tabData.map((data, i) => (
							<Nav.Item as="li" key={i} className="tabMenu">
								<Nav.Link eventKey={data.name.toLowerCase()}>
									{data.icon}{data.name}
								</Nav.Link>
							</Nav.Item>
						))}
					</Nav>
					<Row className="align-items-start mt-4">
						<div className="col-xl-9">
							<Tab.Content>
								{tabData.map((data, i) => (
									<Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
										{data.content}
									</Tab.Pane>
								))}
							</Tab.Content>
						</div>
						<div className="col-xl-3">
							<Card>
								<Card.Body className="text-center">
									{profile && profile.idsGuru.picture && <DynamicLogo logoUrl={profile ? profile.idsGuru.picture : ''} altText="Logo Maestro" />}
									{(!profile || !profile.idsGuru.picture) && <img src={DEFAULT_USER} style={{width: '100%'}} altText="Logo Maestro" />}
									<h4 className="text-start mb-0 mt-4 text-warning">{labelNome}</h4>
									<p className="text-start mt-0 pt-0">Staff Tecnico</p>
									{profile && profile.idsPlay && <Link to={'/struttura/clienti/player_profile/'+profile.idsPlay} className="btn btn-sm btn-booking"><ArrangeHorizontal variant="Bulk" /><span className='ms-2'>Visualizza profilo player</span></Link>}
									
									{isNotCompleted && (
									<Link to={'#'} className="btn btn-sm btn-profile mt-2">
										<Information variant="Bulk" /><span className='ms-2'>Profilo non completato</span>
									</Link>)}
								</Card.Body>
							</Card>
						</div>
					</Row>
				</Tab.Container>
			</div>
		</>
	)
}
export default MaestroEdit;