import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Tab, Nav, Form } from "react-bootstrap";
import { Link } from 'react-router-dom';

const langOptions = [
    { value: "ar", label: "Arabo" },
    { value: "de", label: "Tedesco" },
    { value: "en", label: "Inglese" },
    { value: "it", label: "Italiano" },
    { value: "es", label: "Spagnolo" },
    { value: "fr", label: "Francese" },
    { value: "ru", label: "Russo" }
];

const IdsJunior = (props) => {

    const [formData, setFormData] = useState({
        givenName: '',
        familyName: '',
        username: '',
        email: '',
        phone: '',
        birthdate: '',
        gender: '',
        nationality: '',
        languages: [],
        languagesLabel: '',
        job: '',
        fiscalCode: '',
        description: '',
        streetAddress: '',
        locality: '',
        country: '',
        postalCode: '',
        province: '',
        profileStatus: ''
    });

    useEffect(() => {
        if (props.profile && props.profile.junior) {
            let playerData = props.profile;

            if(playerData && playerData.junior) {
                let myFormData = {};

                // console.log(playerData.junior);

                myFormData.givenName = playerData.junior.givenName;
                myFormData.familyName = playerData.junior.familyName;
                myFormData.username = playerData.junior.username;
                myFormData.email = playerData.junior.email ? playerData.junior.email : '';
                myFormData.phone = playerData.junior.phone ? playerData.junior.phone : '';
                myFormData.birthdate = playerData.junior.birthdate ? playerData.junior.birthdate : '';
                myFormData.gender = playerData.junior.gender ? playerData.junior.gender.substring(0,1) : '';
                myFormData.nationality = playerData.junior.nationality ? playerData.junior.nationality : '';
                myFormData.languages = playerData.junior.spokenLocales ? playerData.junior.spokenLocales : [];
                myFormData.job = playerData.junior.profession ? playerData.junior.profession : '';
                myFormData.fiscalCode = playerData.junior.fiscalCode ? playerData.junior.fiscalCode : '';
                myFormData.description = playerData.junior.description ? playerData.junior.description : '';
                myFormData.streetAddress = playerData.junior.address ? playerData.junior.address.streetAddress : '';
                myFormData.locality = playerData.junior.address ? playerData.junior.address.locality : '';
                myFormData.country = playerData.junior.address ? playerData.junior.address.country : '';
                myFormData.postalCode = playerData.junior.address ? playerData.junior.address.postalCode : '';
                myFormData.province = playerData.junior.address ? playerData.junior.address.province : '';
                myFormData.profileStatus = playerData.junior.profileStatus ? playerData.junior.profileStatus : '';

                if(myFormData.languages) {
                    let languagesLabelTemp = '';
                    myFormData.languages.forEach(element => {
                        langOptions.forEach(element2 => {
                            if(element == element2.value) {
                                languagesLabelTemp += element2.label+'. ';
                            }
                        });
                    });
                    myFormData.languagesLabel = languagesLabelTemp;
                }
                
                setFormData(myFormData);
            }
        }
		
    }, [props]);

    return (
        <>
            <Row>
            <Form>
                <Card>
                    
                    <Card.Header><h4 className="card-title">IDS</h4></Card.Header>
                    <Card.Body>
                       
                            <Row className="mt-3">
                                <Col className="col">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control type="text" value={formData.givenName} {...props.isDisabled && { disabled: true }} />
                                </Col>
                                <Col className="col">
                                    <Form.Label>Cognome</Form.Label>
                                    <Form.Control type="text" value={formData.familyName} {...props.isDisabled && { disabled: true }} />
                                </Col>
                                <Col className="col">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" value={formData.email} {...props.isDisabled && { disabled: true }} />
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className="col">
                                    <Form.Label>Data di nascita</Form.Label>
                                    <Form.Control type="text" value={formData.birthdate} {...props.isDisabled && { disabled: true }} />
                                </Col>
                                <Col className="col">
                                    <Form.Label>Sesso</Form.Label>
                                    <Form.Control type="text" value={formData.gender} {...props.isDisabled && { disabled: true }} />
                                </Col>
                                <Col className="col">
                                    <Form.Label>Nazionalità</Form.Label>
                                    <Form.Control type="text" value={formData.nationality} {...props.isDisabled && { disabled: true }} />
                                </Col>
                               </Row>
                            <Row className="mt-4">
                                <Col className="col">
                                    <Form.Label>Lingue parlate</Form.Label>
                                    {
                                        props.isDisabled && <Form.Control type="text" value={formData.languagesLabel} {...props.isDisabled && { disabled: true }} />
                                    }
                                </Col>
                            </Row>
                            <Row className="mt-4">
                            <Form.Label>Descrizione</Form.Label>
                            <Form.Control as="textarea" value={formData.description} {...props.isDisabled && { disabled: true }}/>
                            </Row>
                       
                    </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h4 className="card-title">Indirizzo</h4></Card.Header>
                    <Card.Body>
                       
                            <Row className="mt-3">
                                <Col className="col">
                                    <Form.Label>Via</Form.Label>
                                    <Form.Control type="text" value={formData.streetAddress} {...props.isDisabled && { disabled: true }} />
                                </Col>
                                <Col className="col">
                                    <Form.Label>Località</Form.Label>
                                    <Form.Control type="text" value={formData.locality} {...props.isDisabled && { disabled: true }} />
                                </Col>
                                <Col className="col">
                                    <Form.Label>Provincia</Form.Label>
                                    <Form.Control type="email" value={formData.province} {...props.isDisabled && { disabled: true }} />
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className="col-xl-4">
                                    <Form.Label>CAP</Form.Label>
                                    <Form.Control type="text" value={formData.postalCode} {...props.isDisabled && { disabled: true }} />
                                </Col>
                                <Col className="col-xl-4">
                                    <Form.Label>Paese</Form.Label>
                                    <Form.Control type="text" value={formData.country} {...props.isDisabled && { disabled: true }} />
                                </Col>
                            </Row>
                            </Card.Body>
                  
                </Card>
                </Form>
            </Row>
            {!props.isDisabled && <div className="d-flex justify-content-end gap-4"><Link to="#" className="btn btn-primary ">Salva</Link></div> }
        </>
    )
}
export default IdsJunior;