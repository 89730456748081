import MyApiClient from '../../api-client/MyApiClient';

import {
    formatError,
    runLogoutTimer,
    saveTokenInsessionStorage,
    saveUserTypeInsessionStorage,
} from '../../services/AuthService';

export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

export function Logout(navigate) {
	sessionStorage.removeItem('userDetails');
    sessionStorage.removeItem('userType');
    navigate('/login');
	return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(username, password, navigate) {
    return async (dispatch) => {
        
        let myApiClient = new MyApiClient();

        try {
            
            let response = await myApiClient.login(username, password);
            
            let my_token = response.authorization;
            let access_token = response.accessToken;
            let userId = response.userId;

            let response_profile = await myApiClient.getProfile(my_token);
            
            let userType = '';
            let userDisplayName = '';
            if(response_profile && response_profile.superAdmin /* && response_profile.superAdmin.profileStatus!=='INACTIVE' */) {
                userType = 'superadmin';
                userDisplayName = response_profile.superAdmin.name;
            } else if(response_profile && response_profile.club && response_profile.club.profileStatus!=='INACTIVE') {
                userType = 'struttura';
                userDisplayName = response_profile.club.name;
            } else if(response_profile && response_profile.idsGuru && response_profile.idsGuru.profileStatus!=='INACTIVE') {
                userType = 'maestro';
                userDisplayName = response_profile.idsGuru.givenName;
            } else {
                const errorMessage = formatError({error: {message: 'USER_DISABLED' }});
                dispatch(loginFailedAction(errorMessage));
                return;
            }

            let response_formatted = {};
            response_formatted.kink = "";
            response_formatted.localId = userId;
            response_formatted.email = username;
            response_formatted.displayName = userDisplayName;
            response_formatted.idToken = my_token;
            response_formatted.accessToken = access_token;
            response_formatted.expiresIn = 604800;
            response = response_formatted;

            saveTokenInsessionStorage(response);
            saveUserTypeInsessionStorage(userType);

            runLogoutTimer(
                dispatch,
                response.expiresIn * 1000,
                navigate,
            );

            dispatch(loginConfirmedAction(response));

            navigate('/'+userType+'/dashboard');
            return;

        } catch (error) {
            const errorMessage = formatError({error: {message: 'EMAIL_NOT_FOUND' }});
            dispatch(loginFailedAction(errorMessage));
        }
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
