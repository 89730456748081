import React, { Fragment, useState,useReducer, useEffect } from "react";
import TabellaLista from "../../Tabella/TabellaLista";
import { ColumnFilter } from "../../table/FilteringTable/ColumnFilter";
import MyApiClient from "../../../../api-client/MyApiClient";

const feedbacks = [
	{ value: "TECHNICAL_DEMONSTRATION", label: "Dimostrazione tecnica" },
	{ value: "EXPLANATION", label: "Spiegazione" },
	{ value: "EFFICIENCY", label: "Efficienza" },
	{ value: "EMPATHY", label: "Empatia" },
	{ value: "FUN", label: "Divertimento" },
	{ value: "CUSTOMER_SERVICE", label: "Servizio clienti" },
	{ value: "TIDINESS_AND_CLEANLINESS", label: "Ordine e pulizia" }
];

const FeedbackMaestro = (props) => {

	const [data_json, setDataJson] = useState(null);

	useEffect(() => {

		if (props.profile && props.profile.idsGuru) {
            let playerData = props.profile;

            if(playerData && playerData.idsGuru) {

				const fetchData = async () => {
					try {
						const tokenDetailsString = sessionStorage.getItem('userDetails');
						if (tokenDetailsString) {
							const tokenDetails = JSON.parse(tokenDetailsString);
							const token = tokenDetails.idToken;
							const apiClient = new MyApiClient();

							let [reviewsData] = await Promise.all([
								apiClient.getProfileReviews(token, playerData.idsGuru.userId)
							]);

							// TODO RIMUOVERE
                			// testerdata:
							/*
							reviewsData = {
								"average": 9.5,
								"reviewsCount": 0,
								"parameters": [
									{
										"type": "TECHNICAL_DEMONSTRATION",
										"average": 6
									},
									{
										"type": "CUSTOMER_SERVICE",
										"average": 9
									}
								],
								"staffAverage": 9.5
							};
							*/

							let data_json_reviews = [];
							if(reviewsData && reviewsData.parameters) {
								reviewsData.parameters.forEach(element => {
									let type_code = element.type;
									let stars = element.average / 2;

									let type_desc = '';
									feedbacks.forEach(element2 => {
										if(type_code == element2.value) {
											type_desc = element2.label;
										}
									});

									let new_obj = {
										'type': type_desc,
										'stars': stars
									};

									data_json_reviews.push(new_obj);
								});
							}
							setDataJson(data_json_reviews);
						}
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};

				fetchData();
			}
		}
    }, [props]);

	const StarRating = ({ value }) => {
		
		const fullStars = Math.floor(value);
		const halfStars = value % 1 !== 0 ? 1 : 0;
		const emptyStars = 5 - fullStars - halfStars;
	  
		const stars = [];
	  
		for (let i = 0; i < fullStars; i++) {
		  stars.push(<i className="fa fa-star" style={{ color: '#e1b000' }} key={`full-${i}`} />);
		}
	  
		for (let i = 0; i < halfStars; i++) {
		  stars.push(<i className="fas fa-star-half-alt" style={{ color: '#e1b000' }} key={`half-${i}`} />);
		}
	  
		for (let i = 0; i < emptyStars; i++) {
		  stars.push(<i className="far fa-star" style={{ color: '#e1b000' }} key={`empty-${i}`} />);
		}
	  
		return (
		  <div className="rating-stars">
			<ul className="d-flex" style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
			  {stars.map((star, index) => (
				<li key={index}>{star}</li>
			  ))}
			</ul>
		  </div>
		);
	};

  	const data_column = [
		{
			Header: 'Abilità',
			accessor: 'type',
			Filter: ColumnFilter
		},
		{
			Header: 'Valutazione',
			accessor: 'stars',
			Cell: ({ value }) => {
				return <StarRating value={value} />;
			},
		},
	];

    return (
		<>
  			<div className="accordion-body2">           
				<TabellaLista
					cardTitle={'Feedback meritocratico'}
					data_column={data_column}
					data_json={data_json}
				/>
       		</div>
        </>
	)
}
export default FeedbackMaestro;