import React, { Fragment } from "react";
import TabellaLista from "../../../../components/Tabella/TabellaLista";
import { Dropdown } from "react-bootstrap";
import { ColumnFilter } from "../../../../components/table/FilteringTable/ColumnFilter";
import { More } from "iconsax-react";

const ListaProdotti = () => {

    const data_column = [

        {
            Header: 'Nome prodotto',
            Footer: 'Nome prodotto',
            accessor: 'nome-prodotto',
            Filter: ColumnFilter
        },
        {
            Header: 'Prezzo',
            Footer: 'Prezzo',
            accessor: 'prezzo',
            Cell: ({ value }) => {
                return (
                    <Fragment>
                        € {value.prezzo} / {value.unit}
                        <br />
                        <b>{value.fitcoin}% Fitcoin (4.00)</b>
                    </Fragment>
                );
            },
        },
        {
            Header: 'Prezzo ai soci',
            Footer: 'Prezzo ai soci',
            accessor: 'prezzo-soci',
            Cell: ({ value }) => {
                return (
                    <Fragment>
                        € {value.prezzo} / {value.unit}
                        <br />
                        <b>{value.fitcoin}% Fitcoin (4.00)</b>
                    </Fragment>
                );
            },
        },

        {
            Header: 'Stato',
            Footer: 'Stato',
            accessor: 'stato',
            Cell: ({ value }) => {
                return (
                    <div className="d-flex align-items-center">
                        <i className={`fa fa-circle me-1 ${value.class}`}></i>
                        {value.label}
                    </div>
                );
            },
            disableFilters: true,
        },
        {
            Header: 'Visibilità',
            Footer: 'Visibilità',
            accessor: 'vsibilita',
            Cell: ({ value }) => {
                return (
                    <div className="d-flex align-items-center">
                        <i className={`fa fa-circle me-1 ${value.class}`}></i>
                        {value.label}
                    </div>
                );
            },
            disableFilters: true,
        },
        {
            Header: 'Azione',
            Footer: 'Azione',
            accessor: 'azione',
            Cell: ({ value }) => {
                return (
                    <Dropdown>
                        <Dropdown.Toggle
                            className="btn-primary light sharp i-false btn-action"
                        >
                            <More />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item>Scarica</Dropdown.Item>
                            <Dropdown.Item>Modifica</Dropdown.Item>
                            <Dropdown.Item>Cancella</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            },
            disableFilters: true,
        },
    ];

    const data_json = [
        {
            "nome-prodotto": "Abbonamento mensile tennis",
            "prezzo": {
                "prezzo": "10,50",
                "unit": "Mese",
                "fitcoin": "4"
            },
            "prezzo-soci": {
                "prezzo": "10,50",
                "unit": "Mese",
                "fitcoin": "4"
            },
            "stato": {
                "label": "Inactive",
                "class": "text-warning"
            },
            "vsibilita": {
                "label": "Inactive",
                "class": "text-warning"
            },
        }
    ];

    return (
        <Fragment>
            <TabellaLista
                cardTitle={'Lista prodotti'}
                hasSearch={true}
                hasFilter={true}
                hasAddMore={true}
				linkAddMore={'/maestro/prodotti/listaprodotti/aggiungi-prodotto'}
				labelAddMore={'Nuovo prodotto'}
                data_column={data_column}
                data_json={data_json}
            />
        </Fragment>
    )
}

export default ListaProdotti