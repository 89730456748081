import React,{ useEffect, useState, Fragment} from 'react';
import { Row, Card, Spinner, Modal, Button } from "react-bootstrap";
import { Coin, Danger } from "iconsax-react";
import MyApiClient from '../../../api-client/MyApiClient';
import { useNavigate } from 'react-router-dom';

const HomeStruttura = () => {

	const navigate = useNavigate();

	const [loadingShowed, setLoadingShowed] = useState(false);
	const [administrationClub, setAdministrationClub] = useState(null);
	//const [administrationClubSales, setAdministrationClubSales] = useState([]);
	//const [data_json, setDataJson] = useState([]);
	const [fitcoinGuadagnati, setFitcoinGuadagnati] = useState(0);
	const [fitcoinDisponibili, setFitcoinDisponibili] = useState(0);
	const [fitcoinSpesi, setFitcoinSpesi] = useState(0);
	const [inactiveShowed, setInactiveShowed] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

					setLoadingShowed(true);
                    const [clubData, /*clubSalesData,*/ fitcoinData, profileData] = await Promise.all([
                        apiClient.getAdministrationClub(token),
                        /*apiClient.getAdministrationClubOverviewSales(token),*/
						apiClient.getWalletFitcoinsSummary(token),
						apiClient.getProfile(token)
                    ]);
					setLoadingShowed(false);

					if(profileData && profileData.club && profileData.club.profileStatus==='INACTIVE') {
						setInactiveShowed(true);
					}

					if(fitcoinData) {
						setFitcoinDisponibili(fitcoinData.balance);

						let fitcoinSpesi = 0;
						let fitcoinGuadagnati = 0;
						fitcoinData.transactions.forEach(transaction => {
							//const transactionId = transaction.transactionId;
							const type = transaction.type;
							const amount = transaction.amount;
							//const createdAt = transaction.createdAt;
							//const isMerchant = transaction.isMerchant;
							//const description = transaction.description;

							//const data = new Date(createdAt);
							//const options = { year: '2-digit', month: '2-digit', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
							//const formattedDate = data.toLocaleDateString('it-IT', options);

							if(type==='EARN') {
								fitcoinGuadagnati += amount;
							}

							if(type==='SPENT') {
								fitcoinSpesi += amount;
							}
						});

						setFitcoinSpesi(fitcoinSpesi);
						setFitcoinGuadagnati(fitcoinGuadagnati);
					}

                    setAdministrationClub(clubData);
                    //setAdministrationClubSales(clubSalesData);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

	/*
	const data_column = [
        {
            Header: 'Prodotto',
            accessor: 'name',
            Filter: ColumnFilter
        },
        {
            Header: 'Quantità',
            accessor: 'quantity',
			Filter: ColumnFilter
        },
        {
            Header: 'Vendite nette',
            accessor: 'totalSalesSum',
			Filter: ColumnFilter
		}
    ];
	*/

	const goToLaMiaStruttura = (event) => {
		navigate('/struttura/lamiastruttura');
	};

	return(
		<>
		<Modal className="fade" show={loadingShowed} centered>
			<Modal.Body className="text-center">
			<Spinner animation="border" role="status">
				<span className="sr-only">Loading...</span>
			</Spinner>
			<div>Caricamento in corso...</div>
			</Modal.Body>
		</Modal>

		{inactiveShowed &&
			<div className="row">
				<div className="col-xl-12">
					<div className="card" style={{ backgroundColor: '#FD7E14', color: '#fff' }}>
						<div className="card-header border-0 flex-wrap flex-column align-content-start align-items-baseline">
							<h4 className="fs-24 font-w500 mb-3 text-white"><Danger style={{ marginRight: '18px' }} size='24'></Danger>Il profilo non è attivo</h4>
							<div>Inserisci le informazioni mancanti, valida il profilo e attiva la struttura nei risultati di ricerca di SportId!</div>
							<div style={{ marginTop: '18px' }}>
							<Button onClick={goToLaMiaStruttura} className="custom-button">Completa il profilo</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		}

		<div className="row">
			{/*
			<div className="col-xl-4">
				<div className="card">
					<div className="card-header border-0 flex-wrap flex-column align-content-start align-items-baseline">
					<span className="mb-3 d-block fs-20 text-primary">Fatturato totale</span>
					<h4 className="fs-44 font-w500 mb-3 text-primary">€ { administrationClub!=null ? administrationClub.totalSalesSum.toFixed(2) : 0 }</h4>
					</div>
				</div>
			</div>
			*/}
			<div className="col-xl-6">
				<div className="card">
					<div className="card-header border-0 flex-wrap flex-column align-content-start align-items-baseline">
					<span className="mb-3 d-block fs-20">Player</span>
					<h4 className="fs-44 font-w500 mb-3">{ administrationClub!=null ? administrationClub.clients : 0 }</h4>
					</div>
				</div>
			</div>
			<div className='col-xl-6'>
				<div className="card">
					<div className="card-header border-0 flex-wrap flex-column align-content-start align-items-baseline">
					<span className="mb-3 d-block fs-20">Junior</span>
					<h4 className="fs-44 font-w500 mb-3">{ administrationClub!=null ? administrationClub.juniors : 0 }</h4>
					</div>
				</div>
			</div>
		</div>
		<div className="row">
			<div className="col-xl-4">
				<div className="card">
					<div className="card-header border-0 flex-wrap flex-column align-content-start align-items-baseline">
					<span className="mb-3 d-block fs-20">Soci</span>
					<h4 className="fs-44 font-w500 mb-3">{ administrationClub!=null ? administrationClub.members : 0 }</h4>
					</div>
				</div>
			</div>
			<div className='col-xl-4'>
				<div className="card">
					<div className="card-header border-0 flex-wrap flex-column align-content-start align-items-baseline">
					<span className="mb-3 d-block fs-20">Staff tecnico</span>
					<h4 className="fs-44 font-w500 mb-3">{ administrationClub!=null ? administrationClub.coaches : 0 }</h4>
					</div>
				</div>
			</div>
			<div className='col-xl-4'>
				<div className="card">
					<div className="card-header border-0 flex-wrap flex-column align-content-start align-items-baseline">
					<span className="mb-3 d-block fs-20">Consiglio direttivo</span>
					<h4 className="fs-44 font-w500 mb-3">{ administrationClub!=null ? administrationClub.directors : 0 }</h4>
					</div>
				</div>
			</div>
			{/*
			<div className='col-xl-3'>
				<div className="card">
					<div className="card-header border-0 flex-wrap flex-column align-content-start align-items-baseline">
					<span className="mb-3 d-block fs-20">Allievi</span>
					<h4 className="fs-44 font-w500 mb-3">{ administrationClub!=null ? administrationClub.trainees : 0 }</h4>
					</div>
				</div>
			</div>
			<div className='col-xl-3'>
				<div className="card">
					<div className="card-header border-0 flex-wrap flex-column align-content-start align-items-baseline">
					<span className="mb-3 d-block fs-20">Squadre</span>
					<h4 className="fs-44 font-w500 mb-3">{ administrationClub!=null ? administrationClub.teams : 0 }</h4>
					</div>
				</div>
			</div>
			*/}
		</div>
		<Row className='ps-3'>
		<Card className='col me-3 bd-left-gray'>
			<Card.Body><h4 className='formSubtitle'>FitCoin guadagnati</h4>
			<h4 className="fs-44 font-w500 mb-3 text-dark"><Coin variant='Bulk' size='44'></Coin>{fitcoinGuadagnati}</h4>
			</Card.Body>
		</Card>
		<Card className='col me-3 bd-left-success'>
			<Card.Body><h4 className='formSubtitle'>FitCoin disponibili</h4>
			<h4 className="fs-44 font-w500 mb-3 text-success"><Coin variant='Bulk' size='44'></Coin>{fitcoinDisponibili}</h4>
			</Card.Body>
		</Card>
		<Card className='col me-3 bd-left-danger'>
			<Card.Body><h4 className='formSubtitle'>FitCoin spesi</h4>
			<h4 className="fs-44 font-w500 mb-3 text-danger"><Coin variant='Bulk' size='44'></Coin>{fitcoinSpesi}</h4>
			</Card.Body>
		</Card>
		</Row>
		
		</>
	)
}
export default HomeStruttura;