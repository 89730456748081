import React from "react";
import { Link } from "react-router-dom";
import FormPrincipale from "../../../../components/Form/Prodotti/formPrincipale";

const AggiungiProdotto = () => {

  return (
    <>

      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Crea nuovo prodotto</h4>
        </div>
        <div className="card-body">

          <FormPrincipale />

        </div>

      </div>


      <div className="d-flex justify-content-end gap-4">
        <Link to={"/maestro/prodotti/listaprodotti"} className="btn btn-dark light mr-4">Indietro</Link>	<Link to="#" className="btn btn-primary">Salva</Link>
      </div>

    </>
  )

}

export default AggiungiProdotto;
