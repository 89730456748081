import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Tab, Nav, Form } from "react-bootstrap";
import TabellaLista from "../Tabella/TabellaLista";
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import { format } from 'date-fns';

const FormaFisPlayer = (props) => {

    const [formData, setFormData] = useState({
        pressioneSanguignaBassa: '',
        pressioneSanguignaAlta: '',
        frequenzaCardiacaMedia: ''
    });

    const [data_json, setDataJson] = useState(null);

    useEffect(() => {
        if (props.profile && props.profile.idsPlay) {
            let playerData = props.profile;

            let formData = {};

            // console.log(playerData.idsPlay);

            formData.pressioneSanguignaBassa = playerData.idsPlay.averageLowBloodPressure ? playerData.idsPlay.averageLowBloodPressure+' mmHg' : '-';
            formData.pressioneSanguignaAlta = playerData.idsPlay.averageHighBloodPressure ? playerData.idsPlay.averageHighBloodPressure+' mmHg' : '-';
            formData.frequenzaCardiacaMedia = playerData.idsPlay.averageHeartRate ? playerData.idsPlay.averageHeartRate+' bpm' : '-';

            let statesData = [];
            if(playerData.idsPlay.states) {
                playerData.idsPlay.states.forEach(element => {
                    let currentState = {
                        "peso": element.weight+" kg",
                        "altezza": element.height+" cm",
                        "bmi": element.bodyMassIndex,
                        "bmi_class": element.bodyMassIndexClass,
                        "piede": element.footNumber,
                        "data": format(element.updatedAt, 'dd/MM/yyyy HH:mm:ss'),
                    };

                    statesData.push(currentState);
                });
            }

            setDataJson(statesData);
            setFormData(formData);
        }
		
    }, [props]);

    const data_column = [
        {
            Header: 'Peso',
            accessor: 'peso',
            Filter: ColumnFilter
        },
        {
            Header: 'Altezza',
            accessor: 'altezza',
            Filter: ColumnFilter
        },
        {
            Header: 'BMI',
            accessor: 'bmi',
            Filter: ColumnFilter
        },
        {
            Header: 'Classe BMI',
            accessor: 'bmi_class',
            Filter: ColumnFilter
        },
        {
            Header: 'N° Piede',
            accessor: 'piede',
            Filter: ColumnFilter
        },
        {
            Header: 'Data aggiornamento',
            accessor: 'data',
            Filter: ColumnFilter
        },
    ];

    /*
    const data_json = [
        {
            "id": 1,
            "peso": "66 kg",
            "altezza": "167 cm",
            "bmi": "25",
            "bmi_class": "Sovrappeso",
            "piede": 37,
            "data": "15 giugno 2024",
        },
        {
            "id": 2,
            "peso": "68 kg",
            "altezza": "167 cm",
            "bmi": "25,4",
            "bmi_class": "Sovrappeso",
            "piede": 37,
            "data": "15 maggio 2024",
        }
    ];
    */

    return (
        <>
            <Row>

                <Form>
                    <Card className="cardAuto">
                        <Card.Header><h4 className="card-title">Informazioni generiche</h4></Card.Header>
                        <Card.Body>
                            <Row className="mt-3">
                                <Col className="col-xl-4">
                                    <Form.Label>Pressione sanguigna bassa media</Form.Label>
                                    <Form.Control type="text" value={formData.pressioneSanguignaBassa} disabled />
                                </Col>
                                <Col className="col-xl-4">
                                    <Form.Label>Pressione sanguigna alta media</Form.Label>
                                    <Form.Control type="text" value={formData.pressioneSanguignaAlta} disabled />
                                </Col>
                                <Col className="col-xl-4">
                                    <Form.Label>Frequenza cardiaca media</Form.Label>
                                    <Form.Control type="text" value={formData.frequenzaCardiacaMedia} disabled />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <TabellaLista
                        cardTitle={'Stati'}
                        hasSearch={false}
                        hasFilter={false}
                        hasAddMore={false}
                        data_column={data_column}
                        data_json={data_json}
                    />
                </Form>
            </Row>
        </>
    )
}
export default FormaFisPlayer;