import React, { useEffect, useState } from 'react';
import { ColumnFilter } from '../../table/FilteringTable/ColumnFilter';
import { Form, Button, Dropdown, Modal, Row, Col, Spinner } from "react-bootstrap";
import { Add, DocumentDownload, More, Trash } from 'iconsax-react';
import TabellaLista from '../../Tabella/TabellaLista';
import MyApiClient from '../../../../api-client/MyApiClient';
import { format } from 'date-fns';
import notitles from "../../../../images/notitles.png";

export const FormTitoli = (props) => {

	const MAX_FILE_SIZE = 5 * 1024 * 1024;
	const ALLOWED_FILE_TYPES = ['application/pdf'];

	const [newTitleName, setNewTitleName] = useState('');
  	const [newTitleFile, setNewTitleFile] = useState(null);

	const [data_json, setDataJson] = useState(null);
	const [modalCentered, setModalCentered] = useState(false);

	const [modalShowed, setModalShowed] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalText, setModalText] = useState('');

	const [confirmShowed, setConfirmShowed] = useState(false);
	const [onContinue, setOnContinue] = useState(null);

	const [loadingShowed, setLoadingShowed] = useState(false);

	const handleTextChange = (event) => {
		setNewTitleName(event.target.value);
	};

	const handleFileChange = (event) => {
		setNewTitleFile(event.target.files[0]);
	};

	const data_column = [
		{
			Header: 'Data di caricamento',
			accessor: 'date',
			Filter: ColumnFilter
		},
		{
			Header: 'Nome',
			accessor: 'name',
			Filter: ColumnFilter
		},
		{
			Header: 'Azione',
			Footer: 'Azione',
			accessor: 'azione',
			Cell: ({ row }) => {
				return (
					<Dropdown>
						<Dropdown.Toggle
							className="btn-primary light sharp i-false btn-action"
						>
							<More />
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item onClick={() => downloadTitle(row.original)} className="text-danger"><span className="me-2"><DocumentDownload size="20" /></span>Scarica</Dropdown.Item>
							{!props.isDisabled && <Dropdown.Item onClick={() => deleteTitle(row.original)} className="text-danger"><span className="me-2"><Trash size="20" /></span>Cancella</Dropdown.Item>}
						</Dropdown.Menu>
					</Dropdown>
				)
			},
			disableFilters: true,
		},
	];

	function updateTitlesTable(club_titles) {

		const updatedDataJson = club_titles.map((title, index) => {
			return {
				'id': title.document.id,
				'name': title.document.name,
				'uri': title.document.uri,
				'date': format(title.document.uploadedAt, 'dd/MM/yyyy HH:mm:ss')
			};
		});
		setDataJson(updatedDataJson);
	}

	useEffect(() => {
		if (props.profile && props.profile.club && props.profile.club.titles) {	
            updateTitlesTable(props.profile.club.titles);
        } else {
            setDataJson(null);
        }
    }, [props]);

	const downloadTitle = (row) => {
        window.open(row.uri, '_blank');
    };

	const deleteTitle = (row) => {
		setOnContinue(() => () => continueDeleteTitle(row));
		setConfirmShowed(true);
    };

	const continueDeleteTitle = (row) => {

		const deleteData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

					setLoadingShowed(true);
                    const saveResult = await apiClient.deleteTitle(token, row.id);
					setLoadingShowed(false);

                    if(!saveResult) {
                        setModalTitle("Errore durante l'operazione");
                        setModalText("Si è verificato un errore durante la cancellazione ("+saveResult.error+")");
                        setModalShowed(true);
                    } else {
						window.location.href = '/struttura/lamiastruttura?tab=titoli';
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        deleteData();
	};

	const addTitle = (e) => {
        e.preventDefault();

		setModalCentered(false);

		if(newTitleName==='') {
			setModalTitle("Errore durante l'operazione");
			setModalText("Il nome del titolo deve essere valorizzato.");
			setModalShowed(true);
			return;
		}

		if(!newTitleFile) {
			setModalTitle("Errore durante l'operazione");
			setModalText("Il file del titolo deve essere valorizzato.");
			setModalShowed(true);
			return;
		}

		let file = newTitleFile;

		if (file) {

			if (file.size > MAX_FILE_SIZE) {
				setModalTitle("Errore durante l'operazione");
				setModalText('Il file selezionato supera la dimensione massima consentita.');
				setModalShowed(true);
				return;
			}

			if (!ALLOWED_FILE_TYPES.includes(file.type)) {
				setModalTitle("Errore durante l'operazione");
				setModalText('Il file selezionato non è di tipo consentito.');
				setModalShowed(true);
				return;
			}

			const reader = new FileReader();
			reader.onloadend = () => {
				const base64String = reader.result.split(',')[1];
				// console.log(base64String);
				continueAddTitle(newTitleName,base64String);
			};
			reader.readAsDataURL(file);
		}
    };

	function continueAddTitle(newTitleName,base64String) {
		//console.log(newTitleName);
		//console.log(base64String);

		const addData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

					let data_update = {
						'document': {
							'content': base64String,
							'name': newTitleName
						}
					}
					
					setLoadingShowed(true);
                    const saveResult = await apiClient.addTitle(token, data_update);
					setLoadingShowed(false);

                    if(!saveResult) {
                        setModalTitle("Errore durante l'operazione");
                        setModalText("Si è verificato un errore durante il salvataggio ("+saveResult.error+")");
                        setModalShowed(true);
                    } else {
						window.location.href = '/struttura/lamiastruttura?tab=titoli';
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        addData();
	}

	return (
		<>
			<Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

			<Modal className="fade" show={confirmShowed} centered>
                <Modal.Header>
                    <Modal.Title>Sei sicuro?</Modal.Title>
                    <Button onClick={() => setConfirmShowed(false)} variant="" className="btn-close">
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Row><Col className="col-xl-64">Sei sicuro di volere continuare?</Col></Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button className="btn btn-light btn-add" onClick={() => setConfirmShowed(false)}>
                        Chiudi
                    </Button>
					<Button className="btn btn-primary btn-add" onClick={onContinue}>
                        Continua
                    </Button>
                </Modal.Footer>
            </Modal>

			<Modal className="fade" show={modalShowed} centered>
                <Modal.Header>
                    <Modal.Title>{modalTitle}</Modal.Title>
                    <Button onClick={() => setModalShowed(false)} variant="" className="btn-close">
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Row><Col className="col-xl-64">{modalText}</Col></Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button className="btn btn-primary btn-add" onClick={() => setModalShowed(false)}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>

			<Modal className="fade" show={modalCentered} centered>
				<Modal.Header>
					<Modal.Title>Aggiungi titolo federale</Modal.Title>
					<Button
						onClick={() => setModalCentered(false)}
						variant=""
						className="btn-close"
					>
					</Button>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Row>
							<Col className="col-lg-12 pb-4">
								<Form.Label>Nome del titolo</Form.Label>
								<Form.Control type="text" placeholder="Inserisci il nome del titolo" onChange={handleTextChange} />
							</Col>
						</Row>
						<Row>
							<Col className="col-lg-12 pb-4">
								<Form.Label>Seleziona file</Form.Label>
								<Form.Control type="file" placeholder="Carica il file" onChange={handleFileChange} />
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer className="justify-content-between">
					<Button className="btn btn-light btn-add" onClick={() => setModalCentered(false)}>
						Annulla
					</Button>
					<Button onClick={addTitle} className="btn btn-primary btn-add">Aggiungi</Button>
				</Modal.Footer>
			</Modal>

			{data_json && data_json.length === 0 &&
				<center>
					<div>
						<img src={notitles} style={{width: '50%'}} alt="" />
					</div>
					<div style={{ fontSize: '26px', color: 'grey', marginTop: '30px'}}>
						Nessun titolo inserito
					</div>
					<div style={{ fontSize: '16px', color: 'grey', fontWeight: '300', marginTop: '20px'}}>
						Non hai ancora inserito nessun titolo
						<br></br>
						Inizia adesso e aggiungi il tuo primo titolo per vedere il dettaglio qui.
					</div>
					<div>
						<Button style={{ marginTop: '20px'}} onClick={() => setModalCentered(true)} className="btn btn-primary btn-sm btn-add" ><Add variant="Bulk" /><span className='ms-2'>Aggiungi</span></Button>
					</div>
				</center>
			}
			
			{data_json && data_json.length>0 &&
				<TabellaLista
					cardTitle={'Lista Titoli'}
					hasSearch={false}
					hasFilter={false}
					data_column={data_column}
					data_json={data_json}
					hasAddMoreAsModal={props.isDisabled ? false : true}
					modalCallBack={() => setModalCentered(true)}
				/>
			}
		</>
	)

}
export default FormTitoli;