import React, { Fragment } from "react";
import TabellaLista from "../../../../components/Tabella/TabellaLista";
import { Button, Dropdown } from "react-bootstrap";
import { ColumnFilter } from "../../../../components/table/FilteringTable/ColumnFilter";
import { ProfileCircle, More} from "iconsax-react";

const ListaAttesa = () => {
    const data_column = [

        {
            Header: 'Prodotto',
            Footer: 'Prodotto',
            accessor: 'prodotto',
            Filter: ColumnFilter
        },
        {
            Header: 'Nome richiedente',
            Footer: 'Nome richiedente',
            accessor: 'nome-richiedente',
            Cell: ({ value }) => {
                return (
                    <Button variant="dark light" size="xs" >
                        <span className="me-2"><ProfileCircle size="24" variant="Bulk" /></span>
                        {value}
                    </Button>
                );
            },
            Filter: ColumnFilter
        },
        {
            Header: 'Data',
            Footer: 'Data',
            accessor: 'data',
            Filter: ColumnFilter
        },
        {
            Header: 'Ora',
            Footer: 'Ora',
            accessor: 'ora',
            Filter: ColumnFilter
        },
        {
            Header: 'Azione',
            Footer: 'Azione',
            accessor: 'azione',
            Cell: ({ value }) => {
                return (
                    <Dropdown>
                        <Dropdown.Toggle
                            className="btn-primary light sharp i-false btn-action"
                        >
                            <More />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item>Scarica</Dropdown.Item>
                            <Dropdown.Item>Modifica</Dropdown.Item>
                            <Dropdown.Item>Cancella</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            },
            disableFilters: true,
        },
    ];

    const data_json = [
        {
            "data": "10 Marzo 2024",
            "ora": "11:30",
            "nome-richiedente": "Nome Cognome",
            "prodotto": "Prodotto",
        }
    ];

    return (
        <Fragment>
            <TabellaLista
                cardTitle={'Lista di attesa'}
                hasSearch={true}
                hasFilter={true}
                hasAddMore={false}
                data_column={data_column}
                data_json={data_json}
            />
        </Fragment>
    )
}

export default ListaAttesa