import React, { useEffect, useState } from "react";
import { Row, Form } from "react-bootstrap";
import TabellaLista from "../Tabella/TabellaLista";
import { Dropdown } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { More, User } from 'iconsax-react';
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import MyApiClient from "../../../api-client/MyApiClient";

const JuniorPlayer = (props) => {

    const data_column = [
        {
            Header: 'Data',
            accessor: 'registration_date',
            Filter: ColumnFilter
        },
        {
            Header: 'Nome',
            accessor: 'nome',
            Filter: ColumnFilter,
        },
        {
            Header: 'Cognome',
            accessor: 'cognome',
            Filter: ColumnFilter,
        },
        {
            Header: 'Stato',
            accessor: 'registration_state',
            Filter: ColumnFilter,
            Cell: ({ value }) => {
                return value === 1 ? <span><i className="fa fa-circle text-success me-1"></i> Attivo</span> : <span><i className="fa fa-circle text-warning me-1"></i> Non attivo</span>
            },
            disableFilters: true,
        },
        {
            Header: 'Azione',
            accessor: 'azione',
            Cell: ({ value }) => {
                return (
                    <Dropdown>
                        <Dropdown.Toggle
                            className="btn-primary light sharp i-false btn-action"
                        >
                            <More />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item><Link to='/struttura/junior/junior_profile'><span className="me-2"><User size="20" /></span>Vedi profilo</Link></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            },
            disableFilters: true,
        },
    ];

    const [data_json, setDataJson] = useState(null);

    useEffect(() => {

        if (props.profile && props.profile.idsPlay) {
            let playerData = props.profile;

            const fetchData = async () => {
                try {
                    const tokenDetailsString = sessionStorage.getItem('userDetails');
                    if (tokenDetailsString) {
                        const tokenDetails = JSON.parse(tokenDetailsString);
                        const token = tokenDetails.idToken;
                        const apiClient = new MyApiClient();

                        const [customersData] = await Promise.all([
                            apiClient.getJuniorsByParent(token, playerData.idsPlay.userId)
                        ]);

                        let data_customers = [];
                        if(customersData) {
                            customersData.forEach(element => {
                                let customers_row = {
                                    "id": element.userId,
                                    "nome": element.givenName,
                                    "cognome": element.familyName
                                };
                                data_customers.push(customers_row);
                            });
                        }
                        setDataJson(data_customers);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };

            fetchData();
        }

    }, [props]);

    return (
        <>
            <Row>
                <Form>
                    <TabellaLista
                        cardTitle={'Lista Junior'}
                        hasSearch={true}
                        hasFilter={false}
                        hasAddMore={false}
                        data_column={data_column}
                        data_json={data_json}
                    />
                </Form>
            </Row>
        </>
    )
}
export default JuniorPlayer;