import React, { useState, useEffect } from "react";
import { Row, Col, Card, Tab, Nav, Modal, Button, Spinner, Form } from "react-bootstrap";
import { Profile, TableDocument, DocumentText, Camera, Information, Coin} from "iconsax-react";
import { Link, useLocation } from 'react-router-dom';
import FormIDS from "../../components/Struttura/LaMiaStruttura/formIDS";
import FormSport from "../../components/Struttura/LaMiaStruttura/formSport";
import FormTitoli from "../../components/Struttura/LaMiaStruttura/formTitoli";
import FormFoto from "../../components/Struttura/LaMiaStruttura/formFoto";
import FormFitCoin from "../../components/Struttura/LaMiaStruttura/formFitcoin";
import MyApiClient from "../../../api-client/MyApiClient";
import DynamicLogo from "../DynamicLogoLoader";
import DEFAULT_STRUTTURA from "../../../images/DEFAULT_STRUTTURA.png";

export const LaMiaStruttura = () => {

	const [profile, setProfile] = useState(null);
	const [loadingShowed, setLoadingShowed] = useState(false);

	const [confirmShowed, setConfirmShowed] = useState(false);
	const [onContinue, setOnContinue] = useState(null);

	const tabData = [
		{
			id: 'ids',
			name: "IDS",
			icon: <Profile size='18' variant='Bulk' />,
			content: <FormIDS profile={profile} />,
		},
		{
			id: 'sport',
			name: "Sport",
			icon: <TableDocument size='18' />,
			content: <FormSport profile={profile} />,
		},
		{
			id: 'titoli',
			name: "Titoli",
			icon: <DocumentText size='18' />,
			content: <FormTitoli profile={profile} />,
		},
		{
			id: 'copertina',
			name: "Foto copertina",
			icon: <Camera size='18' />,
			content: <FormFoto profile={profile} />,
		},
		{
			id: 'fitcoins',
			name: "FitCoins",
			icon: <Coin size='18' />,
			content: <FormFitCoin profile={profile} />,	
		},
	];

	const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [defaultTab, setDefaultTab] = useState(query.get('tab') || tabData[0].name.toLowerCase());

    useEffect(() => {
        const tab = new URLSearchParams(location.search).get('tab');
		if (tab && tab !== defaultTab) {
            setDefaultTab(tab);
        }
    }, [location.search, defaultTab]);

	const MAX_FILE_SIZE = 5 * 1024 * 1024;
	const ALLOWED_FILE_TYPES = ['image/png', 'image/jpeg'];

	const [newTitleFile, setNewTitleFile] = useState(null);
	const [modalCentered, setModalCentered] = useState(false);
  	
	const [modalShowed, setModalShowed] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalText, setModalText] = useState('');

	const handleFileChange = (event) => {
		setNewTitleFile(event.target.files[0]);
	};

	const addTitle = (e) => {
        e.preventDefault();

		setModalCentered(false);

		let file = newTitleFile;

		if (file) {

			if (file.size > MAX_FILE_SIZE) {
				setModalTitle("Errore durante l'operazione");
				setModalText('Il file selezionato supera la dimensione massima consentita.');
				setModalShowed(true);
				return;
			}

			if (!ALLOWED_FILE_TYPES.includes(file.type)) {
				setModalTitle("Errore durante l'operazione");
				setModalText('Il file selezionato non è di tipo consentito.');
				setModalShowed(true);
				return;
			}

			const reader = new FileReader();
			reader.onloadend = () => {
				const base64String = reader.result.split(',')[1];
				// console.log(base64String);
				updateProfile(base64String);
			};
			reader.readAsDataURL(file);
		}
    };

	const deleteLogo = (row) => {
		setOnContinue(() => () => continueDeleteLogo(row));
		setConfirmShowed(true);
    };

	const continueDeleteLogo = (row) => {

        setConfirmShowed(false);
		deleteLogoUpdate();
	};

	function deleteLogoUpdate() {

		const saveData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

                    let profile_send = {};
					profile_send.club = {};
					profile_send.club.logo = '';
                    
					setLoadingShowed(true);
                    const saveResult = await apiClient.updateProfile(token, profile_send);
					setLoadingShowed(false);

                    if(saveResult && saveResult.error) {
                        setModalTitle("Errore durante l'operazione");
                        setModalText("Si è verificato un errore durante il salvataggio ("+saveResult.error+")");
                        setModalShowed(true);
                    } else {
						window.location.reload();
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        saveData();
	}

	function updateProfile(base64String) {

		const saveData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

                    let profile_send = JSON.parse(JSON.stringify(profile));
					profile_send.club.logo = base64String;
                    delete profile_send.club.pictures;

					setLoadingShowed(true);
                    const saveResult = await apiClient.updateProfile(token, profile_send);
					setLoadingShowed(false);

                    if(saveResult && saveResult.error) {
                        setModalTitle("Errore durante l'operazione");
                        setModalText("Si è verificato un errore durante il salvataggio ("+saveResult.error+")");
                        setModalShowed(true);
                    } else {
						window.location.reload();
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        saveData();
	}

	useEffect(() => {
        const fetchData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

					setLoadingShowed(true);
                    const [profileData, titlesData] = await Promise.all([
                        apiClient.getProfile(token),
						apiClient.getTitles(token)
                    ]);
					setLoadingShowed(false);

					profileData.club.titles = titlesData;
                    setProfile(profileData);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

	const profileStatus = profile && profile.club && profile.club.profileStatus ? profile.club.profileStatus : null;

	return (
		<>
			<Modal className="fade" show={confirmShowed} centered>
                <Modal.Header>
                    <Modal.Title>Sei sicuro?</Modal.Title>
                    <Button onClick={() => setConfirmShowed(false)} variant="" className="btn-close">
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Row><Col className="col-xl-64">Sei sicuro di volere continuare?</Col></Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button className="btn btn-light btn-add" onClick={() => setConfirmShowed(false)}>
                        Chiudi
                    </Button>
					<Button className="btn btn-primary btn-add" onClick={onContinue}>
                        Continua
                    </Button>
                </Modal.Footer>
            </Modal>

			<Modal className="fade" show={modalShowed} centered>
                <Modal.Header>
                    <Modal.Title>{modalTitle}</Modal.Title>
                    <Button onClick={() => setModalShowed(false)} variant="" className="btn-close">
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Row><Col className="col-xl-64">{modalText}</Col></Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button className="btn btn-primary btn-add" onClick={() => setModalShowed(false)}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>

			<Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

			<Modal className="fade" show={modalCentered} centered>
                <Modal.Header>
                    <Modal.Title>Seleziona il logo</Modal.Title>
                    <Button
                        onClick={() => setModalCentered(false)}
                        variant=""
                        className="btn-close"
                    >
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col className="col-lg-12 pb-4">
                                <Form.Label>Seleziona file</Form.Label>
                                <Form.Control type="file" placeholder="Carica il file" onChange={handleFileChange} />
                            </Col>
                        </Row>
						<Row>
                            <Col className="col-lg-12 pb-4">
                                <i>Dimensioni consigliate: 400px di larghezza e 225px di altezza</i>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button className="btn btn-light btn-add" onClick={() => setModalCentered(false)}>
                        Annulla
                    </Button>
                    <Button onClick={addTitle} className="btn btn-primary btn-add">Salva</Button>
                </Modal.Footer>
            </Modal>
			
			<div className="custom-tab-1 mtnegativo2">
				<Tab.Container defaultActiveKey={defaultTab}>
					<Nav as="ul" className="nav-tabs justify-content-center" style={{ border: 'none' }}>
						{tabData.map((data, i) => (
							<Nav.Item as="li" key={i} className="tabMenu">
								<Nav.Link eventKey={data.id.toLowerCase()}>
									{data.icon}{data.name}
								</Nav.Link>
							</Nav.Item>
						))}
					</Nav>
					<Row className="align-items-start mt-4">
						<div className="col-xl-9">
							<Tab.Content>
								{tabData.map((data, i) => (
									<Tab.Pane eventKey={data.id.toLowerCase()} key={i}>
										{data.content}
									</Tab.Pane>
								))}
							</Tab.Content>
						</div>
						<div className="col-xl-3">
							<Card>
								<Card.Body>
									{(!profile || !profile.club.logo) && <img onClick={() => setModalCentered(true)} src={DEFAULT_STRUTTURA} style={{width: '100%'}} alt="" />}
									{(profile && profile.club.logo) && <div onClick={() => setModalCentered(true)}>
										<DynamicLogo logoUrl={profile ? profile.club.logo : ''} alt="Logo Struttura Sportiva" />
									</div>}
									{profile && profile.club.logo && <Link to={'#'} onClick={deleteLogo} className="btn btn-sm btn-profile mt-2 mb-2">
										<span className='ms-2'>Elimina il logo</span>
									</Link>}

									<h4 className="text-center mb-0">{profile ? profile.club.name : ''}</h4>
									<p className="text-center mt-0 pt-0">{profile ? profile.club.description : ''}</p>
									
									{
										/*
										export const CLUB_ACTIVE_REQUIRED_FIELDS: (keyof ClubProfileData)[] = [
											'name',
											'incorporationYear',
											'address',
											'vatNumber',
											'phoneNumber',
											'email',
											'spokenLocales',
											'sports',
										]

										export const CLUB_COMPLETE_REQUIRED_FIELDS: (keyof ClubProfileData)[] = [
											'titles', 
											'numberOfMembers'
										]

										Nuova lista di campi per profilo completato:
										'phoneNumber',
										'spokenLocales',
										'vatNumber',
										'incorporationYear',
										'clubNature',
										'legalRepresentativeId' || ('legalRepresentativeFirstName' && 'legalRepresentativeLastName'),
										'address.streetAddress',
										'address.locality'
										'address.postalCode'
										'address.country'
										'address.province'
										'sports',
										*/
									}

									{/*profileStatus==='INACTIVE' && (
										<center>
										<Link to={'#'} className="btn btn-sm btn-danger mt-2">
											<Information variant="Bulk" /><span className='ms-2'>Profilo non attivo</span>
										</Link>
										</center>
									)*/}

									{/*profileStatus==='ACTIVE' && (
										<center>
										<Link to={'#'} className="btn btn-sm btn-profile mt-2">
											<Information variant="Bulk" /><span className='ms-2'>Profilo non completato</span>
										</Link>
										</center>
									)*/}

									{profileStatus!=='COMPLETED' && (
										<center>
										<Link to={'#'} className="btn btn-sm btn-profile mt-2">
											<Information variant="Bulk" /><span className='ms-2'>Profilo non completato</span>
										</Link>
										</center>
									)}
								</Card.Body>
							</Card>
						</div>
					</Row>
				</Tab.Container>
			</div>

		</>
	)

}
export default LaMiaStruttura;