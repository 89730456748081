import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Tab, Nav, Modal, Spinner } from "react-bootstrap";
import { People, Profile, TableDocument, Teacher, Information, Profile2User, Health, MedalStar, DocumentText, Note, ArrangeHorizontal} from "iconsax-react";
import { Link, useLocation, useParams } from 'react-router-dom'
import IdsPlayer from "../../../../components/Clienti/ids_player";
import DatiSportPlayer from "../../../../components/Clienti/datiSportivi_player";
import DatiSanPlayer from "../../../../components/Clienti/datiSanitari_player";
import FormaFisPlayer from "../../../../components/Clienti/formaFisica_player";
import TraguardiPlayer from "../../../../components/Clienti/traguardi_player";
import StudiPlayer from "../../../../components/Clienti/studi_player";
import DocumentiPlayer from "../../../../components/Clienti/Documents_player";
import JuniorPlayer from "../../../../components/Clienti/junior_player";
import SquadrePlayer from "../../../../components/Clienti/squadre_player"; 
import NotePlayer from "../../../../components/Clienti/note_player";
import MyApiClient from "../../../../../api-client/MyApiClient";
import DynamicLogo from "../../../DynamicLogoLoader";
import DEFAULT_USER from "../../../../../images/DEFAULT_USER.png";

export const PlayerEdit = () => {

	const { id } = useParams();

	const [loadingShowed, setLoadingShowed] = useState(false);

	const [profile, setProfile] = useState(null);
	const [labelNome, setLabelNome] = useState('');

	const tabData = [
		{
			id: 'ids',
			name: "IDS",
			icon: <Profile size='18' variant='Bulk' />,
			content: <IdsPlayer isDisabled={true} profile={profile} />,
		},
		{
			id: 'datisportivi',
			name: "Dati sportivi",
			icon: <TableDocument size='18' />,
            content: <DatiSportPlayer profile={profile} />,
		},
		{
			id: 'datisanitari',
			name: "Dati sanitari",
			icon: <Health size='18' />,
			content: <DatiSanPlayer profile={profile} />,
		},
		{
			id: 'formafisica',
			name: "Forma fisica",
			icon: <Health variant="Bulk" size='18' />,
			content: <FormaFisPlayer profile={profile} />,
		},
		{
			id: 'traguardi',
			name: "Traguardi",
			icon: <MedalStar size='18' variant="Bulk" />,
			content: <TraguardiPlayer profile={profile} />,
		},
		{
			id: 'studi',
			name: "Studi",
			icon: <Teacher size='18' />,
			content: <StudiPlayer profile={profile} />,
		},
		{
			id: 'documenti',
			name: "Documenti",
			icon: <DocumentText size='18' variant="Bulk" />,
			content: <DocumentiPlayer profile={profile} />,
		},
		{
			id: 'junior',
			name: "Junior",
			icon: <Profile2User size='18' variant="Bulk" />,
			content: <JuniorPlayer profile={profile} />,
		},
		{
			id: 'squadre',
			name: "Squadre",
			icon: <People size='18' variant="Bulk" />,
			content: <SquadrePlayer profile={profile} />,
		}/*,
		{
			id: 'noteprivate',
			name: "Note private",
			icon: <Note size='18' variant="Bulk" />,
			content:<NotePlayer profile={profile} />,
		},*/
	];

	const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [defaultTab, setDefaultTab] = useState(query.get('tab') || tabData[0].name.toLowerCase());

    useEffect(() => {
        const tab = query.get('tab');
		if (tab && tab !== defaultTab) {
            setDefaultTab(tab);
        }
    }, [location.search, defaultTab]);

	useEffect(() => {
        const fetchData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

					setLoadingShowed(true);
                    const [profileData] = await Promise.all([
                        apiClient.getProfiles(token, id)
                    ]);
					setLoadingShowed(false);

					let labelNomeTemp = '';
					if(profileData.idsPlay) {
						labelNomeTemp = profileData.idsPlay.givenName+' '+profileData.idsPlay.familyName;
					} else if(profileData.idsGuru) {
						window.location.href = '/struttura/maestri/maestro_profile/'+profileData.idsGuru.userId;
					}
					setLabelNome(labelNomeTemp);

					setProfile(profileData);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

	const isNotCompleted = profile && profile.idsPlay.profileStatus != 'COMPLETED' && profile.idsPlay.profileStatus != 'ACTIVE';

	return (
		<>
			<Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

			<div className="custom-tab-1 mtnegativo2">
				<Tab.Container defaultActiveKey={defaultTab}>
					<Nav as="ul" className="nav-tabs justify-content-center" style={{ border: 'none' }}>
						{tabData.map((data, i) => (
							<Nav.Item as="li" key={i} className="tabMenu">
								<Nav.Link eventKey={data.id.toLowerCase()}>
									{data.icon}{data.name}
								</Nav.Link>
							</Nav.Item>
						))}
					</Nav>
					<Row className="align-items-start mt-4">
						<div className="col-xl-9">
							<Tab.Content>
								{tabData.map((data, i) => (
									<Tab.Pane eventKey={data.id.toLowerCase()} key={i}>
										{data.content}
									</Tab.Pane>
								))}
							</Tab.Content>
							<div className="d-flex justify-content-end gap-4 formDisabled">
								<Link to={"#"} className="btn btn-dark light mr-4">Indietro</Link><Link to="#" className="btn btn-primary ">Salva</Link>

							</div>
						</div>
						<div className="col-xl-3">
							<Card>
								<Card.Body className="text-center">
									{profile && profile.idsPlay.picture && <DynamicLogo logoUrl={profile ? profile.idsPlay.picture : ''} />}
									{(!profile || !profile.idsPlay.picture) && <img src={DEFAULT_USER} style={{width: '100%'}} />}
									{/*<img src={playerfoto} style={{width:'60%'}} className="bd-tondo"/>*/}
									<h4 className="text-start mb-0 mt-4 text-warning">{labelNome}</h4>
									<p className="text-start mt-0 pt-0">Socio</p>
									{profile && profile.idsGuru && <Link to={'/struttura/clienti/maestro_profile/'+profile.idsGuru} className="btn btn-sm btn-booking"><ArrangeHorizontal variant="Bulk" /><span className='ms-2'>Visualizza profilo Staff Tecnico</span></Link>}
									
									{isNotCompleted && (
										<Link to={'#'} className="btn btn-sm btn-profile mt-2">
											<Information variant="Bulk" /><span className='ms-2'>Profilo non completato</span>
										</Link>
									)}
								</Card.Body>
							</Card>
						</div>
					</Row>

				</Tab.Container>
			</div>
		</>
	)
}

export default PlayerEdit;