import React, { useState, useEffect } from 'react';
import { Form, Button, Dropdown, Modal, Row, Col, Spinner } from "react-bootstrap";
import { Add, More, Trash } from 'iconsax-react';
import Select from "react-select";
import TabellaLista from '../../Tabella/TabellaLista';
import MyApiClient from '../../../../api-client/MyApiClient';
import nosports from "../../../../images/nosports.png";

/*
FEDERAZIONI NON PASSATO DA API QUINCK
const federazioni = [
	{ value: "FIGC", label: "FIGC" },
	{ value: "ASI", label: "ASI" },
	{ value: "ASDI", label: "ASDI" },
	{ value: "FIN", label: "FIN" }
];
*/

const sports = [
	{ value: "RUNNING", label: "Corsa" },
	{ value: "BIKING", label: "Ciclismo" },
	{ value: "TENNIS", label: "Tennis" },
	{ value: "PADEL", label: "Padel" },
	{ value: "SKIING", label: "Sci" },
	{ value: "CROSS_COUNTRY_SKIING", label: "Fondo" },
	{ value: "SNOWBOARD", label: "Snowboard" },
	{ value: "SWIMMING", label: "Nuoto" },
	{ value: "GOLF", label: "Golf" },
	{ value: "FOOTBALL", label: "Calcio" },
	{ value: "FIVE_A_SIDE_FOOTBALL", label: "Calcio a 5" },
	{ value: "VOLLEYBALL", label: "Pallavolo" },
	{ value: "BASKETBALL", label: "Basket" },
	{ value: "ICE_HOCKEY", label: "Hockey su ghiaccio" },
	{ value: "RUGBY", label: "Rugby" }
];

export const FormSport = (props) => {

	const [selectedSport, setSelectedSport] = useState('');
	const [data_json, setDataJson] = useState(null);
	const [modalCentered, setModalCentered] = useState(false);

	const [modalShowed, setModalShowed] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalText, setModalText] = useState('');

	const [confirmShowed, setConfirmShowed] = useState(false);
	const [onContinue, setOnContinue] = useState(null);

	const [loadingShowed, setLoadingShowed] = useState(false);

	const handleSelectChange = (selectedOptions) => {
		setSelectedSport(selectedOptions.value);
    };

	const data_column = [
		{
			Header: 'Sport',
			accessor: 'sport_name',
		},
		/*
		{
			Header: 'Federazione',
			accessor: 'sport_federazione',
		},
		*/
		{
			Header: 'Azione',
			Footer: 'Azione',
			accessor: 'azione',
			Cell: ({ row }) => {
				return (
					!props.isDisabled &&
					<Dropdown>
						<Dropdown.Toggle className="btn-primary light sharp i-false btn-action">
							<More />
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{/*
							<Dropdown.Item><span className="me-2"><Edit2 size="20" /></span>Modifica</Dropdown.Item>
							<br />
							*/}
							<Dropdown.Item onClick={() => deleteSports(row.original)} className="text-danger"><span className="me-2"><Trash size="20" /></span>Cancella</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				)
			},
			disableFilters: true,
		},
	];

	function updateSportsTable(club_sports) {
		const updatedDataJson = club_sports.map((sport, index) => {
			const sportType = sport.sportType;
			let sportLabel = '';
			sports.forEach(element => {
				if(element.value === sportType) {
					sportLabel = element.label;
				}
			});
			return {
				id: index + 1,
				sport_name: sportLabel,
				// sport_federazione: '',
				type: sportType
			};
		});
		setDataJson(updatedDataJson);
	}

	useEffect(() => {
		if (props.profile && props.profile.club && props.profile.club.sports) {
            updateSportsTable(props.profile.club.sports);
        } else {
            setDataJson([]);
        }
    }, [props]);

	const deleteSports = (row) => {
		setOnContinue(() => () => continueDeleteSport(row));
		setConfirmShowed(true);
	};

	const continueDeleteSport = (row) => {

		setConfirmShowed(false);
		let removeSport = row.type;

		let sports_send = [];
		data_json.forEach(element => {
			if(element.type !== removeSport) {
				sports_send.push({"sportType": element.type});
			}
		});
		
		updateProfile(sports_send);
	}

	const addSports = (e) => {
        e.preventDefault();

		setModalCentered(false);

		if(selectedSport==='') {
			setModalTitle("Errore durante l'operazione");
			setModalText("Lo sport deve essere valorizzato.");
			setModalShowed(true);
			return;
		}
        
		let existing_sp = false;
		data_json.forEach(element => {
			if(element.type === selectedSport) {
				existing_sp = true;
			}
		});

		if(existing_sp) {
			setModalTitle("Errore durante l'operazione");
			setModalText("Lo sport è già presente nella tua struttura.");
			setModalShowed(true);
			return;
		}

        let sports_send = [];
		data_json.forEach(element => {
			sports_send.push({"sportType": element.type});
		});
		sports_send.push({ "sportType": selectedSport });
		updateProfile(sports_send);
    };

	function updateProfile(sports_send) {

		const saveData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

                    let profile_send = JSON.parse(JSON.stringify(props.profile));
					profile_send.club.sports = sports_send;
                    delete profile_send.club.logo;
                    delete profile_send.club.pictures;

					setLoadingShowed(true);
                    const saveResult = await apiClient.updateProfile(token, profile_send);
					setLoadingShowed(false);

                    if(saveResult && saveResult.error) {
                        setModalTitle("Errore durante l'operazione");
                        setModalText("Si è verificato un errore durante il salvataggio ("+saveResult.error+")");
                        setModalShowed(true);
                    } else {
						window.location.href = '/struttura/lamiastruttura?tab=sport';
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        saveData();
	}

	return (
		<>
			<Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

			<Modal className="fade" show={confirmShowed} centered>
                <Modal.Header>
                    <Modal.Title>Sei sicuro?</Modal.Title>
                    <Button onClick={() => setConfirmShowed(false)} variant="" className="btn-close">
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Row><Col className="col-xl-64">Sei sicuro di volere continuare?</Col></Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button className="btn btn-light btn-add" onClick={() => setConfirmShowed(false)}>
                        Chiudi
                    </Button>
					<Button className="btn btn-primary btn-add" onClick={onContinue}>
                        Continua
                    </Button>
                </Modal.Footer>
            </Modal>

			<Modal className="fade" show={modalShowed} centered>
                <Modal.Header>
                    <Modal.Title>{modalTitle}</Modal.Title>
                    <Button onClick={() => setModalShowed(false)} variant="" className="btn-close">
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Row><Col className="col-xl-64">{modalText}</Col></Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button className="btn btn-primary btn-add" onClick={() => setModalShowed(false)}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>

			<Modal className="fade" show={modalCentered} centered>
				<Modal.Header>
					<Modal.Title>Aggiungi Sport</Modal.Title>
					<Button
						onClick={() => setModalCentered(false)}
						variant=""
						className="btn-close"
					>
					</Button>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Row>
							<Col className="col-lg-12 pb-4">
								<Form.Label>Seleziona sport</Form.Label>
								<Select
									name="sport" 
									value={sports.filter(option => selectedSport === option.value)} onChange={handleSelectChange}
									options={sports}
									style={{
										lineHeight: "40px",
										color: "#fd7e14",
										paddingLeft: " 15px",
									}
									}
								/>
							</Col>
							{/*
							<Form.Label>Selezione federazione</Form.Label>
							<Select
								options={federazioni}
								style={{
									lineHeight: "40px",
									color: "#fd7e14",
									paddingLeft: " 15px",
								}
								}
							/>
							*/}
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer className="justify-content-between">
					<Button
						className="btn btn-light btn-add"
						onClick={() => setModalCentered(false)}>
						Annulla
					</Button>
					<Button onClick={addSports} className="btn btn-primary btn-add">Aggiungi</Button>
				</Modal.Footer>
			</Modal>

			{data_json && data_json.length === 0 &&
				<center>
					<div>
						<img src={nosports} style={{width: '50%'}} alt="" />
					</div>
					<div style={{ fontSize: '26px', color: 'grey', marginTop: '30px'}}>
						Nessuno sport inserito
					</div>
					<div style={{ fontSize: '16px', color: 'grey', fontWeight: '300', marginTop: '20px'}}>
						Non hai ancora inserito nessuno sport
						<br></br>
						Inizia adesso e aggiungi il tuo primo sport per vedere il dettaglio qui.
					</div>
					<div>
						<Button style={{ marginTop: '20px'}} onClick={() => setModalCentered(true)} className="btn btn-primary btn-sm btn-add" ><Add variant="Bulk" /><span className='ms-2'>Aggiungi</span></Button>
					</div>
				</center>
			}

			{data_json && data_json.length>0 &&
				<TabellaLista
					cardTitle={'Lista Sport'}
					hasSearch={false}
					hasFilter={false}
					data_column={data_column}
					data_json={data_json}
					hasAddMoreAsModal={props.isDisabled ? false : true}
					modalCallBack={() => setModalCentered(true)}
				/>
			}
		</>
	)
}
export default FormSport;