import React, { Fragment, useState } from "react";
import { Form, Row, Col, FormCheck, InputGroup } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-widgets/DatePicker";
import TimeInput from "react-widgets/TimeInput";

const FormProdottoSemplice = () => {

    const optionsRinnovoAutomatico = [
        { value: "si", label: "Si" },
        { value: "no", label: "No" }
    ]

    const optionsRicorrenza = [
        { value: "mensile", label: "Mensile" },
        { value: "annuale", label: "Annuale" }
    ]

    const [isConScadenza, setConScadenza] = useState(false);
    const [selectedRicorrenzaOption, setSelectedRicorrenzaOption] = useState({ label: "Seleziona...", value: null });
    const [selectedRinnovoAutomaticoOption, setSelectedRinnovoAutomaticoOption] = useState({ value: "si", label: "Si" });


    const handleCheckboxConScadenza = () => {
        setConScadenza(!isConScadenza);
    };

    return (

        <Fragment>

            <Row>
                <Col md="12">
                    <Form.Group controlId="prodottoConScadenza">
                        <FormCheck
                            className="d-flex"
                            type="checkbox"
                            label="Questo prodotto ha una scadenza."
                            checked={isConScadenza}
                            onChange={handleCheckboxConScadenza} />
                    </Form.Group>
                </Col>

                {isConScadenza &&

                    <Col md="12">
                        <Row>
                            <Col>
                                <Form.Label>Seleziona data</Form.Label>
                                <DatePicker
                                    defaultValue={new Date()}
                                    valueFormat={{ dateStyle: "medium" }}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Seleziona ora</Form.Label>
                                <TimeInput
                                    style={{ width: "auto" }}
                                    defaultValue={new Date()}
                                />
                            </Col>
                        </Row>
                        <br />
                    </Col>

                }
            </Row>

            <Row>
                <Col>
                    <Form.Label>Rinnovo automatico</Form.Label>
                    <Select
                        defaultValue={selectedRinnovoAutomaticoOption}
                        onChange={setSelectedRinnovoAutomaticoOption}
                        options={optionsRinnovoAutomatico}
                    />
                </Col>
                <Col>
                    <Form.Label>Ricorrenza</Form.Label>
                    <Select
                        {...selectedRinnovoAutomaticoOption.value === "no" && { isDisabled: true }}
                        defaultValue={selectedRicorrenzaOption}
                        onChange={setSelectedRicorrenzaOption}
                        options={optionsRicorrenza}
                    />
                </Col>
            </Row>

            <br />

            <Row>

                <Col>
                    <Form.Label>Prezzo</Form.Label>
                    <InputGroup>
                        <Form.Control type="number" placeholder="Es. 10"></Form.Control>
                        <span className="input-group-text">€</span>
                    </InputGroup>
                </Col>
                <Col>
                    <Form.Label>Pagamento in FitCoin</Form.Label>
                    <InputGroup>
                        <Form.Control type="number" min="5" placeholder="Es. 10"></Form.Control>
                        <span className="input-group-text">%</span>
                    </InputGroup>
                </Col>
                <Col>
                    <Form.Label>Prezzo per soci</Form.Label>
                    <InputGroup>
                        <Form.Control type="number" placeholder="Es. 10"></Form.Control>
                        <span className="input-group-text">€</span>
                    </InputGroup>
                </Col>
                <Col>
                    <Form.Label>Pagamento in Fitcoin Soci</Form.Label>
                    <InputGroup>
                        <Form.Control type="number" min="5" placeholder="Es. 10"></Form.Control>
                        <span className="input-group-text">%</span>
                    </InputGroup>
                </Col>

            </Row>

            <br />

            <Row>
                <Col>
                    <Form.Group controlId="prodottoConListaAttesa">
                        <FormCheck className="d-flex" type="checkbox" label="Prodotto con lista di attesa aperta" />
                    </Form.Group>
                </Col>
            </Row>

        </Fragment>

    )
}

export default FormProdottoSemplice
