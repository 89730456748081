import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button, Modal, Row, Col, Card, Spinner } from "react-bootstrap";
import Select from "react-select";
import MyApiClient from '../../../../api-client/MyApiClient';

const langOptions = [
    { value: "ar", label: "Arabo" },
    { value: "de", label: "Tedesco" },
    { value: "en", label: "Inglese" },
    { value: "it", label: "Italiano" },
    { value: "es", label: "Spagnolo" },
    { value: "fr", label: "Francese" },
    { value: "ru", label: "Russo" }
];

const optionsTipologia = [
    { value: "ASD", label: "ASD" },
    { value: "SSD", label: "SSD" },
    { value: "SCHOOL", label: "Scuola" },
    { value: "GYM", label: "Palestra" },
    { value: "RENTAL_SHOP", label: "Rental Shop" },
    { value: "CLUB", label: "Club" },
];

const FormIDS = (props) => {

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        phoneNumber: '',
        languages: [],
        vatNumber: '',
        incorporationYear: '',
        websiteUrl: '',
        description: '',
        streetAddress: '',
        locality: '',
        country: '',
        legalRepresentativeId: '',
        legalRepresentativeFirstName: '',
        legalRepresentativeLastName: '',
        postalCode: '',
        province: ''
    });

    const [walletFee, setWalletFee] = useState({ "fee" : '' });
    const [modalShowed, setModalShowed] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalText, setModalText] = useState('');
    const [loadingShowed, setLoadingShowed] = useState(false);

    const [memberOptions, setMemberOptions] = useState([]);
    
    const [isLegalRepresentativeNotMember, setIsLegalRepresentativeNotMember] = useState(false);
    const handleCheckboxChange = (event) => {
        setIsLegalRepresentativeNotMember(event.target.checked);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelectChange = (selectedOptions) => {
        const selectedLanguages = selectedOptions.map(option => option.value);
        setFormData(prevState => ({
            ...prevState,
            languages: selectedLanguages
        }));
    };

    const handleSelectTipologiaStruttura = (selectedOptions) => {
		setFormData(prevState => ({
            ...prevState,
            clubNature: selectedOptions.value
        }));
    };

    const handleSelectMemberStruttura = (selectedOptions) => {
		setFormData(prevState => ({
            ...prevState,
            legalRepresentativeId: selectedOptions.value
        }));
    };

    const handleSaveClick = (e) => {
        e.preventDefault();

        const saveData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

                    let profile_send = JSON.parse(JSON.stringify(props.profile));

                    profile_send.club.name = formData.name;
                    profile_send.club.phoneNumber = formData.phoneNumber;
                    profile_send.club.vatNumber = formData.vatNumber;
                    profile_send.club.incorporationYear = formData.incorporationYear;
                    profile_send.club.description = formData.description;
                    profile_send.club.legalRepresentativeId = formData.legalRepresentativeId!=='' ? formData.legalRepresentativeId : null;
                    profile_send.club.legalRepresentativeFirstName = formData.legalRepresentativeFirstName;
                    profile_send.club.legalRepresentativeLastName = formData.legalRepresentativeLastName;
                    
                    profile_send.club['address'] = {}
                    profile_send.club.address.streetAddress = formData.streetAddress;
                    profile_send.club.address.locality = formData.locality;
                    profile_send.club.address.country = formData.country;
                    profile_send.club.address.postalCode = formData.postalCode;
                    profile_send.club.address.province = formData.province;

                    profile_send.club.spokenLocales = formData.languages;
                    profile_send.club.clubNature = formData.clubNature;
                    delete profile_send.club.logo;
                    delete profile_send.club.pictures;

                    setLoadingShowed(true);
                    const saveResult = await apiClient.updateProfile(token, profile_send);
                    setLoadingShowed(false);

                    if (saveResult && saveResult.error) {
                        setModalTitle("Errore durante l'operazione");
                        setModalText("Si è verificato un errore durante il salvataggio (" + saveResult.error + ")");
                        setModalShowed(true);
                    } else {
                        navigate(0);
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        saveData();
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

                    const [walletFeeData, membershipDataUsers] = await Promise.all([
                        apiClient.getWalletFee(token),
                        apiClient.getMemberships(token,'MEMBER',null)
                    ]);

                    if(membershipDataUsers) {
						let data_member = [];
						membershipDataUsers.forEach(element => {
							let member_row = {
								"value": element.userId,
								"label": element.name
							};
							data_member.push(member_row);
						})

						setMemberOptions(data_member);
					}

                    setWalletFee(walletFeeData);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {

        if (props.profile && props.profile.club) {
            let structureData = props.profile;

            if (structureData && structureData.club) {
                let myFormData = {};
                myFormData.name = structureData.club.name ? structureData.club.name : '';
                myFormData.phoneNumber = structureData.club.phoneNumber ? structureData.club.phoneNumber : '';
                myFormData.languages = structureData.club.spokenLocales ? structureData.club.spokenLocales : [];
                myFormData.clubNature = structureData.club.clubNature ? structureData.club.clubNature : '';
                myFormData.vatNumber = structureData.club.vatNumber ? structureData.club.vatNumber : '';
                myFormData.incorporationYear = structureData.club.incorporationYear ? structureData.club.incorporationYear : null;
                myFormData.websiteUrl = '';
                myFormData.description = structureData.club.description ? structureData.club.description : '';
                myFormData.streetAddress = structureData.club.address ? structureData.club.address.streetAddress : '';
                myFormData.locality = structureData.club.address ? structureData.club.address.locality : '';
                myFormData.country = structureData.club.address ? structureData.club.address.country : '';
                myFormData.legalRepresentativeId = structureData.club.legalRepresentativeId ? structureData.club.legalRepresentativeId : '';
                myFormData.legalRepresentativeFirstName = structureData.club.legalRepresentativeFirstName ? structureData.club.legalRepresentativeFirstName : '';
                myFormData.legalRepresentativeLastName = structureData.club.legalRepresentativeLastName ? structureData.club.legalRepresentativeLastName : '';
                myFormData.postalCode = structureData.club.address ? structureData.club.address.postalCode : '';
                myFormData.province = structureData.club.address ? structureData.club.address.province : '';

                if(myFormData.legalRepresentativeFirstName && myFormData.legalRepresentativeLastName) {
                    setIsLegalRepresentativeNotMember(true);
                }

                setFormData(myFormData);
            }
        }

    }, [props]);

    return (
        <>
            <Modal className="fade" show={modalShowed} centered>
                <Modal.Header>
                    <Modal.Title>{modalTitle}</Modal.Title>
                    <Button onClick={() => setModalShowed(false)} variant="" className="btn-close">
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Row><Col className="col-xl-64">{modalText}</Col></Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button className="btn btn-primary btn-add" onClick={() => setModalShowed(false)}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

            <Card>
                <Card.Header><h4 className="card-title">IDS</h4></Card.Header>
                <Card.Body>
                    <Form>
                        <Row>
                            <Col className="col-xl-6">
                                <Form.Label>Nome</Form.Label>
                                <Form.Control style={{color: '#000000'}} placeholder='Inserisci Nome' type="text" name="name" value={formData.name} onChange={handleChange} disabled={props.isDisabled ? true : false} />
                            </Col>
                            <Col className="col-xl-6">
                                <Form.Label>Telefono</Form.Label>
                                <Form.Control style={{color: '#000000', borderColor: formData.phoneNumber ? '#ced4da' : '#ff0000'}} placeholder='Inserisci Telefono' type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} disabled={props.isDisabled ? true : false} />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col className="col-xl-6">
                                <Form.Label>Email</Form.Label>
                                <Form.Control style={{color: '#000000'}} placeholder='Inserisci E-mail' type="email" name="email" value={formData.email} onChange={handleChange} disabled={props.isDisabled ? true : false} />
                            </Col>
                            <Col className="col-xl-6">
                                <Form.Label>Lingue parlate</Form.Label>
                                <Select
                                    placeholder="Seleziona lingue"
                                    value={langOptions.filter(option => formData.languages.includes(option.value))}
                                    isMulti
                                    options={langOptions}
                                    onChange={handleSelectChange}
                                    isDisabled={props.isDisabled}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            borderColor: formData.languages.length === 0 ? '#ff0000' : '#ced4da',
                                            color: "#000000",
                                            paddingLeft: "15px",
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col className="col-xl-6">
                                <Form.Label>Partita IVA</Form.Label>
                                <Form.Control style={{color: '#000000', borderColor: formData.vatNumber ? '#ced4da' : '#ff0000'}} placeholder='Inserisci Partita IVA' type="text" name="vatNumber" value={formData.vatNumber} onChange={handleChange} disabled={props.isDisabled ? true : false} />
                            </Col>
                            <Col className="col-xl-6">
                                <Form.Label>Anno di costituzione</Form.Label>
                                <Form.Control style={{color: '#000000', borderColor: formData.incorporationYear ? '#ced4da' : '#ff0000'}} placeholder='Inserisci anno di costituzione' type="text" name="incorporationYear" value={formData.incorporationYear} onChange={handleChange} disabled={props.isDisabled ? true : false} />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col className="col-xl-6">
                                <Form.Label>Tipologia giuridica</Form.Label>
                                <Select
                                    placeholder="Seleziona la tipologia"
                                    value={optionsTipologia.filter(option => formData.clubNature === option.value)}
                                    onChange={handleSelectTipologiaStruttura}
                                    options={optionsTipologia}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            borderColor: formData.clubNature === '' ? '#ff0000' : '#ced4da',
                                            color: "#000000",
                                            paddingLeft: "15px",
                                        })
                                    }}
                                />
                            </Col>
                            <Col className="col-xl-6">
                                <Form.Label>URL sito web</Form.Label>
                                <Form.Control style={{color: '#000000'}} placeholder='www.sitoweb.it' type="text" name="websiteUrl" value={formData.websiteUrl} onChange={handleChange} disabled={props.isDisabled ? true : false} />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col className="col-xl-12">
                                <Form.Label>Descrizione</Form.Label>
                                <Form.Control rows={10} style={{color: '#000000'}} placeholder='Inserisci la descrizione della tua struttura' as="textarea" name="description" value={formData.description} onChange={handleChange} disabled={props.isDisabled ? true : false} />
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
                <Card.Header><h5>Rappresentante legale</h5></Card.Header>
                <Card.Body>
                    <Form>
                        {!isLegalRepresentativeNotMember && <Row className="mt-4">
                            <Col className="col-xl-12">
                                <Form.Label>Rappresentante legale</Form.Label>
                                <Select
                                    placeholder="Seleziona il socio rappresentante legale"
                                    value={memberOptions.filter(option => formData.legalRepresentativeId === option.value)}
                                    onChange={handleSelectMemberStruttura}
                                    options={memberOptions}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            borderColor: formData.legalRepresentativeId === '' ? '#ff0000' : '#ced4da',
                                            color: "#000000",
                                            paddingLeft: "15px",
                                        })
                                    }}
                                />
                            </Col>
                        </Row>}
                        <Row className="mt-4">
                            <Col className="col-xl-12">
                                <div>
                                    <label>
                                        <input
                                        type="checkbox"
                                        checked={isLegalRepresentativeNotMember}
                                        onChange={handleCheckboxChange}
                                        />
                                        &nbsp;&nbsp;&nbsp;Il rappresentante legale non fa parte dei soci
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        {isLegalRepresentativeNotMember && <Row className="mt-4">
                            <Col className="col-xl-6">
                                <Form.Label>Nome</Form.Label>
                                <Form.Control type="text" style={{color: '#000000', borderColor: formData.legalRepresentativeFirstName ? '#ced4da' : '#ff0000'}} placeholder='Inserisci nome' name="legalRepresentativeFirstName" value={formData.legalRepresentativeFirstName} onChange={handleChange} disabled={props.isDisabled ? true : false} />
                            </Col>
                            <Col className="col-xl-6">
                                <Form.Label>Cognome</Form.Label>
                                <Form.Control type="text" style={{color: '#000000', borderColor: formData.legalRepresentativeLastName ? '#ced4da' : '#ff0000'}} placeholder='Inserisci cognome' name="legalRepresentativeLastName" value={formData.legalRepresentativeLastName} onChange={handleChange} disabled={props.isDisabled ? true : false} />
                            </Col>
                        </Row>}
                    </Form>
                </Card.Body>
                <Card.Header><h5>Indirizzo</h5></Card.Header>
                <Card.Body>
                    <Form>
                        <Row>
                            <Col className="col-xl-4">
                                <Form.Label>Via</Form.Label>
                                <Form.Control type="text" style={{color: '#000000', borderColor: formData.streetAddress ? '#ced4da' : '#ff0000'}} placeholder='Inserisci indirizzo' name="streetAddress" value={formData.streetAddress} onChange={handleChange} disabled={props.isDisabled ? true : false} />
                            </Col>
                            <Col className="col-xl-4">
                                <Form.Label>Località</Form.Label>
                                <Form.Control type="text" style={{color: '#000000', borderColor: formData.locality ? '#ced4da' : '#ff0000'}} placeholder='Inserisci località' name="locality" value={formData.locality} onChange={handleChange} disabled={props.isDisabled ? true : false} />
                            </Col>
                            <Col className="col-xl-4">
                                <Form.Label>Provincia</Form.Label>
                                <Form.Control type="text" style={{color: '#000000', borderColor: formData.province ? '#ced4da' : '#ff0000'}} placeholder='Inserisci provincia' name="province" value={formData.province} onChange={handleChange} disabled={props.isDisabled ? true : false} />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col className="col-xl-6">
                                <Form.Label>CAP</Form.Label>
                                <Form.Control type="text" style={{color: '#000000', borderColor: formData.postalCode ? '#ced4da' : '#ff0000'}} placeholder='Inserisci CAP' name="postalCode" value={formData.postalCode} onChange={handleChange} disabled={props.isDisabled ? true : false} />
                            </Col>
                            <Col className="col-xl-6">
                                <Form.Label>Paese</Form.Label>
                                <Form.Control type="text" style={{color: '#000000', borderColor: formData.country ? '#ced4da' : '#ff0000'}} placeholder='Inserisci paese' name="country" value={formData.country} onChange={handleChange} disabled={props.isDisabled ? true : false} />
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
                <Card.Header><h5>Commissioni SportID</h5></Card.Header>
                <Card.Body>
                    <Row>
                        <Col className="col-xl-12">
                            <Form.Label>Commissioni</Form.Label>
                            {walletFee.fee}%
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {!props.isDisabled && <div className="d-flex justify-content-end gap-4"><Link onClick={handleSaveClick} to="#" className="btn btn-primary ">Salva</Link></div>}
        </>
    )
}

export default FormIDS