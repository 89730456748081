import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter';
import { Button, Col, Dropdown, Modal, Row, Spinner } from "react-bootstrap";
import { format } from 'date-fns';
import { Add, More, FilterSquare, UserCirlceAdd, UserOctagon, Edit2, Trash } from 'iconsax-react';
import TabellaLista from '../../../../components/Tabella/TabellaLista';
import MyApiClient from '../../../../../api-client/MyApiClient';

const options = [
	{ value: "PLAYERS", label: "Players" },
	{ value: "GURUS", label: "Maestri" },
	{ value: "JUNIORS", label: "Junior" },
	{ value: "CLUB_MEMBERS", label: "Soci" },
	{ value: "CLUB_COACHES", label: "Staff Tecnico" },
	{ value: "CLUB_DIRECTORS", label: "CDA" },
];

export const ComunicazioniGenericheLista = () => {

	const [modalShowed, setModalShowed] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalText, setModalText] = useState('');

	const [confirmShowed, setConfirmShowed] = useState(false);
	const [onContinue, setOnContinue] = useState(null);

	const [loadingShowed, setLoadingShowed] = useState(false);

	const data_column = [

		{
			Header: 'Data',
			Footer: 'Data',
			accessor: 'comunicazione_date',
			Filter: ColumnFilter
		},
		{
			Header: 'Titolo',
			Footer: 'Titolo',
			accessor: 'comunicazione_title',
			Filter: ColumnFilter
		},

		{
			Header: 'Destinatari',
			Footer: 'Destinatari',
			accessor: 'comunicazione_sendto',
			Filter: ColumnFilter
		},
		/*
		{
			Header: 'Nr destinatari',
			Footer: 'Nr destinatari',
			accessor: 'comunicazione_number',
			Filter: ColumnFilter
		},
		*/
		{
			Header: 'Stato',
			Footer: 'Stato',
			accessor: 'comunicazioni_status',
			Filter: ColumnFilter,
			Cell: ({ value }) => {
				return (
					<div className="d-flex align-items-center">
						<i className={`fa fa-circle me-1 ${value?value.class:''}`}></i>
						{value?value.label:''}
					</div>
				)
			},
			disableFilters: true,
		},
		{
			Header: 'Azione',
			Footer: 'Azione',
			accessor: 'azione',
			Cell: ({ row }) => {

				if(row && row.original.comunicazioni_status.label=='Programmata') {
					return (
						<Dropdown>
							<Dropdown.Toggle className="btn-primary light sharp i-false btn-action">
								<More />
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => editDoc(row.original)} ><span className="me-2"><Edit2 size="20" /></span> Modifica</Dropdown.Item>
								<Dropdown.Item onClick={() => deleteDoc(row.original)} className="text-danger"><span className="me-2"><Trash size="20" /></span>Cancella</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					)
				} else {
					return (
						<Dropdown>
							<Dropdown.Toggle className="btn-primary light sharp i-false btn-action">
								<More />
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => editDoc(row.original)} ><span className="me-2"><Edit2 size="20" /></span> Visualizza</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					)
				}
			},
			disableFilters: true,
		}
	];

	const deleteDoc = (row) => {
		setOnContinue(() => () => continueDeleteDoc(row));
		setConfirmShowed(true);
    };

	const editDoc = (row) => {
		window.location.href = '/struttura/comunicazioni/generiche/'+row.id;
    };

	const continueDeleteDoc = (row) => {

		setConfirmShowed(false);
		
		const deleteData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

					setLoadingShowed(true);
                    const saveResult = await apiClient.deleteCommunications(token, row.id);
					setLoadingShowed(false);

                    if(!saveResult) {
                        setModalTitle("Errore durante l'operazione");
                        setModalText("Si è verificato un errore durante la cancellazione");
                        setModalShowed(true);
                    } else {
						window.location.href = '/struttura/comunicazioni/generiche';
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        deleteData();
	};

	const [data_json, setDataJson] = useState(null);

	useEffect(() => {
        const fetchData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    let token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

					setLoadingShowed(true);
					const [documentsData] = await Promise.all([
                        apiClient.getCommunications(token)
                    ]);
					setLoadingShowed(false);

					if(documentsData) {
						let data_requests = [];
						documentsData.forEach(element => {
							if(element.type=='GENERIC') {

								let status = {
									"label": "Programmata",
									"class": "text-blue",
								};

								if(element.scheduledAt<Date.now()) {
									status = {
										"label": "Inviata",
										"class": "text-success",
									};
								}

								let sendto = '';
								element.recipientGroups.forEach(element => {
									options.forEach(element2 => {
										if(element2.value == element) {
											sendto += element2.label+'. ';
										}
									});
								});

								let member_row = {
									"id": element.id,
									"comunicazione_date": format(element.scheduledAt, 'dd/MM/yyyy HH:mm:ss'),
									"comunicazione_title": element.subject,
									"comunicazioni_status": status,
									"comunicazione_sendto": sendto
								};
								data_requests.push(member_row);
							}
						});
						setDataJson(data_requests);
					} else {
						setDataJson([]);
					}
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

    }, []);

	return (
		<>
			<Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

			<Modal className="fade" show={modalShowed} centered>
                <Modal.Header>
                    <Modal.Title>{modalTitle}</Modal.Title>
                    <Button onClick={() => setModalShowed(false)} variant="" className="btn-close">
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Row><Col className="col-xl-64">{modalText}</Col></Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button className="btn btn-primary btn-add" onClick={() => setModalShowed(false)}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>

			<Modal className="fade" show={confirmShowed} centered>
                <Modal.Header>
                    <Modal.Title>Sei sicuro?</Modal.Title>
                    <Button onClick={() => setConfirmShowed(false)} variant="" className="btn-close">
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Row><Col className="col-xl-64">Sei sicuro di volere continuare?</Col></Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button className="btn btn-light btn-add" onClick={() => setConfirmShowed(false)}>
                        Chiudi
                    </Button>
					<Button className="btn btn-primary btn-add" onClick={onContinue}>
                        Continua
                    </Button>
                </Modal.Footer>
            </Modal>

			<TabellaLista
				cardTitle={'Lista Comunicazioni generiche'}
				hasSearch={true}
				hasFilter={true}
				hasAddMore={true}
				labelAddMore={'Nuova comunicazione'}
				linkAddMore={'/struttura/comunicazioni/generiche/add'}
				data_column={data_column}
				data_json={data_json}
			/>
		</>
	)

}
export default ComunicazioniGenericheLista;