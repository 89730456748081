import React,{ Fragment} from 'react';

const DashboardMaestro = () => {
	
	return(
		<Fragment>
			Qui Dashboard Maestro
		</Fragment>
	)
}
export default DashboardMaestro;