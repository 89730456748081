import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Tab, Nav, Form } from "react-bootstrap";
import Select from "react-select";

const langOptions = [
    { value: "ar", label: "Arabo" },
    { value: "de", label: "Tedesco" },
    { value: "en", label: "Inglese" },
    { value: "it", label: "Italiano" },
    { value: "es", label: "Spagnolo" },
    { value: "fr", label: "Francese" },
    { value: "ru", label: "Russo" }
];

const IdsPlayer = (props) => {

    const [formData, setFormData] = useState({
        givenName: '',
        familyName: '',
        username: '',
        email: '',
        phone: '',
        birthdate: '',
        gender: '',
        nationality: '',
        languages: [],
        languagesLabel: '',
        job: '',
        fiscalCode: '',
        description: '',
        streetAddress: '',
        locality: '',
        country: '',
        postalCode: '',
        province: '',
        profileStatus: ''
    });

    useEffect(() => {
        if (props.profile && props.profile.idsPlay) {
            let playerData = props.profile;

            if(playerData && playerData.idsPlay) {
                let myFormData = {};

                // console.log(playerData.idsPlay);

                myFormData.givenName = playerData.idsPlay.givenName;
                myFormData.familyName = playerData.idsPlay.familyName;
                myFormData.username = playerData.idsPlay.username;
                myFormData.email = playerData.idsPlay.email ? playerData.idsPlay.email : '';
                myFormData.phone = playerData.idsPlay.phone ? playerData.idsPlay.phone : '';
                myFormData.birthdate = playerData.idsPlay.birthdate ? playerData.idsPlay.birthdate : '';
                myFormData.gender = playerData.idsPlay.gender ? playerData.idsPlay.gender.substring(0,1) : '';
                myFormData.nationality = playerData.idsPlay.nationality ? playerData.idsPlay.nationality : '';
                myFormData.languages = playerData.idsPlay.spokenLocales ? playerData.idsPlay.spokenLocales : [];
                myFormData.job = playerData.idsPlay.profession ? playerData.idsPlay.profession : '';
                myFormData.fiscalCode = playerData.idsPlay.fiscalCode ? playerData.idsPlay.fiscalCode : '';
                myFormData.description = playerData.idsPlay.description ? playerData.idsPlay.description : '';
                myFormData.streetAddress = playerData.idsPlay.address ? playerData.idsPlay.address.streetAddress : '';
                myFormData.locality = playerData.idsPlay.address ? playerData.idsPlay.address.locality : '';
                myFormData.country = playerData.idsPlay.address ? playerData.idsPlay.address.country : '';
                myFormData.postalCode = playerData.idsPlay.address ? playerData.idsPlay.address.postalCode : '';
                myFormData.province = playerData.idsPlay.address ? playerData.idsPlay.address.province : '';
                myFormData.profileStatus = playerData.idsPlay.profileStatus ? playerData.idsPlay.profileStatus : '';

                if(myFormData.languages) {
                    let languagesLabelTemp = '';
                    myFormData.languages.forEach(element => {
                        langOptions.forEach(element2 => {
                            if(element == element2.value) {
                                languagesLabelTemp += element2.label+'. ';
                            }
                        });
                    });
                    myFormData.languagesLabel = languagesLabelTemp;
                }
                
                setFormData(myFormData);
            }
        }
		
    }, [props]);

    return (
        <>
            <Row>
                <Card>
                    <Form>
                    <Card.Header><h4 className="card-title">IDS</h4></Card.Header>
                    <Card.Body>
                       
                            <Row className="mt-3">
                                <Col className="col">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control type="text" value={formData.givenName} {...props.isDisabled && { disabled: true }} />
                                </Col>
                                <Col className="col">
                                    <Form.Label>Cognome</Form.Label>
                                    <Form.Control type="text" value={formData.familyName} {...props.isDisabled && { disabled: true }} />
                                </Col>
                                <Col className="col">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" value={formData.email} {...props.isDisabled && { disabled: true }} />
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className="col">
                                    <Form.Label>Telefono</Form.Label>
                                    <Form.Control type="text" value={formData.phone} {...props.isDisabled && { disabled: true }} />
                                </Col>
                                <Col className="col">
                                    <Form.Label>Data di nascita</Form.Label>
                                    <Form.Control type="text" value={formData.birthdate} {...props.isDisabled && { disabled: true }} />
                                </Col>
                                <Col className="col">
                                    <Form.Label>Sesso</Form.Label>
                                    <Form.Control type="text" value={formData.gender} {...props.isDisabled && { disabled: true }} />
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className="col">
                                    <Form.Label>Nazionalità</Form.Label>
                                    <Form.Control type="text" value={formData.nationality} {...props.isDisabled && { disabled: true }} />
                                </Col>
                                <Col className="col">
                                    <Form.Label>Lingue parlate</Form.Label>
                                    {/*!props.isDisabled && <Select
                                        value={langOptions.filter(option => formData.languages.includes(option.value))}
                                        isMulti
                                        options={langOptions}
                                        {...props.isDisabled && { disabled: true }}
                                        style={{
                                            lineHeight: "40px",
                                            color: "#fd7e14",
                                            paddingLeft: " 15px",
                                        }
                                        }
                                    />*/}
                                    {
                                        props.isDisabled && <Form.Control type="text" value={formData.languagesLabel} {...props.isDisabled && { disabled: true }} />
                                    }
                                </Col>
                                <Col className="col">
                                    <Form.Label>Professione</Form.Label>
                                    <Form.Control type="text" value={formData.job} {...props.isDisabled && { disabled: true }} />
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className="col">
                                    <Form.Label>Codice Fiscale</Form.Label>
                                    <Form.Control type="text" value={formData.fiscalCode} placeholder="GRGLRD95H11F839L"  {...props.isDisabled && { disabled: true }} />
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Form.Label>Descrizione</Form.Label>
                                <Form.Control as="textarea" value={formData.description} {...props.isDisabled && { disabled: true }}/>
                            </Row>
                       
                    </Card.Body>
                    <Card.Header><h4 className="card-title">Indirizzo</h4></Card.Header>
                    <Card.Body>
                       
                            <Row className="mt-3">
                                <Col className="col">
                                    <Form.Label>Via</Form.Label>
                                    <Form.Control type="text" value={formData.streetAddress}  {...props.isDisabled && { disabled: true }} />
                                </Col>
                                <Col className="col">
                                    <Form.Label>Località</Form.Label>
                                    <Form.Control type="text" value={formData.locality} {...props.isDisabled && { disabled: true }} />
                                </Col>
                                <Col className="col">
                                    <Form.Label>Provincia</Form.Label>
                                    <Form.Control type="email" value={formData.province} {...props.isDisabled && { disabled: true }} />
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className="col-xl-4">
                                    <Form.Label>CAP</Form.Label>
                                    <Form.Control type="text" value={formData.postalCode} {...props.isDisabled && { disabled: true }} />
                                </Col>
                                <Col className="col-xl-4">
                                    <Form.Label>Paese</Form.Label>
                                    <Form.Control type="text" value={formData.country} {...props.isDisabled && { disabled: true }} />
                                </Col>
                            </Row>
                            </Card.Body>
                    </Form>
                </Card>
            </Row>
        </>
    )
}
export default IdsPlayer;