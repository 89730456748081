import { Fragment } from "react"
import { Col, Row, Form, Card } from 'react-bootstrap'
import { Link } from "react-router-dom"
import Select from "react-select"

const AggiungiUtenteGestionale = () => {

    const options = [
        { value: "lore-ipsum-1", label: "Lorem Ipsum 1" },
        { value: "lore-ipsum-2", label: "Lorem Ipsum 2" },
        { value: "lore-ipsum-3", label: "Lorem Ipsum 3" },
        { value: "lore-ipsum-4", label: "Lorem Ipsum 4" },
    ];

    return (
        <Fragment>
            <Card>
                <Card.Header>
                    <h4 className="card-title">Aggiungi utente</h4>
                </Card.Header>

                <Card.Body>

                    <Form>

                        <Row>

                            <Col xxl="4" xl="4" lg="12" sm="12">
                                <Form.Label>Nome</Form.Label>
                                <Form.Control type="text" placeholder="Inserisci il nome" />
                                <br/>
                            </Col>

                            <Col xxl="4" xl="4" lg="12" sm="12">
                                <Form.Label>Cognome</Form.Label>
                                <Form.Control type="text" placeholder="Inserisci il cognome" />
                                <br/>
                            </Col>

                            <Col xxl="4" xl="4" lg="12" sm="12">
                                <Form.Label>Stato</Form.Label>
                                <Select
                                    defaultValue="ITALIANA"
                                    options={options}
                                    style={{
                                        lineHeight: "40px",
                                        color: "#fd7e14",
                                        paddingLeft: " 15px",
                                    }}
                                />
                                <br/>
                            </Col>



                        </Row>

                        <Row>

                            <Col xxl="4" xl="4" lg="12" sm="12">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Inserisci il nome" />
                                <br/>
                            </Col>

                            <Col xxl="4" xl="4" lg="12" sm="12">
                                <Form.Label>Nome utente</Form.Label>
                                <Form.Control type="text" placeholder="Inserisci il cognome" />
                                <br/>
                            </Col>

                            <Col xxl="4" xl="4" lg="12" sm="12">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Inserisci la password" />
                                <br/>
                            </Col>

                        </Row>

                        <Row>
                            <Col xxl="4" xl="4" lg="12" sm="12">
                                <Form.Label>Ruolo</Form.Label>
                                <Select
                                    defaultValue={null}
                                    options={options}
                                    style={{
                                        lineHeight: "40px",
                                        color: "#fd7e14",
                                        paddingLeft: " 15px",
                                    }}
                                />
                                <br/>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                            <Form.Label>Notifiche</Form.Label>
                            <Form.Check type="switch" id="custom-switch" label="Invia email con i dati dell'account." />
                            <Form.Check type="switch" id="custom-switch" label="Forza cambio password." />
                            </Col>
                        </Row>

                    </Form>

                </Card.Body>

            </Card>

            <div className="d-flex justify-content-end gap-4 mt-4">
              <Link to="/superadmin/strumenti/utenti-gestionale" className="btn btn-dark light mr-4">Annulla</Link>
              <Link to="#" className="btn btn-primary ">Salva</Link>

            </div>
        </Fragment>
    )
}

export default AggiungiUtenteGestionale