import React from "react";
import { Dropdown } from "react-bootstrap";
import { ColumnFilter } from "../../../components/table/FilteringTable/ColumnFilter";
import { More } from "iconsax-react";
import TabellaLista from "../../../components/Tabella/TabellaLista";

const data_column = [

	{
		Header: 'Data caricamento',
		Footer: 'Data caricamento',
		accessor: 'data-caricamento',
		Filter: ColumnFilter
	},
	{
		Header: 'Nome documento',
		Footer: 'Nome documento',
		accessor: 'nome-documento',
		Filter: ColumnFilter
	},
	{
		Header: 'Tipologia',
		Footer: 'Tipologia',
		accessor: 'tipologia',
		Filter: ColumnFilter
	},

	{
		Header: 'Caricato da',
		Footer: 'Caricato da',
		accessor: 'caricato-da',
		Filter: ColumnFilter
	},
	{
		Header: 'Azione',
		Footer: 'Azione',
		accessor: 'azione',
		Cell: ({ value }) => {
			return (
				<Dropdown>
					<Dropdown.Toggle
						className="btn-primary light sharp i-false btn-action"
					>
						<More />
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item>Scarica</Dropdown.Item>
						<Dropdown.Item>Modifica</Dropdown.Item>
						<Dropdown.Item>Cancella</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			)
		},
		disableFilters: true,
	},
];
const data_json = [

	{
		"data-caricamento": "10 Marzo 2024",
		"nome-documento": "Lorem Ipsum",
		"tipologia": "Lorem Ipsum",
		"caricato-da": "Nome Cognome"
	},
	{
		"data-caricamento": "10 Marzo 2024",
		"nome-documento": "Lorem Ipsum",
		"tipologia": "Lorem Ipsum",
		"caricato-da": "Nome Cognome"
	}
];

const ListaDocumenti = () => {
	return (
		<TabellaLista
			cardTitle={'Lista documenti'}
			hasSearch={true}
			hasFilter={true}
			hasAddMore={true}
			labelAddMore={'Carica documento'}
			linkAddMore={'/maestro/documenti/aggiungi-documento'}
			data_column={data_column}
			data_json={data_json}
		/>
	)
}

export default ListaDocumenti
