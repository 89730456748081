import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter';
import { Dropdown, Tab, Nav, Modal, Row, Col, Button, Spinner } from "react-bootstrap";
import { More, Like1, Dislike, Send, Send2, Trash } from 'iconsax-react';
import TabellaLista from '../../../../components/Tabella/TabellaLista';
import { format } from 'date-fns';
import MyApiClient from '../../../../../api-client/MyApiClient';

export const StrutturaRichieste = () => {

	const [confirmShowed, setConfirmShowed] = useState(false);
	const [onContinue, setOnContinue] = useState(null);

	const [loadingShowed, setLoadingShowed] = useState(false);

	const data_column = [
		{
			Header: 'Data richiesta',
			accessor: 'date',
			Filter: ColumnFilter
		},
		{
			Header: 'Richiedente',
			accessor: 'name',
			Filter: ColumnFilter,
			Cell: ({ row }) => (
				<div style={{ display: 'flex', alignItems: 'center' }}>
				  {row.original.imageUrl && <img
					src={row.original.imageUrl}
					alt={row.original.name}
					style={{
					  width: '40px',
					  height: '40px',
					  borderRadius: '50%',
					  marginRight: '10px'
					}}
				  />}
				  {row.original.name}
				</div>
			)
		},
		{
			Header: 'Tipologia',
			accessor: 'type',
			Filter: ColumnFilter,
		},
		{
			Header: 'Stato richiesta',
			accessor: 'status',
			Filter: ColumnFilter,
			Cell: ({ value }) => {
				return (
					<div className="d-flex align-items-center">
						<i className={`fa fa-circle me-1 ${value?value.class:''}`}></i>
						{value?value.label:''}
					</div>
				)
			},
			disableFilters: true,
		},
		{
			Header: 'Azione',
			Footer: 'Azione',
			accessor: 'azione',
			Cell: ({ row }) => {
				return (
					<Dropdown>
						<Dropdown.Toggle className="btn-primary light sharp i-false btn-action">
							<More />
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{row.original.status.label=='In attesa' &&
								<Dropdown.Item onClick={() => resendRequest(row.original)}><span className="me-2"><Send2 size="20" /></span> Reinvia richiesta</Dropdown.Item>
							}
							{row.original.status.label=='In attesa' && row.original.invitationType=='USER_TO_CLUB' && 
								<Dropdown.Item onClick={() => acceptRequest(row.original)}><span className="me-2"><Like1 size="20" /></span> Accetta richiesta</Dropdown.Item> 
							}
							{row.original.status.label=='In attesa' && row.original.invitationType=='USER_TO_CLUB' && 
								<Dropdown.Item onClick={() => declineRequest(row.original)}><span className="me-2"><Dislike size="20" /></span> Rifiuta richiesta</Dropdown.Item>
							}
							{row.original.status.label=='In attesa' &&
								<Dropdown.Item onClick={() => deleteRequest(row.original)} className="text-danger"><span className="me-2"><Trash size="20" /></span>Cancella</Dropdown.Item>
							}
						</Dropdown.Menu>
					</Dropdown>
				)
			},
			disableFilters: true,
		},
	];

	const [data_json, setDataJson] = useState(null);

	useEffect(() => {
        const fetchData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    let token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

					setLoadingShowed(true);
					const [membershipData] = await Promise.all([
                        apiClient.getMemberships(token, null, null)
                    ]);
					setLoadingShowed(false);

					if(membershipData) {
						let data_requests = [];
						membershipData.forEach(element => {
							if(element.status != 'CONFIRMED') {

								let image = null;
								if(element.picture && element.picture.uri) {
									image = element.picture.uri;
								}

								let tipologia_desc = '';
								if(element.membershipRank=='MEMBER') {
									tipologia_desc = 'Socio';
								} else if(element.membershipRank=='COACH') {
									tipologia_desc = 'Staff tecnico';
								} else if(element.membershipRank=='DIRECTOR') {
									tipologia_desc = 'Cda';
								}

								let status_desc = '';
								let status_class = '';
								if(element.status=='PENDING') {
									status_desc = 'In attesa';
									status_class = 'text-warning';
								} else if(element.status=='CONFIRMED') {
									status_desc = 'Confermata';
									status_class = 'text-success';
								} else if(element.status=='DECLINED') {
									status_desc = 'Rifiutata';
									status_class = 'text-danger';
								}

								let member_row = {
									"id": element.id,
									"membershipRank": element.membershipRank,
									"userId": element.userId,
									"date": format(element.createdAt, 'dd/MM/yyyy HH:mm:ss'),
									"name": element.name,
									"imageUrl": image,
									"type": tipologia_desc,
									"status": {
										"label": status_desc,
										"class": status_class
									},
									"invitationType": element.invitationType
								};
								data_requests.push(member_row);
							}
						});
						setDataJson(data_requests);
					}
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

    }, []);

	function resendRequest(id) {

	}

	const deleteRequest = (row) => {
		setOnContinue(() => () => continueDeleteRequest(row));
		setConfirmShowed(true);
	};

	const continueDeleteRequest = (row) => {

		setConfirmShowed(false);

		const deleteData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

					setLoadingShowed(true);
                    const deleteElement = await apiClient.deleteMembership(token, row.userId, row.membershipRank);
					setLoadingShowed(false);

                    if(!deleteElement) {
                        setModalTitle("Errore durante l'operazione");
                        setModalText("Si è verificato un errore durante la cancellazione");
                        setModalShowed(true);
                    } else {
						window.location.href = '/struttura/richieste';
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        deleteData();
	}

	function acceptRequest(id) {

	}

	function declineRequest(id) {

	}

	return (
		<>

			<Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

			<Modal className="fade" show={confirmShowed} centered>
                <Modal.Header>
                    <Modal.Title>Sei sicuro?</Modal.Title>
                    <Button onClick={() => setConfirmShowed(false)} variant="" className="btn-close">
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Row><Col className="col-xl-64">Sei sicuro di volere continuare?</Col></Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button className="btn btn-light btn-add" onClick={() => setConfirmShowed(false)}>
                        Chiudi
                    </Button>
					<Button className="btn btn-primary btn-add" onClick={onContinue}>
                        Continua
                    </Button>
                </Modal.Footer>
            </Modal>

			<TabellaLista
				cardTitle={'Richieste'}
				hasSearch={true}
				hasFilter={true}
				hasAddMore={false}
				data_column={data_column}
				data_json={data_json}
			/>
		</>
	)

}
export default StrutturaRichieste;