import React from "react";
import ReactApexChart from "react-apexcharts";

class GraficoAbbonamentiAttivi extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            series: [{
                name: "Player",
                data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
            },
            {
                name: "Maestri(periodo precedente)",
                data: [35, 41, 90, 42, 13, 1, 29, 37, 36, 51, 32, 35]
            },
            {
                name: "Player",
                data: [13, 44, 52, 43, 13, 18, 29, 22, 39, 51, 32, 35]
            },
            {
                name: "Maestri(periodo precedente)",
                data: [1, 44, 62, 42, 13, 18, 29, 2, 36, 51, 32, 35]
            },
            ],
            options: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [5, 5, 5, 5],
                    curve: 'straight',
                    dashArray: [0, 0, 5, 5]
                },
                yaxis: {
                    title: {
                        text: 'Abbonamenti  PREMIUM attivati'
                    },
                    min: 0,
                    max: 100
                },
                legend: {
                    tooltipHoverFormatter: function (val, opts) {
                        return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
                    }
                },
                markers: {
                    size: 0,
                    hover: {
                        sizeOffset: 6
                    }
                },
                xaxis: {
                    categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan', '08 Jan', '09 Jan',
                        '10 Jan', '11 Jan', '12 Jan'
                    ],
                },
                tooltip: {
                    y: [
                        {
                            title: {
                                formatter: function (val) {
                                    return val + " (mins)"
                                }
                            }
                        },
                        {
                            title: {
                                formatter: function (val) {
                                    return val + " per session"
                                }
                            }
                        },
                        {
                            title: {
                                formatter: function (val) {
                                    return val;
                                }
                            }
                        }
                    ]
                },
                grid: {
                    borderColor: '#f1f1f1',
                }
            },


        };
    }

    render() {
        return (
            <div>
                <div id="chart">
                    <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350} />
                </div>
                <div id="html-dist"></div>
            </div>
        );
    }
}

export default GraficoAbbonamentiAttivi

