import React from 'react';

const DynamicLogo = ({ logoUrl, altText = 'Logo' }) => {
    return (
        <img src={logoUrl} alt={altText} style={{
            maxWidth: '100%',
            height: 'auto',
            margin: 'auto',
            display: 'inline-block',
            verticalAlign: 'middle'
        }} />
    );
};

export default DynamicLogo;