import React, { useState, Fragment } from "react";
import { Form, Row, Col} from "react-bootstrap";
import Select from "react-select";
import FormProdottoBundle from "./formProdottoBundle";
import FormProdottoSemplice from "./formProdottoSemplice";
import FormProdottoCorso from "./formProdottoCorso";

const FormPrincipale = () => {

    const options = [
        { value: "prodotto-semplice", label: "Prodotto Semplice" },
        { value: "prodotto-bundle", label: "Prodotto Bundle" },
        { value: "prodotto-corso", label: "Prodotto Corso" },
    ];

    const [selectedOption, setSelectedOption] = useState({ label: "Seleziona...", value: null });

    const [tipologiaProdotto, setTipologiaProdotto] = useState(options[1].value);

    const handleChangeTipologiaEvento = (event) => {
        setTipologiaProdotto(event.value); // Access the 'value' property from the event object
    };



    return (

        <Fragment>
            <Form>
                <Row>
                    <Col className="col-lg-4 col-12">
                        <Form.Label>Tipologia del prodotto</Form.Label>
                        <Select
                            defaultValue={options[1]}
                            onChange={(event) => {
                                setSelectedOption(event);
                                handleChangeTipologiaEvento(event);
                            }}
                            options={options}
                            style={{
                                lineHeight: "40px",
                                color: "#fd7e14",
                                paddingLeft: " 15px",
                            }}
                        />
                    </Col>
                    <Col className="col-lg-4 col-12">
                        <Form.Label>Stato del prodotto</Form.Label>
                        <Select
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                            style={{
                                lineHeight: "40px",
                                color: "#7e7e7e",
                                paddingLeft: " 15px",
                            }}
                        />
                    </Col>
                    <Col className="col-lg-4 col-12">
                        <Form.Label>Visibilità</Form.Label>
                        <Select
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                            style={{
                                lineHeight: "40px",
                                color: "#7e7e7e",
                                paddingLeft: " 15px",
                            }}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Form.Label>Nome Prodotto</Form.Label>
                        <Form.Control type="text" placeholder="Inserisci il nome prodotto" />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Form.Label>Descrizione prodotto</Form.Label>
                        <Form.Control as={"textarea"} rows={5} placeholder="Inserisci il nome prodotto" />
                    </Col>
                </Row>
                <br />
                {tipologiaProdotto === "prodotto-bundle" && <FormProdottoBundle />}
                {tipologiaProdotto === "prodotto-semplice" && <FormProdottoSemplice />}
                {tipologiaProdotto === "prodotto-corso" && <FormProdottoCorso />}

            </Form>
        </Fragment>

    )

}

export default FormPrincipale