import React, { useEffect, useState } from 'react';
import { ColumnFilter } from '../../table/FilteringTable/ColumnFilter';
import { Form, Button, Dropdown, Modal, Row, Col, Card } from "react-bootstrap";
import { More, Coin } from 'iconsax-react';
import TabellaLista from '../../Tabella/TabellaLista';
import MyApiClient from '../../../../api-client/MyApiClient';

export const FormFitCoin = () => {

	const [fitcoinGuadagnati, setFitcoinGuadagnati] = useState(0);
	const [fitcoinDisponibili, setFitcoinDisponibili] = useState(0);
	const [fitcoinSpesi, setFitcoinSpesi] = useState(0);
	const [data_json_in, setDataJsonIn] = useState(null);
	const [data_json_out, setDataJsonOut] = useState(null);

	useEffect(() => {
        const fetchData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

                    const [fitcoinData] = await Promise.all([
                        apiClient.getWalletFitcoinsSummary(token)
                    ]);

					if(fitcoinData) {
						setFitcoinDisponibili(fitcoinData.balance);

						let fitcoinSpesi = 0;
						let fitcoinGuadagnati = 0;
						const updatedDataJsonEarn = [];
						const updatedDataJsonSpent = [];
						fitcoinData.transactions.forEach(transaction => {
							const transactionId = transaction.transactionId;
							const type = transaction.type;
							const amount = transaction.amount;
							const createdAt = transaction.createdAt;
							const isMerchant = transaction.isMerchant;
							const description = transaction.description;

							const data = new Date(createdAt);
							const options = { year: '2-digit', month: '2-digit', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
							const formattedDate = data.toLocaleDateString('it-IT', options);

							if(type=='EARN') {
								updatedDataJsonEarn.push({
									"id": transactionId,
									"azione": description,
									"fitcoin": "+"+amount,
									"accredito": formattedDate,
									"scadenza": "-",
								});

								fitcoinGuadagnati += amount;
							}

							if(type=='SPENT') {
								updatedDataJsonEarn.push({
									"id": transactionId,
									"azione": description,
									"fitcoin": "+"+amount,
									"addebito": formattedDate
								});

								fitcoinSpesi += amount;
							}
						});

						setFitcoinSpesi(fitcoinSpesi);
						setFitcoinGuadagnati(fitcoinGuadagnati);
						setDataJsonIn(updatedDataJsonEarn);
						setDataJsonOut(updatedDataJsonSpent);
					}
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);
	
	const data_column_in = [
		{
			Header: 'Azione',
			accessor: 'azione',
			Filter: ColumnFilter
		},
		{
			Header: 'Fitcoin',
			accessor: 'fitcoin',
			Cell: ({ value }) => {
				return  <span className='text-success'>{value}</span>
			},
			Filter: ColumnFilter
		},
		{
			Header: 'Data di accredito',
			accessor: 'accredito',
			Filter: ColumnFilter
		}/*,
		{
			Header: 'Data scadenza',
			accessor: 'scadenza',
			Filter: ColumnFilter
		}*/
	];

	const data_column_out = [
		{
			Header: 'Azione',
			accessor: 'azione',
			Filter: ColumnFilter
		},
		{
			Header: 'Fitcoin',
			accessor: 'fitcoin',
			Cell: ({ value }) => {
				return  <span className='text-danger'>{value}</span>
			},
			Filter: ColumnFilter
		},
		{
			Header: 'Data di addebito',
			accessor: 'addebito',
			Filter: ColumnFilter
		}
	];

	return (
		<>
		<Row className='ps-3'>
		<Card className='col me-3 bd-left-gray'>
			<Card.Body>FitCoin guadagnati
			<h4 className='mt-3'><Coin></Coin>{fitcoinGuadagnati}</h4>
			</Card.Body>
		</Card>
		<Card className='col me-3 bd-left-success'>
			<Card.Body>FitCoin disponibili
			<h4 className='mt-3 text-success'><Coin></Coin>{fitcoinDisponibili}</h4>
			</Card.Body>
		</Card>
		<Card className='col me-3 bd-left-danger'>
			<Card.Body>FitCoin spesi
			<h4 className='mt-3 text-danger'><Coin></Coin>{fitcoinSpesi}</h4>
			</Card.Body>
		</Card>
		</Row>
			<TabellaLista
				cardTitle={'Accrediti FitCoin'}
				hasSearch={false}
				hasFilter={false}
				data_column={data_column_in}
				data_json={data_json_in}
				hasAddMoreAsModal={false}
			/>
			<TabellaLista
				cardTitle={'Addebiti FitCoin'}
				hasSearch={false}
				hasFilter={false}
				data_column={data_column_out}
				data_json={data_json_out}
				hasAddMoreAsModal={false}
			/>
		</>
	)
}
export default FormFitCoin;