import React, { useEffect, useState } from 'react';
import { Form, FormCheck, Row, Col, Card, Modal, Spinner, Button } from "react-bootstrap";
import Select from "react-select";
import { Link, useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MyApiClient from '../../../../api-client/MyApiClient';
import "react-widgets/styles.css";
import { format } from 'date-fns';

const options = [
  { value: "PLAYERS", label: "Players" },
  { value: "GURUS", label: "Maestri" },
  { value: "JUNIORS", label: "Junior" },
  { value: "CLUB_MEMBERS", label: "Soci" },
  { value: "CLUB_COACHES", label: "Staff Tecnico" },
  { value: "CLUB_DIRECTORS", label: "CDA" },
];

export const ComunicazioneGenericheEdit = () => {

  const { id } = useParams();
  const [isEditable, setIsEditable] = useState(false);
  
  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState({
    type: 'GENERIC',
    subject: '',
    messageHtml: '',
    scheduled: false,
    scheduledAt_date: '',
    scheduledAt_time: '',
    scheduledAt: 0,
    recipientGroups: []
  });

  const [modalShowed, setModalShowed] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  const [confirmShowed, setConfirmShowed] = useState(false);
	const [onContinue, setOnContinue] = useState(null);

  const [loadingShowed, setLoadingShowed] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const handleCheckboxChange = () => {
    setFormData(prevState => ({
      ...prevState,
      ['scheduled']: !isChecked
    }));
    setIsChecked(!isChecked);
  };

  const handleSelectChange = (selectedOptions) => {
    const selectedGroups = selectedOptions.map(option => option.value);
    setFormData(prevState => ({
        ...prevState,
        recipientGroups: selectedGroups
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tokenDetailsString = sessionStorage.getItem('userDetails');
        if (tokenDetailsString) {
          const tokenDetails = JSON.parse(tokenDetailsString);
          let token = tokenDetails.idToken;
          const apiClient = new MyApiClient();

          setLoadingShowed(true);
          const [documentsData] = await Promise.all([
            apiClient.getCommunications(token)
          ]);
          setLoadingShowed(false);

          let formDataRead = null;
          if(documentsData) {
            documentsData.forEach(element => {
              if(element.id==id) {
                formDataRead = [];
                formDataRead.subject = element.subject;
                formDataRead.messageHtml = element.messageHtml;
                formDataRead.recipientGroups = element.recipientGroups;
                formDataRead.scheduled = true;
                setIsChecked(true);

                let data_scheduled = format(element.scheduledAt, 'yyyy-MM-dd HH:mm');
                let data_scheduled_split = data_scheduled.split(' ');
                formDataRead.scheduledAt_date = data_scheduled_split[0];
                formDataRead.scheduledAt_time = data_scheduled_split[1];

                if(element.scheduledAt>Date.now()) {
                  setIsEditable(true);
                }
              }
            }); 
          }

          if(formDataRead) {
            setFormData(formDataRead);
          } else {
            setModalTitle("Errore durante l'operazione");
            setModalText('Errore durante la lettura.');
            setModalShowed(true);
            return;
          }
        }
      } catch (error) {
          console.error("Error fetching data:", error);
      }
    };

    fetchData();

  }, []);

  const handleSaveClick = (e) => {
    e.preventDefault();

    if (!formData.subject) {
      setModalTitle("Errore durante l'operazione");
      setModalText('Il titolo è un campo obbligatorio.');
      setModalShowed(true);
      return;
    }

    if (formData.recipientGroups.length == 0) {
      setModalTitle("Errore durante l'operazione");
      setModalText('Devi selezionare almeno un gruppo di tipologia destinatario per continuare.');
      setModalShowed(true);
      return;
    }

    if (!formData.messageHtml) {
      setModalTitle("Errore durante l'operazione");
      setModalText('Il contenuto della comunicazione è un campo obbligatorio.');
      setModalShowed(true);
      return;
    }

    setOnContinue(() => () => continueSaveDoc());
		setConfirmShowed(true);
  };

  const continueSaveDoc = (row) => {

    setConfirmShowed(false);

    const saveData = async () => {
      try {
          const tokenDetailsString = sessionStorage.getItem('userDetails');
          if (tokenDetailsString) {
              const tokenDetails = JSON.parse(tokenDetailsString);
              const token = tokenDetails.idToken;
              const apiClient = new MyApiClient();

              const dateTimeStr = `${formData.scheduledAt_date}T${formData.scheduledAt_time}:00Z`;
              const date = new Date(dateTimeStr);
              const timestamp = date.getTime();
              formData.scheduledAt = timestamp;

              let data_insert = {
                'type': formData.type,
                'subject': formData.subject,
                'messageHtml': formData.messageHtml,
                'recipientGroups': formData.recipientGroups
              }

              if(formData.scheduledAt) {
                data_insert['scheduledAt'] = formData.scheduledAt;
              }

              setLoadingShowed(true);
              const saveResult = await apiClient.editCommunication(token, id, data_insert);
              setLoadingShowed(false);

              if (saveResult && saveResult.error) {
                setModalTitle("Errore durante l'operazione");
                setModalText("Si è verificato un errore durante il salvataggio (" + saveResult.error + ")");
                setModalShowed(true);
              } else {
                window.location.href = '/superadmin/comunicazioni';
              }
          }
      } catch (error) {
          console.error("Error fetching data:", error);
      }
    };

    saveData();
  };

  return (
    <>
      <style>
          {`
              [dir="ltr"] .ck.ck-dropdown .ck-dropdown__arrow {
                z-index: 0;
              }

              .ck.ck-editor__editable_inline > :first-child {
                height: 90%;
              }

              .ck-content {
                min-height: 200px;
                margin-bottom: 50px;
              }
          `}
      </style>

      <Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

      <Modal className="fade" show={confirmShowed} centered>
        <Modal.Header>
          <Modal.Title>Sei sicuro?</Modal.Title>
          <Button onClick={() => setConfirmShowed(false)} variant="" className="btn-close">
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="col-xl-64">
              Sei sicuro di volere continuare? 
              {formData.scheduled && <b> La comunicazione sarà inviata all'orario schedulato.</b>}
              {!formData.scheduled && <b> La comunicazione sarà inviata immediatamente.</b>}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button className="btn btn-light btn-add" onClick={() => setConfirmShowed(false)}>
            Chiudi
          </Button>
          <Button className="btn btn-primary btn-add" onClick={onContinue}>
            Continua
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal className="fade" show={modalShowed} centered>
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
          <Button onClick={() => setModalShowed(false)} variant="" className="btn-close">
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row><Col className="col-xl-64">{modalText}</Col></Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button className="btn btn-primary btn-add" onClick={() => setModalShowed(false)}>
              Chiudi
          </Button>
        </Modal.Footer>
      </Modal>
      
      <Form>
        <Card>
          <Card.Header><h5>Crea una nuova comunicazione</h5></Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <Form.Label>Titolo</Form.Label>
                <Form.Control type="text" name="subject" value={formData.subject} onChange={handleChange} placeholder="Inserisci il titolo della comunicazione" />
              </Col></Row>
            <Row>
              <Col>
                <Form.Label className="mt-4">Tipologia destinatario</Form.Label>
                <Select
                  value={options.filter(option => formData.recipientGroups ? formData.recipientGroups.includes(option.value) : '')}
                  isMulti
                  options={options}
                  onChange={handleSelectChange}
                  placeholder="Seleziona la tipologia di destinatario a cui spedire"
                  style={{
                    lineHeight: "40px",
                    color: "#fd7e14",
                    paddingLeft: " 15px",
                  }
                  }
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Form.Group controlId="prodottoConListaAttesa">
                <FormCheck
                  className="d-flex"
                  type="checkbox"
                  label="Programma l'invio (se non selezionato la comunicazione verrà inviata immediatamente)"
                  value=""
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
              </Form.Group>
            </Row>
            {isChecked &&
              <Row>
                <Col>
                  <Form.Label>Seleziona data</Form.Label>
                  <Form.Control type="date" name="scheduledAt_date" value={formData.scheduledAt_date} onChange={handleChange} ></Form.Control>
                </Col>
                <Col>
                  <Form.Label>Seleziona ora</Form.Label>
                  <Form.Control type="time" name="scheduledAt_time" value={formData.scheduledAt_time} onChange={handleChange} ></Form.Control>
                </Col>
              </Row>
            }
            <Row>
              <Form.Label className="mt-4">Contenuto</Form.Label>
              <div className="custom-ekeditor">
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.messageHtml}
                  // data="<p>Hello from CKEditor 5!</p>"
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    // console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    // console.log({ event, editor, data });
                    formData.messageHtml = data;
                  }}
                  onBlur={(event, editor) => {
                    // console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    // console.log('Focus.', editor);
                  }}
                />
              </div>
            </Row>
          </Card.Body>
        </Card>
        <div className="d-flex justify-content-end gap-4 mt-4">
          <Link to={"/superadmin/comunicazioni"} className="btn btn-dark light mr-4">Annulla</Link> 
          {/*<Link to={"#"} className="btn btn-dark light mr-4">Salva bozza</Link>*/}
          { isEditable && <Link to="#" onClick={handleSaveClick} className="btn btn-primary ">Salva</Link> }
        </div>
      </Form>
    </>
  )

}
export default ComunicazioneGenericheEdit;