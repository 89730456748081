import { Fragment } from "react"
import TabellaLista from "../../../../components/Tabella/TabellaLista"
import { ColumnFilter } from "../../../../components/table/FilteringTable/ColumnFilter"
import { More } from "iconsax-react"
import { Dropdown, Alert } from "react-bootstrap"

const ListaUtentiGestionale = () => {

    const data_column = [

        {
            Header: 'Data creazione',
            Footer: 'Data creazione',
            accessor: 'data-creazione',
            Filter: ColumnFilter
        },
        {
            Header: 'Nome utente',
            Footer: 'Nome utente',
            accessor: 'nome-utente',
            Filter: ColumnFilter
        },
        {
            Header: 'Email',
            Footer: 'Email',
            accessor: 'email',
            Filter: ColumnFilter
        },
        {
            Header: 'Ruolo',
            Footer: 'Ruolo',
            accessor: 'ruolo',
            Filter: ColumnFilter
        },
        {
            Header: 'Stato',
            Footer: 'Stato',
            accessor: 'stato',
            Cell: ({ value }) => {
                return (
                    <div className="d-flex align-items-center">
                        <i className={`fa fa-circle me-1 ${value.class}`}></i>
                        {value.label}
                    </div>
                );
            },
            disableFilters: true,
        },
        {
            Header: 'Azione',
            Footer: 'Azione',
            accessor: 'azione',
            Cell: ({ value }) => {
                return (
                    <Dropdown>
                        <Dropdown.Toggle
                            className="btn-primary light sharp i-false btn-action"
                        >
                            <More />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item>Edit</Dropdown.Item>
                            <Dropdown.Item>Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            },
            disableFilters: true,
        },
    ];

    const data_json = [
        {
            "data-creazione": "12 Marzo 2024",
            "nome-utente": "Loredana Bassi",
            "email": "loredana@mail.com",
            "ruolo": "Super Admin",
            "stato": {
                "label": "Abilitato",
                "class": "text-success"
            },
        }
    ];

    return (
        <Fragment>
            <TabellaLista
                cardTitle={'Lista Utenti Gestionale'}
                hasSearch={true}
                hasFilter={true}
                hasAddMore={true}
                linkAddMore={'/superadmin/strumenti/aggiungi-utente-gestionale'}
                labelAddMore={'Aggiungi utente'}
                data_column={data_column}
                data_json={data_json}
            />
            <Alert variant="danger">
                <Alert.Heading>Attenzione</Alert.Heading>
                <p>
                    La lista è in fase di creazione.<br />
                    Le "Azioni" non sono definitive
                </p>
            </Alert>
        </Fragment>
    )
}

export default ListaUtentiGestionale