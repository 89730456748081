import React, { Fragment, useState, useMemo, useEffect } from 'react'
import { useTable, useGlobalFilter, useFilters, usePagination, useSortBy } from 'react-table';
import { Dropdown, Row, Col, Card, Button, Collapse } from 'react-bootstrap'
import { ArrowDown2, More, DocumentText } from 'iconsax-react'
import { ColumnFilter } from '../../../components/table/FilteringTable/ColumnFilter'

const ReportStrutture = () => {

    const data_column = [

        {
            Header: '#',
            Footer: '#',
            accessor: '#',
            Filter: ColumnFilter
        },
        {
            Header: 'Nome struttura sportiva',
            Footer: 'Nome struttura sportiva',
            accessor: 'nome-struttura-sportiva',
            Filter: ColumnFilter
        },
        {
            Header: 'Numero player',
            Footer: 'Numero player',
            accessor: 'numero-player',
            Cell: ({ value }) => {
                return (
                    <>
                        <span>
                            {value.primoPeriodo.valore}
                        </span>
                        <br />
                        <span className={`${value.secondoPeriodo.stato == "positivo" ? 'text-success' : 'text-danger'} font-w600`}>
                            {value.secondoPeriodo.valore}
                        </span>

                    </>
                );
            },
            Filter: ColumnFilter
        },
        {
            Header: 'Numero junior',
            Footer: 'Numero junior',
            accessor: 'numero-junior',
            Cell: ({ value }) => {
                return (
                    <>
                        <span>
                            {value.primoPeriodo.valore}
                        </span>
                        <br />
                        <span className={`${value.secondoPeriodo.stato == "positivo" ? 'text-success' : 'text-danger'} font-w600`}>
                            {value.secondoPeriodo.valore}
                        </span>

                    </>
                );
            },
            Filter: ColumnFilter
        },
        {
            Header: 'Numero maestri',
            Footer: 'Numero maestri',
            accessor: 'numero-maestri',
            Cell: ({ value }) => {
                return (
                    <>
                        <span>
                            {value.primoPeriodo.valore}
                        </span>
                        <br />
                        <span className={`${value.secondoPeriodo.stato == "positivo" ? 'text-success' : 'text-danger'} font-w600`}>
                            {value.secondoPeriodo.valore}
                        </span>

                    </>
                );
            },
            Filter: ColumnFilter
        },
        {
            Header: 'Fatturato',
            Footer: 'Fatturato',
            accessor: 'fatturato',
            Cell: ({ value }) => {
                return (
                    <>
                        <span>
                            {value.primoPeriodo.valore}
                        </span>
                        <br />
                        <span className={`${value.secondoPeriodo.stato == "positivo" ? 'text-success' : 'text-danger'} font-w600`}>
                            {value.secondoPeriodo.valore}
                        </span>
                    </>
                );
            },
            Filter: ColumnFilter
        },

    ];

    const data_json = [
        {
            "#": 1,
            "nome-struttura-sportiva": "Struttura sportiva",
            "numero-player": {
                "primoPeriodo": {
                    "valore": "12.354",
                },
                "secondoPeriodo": {
                    "valore": "+2.354",
                    "stato": "positivo"
                },
            },
            "numero-junior": {
                "primoPeriodo": {
                    "valore": "541",
                },
                "secondoPeriodo": {
                    "valore": "+4",
                    "stato": "positivo"
                },
            },
            "numero-maestri": {
                "primoPeriodo": {
                    "valore": "541",
                },
                "secondoPeriodo": {
                    "valore": "+54",
                    "stato": "positivo"
                },
            },
            "fatturato": {
                "primoPeriodo": {
                    "valore": "€ 17.847,00",
                },
                "secondoPeriodo": {
                    "valore": "+ € 544,00",
                    "stato": "positivo"
                },
            },
        },
        {
            "#": 2,
            "nome-struttura-sportiva": "Struttura sportiva",
            "numero-player": {
                "primoPeriodo": {
                    "valore": "11.354",
                },
                "secondoPeriodo": {
                    "valore": "-1.354",
                    "stato": "negativo"
                },
            },
            "numero-junior": {
                "primoPeriodo": {
                    "valore": "541",
                },
                "secondoPeriodo": {
                    "valore": "+124",
                    "stato": "positivo"
                },
            },
            "numero-maestri": {
                "primoPeriodo": {
                    "valore": "541",
                },
                "secondoPeriodo": {
                    "valore": "-54",
                    "stato": "negativo"
                },
            },
            "fatturato": {
                "primoPeriodo": {
                    "valore": "€ 10.877,00",
                },
                "secondoPeriodo": {
                    "valore": "- € 1.571,00",
                    "stato": "negativo"
                },
            },
        },
        {
            "#": 3,
            "nome-struttura-sportiva": "Struttura sportiva",
            "numero-player": {
                "primoPeriodo": {
                    "valore": "11.354",
                },
                "secondoPeriodo": {
                    "valore": "-1.354",
                    "stato": "negativo"
                },
            },
            "numero-junior": {
                "primoPeriodo": {
                    "valore": "541",
                },
                "secondoPeriodo": {
                    "valore": "+124",
                    "stato": "positivo"
                },
            },
            "numero-maestri": {
                "primoPeriodo": {
                    "valore": "541",
                },
                "secondoPeriodo": {
                    "valore": "-54",
                    "stato": "negativo"
                },
            },
            "fatturato": {
                "primoPeriodo": {
                    "valore": "€ 10.877,00",
                },
                "secondoPeriodo": {
                    "valore": "- € 1.571,00",
                    "stato": "negativo"
                },
            },
        }
    ];

    const [isOpen, setIsOpen] = React.useState(false);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    const columns = useMemo(() => data_column, [])
    const data = useMemo(() => data_json, [])
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance

    const { globalFilter, pageIndex } = state

    return (
        <Fragment>
            <Row>
                <Col>
                    <Card>
                        <Card.Header onClick={toggleCollapse}>
                            <Card.Title as="h4">Filtri</Card.Title>
                            <ArrowDown2 size="32" variant="Bulk" />
                        </Card.Header>
                        <Collapse in={isOpen}>
                            <Card.Body>
                                Lorem ipsum dolor text....
                            </Card.Body>
                        </Collapse>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col xl="4" xxl="4" lg="6" sm="6">
                    <Card>
                        <Card.Body className='border-start-5-success'>
                            <h4 className="mb-1 ">Volume di vendite totale</h4>
                            <h2 className=" mb-0 ">€ 175.123,25</h2>
                            <small><b className='text-danger'>-2.505,00</b> dal periodo precendete</small>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl="4" xxl="4" lg="6" sm="6">
                    <Card>
                        <Card.Body className='border-start-5-cyan'>
                            <h4 className="mb-1 ">Player</h4>
                            <h2 className=" mb-0 ">3043</h2>
                            <small><b className='text-success'>+25</b> dal periodo precendete</small>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl="4" xxl="4" lg="6" sm="6">
                    <Card>
                        <Card.Body className='border-start-5-orange'>
                            <h4 className="mb-1 ">Maestri</h4>
                            <h2 className=" mb-0 ">3043</h2>
                            <small><b className='text-success'>+25</b> dal periodo precendete</small>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <h6 className='mb-0'>Natura struttura</h6>
                                    <h3 className='mb-0'>ASD / SSD</h3>

                                    <br />

                                    <h6 className='mb-0'>Nr strutture</h6>
                                    <h3 className='mb-0 '>343</h3>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>

                                    <br /><br />

                                    <h6 className='mb-0'>Fatturato</h6>
                                    <h3 className='mb-0 '>€ 300,00</h3>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>

                                    <br /><br />

                                    <h6 className='mb-0'>Nr maestri</h6>
                                    <h3 className='mb-0 '>343</h3>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>

                                    <br /><br />

                                    <h6 className='mb-0'>Nr soci</h6>
                                    <h3 className='mb-0 '>343</h3>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                <Col>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <h6 className='mb-0'>Natura struttura</h6>
                                    <h3 className='mb-0 '>ASD / SSD</h3>

                                    <br />

                                    <h6 className='mb-0'>Nr strutture</h6>
                                    <h3 className='mb-0 '>343</h3>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>

                                    <br /><br />

                                    <h6 className='mb-0'>Fatturato</h6>
                                    <h3 className='mb-0 '>€ 300,00</h3>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>

                                    <br /><br />

                                    <h6 className='mb-0'>Nr maestri</h6>
                                    <h3 className='mb-0 '>343</h3>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>

                                    <br /><br />

                                    <h6 className='mb-0'>Nr soci</h6>
                                    <h3 className='mb-0 '>343</h3>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                <Col>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <h6 className='mb-0'>Natura struttura</h6>
                                    <h3 className='mb-0 '>ASD / SSD</h3>

                                    <br />

                                    <h6 className='mb-0'>Nr strutture</h6>
                                    <h3 className='mb-0 '>343</h3>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>

                                    <br /><br />

                                    <h6 className='mb-0'>Fatturato</h6>
                                    <h3 className='mb-0 '>€ 300,00</h3>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>

                                    <br /><br />

                                    <h6 className='mb-0'>Nr maestri</h6>
                                    <h3 className='mb-0 '>343</h3>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>

                                    <br /><br />

                                    <h6 className='mb-0'>Nr soci</h6>
                                    <h3 className='mb-0 '>343</h3>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                <Col>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <h6 className='mb-0'>Natura struttura</h6>
                                    <h3 className='mb-0 '>ASD / SSD</h3>

                                    <br />

                                    <h6 className='mb-0'>Nr strutture</h6>
                                    <h3 className='mb-0 '>343</h3>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>

                                    <br /><br />

                                    <h6 className='mb-0'>Fatturato</h6>
                                    <h3 className='mb-0 '>€ 300,00</h3>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>

                                    <br /><br />

                                    <h6 className='mb-0'>Nr maestri</h6>
                                    <h3 className='mb-0 '>343</h3>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>

                                    <br /><br />

                                    <h6 className='mb-0'>Nr soci</h6>
                                    <h3 className='mb-0 '>343</h3>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                <Col>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <h6 className='mb-0'>Natura struttura</h6>
                                    <h3 className='mb-0 '>ASD / SSD</h3>

                                    <br />

                                    <h6 className='mb-0'>Nr strutture</h6>
                                    <h3 className='mb-0 '>343</h3>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>

                                    <br /><br />

                                    <h6 className='mb-0'>Fatturato</h6>
                                    <h3 className='mb-0 '>€ 300,00</h3>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>

                                    <br /><br />

                                    <h6 className='mb-0'>Nr maestri</h6>
                                    <h3 className='mb-0 '>343</h3>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>

                                    <br /><br />

                                    <h6 className='mb-0'>Nr soci</h6>
                                    <h3 className='mb-0 '>343</h3>
                                    <small><b className='text-success'>+25</b> dal periodo precendete</small>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h4">Classifica strutture sportive</Card.Title>
                        </Card.Header>
                        <Card.Body className='pt-0'>
                            <div className="table-responsive" style={{ overflowX: 'hidden' }}>
                                <table {...getTableProps()} className="table dataTable display">
                                    <thead>
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column, index, array) => (
                                                    <th key={column.id} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                        {column.render('Header')}
                                                        {column.isSorted && index !== array.length - 1 && (
                                                            <span className="ml-1">
                                                                {column.isSortedDesc ? <i className="fa fa-arrow-down ms-2 fs-14" /> : <i className="fa fa-arrow-up ms-2 fs-14" />}
                                                            </span>
                                                        )}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()} className="" >

                                        {page.map((row) => {
                                            prepareRow(row)
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map((cell) => {
                                                        return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
                                                    })}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div className="d-flex align-items-center justify-content-between">
                                    <span>
                                        A confronto con il periodo da 01 gennaio 2023 a 11 aprile 2024
                                    </span>
                                </div>

                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>

                <Col xxl="6" xl="6" lg="6" md="12">

                    <Card>
                        <Card.Header>
                            <Card.Title as="h4">Sport più redditizi</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            Qui tabella ancora da fare
                        </Card.Body>
                    </Card>

                </Col>

            </Row>

        </Fragment>
    );
};

export default ReportStrutture