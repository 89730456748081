import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Card, Modal, Spinner, Button } from "react-bootstrap";
import { Link, useParams } from 'react-router-dom';
import "react-widgets/styles.css";
import MyApiClient from '../../../../../api-client/MyApiClient';
import { format } from 'date-fns';

export const ComunicazioneInvitiEdit = () => {

  const { id } = useParams();
  const [isEditable, setIsEditable] = useState(false);

  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState({
    type: 'SIGNUP_INVITATION',
    recipients_text: '',
    recipients: [],
    recipientEmails: [],
    scheduledAt: ''
  });

  const [modalShowed, setModalShowed] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  const [confirmShowed, setConfirmShowed] = useState(false);
	const [onContinue, setOnContinue] = useState(null);

  const [loadingShowed, setLoadingShowed] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'text/plain') {
      const reader = new FileReader();
      reader.onload = (e) => {
          setFormData(prevState => ({
            ...prevState,
            ['recipients_text']: e.target.result
          }));
      };
      reader.readAsText(file);
    } else {
      setModalTitle("Errore durante l'operazione");
      setModalText('Devi caricare un file di tipo txt per continuare.');
      setModalShowed(true);
      return;
    }
  };

  const handleSaveClick = (e) => {
    e.preventDefault();

    if (!formData.recipients_text) {
      setModalTitle("Errore durante l'operazione");
      setModalText('Devi inserire almeno un destinatario per continuare.');
      setModalShowed(true);
      return;
    }

    setOnContinue(() => () => continueSaveDoc());
		setConfirmShowed(true);
  };

  const continueSaveDoc = (row) => {

    setConfirmShowed(false);

    const saveData = async () => {
      try {
          const tokenDetailsString = sessionStorage.getItem('userDetails');
          if (tokenDetailsString) {
              const tokenDetails = JSON.parse(tokenDetailsString);
              const token = tokenDetails.idToken;
              const apiClient = new MyApiClient();

              let recipients = [];
              let recipient_rows = formData.recipients_text.split('\n');
              recipient_rows.forEach(element => {
                let rows_detail = element.split(',');
                if(rows_detail.length==3) {
                  let rows_email = rows_detail[0];
                  let rows_firstname = rows_detail[1];
                  let rows_lastname = rows_detail[2];

                  recipients.push({
                    'email': rows_email,
                    'name': rows_firstname+' '+rows_lastname
                  });
                }
              });

              let data_insert = {
                'type': formData.type,
                'recipients': recipients
              }

              setLoadingShowed(true);
              const saveResult = await apiClient.editCommunication(token, id, data_insert);
              setLoadingShowed(false);

              if (saveResult && saveResult.error) {
                setModalTitle("Errore durante l'operazione");
                setModalText("Si è verificato un errore durante il salvataggio (" + saveResult.error + ")");
                setModalShowed(true);
              } else {
                window.location.href = '/struttura/comunicazioni/inviti';
              }
          }
      } catch (error) {
          console.error("Error fetching data:", error);
      }
    };

    saveData();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tokenDetailsString = sessionStorage.getItem('userDetails');
        if (tokenDetailsString) {
          const tokenDetails = JSON.parse(tokenDetailsString);
          let token = tokenDetails.idToken;
          const apiClient = new MyApiClient();

          setLoadingShowed(true);
          const [documentsData] = await Promise.all([
            apiClient.getCommunications(token)
          ]);
          setLoadingShowed(false);

          let formDataRead = null;
          if(documentsData) {
            documentsData.forEach(element => {
              if(element.id==id) {
                formDataRead = [];
                formDataRead.recipient = element.recipient;
                formDataRead.recipientEmails = element.recipientEmails;
                
                let data_scheduled = format(element.scheduledAt, 'dd/MM/yyyy HH:mm');
                formDataRead.scheduledAt = data_scheduled;
                if(element.scheduledAt>Date.now()) {
                  setIsEditable(true);
                }

                let my_recipients_text = '';
                if(element.recipients) {
                  element.recipients.forEach(element2 => {
                    let temp_name = element2.name.split(' ');
                    if(temp_name.length>1) {
                      let temp_lastname = '';
                      for(var i=1;i<temp_name.length;i++) {
                        temp_lastname += temp_name[i]+' ';
                      }
                      my_recipients_text += element2.email+','+temp_name[0]+','+temp_lastname+'\n';
                    } else {
                      my_recipients_text += element2.email+','+element2.name+'\n';
                    }
                  });
                }

                element.recipientEmails.forEach(element2 => {
                  my_recipients_text += element2+'\n';
                });

                formDataRead.recipients_text = my_recipients_text;
              }
            }); 
          }

          if(formDataRead) {
            setFormData(formDataRead);
          } else {
            setModalTitle("Errore durante l'operazione");
            setModalText('Errore durante la lettura.');
            setModalShowed(true);
            return;
          }
        }
      } catch (error) {
          console.error("Error fetching data:", error);
      }
    };

    fetchData();

  }, []);

  return (
    <>

      <Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

      <Modal className="fade" show={confirmShowed} centered>
        <Modal.Header>
          <Modal.Title>Sei sicuro?</Modal.Title>
          <Button onClick={() => setConfirmShowed(false)} variant="" className="btn-close">
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row><Col className="col-xl-64">Sei sicuro di volere continuare? <b>L'invito sarà inviato immediatamente.</b></Col></Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button className="btn btn-light btn-add" onClick={() => setConfirmShowed(false)}>
            Chiudi
          </Button>
          <Button className="btn btn-primary btn-add" onClick={onContinue}>
            Continua
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal className="fade" show={modalShowed} centered>
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
          <Button onClick={() => setModalShowed(false)} variant="" className="btn-close">
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row><Col className="col-xl-64">{modalText}</Col></Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button className="btn btn-primary btn-add" onClick={() => setModalShowed(false)}>
              Chiudi
          </Button>
        </Modal.Footer>
      </Modal>

      <Form>
        <Card>
          <Card.Header><h5>Crea un nuovo invito a SportId</h5></Card.Header>
          <Card.Body>
           <Row>
            <Col className="col-xl-12">
                <Form.Label className="mt-4">Data</Form.Label>
                {formData.scheduledAt}
              </Col>
              <Col className="col-xl-12">
                <Form.Label className="mt-4">Lista destinatari (un destinatario per riga nel formato “email, nome, cognome”)</Form.Label>
                <Form.Control style={{minHeight: '50px'}} as="textarea" name="recipients_text" value={formData.recipients_text} onChange={handleChange} placeholder="Incolla un destinatario per riga con i seguenti campi “email, nome, cognome” separati da virgola" />
              </Col>
              <Col className="col-xl-12">
                <Form.Label className="mt-4">Carica file con destinatari</Form.Label>
                <Form.Control type="file" onChange={handleFileChange} />
                <p className='mt-4'>Nota: Il formato del file sarà un destinatario per riga con i seguenti campi “email, nome, cognome” separati da virgola</p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <div className="d-flex justify-content-end gap-4 mt-4">
          <Link to={"/struttura/comunicazioni/inviti"} className="btn btn-dark light mr-4">Annulla</Link> 
          {/*<Link to={"#"} className="btn btn-dark light mr-4">Salva bozza</Link>*/}
          { isEditable && <Link to="#" onClick={handleSaveClick} className="btn btn-primary ">Invia</Link> }
        </div>

      </Form>
    </>
  )

}
export default ComunicazioneInvitiEdit;