import React from "react";
import { Dropdown } from "react-bootstrap";
import { ColumnFilter } from "../../../../components/table/FilteringTable/ColumnFilter";
import { More } from "iconsax-react";
import TabellaLista from "../../../../components/Tabella/TabellaLista";

const data_column = [

	{
		Header: 'Data inizio',
		accessor: 'promo_inizio',
		Filter: ColumnFilter
	},
	{
		Header: 'Data fine',
		accessor: 'promo_fine',
		Filter: ColumnFilter
	},
	{
		Header: 'Nome promozione',
		accessor: 'promo_nome',
		Filter: ColumnFilter
	},
    {
		Header: 'Prodotti in promo',
		accessor: 'promo_prodotti',
		Filter: ColumnFilter
	},

	{
		Header: 'Stato',
		accessor: 'promo_status',
		Filter: ColumnFilter,
		Cell: ({ value }) => {
			return (
				<div className="d-flex align-items-center">
					<i className={`fa fa-circle me-1 ${value.class}`}></i>
					{value.label}
				</div>
			)
		},
		disableFilters: true,
	},
	{
		Header: 'Azione',
		Footer: 'Azione',
		accessor: 'azione',
		Cell: ({ value }) => {
			return (
				<Dropdown>
					<Dropdown.Toggle
						className="btn-primary light sharp i-false btn-action"
					>
						<More />
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item>Modifica</Dropdown.Item>
						<Dropdown.Item>Cancella</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			)
		},
		disableFilters: true,
	},
];
const data_json = [

	{
		"promo_inizio": "1 Marzo 2024",
		"promo_fine": "30 giugno 2024",
		"promo_nome": "Summerfest",
        "promo_prodotti": "Lezioni Tennis",
		"promo_status": {
			"label": "Attivo",
			"class": "text-success"
		},
	},
    {
		"promo_inizio": "30 settembre 2023",
		"promo_fine": "30 ottobre 2023",
		"promo_nome": "Bentornati - 5%",
        "promo_prodotti": "Lezioni Nuoto (+altri 10)",
		"promo_status": {
			"label": "Scaduta",
			"class": "text-primary"
		},
	},
    {
		"promo_inizio": "30 settembre 2024",
		"promo_fine": "30 ottobre 2024",
		"promo_nome": "Bentornati - 5%",
        "promo_prodotti": "Lezioni Nuoto (+altri 10)",
		"promo_status": {
			"label": "Programmata",
			"class": "text-blue"
		},
	},
	
];

const ListaPromo = () => {
	return (
		<TabellaLista
			cardTitle={'Lista Promozioni '}
			hasSearch={true}
			hasFilter={true}
			hasAddMore={true}
			labelAddMore={'Nuova promozione'}
			linkAddMore={'/struttura/prodotti/promozioni/aggiungi'}
			data_column={data_column}
			data_json={data_json}
		/>
	)
}

export default ListaPromo
