import { More, User } from "iconsax-react";
import { Alert, Button, Col, Dropdown, Modal, Row, Spinner } from "react-bootstrap";
import { ColumnFilter } from "../../../../components/table/FilteringTable/ColumnFilter";
import TabellaLista from "../../../../components/Tabella/TabellaLista";
import { Fragment, useEffect, useState } from "react";
import MyApiClient from "../../../../../api-client/MyApiClient";

const sports = [
	{ value: "RUNNING", label: "Corsa" },
	{ value: "BIKING", label: "Ciclismo" },
	{ value: "TENNIS", label: "Tennis" },
	{ value: "PADEL", label: "Padel" },
	{ value: "SKIING", label: "Sci" },
	{ value: "CROSS_COUNTRY_SKIING", label: "Fondo" },
	{ value: "SNOWBOARD", label: "Snowboard" },
	{ value: "SWIMMING", label: "Nuoto" },
	{ value: "GOLF", label: "Golf" },
	{ value: "FOOTBALL", label: "Calcio" },
	{ value: "FIVE_A_SIDE_FOOTBALL", label: "Calcio a 5" },
	{ value: "VOLLEYBALL", label: "Pallavolo" },
	{ value: "BASKETBALL", label: "Basket" },
	{ value: "ICE_HOCKEY", label: "Hockey su ghiaccio" },
	{ value: "RUGBY", label: "Rugby" }
];

export const ListaMaestri = () => {

    const [data_json, setDataJson] = useState(null);

    const [modalShowed, setModalShowed] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalText, setModalText] = useState('');

	const [loadingShowed, setLoadingShowed] = useState(false);

    const data_column = [
        {
            Header: 'Nome e Cognome',
            Footer: 'Nome e Cognome',
            accessor: 'name',
            Filter: ColumnFilter,
            Cell: ({ row }) => (
				<div style={{ display: 'flex', alignItems: 'center' }}>
				  {row.original.imageUrl && <img
					src={row.original.imageUrl}
					alt={row.original.name}
					style={{
					  width: '40px',
					  height: '40px',
					  borderRadius: '50%',
					  marginRight: '10px'
					}}
				  />}
				  {row.original.name}
				</div>
			)
        },
        {
            Header: 'Email',
            Footer: 'Email',
            accessor: 'email',
            Filter: ColumnFilter
        },
        {
            Header: 'Sport',
            Footer: 'Sport',
            accessor: 'sports',
            Filter: ColumnFilter
        },
        {
            Header: 'Stato profilo',
            Footer: 'Stato profilo',
            accessor: 'status',
            Cell: ({ value }) => {
                return (value &&
                    <div className="d-flex align-items-center">
                        <i className={`fa fa-circle me-1 ${value.class}`}></i>
                        {value.label}
                    </div>
                );
            },
            disableFilters: true,
        },
        {
            Header: 'Stato richiesta',
            Footer: 'Stato richiesta',
            accessor: 'requeststatus',
            Cell: ({ value }) => {
                return (value &&
                    <div className="d-flex align-items-center">
                        <i className={`fa fa-circle me-1 ${value.class}`}></i>
                        {value.label}
                    </div>
                );
            },
            disableFilters: true,
        },
        {
            Header: 'Azione',
            Footer: 'Azione',
            accessor: 'azione',
            Cell: ({ row }) => {
                return (
                  <Dropdown>
                    <Dropdown.Toggle className="btn-primary light sharp i-false btn-action" >
                      <More />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => openDetail(row.original)}><span className="me-2"><User size="20" /></span> Visualizza</Dropdown.Item>
                      {/*<Dropdown.Item className="text-danger"><span className="me-2"><Trash size="20"/></span>Delete</Dropdown.Item>*/}
                    </Dropdown.Menu>
                  </Dropdown>
                )
              },
            disableFilters: true,
        },
    ];

    function openDetail(row) {
		window.location.href = '/superadmin/anagrafiche/maestri/'+row.id;
	}

    /*
    const data_json = [
        {
            "nome-e-cognome": "Loredana Bassi",
            "email": "loredana@mail.com",
            "sport": "Calcio, Tennis, Nuoto",
            "stato-profilo": {
                "label": "Inactive",
                "class": "text-warning"
            },
            "stato-richiesta": {
                "label": "In attesa",
                "class": "text-warning"
            }
        },
        {
            "nome-e-cognome": "Mario Rossi",
            "email": "mariorossi@mail.com",
            "sport": "Calcio",
            "stato-profilo": {
                "label": "Completed",
                "class": "text-blue"
            },
            "stato-richiesta": {
                "label": "Non accettato",
                "class": "text-danger"
            }
        },
        {
            "nome-e-cognome": "Mario Rossi",
            "email": "mariorossi@mail.com",
            "sport": "Calcio",
            "stato-profilo": {
                "label": "Active",
                "class": "text-success"
            },
            "stato-richiesta": {
                "label": "Accettato",
                "class": "text-success"
            }
        },
    ];
    */

    useEffect(() => {
        const fetchData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    let token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();
            
                    setLoadingShowed(true);
                    const [membershipData] = await Promise.all([
                        apiClient.getProfilesByFilters(token,10000,null,'IDS_GURU')
                    ]);
                    setLoadingShowed(false);
            
                    if(membershipData) {
                        let data_member = [];
                        membershipData.items.forEach(element => {
            
                            let image = null;
                            if(element.pictures && element.pictures[0] && element.pictures[0].uri) {
                                image = element.pictures[0].uri;
                            }
            
                            let status = null;
                            if(element.profileStatus=='INACTIVE') {
                                status = {
                                    "label": "Inattivo",
                                    "class": "text-danger"
                                };
                            } else if(element.profileStatus=='ACTIVE') {
                                status = {
                                    "label": "Attivo",
                                    "class": "text-warning"
                                };
                            } else if(element.profileStatus=='COMPLETED') {
                                status = {
                                    "label": "Completato",
                                    "class": "text-success"
                                };
                            }

                            let sports_taught = '';
                            element.taughtSportTypes.forEach(element => {
                                sports.forEach(element2 => {
                                    if(element2.value == element) {
                                        sports_taught += element2.label+'. ';
                                    }
                                });
                            });
            
                            let member_row = {
                                "id": element.userId,
                                "name": element.name,
                                "imageUrl": image,
                                "status": status,
                                "nr-clienti": 0,
                                "nr-soci": 0,
                                "nr-maestri": 0,
                                "email": "",
                                sports: sports_taught
                            };
                            data_member.push(member_row);
            
                        });
                        setDataJson(data_member);
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        fetchData();
    
    }, []);

    return (
        <>

            <Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

			<Modal className="fade" show={modalShowed} centered>
                <Modal.Header>
                    <Modal.Title>{modalTitle}</Modal.Title>
                    <Button onClick={() => setModalShowed(false)} variant="" className="btn-close">
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Row><Col className="col-xl-64">{modalText}</Col></Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button className="btn btn-primary btn-add" onClick={() => setModalShowed(false)}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>

            <Fragment>
                <TabellaLista
                    cardTitle={'Lista Maestri'}
                    hasSearch={true}
                    hasFilter={true}
                    hasAddMore={false}
                    data_column={data_column}
                    data_json={data_json}
                />

                {/*<Alert variant="danger">
                    <Alert.Heading>Attenzione</Alert.Heading>
                    <p>
                        La lista è in fase di creazione.<br/>
                        Le "Azioni" non sono definitive
                    </p>
                </Alert>*/}

            </Fragment>
        </>
    )
}

export default ListaMaestri