import React, { useContext } from "react";

/// React router dom
import { Routes, Route, Outlet } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import NavStruttura from "./layouts/nav/indexStruttura";
import NavMaestro from "./layouts/nav/indexMaestro";
import NavSuperAdmin from "./layouts/nav/indexSuperAdmin";
import Footer from "./layouts/Footer";
import Setting from "./layouts/Setting";
//import Main from './layouts/Main';

import ScrollToTop from "./layouts/ScrollToTop";

/// Pages
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";

// CUSTOM PAGES - STRUTTURA
import HomeStruttura from "./pages/struttura/dashboard";
import LaMiaStruttura from "./pages/struttura/lamiastruttura";
import Clienti from "./pages/struttura/anagrafiche/clienti";
import JuniorTable from "./pages/struttura/anagrafiche/junior";
import MaestriTable from "./pages/struttura/anagrafiche/maestri";
import VediMaestro from "./pages/struttura/anagrafiche/maestri/maestro_profile";
import CdaTable from "./pages/struttura/anagrafiche/cda";
import SociTable from "./pages/struttura/anagrafiche/soci";
import TeamsTable from "./pages/struttura/anagrafiche/teams";
import TeamsNew from "./pages/struttura/anagrafiche/teams/new";
import TeamsEdit from "./pages/struttura/anagrafiche/teams/edit";
import ComunicazioneGenericheLista from "./pages/struttura/comunicazioni/generiche";
import ComunicazioneGenericheNuova from "./pages/struttura/comunicazioni/generiche/new";
import ComunicazioneGenericheEdit from "./pages/struttura/comunicazioni/generiche/edit";
import ComunicazioneInvitiLista from "./pages/struttura/comunicazioni/inviti";
import ComunicazioneInvitiNuovo from "./pages/struttura/comunicazioni/inviti/new";
import ComunicazioneInvitiEdit from "./pages/struttura/comunicazioni/inviti/edit";
import StruttureDocumenti from "./pages/struttura/documenti";
import StrutturaDocumentiNuovo from "./pages/struttura/documenti/new";
import StrutturaCalendarioEventi from "./pages/struttura/calendario/eventi";
import ListaProdotti from "./pages/struttura/prodotti/lista/listaprodotti";
import AddProdotto from "./pages/struttura/prodotti/lista/addProdotto";
import ListaImpianti from "./pages/struttura/prodotti/impianti/listaImpianti";
import AddImpianto from "./pages/struttura/prodotti/impianti/addImpianto";
import ListaPromo from "./pages/struttura/prodotti/promozioni/listaPromo";
import AddPromo from "./pages/struttura/prodotti/promozioni/addPromo";
import ListaDiAttesa from "./pages/struttura/prodotti/listaAttesa/listaDiAttesa";
import ReportLaMiaStruttura from "./pages/struttura/report/reportStruttura";
import ReportStrutturaMaestri from "./pages/struttura/report/reportMaestri";
import StrutturaUtenti from "./pages/struttura/strumenti/struttureUtenti";
import StrutturaRuoli from "./pages/struttura/strumenti/struttureRuoli";
import StrutturaAddUtente from "./pages/struttura/strumenti/struttureAddUtenteGestionale";
import StrutturaAddRuolo from "./pages/struttura/strumenti/struttureAddRuolo";
import PlayerProfile from "./pages/struttura/anagrafiche/clienti/player_profile";
import JuniorProfile from "./pages/struttura/anagrafiche/junior/junior_profile";
import StrutturaRichieste from "./pages/struttura/anagrafiche/richieste";
import Impostazioni from "./pages/strumenti/impostazioni";

// CUSTOM PAGES - SUPERADMIN

// Dashboard
import HomeSuperAdmin from "./pages/superadmin/dashboard";

// Anagrafiche
import ListaStruttureSportive         from "./pages/superadmin/anagrafiche/strutture_sportive/index";
import StrutturaEdit                  from "./pages/superadmin/anagrafiche/strutture_sportive/edit";
import ListaPlayer                    from "./pages/superadmin/anagrafiche/player/index";
import PlayerEdit                     from "./pages/superadmin/anagrafiche/player/edit";
import ListaJunior                    from "./pages/superadmin/anagrafiche/junior/index";
import JuniorEdit                     from "./pages/superadmin/anagrafiche/junior/edit";
import ListaMaestri                   from "./pages/superadmin/anagrafiche/maestri/index";
import MaestroEdit                    from "./pages/superadmin/anagrafiche/maestri/edit";
import ListaAbilitazioneMaestri       from "./pages/superadmin/anagrafiche/abilitazione_maestri/listaAbilitazioneMaestri";

// Comunicazioni
import ListaComunicazioni             from "./pages/superadmin/comunicazioni/index";
import AggiungiComunicazione          from "./pages/superadmin/comunicazioni/new";
import ModificaComunicazione          from "./pages/superadmin/comunicazioni/edit";

// Documenti
import ListaDocumenti                 from "./pages/superadmin/documenti/listaDocumenti";

// Calendario
import CalendarioEventi               from "./pages/superadmin/calendario/calendario_eventi/calendario_eventi";
import CalendarioPrenotazioni         from "./pages/superadmin/calendario/calendario_prenotazioni/calendario_prenotazioni";

// Prodotti
import ListaProdottiSuperAdmin        from "./pages/superadmin/prodotti/prodotti/listaProdotti";
import ListaAttesa                    from "./pages/superadmin/prodotti/lista-attesa/listaAttesa";
import ListaPromozioni                from "./pages/superadmin/prodotti/promozioni/listaPromozioni";

// Vendite e Fatture 
import VenditeEFattureSuperAdmin                from "./pages/superadmin/vendite-e-fatture/venditeEFatture"; 

// Report 
import ReportSportID                  from "./pages/superadmin/report/reportSportID";
import ReportStrutture                from "./pages/superadmin/report/reportStrutture";
import ReportMaestri                  from "./pages/superadmin/report/reportMaestri";

// Impostazioni 
import ListaUtentiGestionale          from "./pages/superadmin/strumenti/utenti-gestionale/listaUtentiGestionale"; 
import AggiungiUtenteGestionale       from "./pages/superadmin/strumenti/utenti-gestionale/aggiungiUtentiGestionale";


// CUSTOM PAGES - MAESTRO

// Dashboard
import DashboardMaestro from "./pages/maestro/DashboardMaestro";

// Il mio profilo
import IlMioProfilo from "./pages/maestro/IlMioProfilo";

// I miei allievi
import ListaMieiAllievi from "./pages/maestro/miei-allievi/ListaMieiAllievi";

// Communicazioni
import ListaComunicazioniGeneriche from "./pages/maestro/comunicazioni/generiche/listaComunicazioniGeneriche";
import AggiungiComunicazioneGeneriche from "./pages/maestro/comunicazioni/generiche/aggiungiComunicazioneGeneriche";
import ListaComunicazioniInvitiAdIscriversi from "./pages/maestro/comunicazioni/inviti-ad-iscriversi/listaComunicazioniInvitiAdIscriversi";
import AggiungiComunicazioneInvitiAdIscriversi from "./pages/maestro/comunicazioni/inviti-ad-iscriversi/aggiungiComunicazioneInvitiAdIscriversi";

// Documenti
import ListaDocumentiMaestro from "./pages/maestro/documenti/listaDocumenti";
import AggiungiDocumentiMaestro from "./pages/maestro/documenti/aggiungiDocumenti";

// Calendario 
import ListaCalendarioEventiMaestro from "./pages/maestro/calendario/eventi/listaCalendarioEventi";
import ListaCalendarioPrenotazioniMaestro from "./pages/maestro/calendario/prenotazioni/listaCalendarioPrenotazioni";

// Prodotti
import ListaProdottiMaestro from "./pages/maestro/prodotti/prodotti/listaProdotti";
import AggiungiProdottoMaestro from "./pages/maestro/prodotti/prodotti/aggiungiProdotto";
import ListaAttesaMaestro from "./pages/maestro/prodotti/lista-attesa/listaAttesa";
import ListaPromozioniMaestro from "./pages/maestro/prodotti/promozioni/listaPromozioni";
import AggiungiPromozioneMaestro from "./pages/maestro/prodotti/promozioni/aggiungiPromozione";

// Vendite e Fatture
import VenditeEFattureMaestro from "./pages/maestro/vendite-e-fatture/venditeEFatture";

// Report 
import ReportMaestro from "./pages/maestro/report/report";

// Impostazioni
import ImpostazioniMaestro from "./pages/maestro/strumenti/impostazioni";


const Markup = () => {

  const allroutes = [

      // CUSTOM ROUTES - STRUTTURA
    { url: "struttura/dashboard", component: <HomeStruttura/> },
    { url: "struttura/lamiastruttura", component: <LaMiaStruttura/> },
    { url: "struttura/clienti/list", component: <Clienti/> },
    { url: "struttura/clienti/player_profile/:id", component:<PlayerProfile/>},
    { url: "struttura/junior/list", component: <JuniorTable/> },
    { url: "struttura/junior/junior_profile/:id", component: <JuniorProfile/>},
    { url: "struttura/maestri/list", component: <MaestriTable/> },
    { url: "struttura/richieste", component: <StrutturaRichieste/> },
    { url: "struttura/cda/list", component: <CdaTable/> },
    { url: "struttura/soci/list", component: <SociTable/> },
    { url: "struttura/teams/list", component: <TeamsTable/> },
    { url: "struttura/teams/add", component: <TeamsNew/> },
    { url: "struttura/teams/edit/:id", component: <TeamsEdit/> },
    { url: "struttura/comunicazioni/generiche", component: <ComunicazioneGenericheLista/>},
    { url: "struttura/comunicazioni/generiche/add", component: <ComunicazioneGenericheNuova/>},
    { url: "struttura/comunicazioni/generiche/:id", component: <ComunicazioneGenericheEdit/>},
    { url: "struttura/comunicazioni/inviti", component: <ComunicazioneInvitiLista/>},
    { url: "struttura/comunicazioni/inviti/add", component:<ComunicazioneInvitiNuovo/>},
    { url: "struttura/comunicazioni/inviti/:id", component: <ComunicazioneInvitiEdit/>},
    { url: "struttura/documenti", component:<StruttureDocumenti/>},
    { url: "struttura/documenti/add", component:<StrutturaDocumentiNuovo/>},
    { url: "struttura/calendario/eventi", component:<StrutturaCalendarioEventi/>},
    { url: "struttura/maestri/maestro_profile/:id", component:<VediMaestro/>},
    { url: "struttura/prodotti/lista/", component:<ListaProdotti/>},
    { url: "struttura/prodotti/lista/aggiungi", component:<AddProdotto/>},
    { url: "struttura/prodotti/impianti/lista", component:<ListaImpianti/>},
    { url: "struttura/prodotti/impianti/aggiungi", component:<AddImpianto/>},
    { url: "struttura/prodotti/promozioni", component:<ListaPromo/>},
    { url: "struttura/prodotti/promozioni/aggiungi", component:<AddPromo/>},
    { url: "struttura/prodotti/listaAttesa", component:<ListaDiAttesa/>},
    { url: "struttura/report/reportStruttura", component:<ReportLaMiaStruttura/>},
    { url: "struttura/report/reportMaestri", component: <ReportStrutturaMaestri/>},
    { url: "struttura/strumenti/utenti", component:<StrutturaUtenti/>},
    { url: "struttura/strumenti/ruoli", component:<StrutturaRuoli/>},
    { url: "struttura/strumenti/aggiungi_utente", component:<StrutturaAddUtente/>},
    { url: "struttura/strumenti/aggiungi_ruolo", component:<StrutturaAddRuolo/>},
    { url: 'strumenti/impostazioni', component:<Impostazioni/>},

    // CUSTOM ROUTES - SUPERADMIN

    // Dashboard
    { url: "superadmin/dashboard", component: <HomeSuperAdmin /> },

    // Anagrafiche
    { url: "superadmin/anagrafiche/strutture-sportive", component: <ListaStruttureSportive /> },
    { url: "superadmin/anagrafiche/strutture-sportive/:id", component: <StrutturaEdit /> },
    { url: "superadmin/anagrafiche/player", component: <ListaPlayer /> },
    { url: "superadmin/anagrafiche/player/:id", component: <PlayerEdit /> },
    { url: "superadmin/anagrafiche/junior", component: <ListaJunior /> },
    { url: "superadmin/anagrafiche/junior/:id", component: <JuniorEdit /> },
    { url: "superadmin/anagrafiche/maestri", component: <ListaMaestri /> },
    { url: "superadmin/anagrafiche/maestri/:id", component: <MaestroEdit /> },
    { url: "superadmin/anagrafiche/abilitazione-maestri", component: <ListaAbilitazioneMaestri /> },

    // Comunicazioni
    { url: "superadmin/comunicazioni", component: <ListaComunicazioni /> },
    { url: "superadmin/comunicazioni/add", component: <AggiungiComunicazione /> },
    { url: "superadmin/comunicazioni/:id", component: <ModificaComunicazione /> },

    // Documenti
    { url: "superadmin/documenti", component: <ListaDocumenti /> },

    // Calendario
    { url: "superadmin/calendario/calendario-eventi", component: <CalendarioEventi /> },
    { url: "superadmin/calendario/calendario-prenotazioni", component: <CalendarioPrenotazioni /> },

    // Prodotti
    { url: "superadmin/prodotti", component: <ListaProdottiSuperAdmin /> },
    { url: "superadmin/prodotti/promozioni", component: <ListaPromozioni /> },
    { url: "superadmin/prodotti/lista-di-attesa", component: <ListaAttesa /> },

    // Vendite e Fatture
    { url: "superadmin/vendite-fatture", component: <VenditeEFattureSuperAdmin /> },

    // Report
    { url: "superadmin/report/sportid", component: <ReportSportID /> },
    { url: "superadmin/report/strutture", component: <ReportStrutture /> },
    { url: "superadmin/report/maestri", component: <ReportMaestri /> },

    // Strumenti
    { url: "superadmin/strumenti/utenti-gestionale", component: <ListaUtentiGestionale /> },
    { url: "superadmin/strumenti/aggiungi-utente-gestionale", component: <AggiungiUtenteGestionale /> },
    
    /***
     * 
     * CUSTOM ROUTES - MAESTRO
     * 
     */

    // Dashboard
    { url: "maestro/dashboard", component: <DashboardMaestro /> },

    // Il mio profilo
    { url: "maestro/profilo", component: <IlMioProfilo /> },

    // I miei allievi
    { url: "maestro/i-miei-allievi", component: <ListaMieiAllievi /> },

    // Comunicazioni
    { url: "maestro/comunicazioni/generiche", component: <ListaComunicazioniGeneriche /> },
    { url: "maestro/comunicazioni/generiche/aggiungi-comunicazione", component: <AggiungiComunicazioneGeneriche /> },
    { url: "maestro/comunicazioni/inviti-ad-iscriversi", component: <ListaComunicazioniInvitiAdIscriversi /> },
    { url: "maestro/comunicazioni/inviti-ad-iscriversi/aggiungi-comunicazione", component: <AggiungiComunicazioneInvitiAdIscriversi /> },

    // Documenti 
    { url: "maestro/documenti", component: <ListaDocumentiMaestro /> },
    { url: "maestro/documenti/aggiungi-documento", component: <AggiungiDocumentiMaestro /> },
    

    // Calendario
    { url: "maestro/calendario/eventi", component: <ListaCalendarioEventiMaestro /> },
    { url: "maestro/calendario/prenotazioni", component: <ListaCalendarioPrenotazioniMaestro /> },

    // Prodotti
    { url: "maestro/prodotti/listaprodotti", component: <ListaProdottiMaestro /> },
    { url: "maestro/prodotti/listaprodotti/aggiungi-prodotto", component: <AggiungiProdottoMaestro /> },

    { url: "maestro/prodotti/lista-di-attesa", component: <ListaAttesaMaestro /> },

    { url: "maestro/prodotti/promozioni", component: <ListaPromozioniMaestro /> },
    { url: "maestro/prodotti/promozioni/aggiungi-promozione", component: <AggiungiPromozioneMaestro /> },

    // Vendite e Fatture
    { url: "maestro/vendite-fatture", component: <VenditeEFattureMaestro /> },

    // Report
    { url: "maestro/report", component: <ReportMaestro /> },

    // Strumenti
    { url: "maestro/strumenti/impostazioni", component: <ImpostazioniMaestro /> },

  ];

  //let path = window.location.pathname;
  //path = path.split("/");
  //path = path[path.length - 1];

  //let pagePath = path.split("-").includes("page");
  //const { menuToggle } = useContext(ThemeContext);

  return (
    <>
      <Routes>
        <Route path='page-lock-screen' element={<LockScreen />} />
        <Route path='page-error-400' element={<Error400 />} />
        <Route path='page-error-403' element={<Error403 />} />
        <Route path='page-error-404' element={<Error404 />} />
        <Route path='page-error-500' element={<Error500 />} />
        <Route path='page-error-503' element={<Error503 />} />
        <Route element={<MainLayout />} >
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
      </Routes>
      <Setting />
      <ScrollToTop />

    </>
  );
};

function MainLayout() {

  const userType = sessionStorage.getItem('userType');
  // console.log(userType);

  const { menuToggle } = useContext(ThemeContext);
  return (
    <div id="main-wrapper" className={`show ${menuToggle ? "menu-toggle" : ""}`}>
      {userType === 'struttura' && <NavStruttura />}
      {userType === 'maestro' && <NavMaestro />}
      {userType === 'superadmin' && <NavSuperAdmin />}
      <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  )
};

export default Markup;