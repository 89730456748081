import React from "react";
import { Row, Form } from "react-bootstrap";
import TabellaLista from "../Tabella/TabellaLista";
import { Dropdown } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { More, People } from 'iconsax-react';
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import profile01 from "../../../images/profile/small/pèerderbrema.jpg";

const SquadrePlayer = () => {
    const data_column = [
        {
            Header: 'Nome',
            accessor: 'nome',
            Cell: ({ value }) => {
				return <span><img src={profile01} style={{ width: '10%' }} className='bd-tondo' alt="" /> {value}</span> 
			},
            Filter: ColumnFilter,
        },
        {
            Header: 'Capitano',
            accessor: 'captain',
            Filter: ColumnFilter,
        },
        {
            Header: 'Membri',
            accessor: 'members',
            Filter: ColumnFilter,
        },
        {
            Header: 'Sport',
            accessor: 'sport',
            Filter: ColumnFilter,
        },
        {
            Header: 'Azione',
            accessor: 'azione',
            Cell: ({ value }) => {
                return (
                    <Dropdown>
                        <Dropdown.Toggle
                            className="btn-primary light sharp i-false btn-action"
                        >
                            <More />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item><Link to='#'><span className="me-2"><People size="20" /></span>Consulta squadra</Link></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            },
            disableFilters: true,
        },

    ];
    const data_json = [
        /*
        {
            "id": 1,
            "nome": "Perder Brema",
            "captain":"Mario Rossi",
            "members": 12,
            "sport":"Calcio",
        },
        */
    ];
    return (
        <>
            <Row>
                <Form>

                    <TabellaLista
                        cardTitle={'Squadre'}
                        hasSearch={true}
                        hasFilter={false}
                        hasAddMore={false}
                        data_column={data_column}
                        data_json={data_json}
                    />
                </Form>

            </Row>
        </>
    )
}
export default SquadrePlayer;