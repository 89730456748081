import { Row, Col, Card, Form} from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
const options = [
    { value: "Documenti", label: "Documenti" },
    { value: "Vendite e fatture", label: "Vendite e fatture" },
    { value: "Impianti", label: "Impianti" },
    { value: "Prodotti", label: "Prodotti" },
    { value: "La mia struttura", label: "La mia struttura" },
    { value: "Anagrafiche", label: "Anagrafiche" },
];

const StrutturaAddRuolo = () => {

    return (
        <>
            <Form>
                <Card>
                    <Card.Header>
                        <Card.Title>Crea un nuovo ruolo per il gestionale</Card.Title>

                    </Card.Header>
                    <Card.Body>

                        <Row className="mb-4">
                            <Col className="col-xl-12">
                                <Form.Label>Nome ruolo</Form.Label>
                                <Form.Control type="text" placeholder="Inserisci il nome" />
                            </Col>

                        </Row>
                        <Form.Label>Seleziona i permessi da associare al ruolo</Form.Label>
                        <Select
                            defaultValue={""}
                            options={options}
                            isMulti
                            style={{
                                lineHeight: "40px",
                                color: "#fd7e14",
                                paddingLeft: " 15px",
                            }
                            }
                        />



                    </Card.Body>
                </Card >
                <div className="d-flex justify-content-end gap-4">
                    <Link to={"#"} className="btn btn-dark light mr-4">Annulla</Link>	<Link to="#" className="btn btn-primary ">Salva</Link>
                </div>
            </Form>
        </>

    )
}

export default StrutturaAddRuolo