import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter';
import { Form, Button, Dropdown, Modal, Row, Col, Spinner} from "react-bootstrap";
import { More, User, Trash } from 'iconsax-react';
import TabellaLista from '../../../../components/Tabella/TabellaLista';
import MyApiClient from '../../../../../api-client/MyApiClient';
import { format } from 'date-fns';
import Select from "react-select";

const sports = [
	{ value: "RUNNING", label: "Corsa" },
	{ value: "BIKING", label: "Ciclismo" },
	{ value: "TENNIS", label: "Tennis" },
	{ value: "PADEL", label: "Padel" },
	{ value: "SKIING", label: "Sci" },
	{ value: "CROSS_COUNTRY_SKIING", label: "Fondo" },
	{ value: "SNOWBOARD", label: "Snowboard" },
	{ value: "SWIMMING", label: "Nuoto" },
	{ value: "GOLF", label: "Golf" },
	{ value: "FOOTBALL", label: "Calcio" },
	{ value: "FIVE_A_SIDE_FOOTBALL", label: "Calcio a 5" },
	{ value: "VOLLEYBALL", label: "Pallavolo" },
	{ value: "BASKETBALL", label: "Basket" },
	{ value: "ICE_HOCKEY", label: "Hockey su ghiaccio" },
	{ value: "RUGBY", label: "Rugby" }
];

export const MaestriTable = () => {

	const [modalCentered, setModalCentered] = useState(false);
	const [data_json, setDataJson] = useState(null);

	const [showedSports, setShowedSports] = useState([]);

	const [confirmShowed, setConfirmShowed] = useState(false);
	const [onContinue, setOnContinue] = useState(null);

	const [memberOptions, setMemberOptions] = useState(null);
	const [memberOptionsFull, setMemberOptionsFull] = useState(null);

	const [modalShowed, setModalShowed] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalText, setModalText] = useState('');

	const [loadingShowed, setLoadingShowed] = useState(false);
	
	const [selectedMember, setSelectedMember] = useState('');
	const handleSelectChange = (selectedOptions) => {
		setSelectedMember(selectedOptions.value);
    };

	const [selectedSport, setSelectedSport] = useState('');
	const handleSelectChangeSport = (selectedOptions) => {
		setSelectedSport(selectedOptions.value);
    };

	const data_column = [

		{
			Header: 'Nome e cognome',
			accessor: 'name',
			Filter: ColumnFilter,
			Cell: ({ row }) => (
				<div style={{ display: 'flex', alignItems: 'center' }}>
				  {row.original.imageUrl && <img
					src={row.original.imageUrl}
					alt={row.original.name}
					style={{
					  width: '40px',
					  height: '40px',
					  borderRadius: '50%',
					  marginRight: '10px'
					}}
				  />}
				  {row.original.name}
				</div>
			)
		},
		/*
		{
			Header: 'Email',
			Footer: 'Email',
			accessor: 'maestro_email',
			Filter: ColumnFilter
		},
		*/
		{
			Header: 'Sport',
			Footer: 'Sport',
			accessor: 'sport',
			Filter: ColumnFilter
		},
		{
			Header: 'Stato profilo',
			Footer: 'Stato profilo',
			accessor: 'status',
			Filter: ColumnFilter,
			Cell: ({ value }) => {
				return (
					<div className="d-flex align-items-center">
						<i className={`fa fa-circle me-1 ${value?value.class:''}`}></i>
						{value?value.label:''}
					</div>
				)
			},
			disableFilters: true,
		},
		{
			Header: 'Azione',
			Footer: 'Azione',
			accessor: 'azione',
			Cell: ({ row }) => {
				return (
					<Dropdown>
						<Dropdown.Toggle className="btn-primary light sharp i-false btn-action">
							<More />
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item onClick={() => openDetail(row.original)}><span className="me-2"><User size="20" /></span> Visualizza</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				)
			},
			disableFilters: true,
		},
	];

	const inviteUser = (e) => {
		setModalCentered(false);
		setOnContinue(() => () => continueInviteUser());

		if(!selectedMember) {
			setModalTitle("Errore durante l'operazione");
			setModalText("Il socio a cui inviare l'invito deve essere valorizzato per continuare");
			setModalShowed(true);
			return;
		}

		if(!selectedSport) {
			setModalTitle("Errore durante l'operazione");
			setModalText("Lo sport deve essere valorizzato per continuare");
			setModalShowed(true);
			return;
		}

		// CONTROLLO CHE LO SPORT SELEZIONATO SIA TRA QUELLI DEL MAESTRO
		let founded_match = false;
		memberOptionsFull.items.forEach(element => {
			if(element.userId == selectedMember) {
				element.taughtSportTypes.forEach(element2 => {
					if(element2 == selectedSport) {
						// OK
						founded_match = true;
					}
				});
			}
		});

		if(!founded_match) {
			setModalTitle("Errore durante l'operazione");
			setModalText("Il maestro selezionato non può insegnare lo sport indicato.");
			setModalShowed(true);
			return;
		}

		setConfirmShowed(true);
	};

	const continueInviteUser = () => {

		setConfirmShowed(false);

        const inviteUs = async () => {
            try {
				
				const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
					
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
					const clubId = tokenDetails.localId;
                    const apiClient = new MyApiClient();

					let data_update = {
                        'userInvitations': [
							{
								'userId': selectedMember,
								'membershipRank': 'COACH',
								'sport': selectedSport
							}
						]
                    };

					setModalCentered(false);
					setLoadingShowed(true);
					const saveResult = await apiClient.inviteUser(token, clubId, data_update);
					setLoadingShowed(false);

                    if (saveResult && saveResult.error) {
                        setModalTitle("Errore durante l'operazione");
                        setModalText("Si è verificato un errore durante l'operazione ("+saveResult.error+").");
                        setModalShowed(true);
                    } else {
						window.location.href = '/struttura/maestri/list';
                    }
                }

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        inviteUs();
    };

	useEffect(() => {
        const fetchData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    let token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

					setLoadingShowed(true);
					const [membershipData, membershipDataUsers, profile] = await Promise.all([
                        apiClient.getMemberships(token,'COACH',null),
						apiClient.getProfilesByFilters(token,10000,null,'IDS_GURU'),
						apiClient.getProfile(token)
                    ]);
					setLoadingShowed(false);

					if(membershipData) {
						let data_member = [];
						membershipData.forEach(element => {
							
							let sport_decode = '';
							sports.forEach(element2 => {
								if(element2.value == element.sport) {
									sport_decode = element2.label;
								}
							});

							let status = null;
							if(element.status=='CONFIRMED') {
								status = {
									"label": "Confermato",
									"class": "text-success"
								};
							} else {
								status = {
									"label": "Non confermato",
									"class": "text-primary"
								};
							}

							let image = null;
							if(element.picture && element.picture.uri) {
								image = element.picture.uri;
							}

							let member_row = {
								"id": element.userId,
								"name": element.name,
								"sport": sport_decode,
								"status": status,
								"imageUrl": image
							};
							data_member.push(member_row);
							
						});
						setDataJson(data_member);
					}

					if(membershipDataUsers && membershipDataUsers.items) {
						let data_member = [];
						membershipDataUsers.items.forEach(element => {
							
							let member_taught_sports = ' (';
							element.taughtSportTypes.forEach(element => {
								sports.forEach(element2 => {
									if(element==element2.value) {
										member_taught_sports += element2.label+', ';
									}
								});
							});
							if(member_taught_sports==' (') {
								member_taught_sports = '';
							} else {
								member_taught_sports = member_taught_sports.substring(0,member_taught_sports.length-2)+')';
							}

							let member_row = {
								"value": element.userId,
								"label": element.name+member_taught_sports
							};
							data_member.push(member_row);
						})

						setMemberOptions(data_member);
						setMemberOptionsFull(membershipDataUsers);
					}

					let new_sports = [];
					if(profile && profile.club && profile.club.sports) {
						profile.club.sports.forEach(element => {
							sports.forEach(element2 => {
								if(element2.value == element.sportType) {
									new_sports.push(element2);
								}
							});
						});
					}
					setShowedSports(new_sports);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

    }, []);

	function openDetail(row) {
		window.location.href = '/struttura/maestri/maestro_profile/'+row.id;
	}

	return (
		<>
			<Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

			<Modal className="fade" show={confirmShowed} centered>
				<Modal.Header>
					<Modal.Title>Sei sicuro?</Modal.Title>
					<Button onClick={() => setConfirmShowed(false)} variant="" className="btn-close">
					</Button>
				</Modal.Header>
				<Modal.Body>
					<Row><Col className="col-xl-64">Sei sicuro di volere continuare? <b>Un invito sarà inviato all'utente selezionato.</b></Col></Row>
				</Modal.Body>
				<Modal.Footer className="justify-content-between">
					<Button className="btn btn-light btn-add" onClick={() => setConfirmShowed(false)}>
						Chiudi
					</Button>
					<Button className="btn btn-primary btn-add" onClick={onContinue}>
						Continua
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal className="fade" show={modalShowed} centered>
                <Modal.Header>
                    <Modal.Title>{modalTitle}</Modal.Title>
                    <Button onClick={() => setModalShowed(false)} variant="" className="btn-close">
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Row><Col className="col-xl-64">{modalText}</Col></Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button className="btn btn-primary btn-add" onClick={() => setModalShowed(false)}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>

			<TabellaLista
				cardTitle={'Staff Tecnico'}
				hasSearch={true}
				hasFilter={true}
				data_column={data_column}
				data_json={data_json}
				hasAddMoreAsModal={true}
				modalCallBack={() => setModalCentered(true)}
				modalContent={
					<Modal className="fade" show={modalCentered} centered>
						<Modal.Header>
							<Modal.Title>Invita un maestro nella tua struttura</Modal.Title>
							<Button onClick={() => setModalCentered(false)} variant="" className="btn-close" >
							</Button>
						</Modal.Header>
						<Modal.Body>
							<Form>
								<Row>
									<Col className="col-lg-12 pb-4">
										<Form.Label>Seleziona un maestro</Form.Label>
                                    	<Select placeholder="Seleziona un maestro..." 
											value={memberOptions?memberOptions.filter(option => selectedMember == option.value):''}
											options={memberOptions?memberOptions:[]} onChange={handleSelectChange}
											style={{lineHeight: "40px",color: "#fd7e14",paddingLeft: " 15px"}}
										/>
										<br></br>
										<Form.Label>Sport della tua struttura</Form.Label>
										<Select placeholder="Seleziona lo sport..." 
											value={showedSports?showedSports.filter(option => selectedSport == option.value):''}
											options={showedSports?showedSports:[]} onChange={handleSelectChangeSport}
											style={{lineHeight: "40px",color: "#fd7e14",paddingLeft: " 15px"}}
										/>
									</Col>
								</Row>
							</Form>
						</Modal.Body>
						<Modal.Footer className="justify-content-between">
							<Button className="btn btn-light btn-add" onClick={() => setModalCentered(false)}>
								Annulla
							</Button>
							<Button onClick={inviteUser} className="btn btn-primary btn-add">Invia invito</Button>
						</Modal.Footer>
					</Modal>
				}
			/>
		</>
	)
}

export default MaestriTable;