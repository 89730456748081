import React, { Fragment, useState } from "react";
import { Row, Col, Card, Form, Modal, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import MyApiClient from "../../../api-client/MyApiClient";

const Impostazioni = () => {

  const [modalShowed, setModalShowed] = useState(false);
  const [loadingShowed, setLoadingShowed] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const defaultHandleClose = () => {
    setModalShowed(false);
  };
  const [handleClose, setHandleClose] = useState(() => defaultHandleClose);

  const [confirmShowed, setConfirmShowed] = useState(false);

  const [formData1, setFormData1] = useState({
    email: ''
  });

  const [formData2, setFormData2] = useState({
    oldpassword: '',
    newpassword: '',
    confirmpassword: ''
  });

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setFormData1(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setFormData2(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const handleSaveClick1 = (e) => {
    e.preventDefault();

    if(formData1.email.trim()=='') {
      setModalTitle("Errore durante l'operazione");
      setModalText("Il nuovo indirizzo e-mail deve essere valorizzato per continuare");
      setModalShowed(true);
      return;
    }

    setConfirmShowed(true);
  };

  const handleSaveClick2 = (e) => {
    e.preventDefault();
    
    const saveData2 = async () => {
      try {

        if(formData2.oldpassword.trim()=='' || formData2.newpassword.trim()=='' || formData2.confirmpassword.trim()=='') {
          setModalTitle("Errore durante l'operazione");
          setModalText("Le password devono essere valorizzate per continuare");
          setModalShowed(true);
          return;
        }

        if(formData2.newpassword != formData2.confirmpassword) {
          setModalTitle("Errore durante l'operazione");
          setModalText("La conferma della nuova password non è corretta");
          setModalShowed(true);
          return;
        }

        const tokenDetailsString = sessionStorage.getItem('userDetails');
        if (tokenDetailsString) {
          const tokenDetails = JSON.parse(tokenDetailsString);
          const token = tokenDetails.idToken;
          const accessToken = tokenDetails.accessToken;
          const apiClient = new MyApiClient();

          setLoadingShowed(true);
          const [saveResult] = await Promise.all([
            apiClient.changePassword(token, accessToken, formData2.oldpassword, formData2.newpassword)
          ]);
          setLoadingShowed(false);

          if (saveResult && saveResult.error) {
            setModalTitle("Errore durante l'operazione");
            setModalText("Si è verificato un errore durante l'operazione (" + saveResult.error + ")");
            setModalShowed(true);
          } else {
            formData2.oldpassword = '';
            formData2.newpassword = '';
            formData2.confirmpassword = '';

            setModalTitle("Salvataggio completato");
            setModalText("La password è stata aggiornata correttamente, al prossimo accesso dovrai utilizzare la nuova password");
            setModalShowed(true);
          }
        }
      } catch (error) {
          console.error("Error fetching data:", error);
      }
    };

    saveData2();
  };

  const continueChangeUsername = () => {

    setConfirmShowed(false);

		const saveData1 = async () => {
      try {

        const tokenDetailsString = sessionStorage.getItem('userDetails');
        if (tokenDetailsString) {
            const tokenDetails = JSON.parse(tokenDetailsString);
            const token = tokenDetails.idToken;
            const apiClient = new MyApiClient();

            setLoadingShowed(true);
            const [saveResult] = await Promise.all([
              apiClient.changeEmail(token, formData1.email)
            ]);
            setLoadingShowed(false);

            if (saveResult && saveResult.error) {
              setModalTitle("Errore durante l'operazione");
              setModalText("Si è verificato un errore durante l'operazione (" + saveResult.error + ")");
              setModalShowed(true);
            } else {
              formData1.email = '';
              
              setModalTitle("Salvataggio completato");
              setModalText("L'indirizzo e-mail è stato aggiornato correttamente");
              setModalShowed(true);
            }
        }
      } catch (error) {
          console.error("Error fetching data:", error);
      }
    };

    saveData1();
	}

  return (
    <>
      <Modal className="fade" show={confirmShowed} centered>
        <Modal.Header>
            <Modal.Title>Sei sicuro? </Modal.Title>
            <Button onClick={() => setConfirmShowed(false)} variant="" className="btn-close">
            </Button>
        </Modal.Header>
        <Modal.Body>
            <Row><Col className="col-xl-64">Il nuovo indirizzo a cui sarà inviato il link per la conferma del cambio è <b>{formData1.email}</b>. Sei sicuro di avere l'accesso a questo indirizzo e-mail?</Col></Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
            <Button className="btn btn-light btn-add" onClick={() => setConfirmShowed(false)}>
                Chiudi
            </Button>
            <Button className="btn btn-primary btn-add" onClick={continueChangeUsername}>
                Continua
            </Button>
        </Modal.Footer>
      </Modal>

      <Modal className="fade" show={modalShowed} centered>
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
          <Button onClick={() => setModalShowed(false)} variant="" className="btn-close">
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row><Col className="col-xl-64">{modalText}</Col></Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button className="btn btn-primary btn-add" onClick={handleClose}>
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal className="fade" show={loadingShowed} centered>
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
          <div>Caricamento in corso...</div>
        </Modal.Body>
      </Modal>

      <Form>
        <Card>
          <Card.Header><h5>Cambia email</h5></Card.Header>
          <Card.Body>
            <Row>
              <Col className="col-xl-12">
                <Form.Label className="mt-1">Nuova E-mail</Form.Label>
                <Form.Control type="text" name="email" value={formData1.email} onChange={handleChange1} placeholder="Inserisci la nuova e-mail" />
              </Col>
            </Row>
            <div className="d-flex justify-content-end gap-4 mt-4">
              <Link onClick={handleSaveClick1} to="#" className="btn btn-primary ">Conferma cambio e-mail</Link>
            </div>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header><h5>Cambia password</h5></Card.Header>
          <Card.Body>
            <Row>
              <Col className="col-xl-4">
                <Form.Label className="mt-1">Vecchia password</Form.Label>
                <Form.Control type="password" name="oldpassword" value={formData2.oldpassword} onChange={handleChange2} placeholder="Inserisci la vecchia password" />
              </Col>
              <Col className="col-xl-4">
                <Form.Label className="mt-1">Nuova password</Form.Label>
                <Form.Control type="password" name="newpassword" value={formData2.newpassword} onChange={handleChange2} placeholder="Inserisci la nuova password" />
              </Col>
              <Col className="col-xl-4">
                <Form.Label className="mt-1">Conferma password</Form.Label>
                <Form.Control type="password" name="confirmpassword" value={formData2.confirmpassword} onChange={handleChange2} placeholder="Conferma la nuova password" />
              </Col>
            </Row>
            <div className="d-flex justify-content-end gap-4 mt-4">
              <Link onClick={handleSaveClick2} to="#" className="btn btn-primary ">Conferma cambio password</Link>
            </div>
          </Card.Body>
        </Card>
        
      </Form>
    </>
  )

}
export default Impostazioni;