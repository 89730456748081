import { Home, Profile, People, Building, Book, Sms, ArchiveBox, Calendar, ShoppingBag, ReceiptText, Diagram, Setting2  } from 'iconsax-react';


export const MenuMaestroList = [
    {   
        title:'Dashboard',
        iconStyle: <span style={{ 'marginRight': '10px' }}><Home color="#737B8B" /></span>,
        to: '/maestro/dashboard',
    },

    {   
        title:'Il mio profilo',
        iconStyle: <span style={{ 'marginRight': '10px' }}><Profile color="#737B8B" /></span>,
        to: '/maestro/profilo',
    },

    {   
        title:'Il miei allievi',
        iconStyle: <span style={{ 'marginRight': '10px' }}><People color="#737B8B" /></span>,
        to: '/maestro/i-miei-allievi',
    },

    //Dashboard
    {
        title: 'Comunicazioni',	
        classsChange: 'mm-collapse',		
        iconStyle: <span style={{ 'marginRight': '10px' }}><Sms color="#737B8B" /></span>,

        content: [
            
            {
                title: 'Generiche',
                to: '/maestro/comunicazioni/generiche',					
            },
            {
                title: 'Inviti ad iscriversi',
                to: '/maestro/comunicazioni/inviti-ad-iscriversi',
            }
            
        ],
    },

    // Documenti
    {   
        title:'Documenti',
        iconStyle: <span style={{ 'marginRight': '10px' }}><ArchiveBox color="#737B8B" /></span>,
        to: '/maestro/documenti',
    },

    // Calendario 

    {
        title: 'Calendario',
        classsChange: 'mm-collapse',
        iconStyle: <span style={{ 'marginRight': '10px' }}><Calendar color="#737B8B" /></span>,
        content: [
            {
                title: 'Eventi',
                to: '/maestro/calendario/eventi',
            },
            {
                title: 'Prenotazioni',
                to: '/maestro/calendario/prenotazioni',
            }
        ],
    },
  
    // Prodotti 
    {
        title: 'Prodotti',
        classsChange: 'mm-collapse',
        iconStyle: <span style={{ 'marginRight': '10px' }}><ShoppingBag color="#737B8B" /></span>,
        content: [
            {
                title: 'Lista Prodotti',
                to: '/maestro/prodotti/listaprodotti',
            },
            {
                title: 'Promozioni',
                to: '/maestro/prodotti/promozioni',
            }, 
            {
                title: 'Lista di attesa',
                to: '/maestro/prodotti/lista-di-attesa',
            }
        ]
    },

    // Vendite e Fatture
    {
        title: 'Vendite e Fatture',
        iconStyle: <span style={{ 'marginRight': '10px' }}><ReceiptText color="#737B8B" /></span>,
        to: '/maestro/vendite-fatture',
    },


    // Report
    {
        title: 'Report',
        iconStyle: <span style={{ 'marginRight': '10px' }}><Diagram color="#737B8B" /></span>,
        to: '/maestro/report',
    },

    // Strumenti
    {
        title: 'Strumenti',
        classsChange: 'mm-collapse',
        iconStyle: <span style={{ 'marginRight': '10px' }}><Setting2 color="#737B8B" /></span>,
        content: [
            {
                title: 'Impostazioni',
                to: '/maestro/strumenti/impostazioni',
            },
        ]
    },

]