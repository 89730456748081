import React, { Fragment, useState } from 'react'
import { Row, Col, Card, Collapse, Form, FormCheck, Button } from 'react-bootstrap'
import { ArrowDown2, FilterSquare } from 'iconsax-react'
import GraficoAbbonamentiAttivi from '../../../components/SuperAdmin/Dashboard/graficoAbbonamentiAttivi'
import TabellaLista from '../../../components/Tabella/TabellaLista'
import Select from "react-select";
import { ColumnFilter } from '../../../components/table/FilteringTable/ColumnFilter'


const data_column = [

	{
		Header: 'Impianto',
		accessor: 'impianto_nome',
		Filter: ColumnFilter
	},
	{
		Header: 'Ore vendute',
		accessor: 'impianto_ore',
		Filter: ColumnFilter
	},
	{
		Header: 'Vendite nette',
		accessor: 'impianto_vendite',
		Filter: ColumnFilter
	},
];
const data_json = [

	{
		"id": 1,
		"impianto_nome": "Campo tennis terra rossa",
		"impianto_ore": "285",
		"impianto_vendite": "€ 7450,00",
	},
	{
		"id": 2,
		"impianto_nome": "Campo padel blu",
		"impianto_ore": "115",
		"impianto_vendite": "€ 1190,00",

	},
	{
		"id": 3,
		"impianto_nome": "Campo calcetto erba sintetica",
		"impianto_ore": "320",
		"impianto_vendite": "€ 8850,00",

	}
];
const prodotti_column = [

	{
		Header: 'Prodotto',
		accessor: 'prodotto_nome',
		Filter: ColumnFilter
	},
	{
		Header: 'Quantità',
		accessor: 'prodotto_qta',
		Filter: ColumnFilter
	},
	{
		Header: 'Vendite nette',
		accessor: 'prodotto_vendite',
		Filter: ColumnFilter
	},
];
const product_json = [

	{
		"id": 1,
		"prodotto_nome": "Lezioni di tennis",
		"prodotto_qta": "285",
		"prodotto_vendite": "€ 7450,00",
	},
	{
		"id": 2,
		"prodotto_nome": "Corso di padel",
		"prodotto_qta": "125",
		"prodotto_vendite": "€ 2450,00",

	},
	{
		"id": 3,
		"prodotto_nome": "Torneo Calcetto",
		"prodotto_qta": "425",
		"prodotto_vendite": "€ 8250,00",
	}
];
const ReportLaMiaStruttura = () => {
	const options = [
		{ value: "lorem-ipsum", label: "Lorem Ipsum" },
		{ value: "lorem-ipsum", label: "Lorem Ipsum" },
		{ value: "lorem-ipsum", label: "Lorem Ipsum" },
	];

	const [selectedOption, setSelectedOption] = useState({ label: "Seleziona...", value: null });

	const [isConfronto, setisConfronto] = useState(false);

	const handleCheckboxConfronto = () => {
		setisConfronto(!isConfronto);
	};

	const [isOpen, setIsOpen] = React.useState(false);

	const toggleCollapse = () => {
		setIsOpen(!isOpen);
	};

	return (
		<Fragment>
			<Row>
				<Col>
					<Card>
					<Card.Body>
						<div className='d-flex align-items-center justify-content-between' onClick={toggleCollapse}>
							<Card.Title as="h4">Filtri</Card.Title>
							<ArrowDown2 size="32" variant="Bulk" />
						</div>

							<Collapse in={isOpen}>
								<Form>
									<Row>
										<Col xxl="4" xl="4" lg="4">
											<Form.Label>Periodo</Form.Label>
											<Select
												defaultValue={selectedOption}
												onChange={setSelectedOption}
												options={options}
											/>
											<br />
										</Col>

										<Col xxl="4" xl="4" lg="4">
											<Form.Label>Dalla data</Form.Label>
											<Form.Control type="date" />
											<br />
										</Col>

										<Col xxl="4" xl="4" lg="4">
											<Form.Label>Alla data</Form.Label>
											<Form.Control type="date" />
											<br />
										</Col>

										<Col xxl="12">
											<Form.Group controlId="confronto">
												<FormCheck
													className="d-flex"
													type="checkbox"
													label="Questo prodotto ha una scadenza."
													checked={isConfronto}
													onChange={handleCheckboxConfronto} />
											</Form.Group>
											<br />
										</Col>

										{isConfronto &&

											<Fragment>

												<Col xxl="4" xl="4" lg="4">
													<Form.Label>Periodo</Form.Label>
													<Select
														defaultValue={selectedOption}
														onChange={setSelectedOption}
														options={options}
													/>
													<br />
												</Col>

												<Col xxl="4" xl="4" lg="4">
													<Form.Label>Dalla data</Form.Label>
													<Form.Control type="date" />
													<br />
												</Col>

												<Col xxl="4" xl="4" lg="4">
													<Form.Label>Alla data</Form.Label>
													<Form.Control type="date" />
													<br />
												</Col>

											</Fragment>
										}

										<Col xxl="12">
											<Button variant="primary btn-block rounded-3" size="sm"> <FilterSquare size="20" variant="Bulk" /> Applica filtro </Button>
										</Col>

									</Row>
								</Form>
							</Collapse>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<Row>
				<Col xl="3" xxl="4" lg="6" sm="6">
					<Card>
						<Card.Body className='border-start-5-success'>
							<h4 className="mb-1 font-w300">Clienti</h4>
							<h2 className=" mb-0 ">3043</h2>
							<small><b className='text-danger'>-25</b> dal periodo precendete</small>
						</Card.Body>
					</Card>
				</Col>
				<Col xl="3" xxl="4" lg="6" sm="6">
					<Card>
						<Card.Body className='border-start-5-cyan'>
							<h4 className="mb-1 font-w300">Soci</h4>
							<h2 className=" mb-0 ">304</h2>
							<small><b className='text-success'>+25</b> dal periodo precendete</small>
						</Card.Body>
					</Card>
				</Col>
				<Col xl="3" xxl="4" lg="6" sm="6">
					<Card>
						<Card.Body className='border-start-5-gray'>
							<h4 className="mb-1 font-w300">Junior</h4>
							<h2 className=" mb-0 ">1250</h2>
							<small><b className='text-success'>+5</b> dal periodo precendete</small>
						</Card.Body>
					</Card>
				</Col>
				<Col xl="3" xxl="4" lg="6" sm="6">
					<Card>
						<Card.Body className='border-start-5-orange'>
							<h4 className="mb-1 font-w300">Maestri</h4>
							<h2 className=" mb-0 ">30</h2>
							<small><b className='text-danger'>-8</b> dal periodo precendete</small>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<Row>
				<Col xxl="6" xl="6" lg="12" sm="12">

					<TabellaLista
						cardTitle={'Lista Impianti '}
						hasSearch={false}
						hasFilter={false}
						hasAddMore={false}
						labelAddMore={'Nuovo impianto'}
						linkAddMore={'/struttura/prodotti/impianti/aggiungi'}
						data_column={data_column}
						data_json={data_json}
					/>


				</Col>
				<Col xxl="6" xl="6" lg="12" sm="12">

					<TabellaLista
						cardTitle={'Prodotti più venduti'}
						hasSearch={false}
						hasFilter={false}
						hasAddMore={false}
						labelAddMore={'Nuovo impianto'}
						linkAddMore={'/struttura/prodotti/impianti/aggiungi'}
						data_column={prodotti_column}
						data_json={product_json}
					/>
				</Col>
			</Row>

			<Row>
				<Col>
					<Card>
						<Card.Header>
							<Card.Title as="h4">Andamento fatturato</Card.Title>
						</Card.Header>

						<Card.Body>
							<GraficoAbbonamentiAttivi />
						</Card.Body>

					</Card>
				</Col>
			</Row>

		</Fragment>
	);
};

export default ReportLaMiaStruttura