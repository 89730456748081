import React from 'react';
import { Link } from 'react-router-dom';
import { ColumnFilter } from '../../../components/table/FilteringTable/ColumnFilter';
import { Dropdown } from "react-bootstrap";
import { More, Trash, Edit2 } from 'iconsax-react';
import TabellaLista from '../../../components/Tabella/TabellaLista';


const StrutturaUtenti = () => {
    const data_column = [

        {
            Header: 'Data di creazione',
            accessor: 'us_date',
            Filter: ColumnFilter
        },
        {
            Header: 'Nome utente',
            accessor: 'us_name',
            Filter: ColumnFilter
        },

        {
            Header: 'Email',
            accessor: 'us_email',
            Filter: ColumnFilter
        },
        {
            Header: 'Ruolo',
            accessor: 'us_role',
            Filter: ColumnFilter
        },
        {
            Header: 'Stato',
            accessor: 'us_status',
            Filter: ColumnFilter,
            Cell: ({ value }) => {
                return (
                    <div className="d-flex align-items-center">
                        <i className={`fa fa-circle me-1 ${value.class}`}></i>
                        {value.label}
                    </div>
                )
            },
            disableFilters: true,
        },
        {
            Header: 'Azione',
            accessor: 'azione',
            Cell: ({ value }) => {
                return (
                    <Dropdown>
                        <Dropdown.Toggle
                            className="btn-primary light sharp i-false btn-action"
                        >
                            <More />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        <Dropdown.Item><Link to='#'><span className="me-2"><Edit2 size="20" /></span> Modifica</Link></Dropdown.Item>

<br />
<Dropdown.Item className="text-danger"><span className="me-2"><Trash size="20" /></span>Cancella</Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                )
            },
            disableFilters: true,
        },
    ];

    const data_json = [
        {
            "id": 1,
            "us_date": "12 novembre 2023",
            "us_name": "Antonio Verdi",
            "us_email": "a.verdi@asdlasportiva.com",
            "us_role": "ADMIN",
            "us_status": {
                "label": "Attivo",
                "class": "text-success",
            }
        },
        {
            "id": 2,
            "us_date": "15 marzo 2024",
            "us_name": "Luisa Ranieri",
            "us_email": "luisaranieri25@gmail.com",
            "us_role": "MKT gestore",
            "us_status": {
                "label": "Sospeso",
                "class": "text-primary",
            }
        },
        {
            "id": 3,
            "us_date": "20 aprile 2024",
            "us_name": "Marco Valdo",
            "us_email": "marco.valdo@asdlasportiva.com",
            "us_role": "gestore Impianti",
            "us_status": {
                "label": "Attivo",
                "class": "text-success",
            }
        }
    ];
    return (
        <>
            <TabellaLista
                cardTitle={'Lista Utenti Gestionale'}
                hasSearch={true}
                hasFilter={true}
                hasAddMore={true}
                labelAddMore={'Aggiungi'}
                linkAddMore={'/struttura/strumenti/aggiungi_utente'}
                data_column={data_column}
                data_json={data_json}
            />
        </>

    )
}

export default StrutturaUtenti