import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Button, Col, Dropdown, Form, Modal, Row, Spinner } from "react-bootstrap";
import TabellaLista from "../../../../components/Tabella/TabellaLista";
import { More, Trash } from "iconsax-react";
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter';
import MyApiClient from "../../../../../api-client/MyApiClient";

import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "react-widgets/styles.css";

const sports = [
	{ value: "RUNNING", label: "Corsa" },
	{ value: "BIKING", label: "Ciclismo" },
	{ value: "TENNIS", label: "Tennis" },
	{ value: "PADEL", label: "Padel" },
	{ value: "SKIING", label: "Sci" },
	{ value: "CROSS_COUNTRY_SKIING", label: "Fondo" },
	{ value: "SNOWBOARD", label: "Snowboard" },
	{ value: "SWIMMING", label: "Nuoto" },
	{ value: "GOLF", label: "Golf" },
	{ value: "FOOTBALL", label: "Calcio" },
	{ value: "FIVE_A_SIDE_FOOTBALL", label: "Calcio a 5" },
	{ value: "VOLLEYBALL", label: "Pallavolo" },
	{ value: "BASKETBALL", label: "Basket" },
	{ value: "ICE_HOCKEY", label: "Hockey su ghiaccio" },
	{ value: "RUGBY", label: "Rugby" }
];

const TeamsNew = () => {

  const [formData, setFormData] = useState({
    name: '',
    sport: '',
    captainId: '',
    logo: ''
  });

  const MAX_FILE_SIZE = 5 * 1024 * 1024;
	const ALLOWED_FILE_TYPES = ['image/png', 'image/jpeg'];

  const [showedSports, setShowedSports] = useState([]);

  const fileInputRef = useRef(null);

  const [selectedMember, setSelectedMember] = useState('');
  const [data_json, setDataJson] = useState([]);
  const [modalCentered, setModalCentered] = useState(false);

  const [modalShowed, setModalShowed] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  const [memberOptions, setMemberOptions] = useState([]);
  const [memberPlayerOptions, setMemberPlayerOptions] = useState([]);
  const [loadingShowed, setLoadingShowed] = useState(false);

  const handleSelectModalChange = (selectedOptions) => {
		setSelectedMember(selectedOptions.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const handleSelectCaptainChange = (selectedOptions) => {
    const selectedCaptain = selectedOptions.value;
    setFormData(prevState => ({
        ...prevState,
        captainId: selectedCaptain
    }));
  };

  const handleSelectSportChange = (selectedOptions) => {
    const selectedSport = selectedOptions.value;
    setFormData(prevState => ({
        ...prevState,
        sport: selectedSport
    }));
  };

  const handleFileInputChange = (e) => {
		const file = e.target.files[0];
	
		if (file) {

			if (file.size > MAX_FILE_SIZE) {
				setModalTitle("Errore durante l'operazione");
				setModalText('Il file selezionato supera la dimensione massima consentita.');
				setModalShowed(true);
				return;
			}

			if (!ALLOWED_FILE_TYPES.includes(file.type)) {
				setModalTitle("Errore durante l'operazione");
				setModalText('Il file selezionato non è di tipo PNG o JPEG.');
				setModalShowed(true);
				return;
			}

			const reader = new FileReader();
			reader.onloadend = () => {
				const base64String = reader.result.split(',')[1];
        setFormData(prevState => ({
          ...prevState,
          logo: base64String
        }));
			};
			reader.readAsDataURL(file);
		}
	};

  const data_column = [
    {
        Header: 'Membro',
        accessor: 'member',
        Filter: ColumnFilter
    },
    {
        Header: 'Ruolo',
        accessor: 'role',
        Filter: ColumnFilter
    },
    {
        Header: 'Azione',
        accessor: 'azione',
        Cell: ({ row }) => {
            return (
                <Dropdown>
                    <Dropdown.Toggle className="btn-primary light sharp i-false btn-action" >
                        <More />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {/*<Dropdown.Item>Modifica</Dropdown.Item>*/}
                        <Dropdown.Item onClick={() => deleteMember(row.original)} className="text-danger"><span className="me-2"><Trash size="20" /></span>Cancella</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )
        },
        disableFilters: true,
    },
  ];

  const deleteMember = (row) => {
		let removeMember = row.user_id;

    let data_json_new = [];
    data_json.forEach(element => {
			if(element.user_id != removeMember) {
				data_json_new.push(element);
			}
		});

    setDataJson(data_json_new);
	};

  const handleSaveClick = (e) => {
    e.preventDefault();

    // console.log(formData);

    // CONTROLLI
    if (!formData.name) {
      setModalTitle("Errore durante l'operazione");
      setModalText('Il nome del team è obbligatorio');
      setModalShowed(true);
      return;
    }

    if (!formData.sport) {
      setModalTitle("Errore durante l'operazione");
      setModalText('Lo sport del team è obbligatorio');
      setModalShowed(true);
      return;
    }

    if (!formData.captainId) {
      setModalTitle("Errore durante l'operazione");
      setModalText('Il capitano del team è obbligatorio');
      setModalShowed(true);
      return;
    }

    const saveData = async () => {
      try {
          const tokenDetailsString = sessionStorage.getItem('userDetails');
          if (tokenDetailsString) {
              const tokenDetails = JSON.parse(tokenDetailsString);
              const token = tokenDetails.idToken;
              const apiClient = new MyApiClient();

              let obj_to_send = {
                'captainId': formData.captainId,
                'name': formData.name,
                'sport': formData.sport,
                'logo': formData.logo,
                'slogan': '',
                'address': {
                  'city': '',
                  'region': ''
                }
              };

              let members_array = [];
              data_json.forEach(element => {
                members_array.push(element.user_id);
              });
              obj_to_send['membersIds'] = members_array;

              setLoadingShowed(true);
              const saveResult = await apiClient.createStructureTeam(token, obj_to_send);
              setLoadingShowed(false);

              if (!saveResult) {
                setModalTitle("Errore durante l'operazione");
                setModalText("Si è verificato un errore durante il salvataggio (" + saveResult.error + ")");
                setModalShowed(true);
              } else {
                window.location.href = '/struttura/teams/list';
              }
          }
      } catch (error) {
          console.error("Error fetching data:", error);
      }
    };

    saveData();
  };

  const addMembers = (e) => {
    if(selectedMember) {

      // CONTROLLO GIA' PRESENTE
      let founded_exist = false;
      data_json.forEach(element => {
        if(element.user_id==selectedMember) {
          founded_exist = true;
        }
      });

      if(founded_exist) {
        setModalCentered(false);
        setModalTitle("Errore durante l'operazione");
        setModalText("Questo membro è già associato a questa squadra");
        setModalShowed(true);
        return;
      }

      let member_desc = '';
      memberPlayerOptions.forEach(element => {
        if(element.value == selectedMember) {
          member_desc = element.label;
        }
      });

      let element_row = {
        'user_id': selectedMember,
        'member': member_desc
      };

      data_json.push(element_row);
    }

    setModalCentered(false);
    setSelectedMember('');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tokenDetailsString = sessionStorage.getItem('userDetails');
        if (tokenDetailsString) {
          const tokenDetails = JSON.parse(tokenDetailsString);
          let token = tokenDetails.idToken;
          const apiClient = new MyApiClient();

          setLoadingShowed(true);
          const [membershipData,membershipDataPlayers,profile] = await Promise.all([
              apiClient.getMemberships(token,'COACH',null),
              apiClient.getMemberships(token,'MEMBER',null),
              apiClient.getProfile(token)
          ]);
          setLoadingShowed(false);

          if(membershipData) {
            let data_member = [];
            membershipData.forEach(element => {
              let member_row = {
                "value": element.userId,
                "label": element.name
              };
              data_member.push(member_row);
            })

            setMemberOptions(data_member);
          }

          if(membershipDataPlayers) {
            let data_member = [];
            membershipDataPlayers.forEach(element => {
              let member_row = {
                "value": element.userId,
                "label": element.name
              };
              data_member.push(member_row);
            })

            setMemberPlayerOptions(data_member);
          }

          let new_sports = [];
					if(profile && profile.club && profile.club.sports) {
						profile.club.sports.forEach(element => {
							sports.forEach(element2 => {
								if(element2.value == element.sportType) {
									new_sports.push(element2);
								}
							});
						});
					}
					setShowedSports(new_sports);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

  }, []);

  return (
    <>
      <Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

      <Modal className="fade" show={modalShowed} centered>
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
          <Button onClick={() => setModalShowed(false)} variant="" className="btn-close">
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row><Col className="col-xl-64">{modalText}</Col></Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button className="btn btn-primary btn-add" onClick={() => setModalShowed(false)}>
              Chiudi
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="card">
        <div className="card-header">

          <h4 className="card-title">Crea squadra</h4>
        </div>
        <div className="card-body">
          <Form>
            <div className="row">
              <div className="col-xl-3">
                <label>Nome team</label>
                <input name="name" value={formData.name} onChange={handleChange} type="text" className="form-control form-control-sm" />
              </div>

              <div className="col-xl-3">
                <label>Sport</label>
                <Select
                  value={showedSports.filter(option => formData.sport == (option.value))}
                  options={showedSports}
                  onChange={handleSelectSportChange}
                  style={{
                    lineHeight: "40px",
                    color: "#7e7e7e",
                    paddingLeft: " 15px",
                  }}
                />
              </div>

              <div className="col-xl-3">
                <label>Allenatore</label>
                <Select
                  value={memberOptions.filter(option => formData.captainId == (option.value))}
                  options={memberOptions}
                  onChange={handleSelectCaptainChange}
                  style={{
                    lineHeight: "40px",
                    color: "#7e7e7e",
                    paddingLeft: " 15px",
                  }}
                />          
              </div>
              <div className="col-xl-3">
                <label>Carica il logo</label>
                <input ref={fileInputRef} className="form-control form-control-sm" type="file" onChange={handleFileInputChange}></input>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <TabellaLista
        cardTitle={'Membri della squadra'}
        hasSearch={false}
				hasFilter={false}
				data_column={data_column}
				data_json={data_json}
				hasAddMoreAsModal={true}
        modalCallBack={() => setModalCentered(true)}
        modalContent={
					<Modal className="fade" show={modalCentered} centered>
						<Modal.Header>
							<Modal.Title>Aggiungi Membro</Modal.Title>
							<Button
								onClick={() => setModalCentered(false)}
								variant=""
								className="btn-close"
							>
							</Button>
						</Modal.Header>
						<Modal.Body>
							<Form>
								<Row>
									<Col className="col-lg-12 pb-4">
										<Form.Label>Seleziona membro</Form.Label>
										<Select
											name="sport" 
											value={memberPlayerOptions.filter(option => selectedMember == option.value)} onChange={handleSelectModalChange}
											options={memberPlayerOptions}
											style={{
												lineHeight: "40px",
												color: "#fd7e14",
												paddingLeft: " 15px",
											}
											}
										/>
									</Col>
								</Row>
							</Form>
						</Modal.Body>
						<Modal.Footer className="justify-content-between">
							<Button
								className="btn btn-light btn-add"
								onClick={() => setModalCentered(false)}>
								Annulla
							</Button>
							<Button onClick={addMembers} className="btn btn-primary btn-add">Aggiungi</Button>
						</Modal.Footer>
					</Modal>
				}
			/>
      <div className="d-flex justify-content-end gap-4 mt-4">
        <Link to={"/struttura/teams/list"} className="btn btn-dark light mr-4">Annulla</Link> 
        <Link to="#" onClick={handleSaveClick} className="btn btn-primary ">Salva</Link>
      </div>
    </>
  )
}

export default TeamsNew;
