import React, { Fragment } from "react";
import TabellaLista from "../../../../components/Tabella/TabellaLista";
import { Button, Alert } from "react-bootstrap";
import { ColumnFilter } from "../../../../components/table/FilteringTable/ColumnFilter";
import { ProfileCircle, InfoCircle } from "iconsax-react";

const ListaProdotti = () => {

    const data_column = [

        {
            Header: 'Nome prodotto',
            Footer: 'Nome prodotto',
            accessor: 'nome-prodotto',
            Filter: ColumnFilter
        },
        {
            Header: 'Nome Struttura',
            Footer: 'Nome Struttura',
            accessor: 'nome-struttura',
            Cell: ({ value }) => {
                return (
                    <Button variant="dark light" size="xs" >
                        <span className="me-2"><ProfileCircle size="24" variant="Bulk" /></span>
                        {value}
                    </Button>
                );
            },
            Filter: ColumnFilter
        },
        {
            Header: 'Prezzo',
            Footer: 'Prezzo',
            accessor: 'prezzo',
            Cell: ({ value }) => {
                return (
                    <Fragment>
                        € {value.prezzo} / {value.unit}
                        <br />
                        <b>{value.fitcoin}% Fitcoin (4.00)</b>
                    </Fragment>
                );
            },
        },
        {
            Header: 'Prezzo ai soci',
            Footer: 'Prezzo ai soci',
            accessor: 'prezzo-soci',
            Cell: ({ value }) => {
                return (
                    <Fragment>
                        € {value.prezzo} / {value.unit}
                        <br />
                        <b>{value.fitcoin}% Fitcoin (4.00)</b>
                    </Fragment>
                );
            },
        },

        {
            Header: 'Stato',
            Footer: 'Stato',
            accessor: 'stato',
            Cell: ({ value }) => {
                return (
                    <div className="d-flex align-items-center">
                        <i className={`fa fa-circle me-1 ${value.class}`}></i>
                        {value.label}
                    </div>
                );
            },
            disableFilters: true,
        },
        {
            Header: 'Visibilità',
            Footer: 'Visibilità',
            accessor: 'vsibilita',
            Cell: ({ value }) => {
                return (
                    <div className="d-flex align-items-center">
                        <i className={`fa fa-circle me-1 ${value.class}`}></i>
                        {value.label}
                    </div>
                );
            },
            disableFilters: true,
        },
    ];

    const data_json = [
        {
            "nome-prodotto": "Loredana Bassi",
            "nome-struttura": "Nome struttura",
            "prezzo": {
                "prezzo": "10,50",
                "unit": "Mese",
                "fitcoin": "4"
            },
            "prezzo-soci": {
                "prezzo": "10,50",
                "unit": "Mese",
                "fitcoin": "4"
            },
            "stato": {
                "label": "Inactive",
                "class": "text-warning"
            },
            "vsibilita": {
                "label": "Inactive",
                "class": "text-warning"
            },
        }
    ];

    return (
        <Fragment>
            <TabellaLista
                cardTitle={'Lista Junior'}
                hasSearch={true}
                hasFilter={true}
                hasAddMore={false}
                data_column={data_column}
                data_json={data_json}
            />
            <Alert variant="Dark">
                <span className="me-2"><InfoCircle size="20" variant="Bulk" /></span>
                Il dettaglio del prodotto è visibile solo dalla vista struttura
            </Alert>
        </Fragment>
    )
}

export default ListaProdotti