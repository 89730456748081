import { More, DocumentText } from "iconsax-react";
import { Dropdown, Alert, Button } from "react-bootstrap";
import { ColumnFilter } from "../../../../components/table/FilteringTable/ColumnFilter";
import TabellaLista from "../../../../components/Tabella/TabellaLista";

export const ListaAbilitazioneMaestri = () => {

    const data_column = [

        {
            Header: 'Data richiesta',
            Footer: 'Data richiesta',
            accessor: 'data-richiesta',
            Filter: ColumnFilter
        },
        {
            Header: 'Nome e Cognome',
            Footer: 'Nome e Cognome',
            accessor: 'nome-e-cognome',
            Filter: ColumnFilter
        },
        {
            Header: 'Sport',
            Footer: 'Sport',
            accessor: 'sport',
            Filter: ColumnFilter
        },
        {
            Header: 'Tesserino',
            Footer: 'Tesserino',
            accessor: 'tesserino',
            Cell: ({ value }) => {
                return (
                    <Button variant="dark light" size="xs" >
                        <span className="me-2"><DocumentText size="24" variant="Bulk" /></span>
                        {value}
                    </Button>
                );
            },
            disableFilters: true,
        },
        {
            Header: 'Stato richiesta',
            Footer: 'Stato richiesta',
            accessor: 'stato-richiesta',
            Cell: ({ value }) => {
                return (
                    <div className="d-flex align-items-center">
                        <i className={`fa fa-circle me-1 ${value.class}`}></i>
                        {value.label}
                    </div>
                );
            },
            disableFilters: true,
        },
        {
            Header: 'Azione',
            Footer: 'Azione',
            accessor: 'azione',
            Cell: ({ value }) => {
                return (
                    <Dropdown>
                        <Dropdown.Toggle
                            className="btn-primary light sharp i-false btn-action"
                        >
                            <More />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item>Edit</Dropdown.Item>
                            <Dropdown.Item>Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            },
            disableFilters: true,
        },
    ];

    const data_json = [
        {
            "data-richiesta": "12 Marzo 2024",
            "nome-e-cognome": "Loredana Bassi",
            "email": "loredana@mail.com",
            "sport": "Calcio, Tennis, Nuoto",
            "stato-richiesta": {
                "label": "In attesa",
                "class": "text-warning"
            },
            "tesserino": "Tesserino tennis.pdf",
        },
        {
            "data-richiesta": "12 Marzo 2024",
            "nome-e-cognome": "Loredana Bassi",
            "email": "loredana@mail.com",
            "sport": "Calcio, Tennis, Nuoto",
            "stato-richiesta": {
                "label": "Non accettato",
                "class": "text-danger"
            },
            "tesserino": "Tesserino tennis.pdf",
        }
    ];

    return (
        <>
            <TabellaLista
                cardTitle={'Lista Abilitazioni Maestri'}
                hasSearch={true}
                hasFilter={true}
                hasAddMore={false}
                data_column={data_column}
                data_json={data_json}
            />

            <Alert variant="danger">
                <Alert.Heading>Attenzione</Alert.Heading>
                <p>
                    La lista è in fase di creazione.<br />
                    Le "Azioni" non sono definitive
                </p>
            </Alert>
        </>
    )

}
export default ListaAbilitazioneMaestri;