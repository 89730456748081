import React from "react";
import { Dropdown } from "react-bootstrap";
import { ColumnFilter } from "../../../../components/table/FilteringTable/ColumnFilter";
import { More } from "iconsax-react";
import TabellaLista from "../../../../components/Tabella/TabellaLista";

const data_column = [

	{
		Header: 'Nome impianto',
		Footer: 'Nome impianto',
		accessor: 'impianto_nome',
		Filter: ColumnFilter
	},
	{
		Header: 'Disponibilità',
		Footer: 'Disponibilità',
		accessor: 'impianto_date',
		Filter: ColumnFilter
	},
	{
		Header: 'Sport',
		Footer: 'Sport',
		accessor: 'impianto_sport',
		Filter: ColumnFilter
	},

	{
		Header: 'Stato impianto',
		Footer: 'Stato impianto',
		accessor: 'impianto_status',
		Filter: ColumnFilter,
		Cell: ({ value }) => {
			return (
				<div className="d-flex align-items-center">
					<i className={`fa fa-circle me-1 ${value.class}`}></i>
					{value.label}
				</div>
			)
		},
		disableFilters: true,
	},
	{
		Header: 'Azione',
		Footer: 'Azione',
		accessor: 'azione',
		Cell: ({ value }) => {
			return (
				<Dropdown>
					<Dropdown.Toggle
						className="btn-primary light sharp i-false btn-action"
					>
						<More />
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item>Modifica</Dropdown.Item>
						<Dropdown.Item>Cancella</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			)
		},
		disableFilters: true,
	},
];
const data_json = [

	{
		"impianto_nome": "Campo tennis terra rossa",
		"impianto_date": "Dal 04/04 al 31/10",
		"impianto_sport": "Tennis",
		"impianto_status": {
			"label": "Attivo",
			"class": "text-success"
		},
	},
	{
		"impianto_nome": "Campo padel blu (coperto)",
		"impianto_date": "Tutto l'anno",
		"impianto_sport": "Padel",
		"impianto_status": {
			"label": "Attivo",
			"class": "text-success"
		},
	},
	{
		"impianto_nome": "Campo padel rosso (coperto)",
		"impianto_date": "Tutto l'anno",
		"impianto_sport": "Padel",
		"impianto_status": {
			"label": "Attivo",
			"class": "text-success"
		},
	},
	{
		"impianto_nome": "Campo calcetto in erba",
		"impianto_date": "Dal 30/05 al 30/09",
		"impianto_sport": "Calcetto",
		"impianto_status": {
			"label": "Inattivo",
			"class": "text-primary"
		},
	},
	{
		"impianto_nome": "Campo calcetto in erba sintetica",
		"impianto_date": "Tutto l'anno",
		"impianto_sport": "Calcetto",
		"impianto_status": {
			"label": "In ristrutturazione",
			"class": "text-danger"
		},
	}
];

const ListaImpianti = () => {
	return (
		<TabellaLista
			cardTitle={'Lista Impianti '}
			hasSearch={true}
			hasFilter={true}
			hasAddMore={true}
			labelAddMore={'Nuovo impianto'}
			linkAddMore={'/struttura/prodotti/impianti/aggiungi'}
			data_column={data_column}
			data_json={data_json}
		/>
	)
}

export default ListaImpianti
