import React, { useEffect, useState } from 'react';
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter';
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import { More, User } from 'iconsax-react';
import TabellaLista from '../../../../components/Tabella/TabellaLista';
import MyApiClient from '../../../../../api-client/MyApiClient';
import { format } from 'date-fns';

export const PlayerTable = () => {

	const [loadingShowed, setLoadingShowed] = useState(false);

	const data_column = [
		{
			Header: 'Nome e cognome',
			Footer: 'Nome e cognome',
			accessor: 'name',
			Filter: ColumnFilter,
			Cell: ({ row }) => (
				<div style={{ display: 'flex', alignItems: 'center' }}>
				  {row.original.imageUrl && <img
					src={row.original.imageUrl}
					alt={row.original.name}
					style={{
					  width: '40px',
					  height: '40px',
					  borderRadius: '50%',
					  marginRight: '10px'
					}}
				  />}
				  {row.original.name}
				</div>
			)
		},
		{
			Header: 'Email',
			Footer: 'Email',
			accessor: 'email',
			Filter: ColumnFilter
		},
		{
			Header: 'Totale speso',
			accessor: 'totale',
			Filter: ColumnFilter
		},
		{
			Header: 'Data di attivazione',
			accessor: 'date',
			Filter: ColumnFilter
		},
		{
			Header: 'Azione',
			Footer: 'Azione',
			accessor: 'azione',
			Cell: ({ row }) => {
				return (
					<Dropdown>
						<Dropdown.Toggle className="btn-primary light sharp i-false btn-action">
							<More/>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item onClick={() => openDetail(row.original)}><span className="me-2"><User size="20" /></span> Visualizza</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				)
			},
			disableFilters: true,
		},
	];

	const [data_json, setDataJson] = useState(null);

	useEffect(() => {
        const fetchData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    let token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

					setLoadingShowed(true);
					const [customersData] = await Promise.all([
                        apiClient.getCustomers(token)
                    ]);
					setLoadingShowed(false);

					let data_customers = [];
					if(customersData && customersData.total && customersData.total > 0) {
						customersData.items.forEach(element => {
							
							let createdAt = new Date(element.createdAt);

							let image = null;
							if(element.picture && element.picture.uri) {
								image = element.picture.uri;
							}
							
							let customers_row = {
								"id": element.customerUserId,
								"date": format(createdAt, 'dd/MM/yyyy'),
								"name": element.givenName+' '+element.familyName,
								"totale": "€ "+element.totalSpent,
								"email": element.email,
								"imageUrl": image
							};
							data_customers.push(customers_row);
						});
					}
					setDataJson(data_customers);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

    }, []);

	function openDetail(row) {
		window.location.href = '/struttura/clienti/player_profile/'+row.id;
	}

	return (
		<>
			<Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

			<TabellaLista
				cardTitle={'Lista Clienti'}
				hasSearch={true}
				hasFilter={true}
				hasAddMore={false}
				data_column={data_column}
				data_json={data_json}
			/>
		</>
	)

}
export default PlayerTable;