import axios from "axios";

export default class MyApiClient {
    constructor() {
        this.basePath = 'https://o60fgkagq6.execute-api.eu-west-1.amazonaws.com/Api'.replace(/\/+$/, '');
    }

    async login(username, password) {
        const url = `${this.basePath}/auth/login`;
        
        const payload = {
            username: username,
            password: password
        };

        try {
            const response = await axios.post(url, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            if (response.status === 200) {
                return response.data;
            } else {
                return { error: 'Errore generico durante l\'operazione (stato '+response.status+')' };
            }
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {error: errorResponseData.detail};
            } else {
                return {error: 'Errore generico'};
            }
        }
    }

    async register(nomeStruttura, tipoStruttura, email, password) {
        const url = `${this.basePath}/auth/signup`;
        
        const payload = {
            user: {
                club: {
                    clubNature: tipoStruttura,
                    email: email,
                    locale: 'it-IT',
                    name: nomeStruttura
                }
            },
            password: password
        };

        try {
            const response = await axios.post(url, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            if (response.status === 201) {
                return response.data;
            } else {
                return { error: 'Errore generico durante l\'operazione (stato '+response.status+')' };
            }
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {error: errorResponseData.detail};
            } else {
                return {error: 'Errore generico durante l\'operazione'};
            }
        }
    }

    async forgotpassword1(username) {
        const url = `${this.basePath}/auth/forgotPassword`;
        
        const payload = {
            username: username
        };

        try {
            const response = await axios.post(url, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            if (response.status === 204) {
                return response;
            } else {
                return {'error': 'Errore generico'};
            }

        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async forgotpassword2(username, temporaryPassword, newPassword) {
        const url = `${this.basePath}/auth/resetPassword`;
        
        const payload = {
            username: username,
            temporaryPassword: temporaryPassword,
            newPassword: newPassword
        };

        try {
            const response = await axios.post(url, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            if (response.status === 204) {
                return response;
            } else {
                return {'error': 'Errore generico'};
            }

        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async changePassword(token, accesstoken, oldpassword, newpassword) {
        const url = `${this.basePath}/auth/credentials`;
        
        const payload = {
            oldPassword: oldpassword,
            newPassword: newpassword
        };

        try {
            const response = await axios.patch(url, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token,
                    'access-token': accesstoken
                }
            });
            
            if (response.status === 200 || response.status === 204) {
                return response;
            } else {
                return {'error': 'Errore generico'};
            }

        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async changeEmail(token, email) {
        const url = `${this.basePath}/auth/credentials/email`;
        
        const payload = {
            email: email
        };

        try {
            const response = await axios.put(url, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                }
            });
            
            if (response.status === 200 || response.status === 204) {
                return response;
            } else {
                return {'error': 'Errore generico'};
            }

        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async getProfile(token) {
        const url = `${this.basePath}/profile`;
        
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            throw error;
        }
    }

    async updateProfile(token, dataUpdate) {
        const url = `${this.basePath}/profile`;
        
        try {
            
            let data = JSON.stringify(dataUpdate);
            
            let config = {
                method: 'patch',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json', 
                    'Content-Type': 'application/json'
                },
                data: data
            };
            
            const response = await axios.request(config);

            if (response.status === 200) {
                return response;
            } else {
                return {'error': 'Errore generico'};
            }

        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async getAdministrationClub(token) {
        const url = `${this.basePath}/administration/club/overview`;
        
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async getAdministrationClubOverviewSales(token) {
        const url = `${this.basePath}/administration/club/overview/sales`;
        
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async getWalletFee(token) {
        const url = `${this.basePath}/wallet/fee`;
        
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async addCopertina(token, dataUpdate) {
        const url = `${this.basePath}/profile/pictures`;
        
        let data = JSON.stringify(dataUpdate);

        try {
            
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json', 
                    'Content-Type': 'application/json'
                },
                data: data
            };
            
            const response = await axios.request(config);

            if (response.status === 201) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            return false;
        }
    }

    async deleteCopertina(token,id) {
        const url = `${this.basePath}/profile/pictures/${id}`;
        
        try {
            
            let config = {
                method: 'delete',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json', 
                    'Content-Type': 'application/json'
                }
            };
            
            const response = await axios.request(config);

            if (response.status === 200) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            return false;
        }
    }

    async getTitles(token) {
        const url = `${this.basePath}/profile/titles`;
        
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async addTitle(token, dataUpdate) {
        const url = `${this.basePath}/profile/titles`;
        
        let data = JSON.stringify(dataUpdate);

        try {
            
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json', 
                    'Content-Type': 'application/json'
                },
                data: data
            };
            
            const response = await axios.request(config);

            if (response.status === 201) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            return false;
        }
    }

    async deleteTitle(token,id) {
        const url = `${this.basePath}/profile/titles/${id}`;
        
        try {
            
            let config = {
                method: 'delete',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json', 
                    'Content-Type': 'application/json'
                }
            };
            
            const response = await axios.request(config);

            if (response.status === 200) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            return false;
        }
    }

    async getWalletFitcoinsSummary(token) {
        const url = `${this.basePath}/wallet/fitcoins/summary`;
        
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async getCustomers(token) {
        const url = `${this.basePath}/administration/customers`;

        try {
            
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json', 
                    'Content-Type': 'application/json'
                }
            };
            
            const response = await axios.request(config);
            return response.data;

        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async getJuniors(token) {
        const url = `${this.basePath}/juniors`;

        try {
            
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json', 
                    'Content-Type': 'application/json'
                }
            };
            
            const response = await axios.request(config);
            return response.data;

        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async getMemberships(token,membershipRanks,notMembershipRanks) {
        let url = `${this.basePath}/memberships`;
        
        let added = false;
        if(membershipRanks) {
            if(added) {
                url+='&';
            } else {
                url+='?';
            }
            url += `membershipRanks=${membershipRanks}`;
            added = true;
        }
        if(notMembershipRanks) {
            if(added) {
                url+='&';
            } else {
                url+='?';
            }
            url += `notMembershipRanks=${notMembershipRanks}`;
            added = true;
        }

        try {
            
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json'
                }
            };
            
            const response = await axios.request(config);
            return response.data;
            
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async getProfiles(token,id) {
        const url = `${this.basePath}/profiles/${id}`;

        try {
            
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json', 
                    'Content-Type': 'application/json'
                }
            };
            
            const response = await axios.request(config);
            return response.data;
            
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async getProfilesByFilters(token,limit,name,type) {
        let url = `${this.basePath}/profiles?limit=${limit}&userTypes=${type}`;
        if(name) {
            url += '&user='+encodeURIComponent(name);
        }

        try {
            
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json', 
                    'Content-Type': 'application/json'
                }
            };
            
            const response = await axios.request(config);
            return response.data;
            
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async inviteUser(token, clubId, dataUpdate) {
        const url = `${this.basePath}/clubs/${clubId}/inviteUsers`;
        
        let data = JSON.stringify(dataUpdate);

        try {
            
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json', 
                    'Content-Type': 'application/json'
                },
                data: data
            };
            
            const response = await axios.request(config);

            if (response.status === 201) {
                return response;
            } else {
                return {'error': 'Errore generico'};
            }
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async deleteMembership(token, userId, membershipRank) {
        const url = `${this.basePath}/profile/memberships/${userId}?membershipRank=${membershipRank}`;
        
        try {
            
            let config = {
                method: 'delete',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json'
                }
            };
            
            const response = await axios.request(config);

            if (response.status === 204) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            return false;
        }
    }

    async getDocuments(token) {
        const url = `${this.basePath}/profile/documents`;

        try {
            
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json', 
                    'Content-Type': 'application/json'
                }
            };
            
            const response = await axios.request(config);
            return response.data;
            
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async deleteDoc(token,id) {
        const url = `${this.basePath}/profile/documents/${id}`;
        
        try {
            
            let config = {
                method: 'delete',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json', 
                    'Content-Type': 'application/json'
                }
            };
            
            const response = await axios.request(config);

            if (response.status === 200) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            return false;
        }
    }

    async addDocument(token, dataInsert) {
        const url = `${this.basePath}/profile/documents`;
        
        let data = JSON.stringify(dataInsert);

        try {
            
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json', 
                    'Content-Type': 'application/json'
                },
                data: data
            };
            
            const response = await axios.request(config);

            if (response.status === 201) {
                return response;
            } else {
                return {'error': 'Errore generico'};
            }
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async getCommunications(token) {
        const url = `${this.basePath}/administration/communications?limit=1000`;

        try {
            
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json', 
                    'Content-Type': 'application/json'
                }
            };
            
            const response = await axios.request(config);
            return response.data;
            
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async addCommunication(token, dataInsert) {
        const url = `${this.basePath}/administration/communications`;
        
        let data = JSON.stringify(dataInsert);

        try {
            
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json', 
                    'Content-Type': 'application/json'
                },
                data: data
            };
            
            const response = await axios.request(config);

            if (response.status === 204) {
                return response;
            } else {
                return {'error': 'Errore generico'};
            }

        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async deleteCommunications(token,id) {
        const url = `${this.basePath}/administration/communications/${id}`;
        
        try {
            
            let config = {
                method: 'delete',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json', 
                    'Content-Type': 'application/json'
                }
            };
            
            const response = await axios.request(config);

            if (response.status === 204) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            return false;
        }
    }

    async editCommunication(token, id, dataEdit) {
        const url = `${this.basePath}/administration/communications/${id}`;
        
        let data = JSON.stringify(dataEdit);

        try {
            
            let config = {
                method: 'patch',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json', 
                    'Content-Type': 'application/json'
                },
                data: data
            };
            
            const response = await axios.request(config);

            if (response.status === 204) {
                return response;
            } else {
                return {'error': 'Errore generico'};
            }

        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async getTeams(token,clubId) {
        const url = `${this.basePath}/clubs/${clubId}/teams`;
        
        try {
            
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json'
                }
            };
            
            const response = await axios.request(config);
            return response.data;
            
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async getProfileReviews(token,userId) {
        const url = `${this.basePath}/profiles/${userId}/reviews/summary`;
        
        try {
            
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json'
                }
            };
            
            const response = await axios.request(config);
            return response.data;
            
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async createStructureTeam(token, dataCreate) {
        const url = `${this.basePath}/clubs/teams`;
        
        let data = JSON.stringify(dataCreate);

        try {
            
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json', 
                    'Content-Type': 'application/json'
                },
                data: data
            };
            
            const response = await axios.request(config);

            if (response.status === 201) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            return false;
        }
    }

    async getTeam(token,clubId,teamId) {
        const url = `${this.basePath}/clubs/${clubId}/teams/${teamId}`;
        
        try {
            
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json'
                }
            };
            
            const response = await axios.request(config);
            return response.data;
            
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async updateStructureTeam(token, clubId, teamId, dataUpdate) {
        const url = `${this.basePath}/clubs/${clubId}/teams/${teamId}`;
        
        try {
            
            let data = JSON.stringify(dataUpdate);
            
            let config = {
                method: 'patch',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json', 
                    'Content-Type': 'application/json'
                },
                data: data
            };
            
            const response = await axios.request(config);

            if (response.status === 200) {
                return response;
            } else {
                return {'error': 'Errore generico'};
            }

        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async getSeasonCurrentRewards(token,userId) {
        const url = `${this.basePath}/seasons/current/rewards?userId=${userId}`;
        
        try {
            
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json'
                }
            };
            
            const response = await axios.request(config);
            return response.data;
            
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async getSportsSkills(token,userId) {
        const url = `${this.basePath}/sports/skills?userId=${userId}`;
        
        try {
            
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json'
                }
            };
            
            const response = await axios.request(config);
            return response.data;
            
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async getAdministrationSuperAdmin(token) {
        const url = `${this.basePath}/administration/overview`;
        
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }

    async getJuniorsByParent(token,parentId) {
        const url = `${this.basePath}/juniorsbyparent/${parentId}`;
        
        try {
            
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: url,
                headers: { 
                    'authorization': token, 
                    'accept': 'application/json'
                }
            };
            
            const response = await axios.request(config);
            return response.data;
            
        } catch (error) {
            console.error('Error in MyApiClient: '+error);
            if (error.response) {
                const errorResponseData = error.response.data;
                console.log('Error response data:', errorResponseData.detail);
                return {'error': errorResponseData.detail};
            } else {
                return {'error': 'Errore generico'};
            }
        }
    }
}