import React, { Fragment } from 'react';
import { Form, Row, Col, Card } from "react-bootstrap";
import Select from "react-select";
import { Link } from 'react-router-dom';
import "react-widgets/styles.css";

// NON USATA

export const AggiungiDocumenti = () => {

	const options = [
		{ value: "Strutture", label: "Strutture" },
		{ value: "Maestri", label: "Mestri" },
		{ value: "Player", label: "Player" },
		{ value: "CDA", label: "CdA" },
		{ value: "Soci", label: "Soci" },
		{ value: "Teams", label: "Teams" },
	];

	return (
		<Fragment>
			<Form>
				<Card>
					<Card.Header as="h4" className='card-title'>Carica nuovo documento</Card.Header>
					<Card.Body>

						<Row>
						<Col className='col-xl-3'>
								<Form.Label>Data documento</Form.Label>
								<Form.Control type="date" placeholder="Data inizio"/>
								<br />
							</Col>
							<Col className='col-xl-3'>
								<Form.Label>Nome documento</Form.Label>
								<Form.Control type="text" placeholder="Inserisci il nome del documento" />
								<br />
							</Col>
							<Col className='col-xl-3'>
								<Form.Label>Seleziona tipolgia di documento</Form.Label>
								<Select
									defaultValue={""}
									options={options}
									isMulti
									style={{
										lineHeight: "40px",
										color: "#fd7e14",
										paddingLeft: " 15px",
									}
									}
								/>
								<br />
							</Col>
							<Col className='col-xl-3'>
								<Form.Label>Seleziona documento</Form.Label>
								<Form.Control type="file" placeholder="Inserisci il nome del documento" />
								<br />
							</Col>
</Row>
							<Col>
								<Form.Label>Descrizione</Form.Label>
								<Form.Control as="textarea" rows={5} placeholder="Inserisci la descrizione del documento" />
								<br />
							</Col>
					</Card.Body>
				</Card>


				<div className="d-flex justify-content-end gap-4 mt-4">
					<Link to={"#"} className="btn btn-dark light mr-4">Annulla</Link> <Link to="#" className="btn btn-primary ">Carica</Link>
				</div>
			</Form>
		</Fragment>
	)

}
export default AggiungiDocumenti;