import React, { useEffect, useState } from "react";
import { Row, Col, Card, Tab, Nav, Form, Button } from "react-bootstrap";
import TabellaLista from "../Tabella/TabellaLista";
import { More, DocumentDownload, TableDocument } from 'iconsax-react';
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import { format } from 'date-fns';

const DocumentiPlayer = (props) => {

    const [data_json, setDataJson] = useState(null);

    useEffect(() => {
        if (props.profile && props.profile.idsPlay) {
            let playerData = props.profile;

            // console.log(playerData.idsPlay);

            let new_data_json = [];
            if (playerData.idsPlay.medicalCertificates) {

                playerData.idsPlay.medicalCertificates.forEach(element => {
                    
                    new_data_json.push({
                        "id": element.document.id,
                        "date": format(element.document.uploadedAt, 'dd/MM/yyyy HH:mm:ss'),
                        "document": {
                            'name': element.document.name,
                            'uri': element.document.uri
                        },
                        "state": element.verificationStatus
                    });
                });
            }

            setDataJson(new_data_json);
        }
		
    }, [props]);

    const data_column = [
        {
            Header: 'Data',
            accessor: 'date',
            Filter: ColumnFilter
        },
        {
            Header: 'Nome documento',
            accessor: 'document',
            Filter: ColumnFilter,
            Cell: ({ value }) => {
				return (
					<Button onClick={() => downloadDoc(value.uri)} variant="dark light" size="xs" >
						<span className="me-2"><TableDocument size="24" variant="Bulk" /></span>
						{value.name}
					</Button>
				)
			},
			disableFilters: true,
        },
        {
            Header: 'Stato',
            accessor: 'state',
            Filter: ColumnFilter,
			Cell: ({ value }) => {
                if(value == 'PENDING') {
                    return <span><i className="fa fa-circle text-warning me-1"></i> In approvazione</span>
                } else if(value == 'APPROVED') {
                    return <span><i className="fa fa-circle text-success me-1"></i> Approvato</span>
                } else if(value == 'REJECTED') {
                    return <span><i className="fa fa-circle text-danger me-1"></i> Rifiutato</span>
                }
			},
			disableFilters: true,
        }
    ];

    const downloadDoc = (uri) => {
        window.open(uri, '_blank');
    };

    return (
        <>
            <Row>
                <TabellaLista
                    cardTitle={'Documenti'}
                    hasSearch={true}
                    hasFilter={false}
                    hasAddMore={false}
                    data_column={data_column}
                    data_json={data_json}
                />
            </Row>
        </>
    )
}
export default DocumentiPlayer;