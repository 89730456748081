import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter';
import { Dropdown } from "react-bootstrap";
import { More} from 'iconsax-react';
import TabellaLista from '../../../../components/Tabella/TabellaLista';

export const ListaComunicazioniGeneriche = () => {

	const data_column = [

		{
			Header: 'Data',
			Footer: 'Data',
			accessor: 'comunicazione_date',
			Filter: ColumnFilter
		},
		{
			Header: 'Titolo',
			Footer: 'Titolo',
			accessor: 'comunicazione_title',
			Filter: ColumnFilter
		},

		{
			Header: 'Destinatari',
			Footer: 'Destinatari',
			accessor: 'comunicazione_sendto',
			Filter: ColumnFilter
		},
		{
			Header: 'Nr destinatari',
			Footer: 'Nr destinatari',
			accessor: 'comunicazione_number',
			Filter: ColumnFilter
		},
		{
			Header: 'Stato',
			Footer: 'Stato',
			accessor: 'comunicazioni_status',
			Filter: ColumnFilter,
			Cell: ({ value }) => {
				return (
					<div className="d-flex align-items-center">
						<i className={`fa fa-circle me-1 ${value.class}`}></i>
						{value.label}
					</div>
				)
			},
			disableFilters: true,
		},
		{
			Header: 'Azione',
			Footer: 'Azione',
			accessor: 'azione',
			Cell: ({ value }) => {
				return (
					<Dropdown>
						<Dropdown.Toggle
							className="btn-primary light sharp i-false btn-action"
						>
							<More />
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item><Link to=''>Reinvia</Link></Dropdown.Item>
							<Dropdown.Item><Link to=''>Modifica</Link></Dropdown.Item>
							<Dropdown.Item><Link to=''>Cancella</Link></Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				)
			},
			disableFilters: true,
		},
	];

	const data_json = [
		{
			"id": 1,
			"comunicazione_date": "12 marzo 2024",
			"comunicazione_title": "Aggiornamento regolamento Strutture",
			"comunicazione_sendto": "Strutture",
			"comunicazione_number": 25,
			"comunicazioni_status": {
				"label": "Inviata",
				"class": "text-success",
			}
		},
		{
			"id": 2,
			"comunicazione_date": "3 giugno 2024",
			"comunicazione_title": "Aggiornamento App",
			"comunicazione_sendto": "Strutture, Maestri, Player",
			"comunicazione_number": 524,
			"comunicazioni_status": {
				"label": "Programmata",
				"class": "text-blue",
			}
		},
		{
			"id": 3,
			"comunicazione_date": "20 maggio 2024",
			"comunicazione_title": "Chiusure straordinarie",
			"comunicazione_sendto": "Strutture, Maestri, Player",
			"comunicazione_number": 524,
			"comunicazioni_status": {
				"label": "Bozza",
				"class": "text-primary",
			}
		}
	];

	return (
		<Fragment>
			<TabellaLista
				cardTitle={'Lista Comunicazioni generiche'}
				hasSearch={true}
				hasFilter={true}
				hasAddMore={true}
				labelAddMore={'Nuova comunicazione'}
				linkAddMore={'/maestro/comunicazioni/generiche/aggiungi-comunicazione'}
				data_column={data_column}
				data_json={data_json}
			/>
		</Fragment>
	)

}
export default ListaComunicazioniGeneriche;