import { Book, Home, Task, Sms, ArchiveBox, Calendar, ShoppingBag, WalletMoney, Chart, Setting2 } from 'iconsax-react';
import React from 'react';
//import icon. for React Native import from 'iconsax-react-native'

export const MenuSuperAdminList = [
    {
        title: 'Dashboard',
        iconStyle: <span style={{ 'marginRight': '10px' }}><Home color="#737B8B" /></span>,
        to: '/superadmin/dashboard',
    },


    {
        title: 'Anagrafiche',
        classsChange: 'mm-collapse',
        iconStyle: <span style={{ 'marginRight': '10px' }}><Task color="#737B8B" /></span>,
        content: [

            {
                title: 'Strutture Sportive',
                to: 'superadmin/anagrafiche/strutture-sportive',
            },
            {
                title: 'Player',
                to: 'superadmin/anagrafiche/player',
            },
            {
                title: 'Junior',
                to: 'superadmin/anagrafiche/junior',
            },
            {
                title: 'Maestri',
                to: 'superadmin/anagrafiche/maestri',
            },
            /*
            {
                title: 'Abilitazione maestri',
                to: 'superadmin/anagrafiche/abilitazione-maestri',
            }
            */
        ],
    },

    {
        title: 'Comunicazioni',
        iconStyle: <span style={{ 'marginRight': '10px' }}><Sms color="#737B8B" /></span>,
        to: '/superadmin/comunicazioni',
    },
    /*
    {
        title: 'Documenti',
        iconStyle: <span style={{ 'marginRight': '10px' }}><ArchiveBox color="#737B8B" /></span>,
        to: '/superadmin/documenti',
    },
    */
    /*
    {
        title: 'Calendario',
        classsChange: 'mm-collapse',
        iconStyle: <span style={{ 'marginRight': '10px' }}><Calendar color="#737B8B" /></span>,
        content: [

            {
                title: 'Eventi',
                to: 'superadmin/calendario/calendario-eventi',
            },
            {
                title: 'Prenotazioni',
                to: 'superadmin/calendario/calendario-prenotazioni',
            }
        ],
    },
    */
    /*
    {
        title: 'Prodotti',
        classsChange: 'mm-collapse',
        iconStyle: <span style={{ 'marginRight': '10px' }}><ShoppingBag color="#737B8B" /></span>,
        content: [
            {
                title: 'Prodotti',
                to: 'superadmin/prodotti/',
            },
            {
                title: 'Promozioni',
                to: 'superadmin/prodotti/promozioni',
            },
            {
                title: 'Lista di attesa',
                to: 'superadmin/prodotti/lista-di-attesa',
            }
        ],
    },
    */
    {
        title: 'Fatturazione',
        iconStyle: <span style={{ 'marginRight': '10px' }}><WalletMoney color="#737B8B" /></span>,
        to: '/superadmin/vendite-fatture',
    }, 
    /*
    {
        title: 'Report',
        classsChange: 'mm-collapse',
        iconStyle: <span style={{ 'marginRight': '10px' }}><Chart color="#737B8B" /></span>,
        content: [
            {
                title: 'SportID',
                to: 'superadmin/report/sportid',
            },
            {
                title: 'Strutture',
                to: 'superadmin/report/strutture',
            },
            {
                title: 'Maestri',
                to: 'superadmin/report/maestri',
            }
        ],
    },
    */
    {
        title: 'Strumenti',
        iconStyle: <span style={{ 'marginRight': '10px' }}><Setting2 color="#737B8B" /></span>,
        content: [

            {   
                title: 'Impostazioni',
                // to: '/superadmin/strumenti/utenti-gestionale',
                to: 'strumenti/impostazioni'
            }
        ]
    }

]