import React, { Fragment, useState} from 'react'
import { Row, Col, Card, Collapse, Form, FormCheck, Button } from 'react-bootstrap'
import { ArrowDown2, FilterSquare} from 'iconsax-react'
import GraficoAbbonamentiAttivi from '../../../components/SuperAdmin/Dashboard/graficoAbbonamentiAttivi'
import TabellaLista from '../../../components/Tabella/TabellaLista'
import Select from "react-select";
import { ColumnFilter } from '../../../components/table/FilteringTable/ColumnFilter'

const data_column = [

	{
		Header: 'Maestro',
		accessor: 'maestro_nome',
		Filter: ColumnFilter
	},
	{
		Header: 'Ore di lavoro',
		accessor: 'maestro_ore',
		Filter: ColumnFilter
	},
	{
		Header: 'Allievi',
		accessor: 'maestro_allievi',
		Filter: ColumnFilter
	},
    {
		Header: 'Fatturato',
		accessor: 'maestro_fatturato',
		Filter: ColumnFilter
	},
];

const data_json = [

	{
		"id": 1,
		"maestro_nome": "Giorgia Pedrini",
		"maestro_ore": "2.850",
		"maestro_allievi": "86",
        "maestro_fatturato": "€ 17.450,00",
	},
	{
		"id": 2,
		"maestro_nome": "Mario Rossi",
		"maestro_ore": "2.005",
		"maestro_allievi": "105",
        "maestro_fatturato": "€ 10.760,50",

	},
	{
		"id": 3,
		"maestro_nome": "Charles Master",
		"maestro_ore": "1.896",
		"maestro_allievi": "75",
        "maestro_fatturato": "€ 8.948,70",

	}
];

const ReportStrutturaMaestri = () => { 


    const options = [
        { value: "lorem-ipsum", label: "Lorem Ipsum" },
        { value: "lorem-ipsum", label: "Lorem Ipsum" },
        { value: "lorem-ipsum", label: "Lorem Ipsum" },
    ];

    const [selectedOption, setSelectedOption] = useState({ label: "Seleziona...", value: null });

    const [isConfronto, setisConfronto] = useState(false);

    const handleCheckboxConfronto = () => {
        setisConfronto(!isConfronto);
    };

    const [isOpen, setIsOpen] = React.useState(false);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Fragment>
            <Row>
                <Col>
                    <Card>
                    <Card.Body>
                            <div className='d-flex align-items-center justify-content-between' onClick={toggleCollapse}>
                                <Card.Title as="h4">Filtri</Card.Title>
                                <ArrowDown2 size="32" variant="Bulk" />
                            </div>
                            <Collapse in={isOpen}>
                                <Form>
                                    <Row>
                                        <Col xxl="4" xl="4" lg="4">
                                            <Form.Label>Periodo</Form.Label>
                                            <Select
                                                defaultValue={selectedOption}
                                                onChange={setSelectedOption}
                                                options={options}
                                            />
                                            <br />
                                        </Col>

                                        <Col xxl="4" xl="4" lg="4">
                                            <Form.Label>Dalla data</Form.Label>
                                            <Form.Control type="date" />
                                            <br />
                                        </Col>

                                        <Col xxl="4" xl="4" lg="4">
                                            <Form.Label>Alla data</Form.Label>
                                            <Form.Control type="date" />
                                            <br />
                                        </Col>

                                        <Col xxl="12">
                                            <Form.Group controlId="confronto">
                                                <FormCheck
                                                    className="d-flex"
                                                    type="checkbox"
                                                    label="Questo prodotto ha una scadenza."
                                                    checked={isConfronto}
                                                    onChange={handleCheckboxConfronto} />
                                            </Form.Group>
                                            <br/>
                                        </Col>

                                        {isConfronto &&

                                            <Fragment>

                                                <Col xxl="4" xl="4" lg="4">
                                                    <Form.Label>Periodo</Form.Label>
                                                    <Select
                                                        defaultValue={selectedOption}
                                                        onChange={setSelectedOption}
                                                        options={options}
                                                    />
                                                    <br />
                                                </Col>

                                                <Col xxl="4" xl="4" lg="4">
                                                    <Form.Label>Dalla data</Form.Label>
                                                    <Form.Control type="date" />
                                                    <br />
                                                </Col>

                                                <Col xxl="4" xl="4" lg="4">
                                                    <Form.Label>Alla data</Form.Label>
                                                    <Form.Control type="date" />
                                                    <br />
                                                </Col>

                                            </Fragment>
                                        }

                                        <Col xxl="12">
                                            <Button variant="primary btn-block rounded-3" size="sm"> <FilterSquare size="20" variant="Bulk"/> Applica filtro </Button>
                                        </Col>

                                    </Row>
                                </Form>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col xl="3" xxl="4" lg="6" sm="6">
                    <Card>
                        <Card.Body className='border-start-5-success'>
                            <h4 className="mb-1 font-w300">Volume di vendita totale</h4>
                            <h2 className=" mb-0 ">175.123,55 €</h2>
                            <small><b className='text-success'>+15%</b> dal periodo precendete</small>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl="3" xxl="4" lg="6" sm="6">
                    <Card>
                        <Card.Body className='border-start-5-cyan'>
                            <h4 className="mb-1 font-w300">Maestri</h4>
                            <h2 className=" mb-0 ">30</h2>
                            <small><b className='text-danger'>-8</b> dal periodo precendete</small>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl="3" xxl="4" lg="6" sm="6">
                    <Card>
                        <Card.Body className='border-start-5-gray'>
                            <h4 className="mb-1 font-w300">Ore di lavoro</h4>
                            <h2 className=" mb-0 ">7650</h2>
                            <small><b className='text-success'>+35%</b> dal periodo precendete</small>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl="3" xxl="4" lg="6" sm="6">
                    <Card>
                        <Card.Body className='border-start-5-orange'>
                            <h4 className="mb-1 font-w300">Allievi</h4>
                            <h2 className=" mb-0 ">305</h2>
                            <small><b className='text-danger'>-18</b> dal periodo precendete</small>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col xxl="12" xl="12" lg="12" sm="12">
                 
                        <TabellaLista
			cardTitle={'Classifica Maestri '}
			hasSearch={false}
			hasFilter={false}
			hasAddMore={false}
			labelAddMore={'Nuovo impianto'}
			linkAddMore={'/struttura/prodotti/impianti/aggiungi'}
			data_column={data_column}
			data_json={data_json}
		/>
                    

                 </Col>
            </Row>

            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h4">Statistiche maestri - Ore di lavoro - Nr di allievi</Card.Title>
                        </Card.Header>
                        
                            <Card.Body>
                                <GraficoAbbonamentiAttivi/>
                            </Card.Body>
                        
                    </Card>
                </Col>
            </Row>
            
        </Fragment>
    );
};

export default ReportStrutturaMaestri