import { More, User } from "iconsax-react";
import { Dropdown, Alert, Modal, Spinner, Button, Row, Col } from "react-bootstrap";
import { ColumnFilter } from "../../../../components/table/FilteringTable/ColumnFilter";
import TabellaLista from "../../../../components/Tabella/TabellaLista";
import { Fragment, useEffect, useState } from "react";
import MyApiClient from "../../../../../api-client/MyApiClient";

export const ListaPlayer = () => {

    const [data_json, setDataJson] = useState(null);

    const [modalShowed, setModalShowed] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalText, setModalText] = useState('');

	const [loadingShowed, setLoadingShowed] = useState(false);

    const data_column = [
        {
            Header: 'Data registrazione',
            Footer: 'Data registrazione',
            accessor: 'dateregistration',
            Filter: ColumnFilter
        },
        {
            Header: 'Nome e Cognome',
            Footer: 'Nome e Cognome',
            accessor: 'name',
            Filter: ColumnFilter,
            Cell: ({ row }) => (
				<div style={{ display: 'flex', alignItems: 'center' }}>
                    {row.original.imageUrl && <img
                        src={row.original.imageUrl}
                        alt={row.original.name}
                        style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        marginRight: '10px'
                        }}
                    />}
                    {row.original.name}
				</div>
			)
        },
        {
            Header: 'Email',
            Footer: 'Email',
            accessor: 'email',
            Filter: ColumnFilter
        },
        {
            Header: 'Stato',
            Footer: 'Stato',
            accessor: 'status',
            Cell: ({ value }) => {
              return (value && 
                <div className="d-flex align-items-center">
                  <i className={`fa fa-circle me-1 ${value.class}`}></i>
                  {value.label}
                </div>
              );
            },
            disableFilters: true,
        },
        {
            Header: 'Abbonamento',
            Footer: 'Abbonamento',
            accessor: 'abbonamento',
            Cell: ({ value }) => {
                return (value && 
                    <div className="d-flex align-items-center">
                        <i className={`fa fa-star me-1 ${value.class}`}></i>
                        {value.label}
                    </div>
                )
            },
            disableFilters: true,
        },
        {
            Header: 'Azione',
            Footer: 'Azione',
            accessor: 'azione',
            Cell: ({ row }) => {
                return (
                    <Dropdown>
                    <Dropdown.Toggle className="btn-primary light sharp i-false btn-action" >
                        <More />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => openDetail(row.original)}><span className="me-2"><User size="20" /></span> Visualizza</Dropdown.Item>
                        {/*<Dropdown.Item className="text-danger"><span className="me-2"><Trash size="20"/></span>Delete</Dropdown.Item>*/}
                    </Dropdown.Menu>
                    </Dropdown>
                )
            },
            disableFilters: true,
        },
    ];

    function openDetail(row) {
		window.location.href = '/superadmin/anagrafiche/player/'+row.id;
	}

    useEffect(() => {
        const fetchData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    let token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();
            
                    setLoadingShowed(true);
                    const [membershipData] = await Promise.all([
                        apiClient.getProfilesByFilters(token,10000,null,'IDS_PLAY')
                    ]);
                    setLoadingShowed(false);
            
                    if(membershipData) {
                        let data_member = [];
                        membershipData.items.forEach(element => {
            
                            let image = null;
                            if(element.pictures && element.pictures[0] && element.pictures[0].uri) {
                                image = element.pictures[0].uri;
                            }
            
                            let status = null;
                            if(element.profileStatus=='INACTIVE') {
                                status = {
                                    "label": "Inattivo",
                                    "class": "text-danger"
                                };
                            } else if(element.profileStatus=='ACTIVE') {
                                status = {
                                    "label": "Attivo",
                                    "class": "text-warning"
                                };
                            } else if(element.profileStatus=='COMPLETED') {
                                status = {
                                    "label": "Completato",
                                    "class": "text-success"
                                };
                            }
            
                            let member_row = {
                                "id": element.userId,
                                "name": element.name,
                                "imageUrl": image,
                                "status": status,
                                "nr-clienti": 0,
                                "nr-soci": 0,
                                "nr-maestri": 0,
                                "email": ""
                            };
                            data_member.push(member_row);
            
                        });
                        setDataJson(data_member);
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        fetchData();
    
    }, []);

    /*
    const data_json = [
        {
            "nome-e-cognome": "Loredana Bassi",
            "email": "loredana@mail.com",
            "membri": 12,
            "data-registrazione": "12 Marzo 2024",
            "stato": {
                "label": "Inactive",
                "class": "text-warning"
            },
            "abbonamento": {
                "label": "Smart",
                "class": "text-dark"
            },
        },
        {
            "nome-e-cognome": "Loredana Bassi",
            "email": "loredana@mail.com",
            "membri": 12,
            "data-registrazione": "12 Marzo 2024",
            "stato": {
                "label": "Completed",
                "class": "text-blue"
            },
            "abbonamento": {
                "label": "Premium",
                "class": "text-warning"
            },
        },
        {
            "nome-e-cognome": "Loredana Bassi",
            "email": "loredana@mail.com",
            "membri": 12,
            "data-registrazione": "12 Marzo 2024",
            "stato": {
                "label": "Active",
                "class": "text-success"
            },
            "abbonamento": {
                "label": "Premium",
                "class": "text-warning"
            },
        },
    ];
    */

    return (
        <>
            <Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

			<Modal className="fade" show={modalShowed} centered>
                <Modal.Header>
                    <Modal.Title>{modalTitle}</Modal.Title>
                    <Button onClick={() => setModalShowed(false)} variant="" className="btn-close">
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Row><Col className="col-xl-64">{modalText}</Col></Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button className="btn btn-primary btn-add" onClick={() => setModalShowed(false)}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>

            <Fragment>
                <TabellaLista
                    cardTitle={'Lista Player'}
                    hasSearch={true}
                    hasFilter={true}
                    hasAddMore={false}
                    data_column={data_column}
                    data_json={data_json}
                />
                
                {/*<Alert variant="danger">
                    <Alert.Heading>Attenzione</Alert.Heading>
                    <p>
                        La lista è in fase di creazione.<br/>
                        Le "Azioni" non sono definitive
                    </p>
                </Alert>*/}
            </Fragment>
        </>
    )
}

export default ListaPlayer