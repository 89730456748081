import React, { useEffect, useState } from 'react';
import { ColumnFilter } from '../../../../components/table/FilteringTable/ColumnFilter';
import { Form, Button, Dropdown, Modal, Row, Col, Spinner} from "react-bootstrap";
import { More, User} from 'iconsax-react';
import Select from "react-select";
import TabellaLista from '../../../../components/Tabella/TabellaLista';
import MyApiClient from '../../../../../api-client/MyApiClient';
import { format } from 'date-fns';

export const SociTable = () => {

	const [modalCentered, setModalCentered] = useState(false);
	const [playersOptions, setPlayerOptions] = useState(null);
	const [data_json, setDataJson] = useState(null);
	const [showLoad, setShowLoad] = useState(false);

	const [confirmShowed, setConfirmShowed] = useState(false);
	const [onContinue, setOnContinue] = useState(null);

	const [modalShowed, setModalShowed] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalText, setModalText] = useState('');

	const [loadingShowed, setLoadingShowed] = useState(false);
	
	const [userSearch, setUserSearch] = useState('');
	const handleChange = (e) => {
        const { value } = e.target;
        setUserSearch(value);
    };

	const [selectedPlayer, setSelectedPlayer] = useState('');
	const handleSelectChange = (selectedOptions) => {
		setSelectedPlayer(selectedOptions.value);
    };

	const data_column = [
		{
			Header: 'Nome e cognome',
			Footer: 'Nome e cognome',
			accessor: 'name',
			Filter: ColumnFilter,
			Cell: ({ row }) => (
				<div style={{ display: 'flex', alignItems: 'center' }}>
				  {row.original.imageUrl && <img
					src={row.original.imageUrl}
					alt={row.original.name}
					style={{
					  width: '40px',
					  height: '40px',
					  borderRadius: '50%',
					  marginRight: '10px'
					}}
				  />}
				  {row.original.name}
				</div>
			)
		},
		{
			Header: 'Email',
			Footer: 'Email',
			accessor: 'cda_email',
			Filter: ColumnFilter
		},
		{
			Header: 'Registrazione socio',
			Footer: 'Registrazione socio',
			accessor: 'date',
			Filter: ColumnFilter
		},
		{
			Header: 'Scadenza socio',
			Footer: 'Scadenza socio',
			accessor: 'scadenza',
			Filter: ColumnFilter
		},
		{
			Header: 'Stato',
			Footer: 'Stato',
			accessor: 'status',
			Filter: ColumnFilter,
			Cell: ({ value }) => {
				return (
					<div className="d-flex align-items-center">
						<i className={`fa fa-circle me-1 ${value?value.class:''}`}></i>
						{value?value.label:''}
					</div>
				)
			},
			disableFilters: true,
		},
		{
			Header: 'Azione',
			Footer: 'Azione',
			accessor: 'azione',
			Cell: ({ row }) => {
				return (
					<Dropdown>
						<Dropdown.Toggle className="btn-primary light sharp i-false btn-action">
							<More/>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item onClick={() => openDetail(row.original)}><span className="me-2"><User size="20" /></span> Visualizza</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				)
			},
			disableFilters: true,
		},
	];

	const verifySearchUser = (e) => {
        e.preventDefault();

		if(userSearch.length>=3) {
			searchUser(e);
		}
	};

	const searchUser = (e) => {
        e.preventDefault();

        const searchUs = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
					
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

					setShowLoad(true);
					const [playersData] = await Promise.all([
						apiClient.getProfilesByFilters(token,10000,userSearch,'IDS_PLAY')
                    ]);
					setShowLoad(false);

                    if (playersData && playersData.error) {
                        setModalTitle("Errore durante l'operazione");
                        setModalText("Si è verificato un errore durante la lettura (" + saveResult.error + ")");
                        setModalShowed(true);
                    } else {
						let firstId = '';
                        let playerOpt = [];
						playersData.items.forEach(element => {
							if(firstId==='') {
								firstId = element.userId;
							}
							playerOpt.push({ 'value': element.userId, 'label': element.name });
						});
						setPlayerOptions(playerOpt);
						setSelectedPlayer(firstId);
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        searchUs();
    };

	const inviteUser = (e) => {

		setModalCentered(false);
		setOnContinue(() => () => continueInviteUser());

		if(!selectedPlayer) {
			setModalTitle("Errore durante l'operazione");
			setModalText("Il player a cui inviare l'invito deve essere valorizzato per continuare");
			setModalShowed(true);
			return;
		}

		setConfirmShowed(true);
	};

	const continueInviteUser = () => {

		setConfirmShowed(false);

        const inviteUs = async () => {
            try {

                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
					
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
					const clubId = tokenDetails.localId;
                    const apiClient = new MyApiClient();

					let data_update = {
                        'userInvitations': [
							{
								'userId': selectedPlayer,
								'membershipRank': 'MEMBER'
							}
						]
                    };

					setModalCentered(false);
					setLoadingShowed(true);
					const saveResult = await apiClient.inviteUser(token, clubId, data_update);
					setLoadingShowed(false);

                    if (saveResult && saveResult.error) {
                        setModalTitle("Errore durante l'operazione");
                        setModalText("Si è verificato un errore durante l'operazione ("+saveResult.error+").");
                        setModalShowed(true);
                    } else {
						window.location.href = '/struttura/soci/list';
                    }
                }

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        inviteUs();
    };

	useEffect(() => {
        const fetchData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    let token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

					setLoadingShowed(true);
					const [membershipData] = await Promise.all([
                        apiClient.getMemberships(token, 'MEMBER', null)
                    ]);
					setLoadingShowed(false);

					if(membershipData) {
						let data_member = [];
						membershipData.forEach(element => {

							let status = null;
							if(element.status==='CONFIRMED') {
								status = {
									"label": "Confermato",
									"class": "text-success"
								};
							} else {
								status = {
									"label": "Non confermato",
									"class": "text-primary"
								};
							}

							let createdAt = new Date(element.createdAt);
							let scadenzaAt = new Date(createdAt);
							scadenzaAt = scadenzaAt.setFullYear(scadenzaAt.getFullYear() + 1);

							let image = null;
							if(element.picture && element.picture.uri) {
								image = element.picture.uri;
							}

							let member_row = {
								"id": element.userId,
								"name": element.name,
								"status": status,
								"date": format(createdAt, 'dd/MM/yyyy HH:mm:ss'),
								"scadenza": format(scadenzaAt, 'dd/MM/yyyy'),
								"imageUrl": image
							};
							data_member.push(member_row);
							
						});
						setDataJson(data_member);
					}
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

    }, []);

	function openDetail(row) {
		window.location.href = '/struttura/clienti/player_profile/'+row.id;
	}

	return (
		<>
			<Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

			<Modal className="fade" show={confirmShowed} centered>
				<Modal.Header>
					<Modal.Title>Sei sicuro?</Modal.Title>
					<Button onClick={() => setConfirmShowed(false)} variant="" className="btn-close">
					</Button>
				</Modal.Header>
				<Modal.Body>
					<Row><Col className="col-xl-64">Sei sicuro di volere continuare? <b>Un invito sarà inviato all'utente selezionato.</b></Col></Row>
				</Modal.Body>
				<Modal.Footer className="justify-content-between">
					<Button className="btn btn-light btn-add" onClick={() => setConfirmShowed(false)}>
						Chiudi
					</Button>
					<Button className="btn btn-primary btn-add" onClick={onContinue}>
						Continua
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal className="fade" show={modalShowed} centered>
                <Modal.Header>
                    <Modal.Title>{modalTitle}</Modal.Title>
                    <Button onClick={() => setModalShowed(false)} variant="" className="btn-close">
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Row><Col className="col-xl-64">{modalText}</Col></Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button className="btn btn-primary btn-add" onClick={() => setModalShowed(false)}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>

			<TabellaLista
				cardTitle={'Lista Soci'}
				hasSearch={true}
				hasFilter={true}
				data_column={data_column}
				data_json={data_json}
				hasAddMoreAsModal={true}
				modalCallBack={() => setModalCentered(true)}
				modalContent={
					<Modal className="fade" show={modalCentered} centered>
						<Modal.Header>
							<Modal.Title>Aggiungi un socio</Modal.Title>
							<Button onClick={() => setModalCentered(false)} variant="" className="btn-close"></Button>
						</Modal.Header>
						<Modal.Body>
							<Form>
								<Row>
									<Col className="col-lg-12 pb-4">
										<Form.Label>Cerca tra gli utenti di SportId</Form.Label>
										<Form.Control type="text" name="userSearch" value={userSearch} onKeyUp={verifySearchUser} onChange={handleChange} />
									</Col>
									{/*
									<Col className="col-lg-3 pt-4">
										<Button onClick={searchUser} className="btn btn-primary btn-add">Cerca</Button>
									</Col>
									*/}
								</Row>
								<Row>
									<Col className="col-lg-12 pb-4">
									{ showLoad && <p style={{color: "red", fontStyle: "italic"}}>Ricerca in corso...</p> }
									<Form.Label>Seleziona un utente tra quelli filtrati</Form.Label>
                                    <Select placeholder="Filtra gli utenti per visualizzarli..." 
										value={playersOptions?playersOptions.filter(option => selectedPlayer === option.value):''}
										options={playersOptions?playersOptions:[]} onChange={handleSelectChange} style={{lineHeight: "40px",color: "#fd7e14",paddingLeft: " 15px"}} />
									</Col>
								</Row>
							</Form>
						</Modal.Body>
						<Modal.Footer className="justify-content-between">
							<Button className="btn btn-light btn-add" onClick={() => setModalCentered(false)} >
								Annulla
							</Button>
							<Button onClick={inviteUser} className="btn btn-primary btn-add">Invia invito</Button>
						</Modal.Footer>
					</Modal>
				}
			/>
		</>
	)
}
export default SociTable;