import React, { Fragment } from "react";
import TabellaLista from "../../../../components/Tabella/TabellaLista";
import { Button, Alert } from "react-bootstrap";
import { ColumnFilter } from "../../../../components/table/FilteringTable/ColumnFilter";
import { ProfileCircle, InfoCircle } from "iconsax-react";

const ListaAttesa = () => {
    const data_column = [

        {
            Header: 'Data',
            Footer: 'Data',
            accessor: 'data',
            Filter: ColumnFilter
        },
        {
            Header: 'Ora',
            Footer: 'Ora',
            accessor: 'ora',
            Filter: ColumnFilter
        },
        {
            Header: 'Nome richiedente',
            Footer: 'Nome richiedente',
            accessor: 'nome-richiedente',
            Cell: ({ value }) => {
                return (
                    <Button variant="dark light" size="xs" >
                        <span className="me-2"><ProfileCircle size="24" variant="Bulk" /></span>
                        {value}
                    </Button>
                );
            },
            Filter: ColumnFilter
        },
        {
            Header: 'Struttura richiesta',
            Footer: 'Struttura richiesta',
            accessor: 'struttura-richiesta',
            Cell: ({ value }) => {
                return (
                    <Button variant="dark light" size="xs" >
                        <span className="me-2"><ProfileCircle size="24" variant="Bulk" /></span>
                        {value}
                    </Button>
                );
            },
        },
        {
            Header: 'Maestro richiesto',
            Footer: 'Maestro richiesto',
            accessor: 'maestro-richiesto',
            Cell: ({ value }) => {
                return (
                    <Button variant="dark light" size="xs" >
                        <span className="me-2"><ProfileCircle size="24" variant="Bulk" /></span>
                        {value}
                    </Button>
                );
            },
        },
        {
            Header: 'Impianto richiesto',
            Footer: 'Impianto richiesto',
            accessor: 'impianto-richiesto',
            Filter: ColumnFilter
            
        }
    ];

    const data_json = [
        {
            "data": "10 Marzo 2024",
            "ora": "11:30",
            "nome-richiedente": "Nome Cognome",
            "struttura-richiesta": "Struttura",
            "maestro-richiesto": "Nome Cognome",
            "impianto-richiesto": "Impianto"
        }
    ];

    return (
        <Fragment>
            <TabellaLista
                cardTitle={'Lista di attesa'}
                hasSearch={true}
                hasFilter={true}
                hasAddMore={false}
                data_column={data_column}
                data_json={data_json}
            />
        </Fragment>
    )
}

export default ListaAttesa