import React, { useEffect, useState } from "react";
import { Row, Col, Card, Tab, Nav, Form } from "react-bootstrap";
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import TabellaLista from "../Tabella/TabellaLista";

const sports = [
	{ value: "RUNNING", label: "Corsa" },
	{ value: "BIKING", label: "Ciclismo" },
	{ value: "TENNIS", label: "Tennis" },
	{ value: "PADEL", label: "Padel" },
	{ value: "SKIING", label: "Sci" },
	{ value: "CROSS_COUNTRY_SKIING", label: "Fondo" },
	{ value: "SNOWBOARD", label: "Snowboard" },
	{ value: "SWIMMING", label: "Nuoto" },
	{ value: "GOLF", label: "Golf" },
	{ value: "FOOTBALL", label: "Calcio" },
	{ value: "FIVE_A_SIDE_FOOTBALL", label: "Calcio a 5" },
	{ value: "VOLLEYBALL", label: "Pallavolo" },
	{ value: "BASKETBALL", label: "Basket" },
	{ value: "ICE_HOCKEY", label: "Hockey su ghiaccio" },
	{ value: "RUGBY", label: "Rugby" }
];

const DatiSportPlayer = (props) => {

    const [formData, setFormData] = useState({
        primaryHand: '',
        primaryFoot: ''
    });

    const [data_json, setDataJson] = useState(null);

    useEffect(() => {
        if (props.profile && props.profile.idsPlay) {
            let playerData = props.profile;

            let myFormData = {};

            // console.log(playerData.idsPlay);

            myFormData.primaryHand = playerData.idsPlay.primaryHand ? playerData.idsPlay.primaryHand:'';
            myFormData.primaryFoot = playerData.idsPlay.primaryFoot ? playerData.idsPlay.primaryFoot:'';

            if(myFormData.primaryHand=='RIGHT') { myFormData.primaryHand = 'Destra'; }
            if(myFormData.primaryFoot=='RIGHT') { myFormData.primaryFoot = 'Destro'; }
            if(myFormData.primaryHand=='LEFT') { myFormData.primaryHand = 'Sinistra'; }
            if(myFormData.primaryFoot=='LEFT') { myFormData.primaryFoot = 'Sinistro'; }
            if(myFormData.primaryHand=='AMBIDEXTROUS') { myFormData.primaryHand = 'Ambidestro'; }
            if(myFormData.primaryFoot=='AMBIDEXTROUS') { myFormData.primaryFoot = 'Ambidestro'; }
            
            setFormData(myFormData);

            if (playerData.idsPlay.sports) {
                let new_data_json = [];
                playerData.idsPlay.sports.forEach(element => {
                    let sportLabel = '';
                    sports.forEach(element2 => {
                        if(element2.value == element.sportType) {
                            sportLabel = element2.label;
                        }
                    });

                    let labelStaff = 'NO';
                    if(element.isGuru) {
                        labelStaff = 'SI';
                    }

                    new_data_json.push({
                        "id": element.sportType,
                        "sport": sportLabel,
                        "level": element.percentageOfLevelsCompletion.toFixed(2)+"%",
                        "role": "-",
                        "skill": element.percentageOfSkillsCompletion.toFixed(2)+"%",
                        "staff": labelStaff
                    });
                });

                setDataJson(new_data_json);
            } else {
                setDataJson(null);
            }
        }
		
    }, [props]);

    const data_column = [
        {
            Header: 'Sport',
            accessor: 'sport',
            Filter: ColumnFilter
        },
        {
            Header: 'Livello',
            accessor: 'level',
            Filter: ColumnFilter
        },
        {
            Header: 'Ruolo',
            accessor: 'role',
            Filter: ColumnFilter
        },
        {
            Header: 'Skill',
            accessor: 'skill',
            Filter: ColumnFilter
        },
        {
            Header: 'Maestro',
            accessor: 'staff',
            Filter: ColumnFilter
        },
    ];

    return (
        <>
            <Row>
                <Form>
                    <Card className="cardAuto">
                        <Card.Header><h4 className="card-title">Informazioni generiche</h4></Card.Header>
                        <Card.Body>
                            <Row className="mt-3">
                                <Col className="col-xl-3">
                                    <Form.Label>Mano primaria</Form.Label>
                                    <Form.Control type="text" value={formData.primaryHand} disabled />
                                </Col>
                                <Col className="col-xl-3">
                                    <Form.Label>Piede primario</Form.Label>
                                    <Form.Control type="text" value={formData.primaryFoot} disabled />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <TabellaLista
                        cardTitle={'Sport praticati'}
                        hasSearch={true}
                        hasFilter={false}
                        hasAddMore={false}
                        data_column={data_column}
                        data_json={data_json}
                    />
                </Form>
            </Row>
        </>
    )
}
export default DatiSportPlayer;