import React, { Fragment, useState } from "react";
import { Form, Row, Col, FormCheck, InputGroup } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-widgets/DatePicker";
import TimeInput from "react-widgets/TimeInput";
import { Trash, AddSquare } from "iconsax-react";


const FormProdottoCorso = () => {

    const [selectedMaestroOption, setSelectedMaestroOption] = useState({ value: null, label: "Seleziona..." });
    const selectMaestro = [
        { value: "lorem-ipsum-1", label: "Lorem Ipsum" },
        { value: "lorem-ipsum-2", label: "Lorem Ipsum" },
        { value: "lorem-ipsum-3", label: "Lorem Ipsum" },
    ];

    const [data, setData] = useState([1]);

    const handleAggiungiRiga = () => {
        const nextNumber = Math.max(...data) + 1;
        setData([...data, nextNumber]);
    }
    const handleRimuoviRiga = (i) => {
        const deleteVal = [...data]
        deleteVal.splice(i, 1)
        setData(deleteVal)
    }


    return (
        <Fragment>

            <Row>
                <Col xxl="4" xl="4" lg="4" md="4">

                    <Form.Label>Maestro</Form.Label>
                    <Select
                        defaultValue={selectedMaestroOption}
                        onChange={setSelectedMaestroOption}
                        options={selectMaestro}
                    />
                    <br />
                </Col>

                <Col xxl="4" xl="4" lg="4" md="4">

                    <Form.Label>Impianto</Form.Label>
                    <Select
                        defaultValue={selectedMaestroOption}
                        onChange={setSelectedMaestroOption}
                        options={selectMaestro}
                    />
                    <br />
                </Col>

                <Col xxl="4" xl="4" lg="4" md="4">

                    <Form.Label>Nr mass. di partecipanti</Form.Label>
                    <Form.Control type="number" min={1} defaultValue={1} />
                    <br />
                </Col>

                <Col xxl="4" xl="4" lg="4" md="4">

                    <Form.Label>Data inizio</Form.Label>
                    <DatePicker
                        defaultValue={new Date()}
                        valueFormat={{ dateStyle: "medium" }}
                    />
                    <br />
                </Col>

                <Col xxl="4" xl="4" lg="4" md="4">

                    <Form.Label>Data fine</Form.Label>
                    <DatePicker
                        defaultValue={new Date()}
                        valueFormat={{ dateStyle: "medium" }}
                    />
                    <br />
                </Col>

                <Col xxl="4" xl="4" lg="4" md="4">

                    <Form.Label>Ricorrenza</Form.Label>
                    <Select
                        defaultValue={selectedMaestroOption}
                        onChange={setSelectedMaestroOption}
                        options={selectMaestro}
                    />
                    <br />

                </Col>

                <Col xxl="12" xl="12" lg="12" md="12" sm="12">
                    <Form.Label>Giorni (Questa lista viene popolata in automatico in base alle date e ricorrenza selezionate sopra)</Form.Label>
                    <div className="bg-body p-4">
                        {data.map((val, i) => (
                            <Row className="pb-4">
                                <Col>
                                    <Row>
                                        <Col xxl="4" xl="4" lg="4" md="4">
                                            <Form.Label>Giorno</Form.Label>
                                            <DatePicker
                                                defaultValue={new Date()}
                                                valueFormat={{ dateStyle: "medium" }}
                                            />
                                        </Col>
                                        <Col xxl="4" xl="4" lg="4" md="4">
                                            <Form.Label>Dalle</Form.Label>
                                            <TimeInput
                                                style={{ width: "auto" }}
                                                defaultValue={new Date()}
                                            />
                                        </Col>
                                        <Col xxl="4" xl="4" lg="4" md="4">
                                            <Form.Label>Alle</Form.Label>
                                            <TimeInput
                                                style={{ width: "auto" }}
                                                defaultValue={new Date()}
                                            />
                                        </Col>

                                    </Row>
                                </Col>
                                <Col className="d-flex flex-column justify-content-around col-auto ">
                                    <Form.Label></Form.Label>
                                    <div onClick={() => handleRimuoviRiga(i)}><Trash size="24" color="red" variant="Bulk" /></div>
                                </Col>
                            </Row>
                        ))}

                        <div className="bg-white text-center p-1" onClick={handleAggiungiRiga}>Aggiungi  <AddSquare size="24" color="#737B8B" variant="Bulk" /></div>

                    </div>
                    <br/>
                </Col>
                <Col xxl="3" xl="3" lg="3" md="3">
                    <Form.Label>Prezzo</Form.Label>
                    <InputGroup>
                        <Form.Control type="number" placeholder="Es. 10"></Form.Control>
                        <span className="input-group-text">€</span>
                    </InputGroup>
                    <br />
                </Col>
                <Col xxl="3" xl="3" lg="3" md="3">
                    <Form.Label>Pagamento in FitCoin</Form.Label>
                    <InputGroup>
                        <Form.Control type="number" placeholder="Es. 10"></Form.Control>
                        <span className="input-group-text">%</span>
                    </InputGroup>
                    <br />
                </Col>
                <Col xxl="3" xl="3" lg="3" md="3">
                    <Form.Label>Prezzo per soci</Form.Label>
                    <InputGroup>
                        <Form.Control type="number" placeholder="Es. 10"></Form.Control>
                        <span className="input-group-text">€</span>
                    </InputGroup>
                    <br />
                </Col>
                <Col xxl="3" xl="3" lg="3" md="3">
                    <Form.Label>Pagamento in FitCoin per soci</Form.Label>
                    <InputGroup>
                        <Form.Control type="number" placeholder="Es. 10"></Form.Control>
                        <span className="input-group-text">%</span>
                    </InputGroup>
                    <br />
                </Col>

                <Col xxl="12" xl="12" lg="12" md="12" sm="12">
                    <Form.Group controlId="prodottoConListaAttesa">
                        <FormCheck className="d-flex" type="checkbox" label="Prodotto con lista di attesa aperta" />
                    </Form.Group>
                </Col>
            </Row>

        </Fragment>
    )

}

export default FormProdottoCorso