import React, { Fragment, useEffect, useReducer, useState } from "react";
import {  Card,} from "react-bootstrap";
import profile01 from "../../../../images/video.png";

const VideoMaestro = (props) => {

    const videoStyle = {
        width: '100%',
        height: 'auto',
        margin: '0 auto',
        display: 'block'
    };

    const [formData, setFormData] = useState({
        presentationVideo: ''
    });

    useEffect(() => {
        if (props.profile && props.profile.idsGuru) {
            let playerData = props.profile;

            if(playerData && playerData.idsGuru) {
                let formData = {};

                // console.log(playerData.idsGuru);

                formData.presentationVideo = playerData.idsGuru.presentationVideo ? playerData.idsGuru.presentationVideo : '';
                
                // video tester
                // formData.presentationVideo = 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4';
                
                setFormData(formData);
            }
        }
		
    }, [props]);

    return (
        <>
            <Card>
                <Card.Header>Video</Card.Header>
                <Card.Body>
                    {!formData.presentationVideo && 
                        (<div className="m-5 text-center">Nessun video di presentazione presente</div>)
                    }
                    {formData.presentationVideo && <div className="video-container">
                        <video controls style={videoStyle}>
                            <source src={formData.presentationVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>}
                </Card.Body>
            </Card>
        </>
    )
}

export default VideoMaestro;