import React, { Fragment } from 'react';
import { Form, Row, Col, Card } from "react-bootstrap";
import Select from "react-select";
import { Link } from 'react-router-dom';
import "react-widgets/styles.css";

export const AggiungiDocumenti = () => {

    const options = [
        { value: "Strutture", label: "Strutture" },
        { value: "Maestri", label: "Mestri" },
        { value: "Player", label: "Player" },
        { value: "CDA", label: "CdA" },
        { value: "Soci", label: "Soci" },
        { value: "Teams", label: "Teams" },
    ];

    return (
        <Fragment>
            <Card>
                <Card.Header as="h4" className='card-title'>Carica nuovo documento</Card.Header>
                <Card.Body>
                    <Form>
                        <Row>
                            <Col xxl="4" xl="4" lg="4">
                                <Form.Label>Nome documento</Form.Label>
                                <Form.Control type="text" placeholder="Inserisci il nome del documento" />
                                <br/>
                            </Col>
                            <Col xxl="4" xl="4" lg="4">
                                <Form.Label>Seleziona tipolgia di documento</Form.Label>
                                <Select
                                    defaultValue={""}
                                    options={options}
                                    isMulti
                                    style={{
                                        lineHeight: "40px",
                                        color: "#fd7e14",
                                        paddingLeft: " 15px",
                                    }
                                    }
                                />
                                <br/>
                            </Col>
                            <Col xxl="4" xl="4" lg="4">
                                <Form.Label>Seleziona documento</Form.Label>
                                <Form.Control type="file" placeholder="Inserisci il nome del documento" />
                                <br/>
                            </Col>
                            
                            <Col>
                                <Form.Label>Descrizione</Form.Label>
                                <Form.Control as="textarea" rows={5} placeholder="Inserisci la descrizione del documento" />
                                <br/>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            <div className="d-flex justify-content-end gap-4 mt-4">
				<Link to={"#"} className="btn btn-dark light mr-4">Annulla</Link> <Link to="#" className="btn btn-primary ">Carica</Link>
			</div>
        </Fragment>
    )

}
export default AggiungiDocumenti;