import React, { Fragment, useEffect, useState } from "react";
import { Form, Button, Modal, Row, Col, Card, Spinner } from "react-bootstrap";
import { Add, Trash } from "iconsax-react";
import DynamicLogo from "../../../pages/DynamicLogoLoader";
import MyApiClient from "../../../../api-client/MyApiClient";

export const FormFoto = (props) => {

    const MAX_FILE_SIZE = 5 * 1024 * 1024;
	const ALLOWED_FILE_TYPES = ['image/png', 'image/jpeg'];

    const [newTitleFile, setNewTitleFile] = useState(null);

	const [profile, setProfile] = useState(null);
    const [modalCentered, setModalCentered] = useState(false);

    const [modalShowed, setModalShowed] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalText, setModalText] = useState('');

    const [loadingShowed, setLoadingShowed] = useState(false);

    const [confirmShowed, setConfirmShowed] = useState(false);
	const [onContinue, setOnContinue] = useState(null);

	const handleFileChange = (event) => {
		setNewTitleFile(event.target.files[0]);
	};

    const addTitle = (e) => {
        e.preventDefault();

		setModalCentered(false);

		let file = newTitleFile;

		if (file) {

			if (file.size > MAX_FILE_SIZE) {
				setModalTitle("Errore durante l'operazione");
				setModalText('Il file selezionato supera la dimensione massima consentita.');
				setModalShowed(true);
				return;
			}

			if (!ALLOWED_FILE_TYPES.includes(file.type)) {
				setModalTitle("Errore durante l'operazione");
				setModalText('Il file selezionato non è di tipo consentito.');
				setModalShowed(true);
				return;
			}

			const reader = new FileReader();
			reader.onloadend = () => {
				const base64String = reader.result.split(',')[1];
				// console.log(base64String);
				updateProfile(base64String);
			};
			reader.readAsDataURL(file);
		}
    };

    const deleteTitle = (row) => {
		setOnContinue(() => () => continueDeleteTitle(row));
		setConfirmShowed(true);
    };

    const continueDeleteTitle = (row) => {

        setConfirmShowed(false);

		const deleteData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

                    let id_to_delete = profile.club.pictures[0].id;

					setLoadingShowed(true);
                    const deleteResult = await apiClient.deleteCopertina(token, id_to_delete);
                    setLoadingShowed(false);

                    if(!deleteResult) {
                        setModalTitle("Errore durante l'operazione");
                        setModalText("Si è verificato un errore durante la cancellazione ("+saveResult.error+")");
                        setModalShowed(true);
                    } else {
						window.location.href = '/struttura/lamiastruttura?tab=copertina';
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        deleteData();
	};

    function updateProfile(base64String) {

		const saveData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

                    if(profile && profile.club.pictures && profile.club.pictures.length > 0) {
                        let id_to_delete = profile.club.pictures[0].id;

                        setLoadingShowed(true);
                        const deleteResult = await apiClient.deleteCopertina(token, id_to_delete);
                        setLoadingShowed(false);

                        if(!deleteResult) {
                            setModalTitle("Errore durante l'operazione");
                            setModalText("Si è verificato un errore durante la cancellazione ("+deleteResult.error+")");
                            setModalShowed(true);
                            return;
                        }
                    }

                    let data_update = {
                        'content': base64String,
                        'name': 'Immagine di copertina'
                    };

                    setLoadingShowed(true);
                    const saveResult = await apiClient.addCopertina(token, data_update);
                    setLoadingShowed(false);

                    if(!saveResult) {
                        setModalTitle("Errore durante l'operazione");
                        setModalText("Si è verificato un errore durante il salvataggio ("+saveResult.error+")");
                        setModalShowed(true);
                    } else {
						window.location.href = '/struttura/lamiastruttura?tab=copertina';
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        saveData();
	}

    useEffect(() => {
		if (props.profile) {
            setProfile(props.profile);
        }
    }, [props]);
    
    return (
        <>

            <Modal className="fade" show={confirmShowed} centered>
                <Modal.Header>
                    <Modal.Title>Sei sicuro?</Modal.Title>
                    <Button onClick={() => setConfirmShowed(false)} variant="" className="btn-close">
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Row><Col className="col-xl-64">Sei sicuro di volere continuare?</Col></Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button className="btn btn-light btn-add" onClick={() => setConfirmShowed(false)}>
                        Chiudi
                    </Button>
					<Button className="btn btn-primary btn-add" onClick={onContinue}>
                        Continua
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

            <Modal className="fade" show={modalShowed} centered>
                <Modal.Header>
                    <Modal.Title>{modalTitle}</Modal.Title>
                    <Button onClick={() => setModalShowed(false)} variant="" className="btn-close">
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Row><Col className="col-xl-64">{modalText}</Col></Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button className="btn btn-primary btn-add" onClick={() => setModalShowed(false)}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className="fade" show={modalCentered} centered>
                <Modal.Header>
                    <Modal.Title>Carica foto di copertina</Modal.Title>
                    <Button
                        onClick={() => setModalCentered(false)}
                        variant=""
                        className="btn-close"
                    >
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col className="col-lg-12 pb-4">
                                <Form.Label>Seleziona la foto</Form.Label>
                                <Form.Control type="file" placeholder="Carica il file" onChange={handleFileChange} />
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button className="btn btn-light btn-add" onClick={() => setModalCentered(false)}>
                        Annulla
                    </Button>
                    <Button onClick={addTitle} className="btn btn-primary btn-add">Salva</Button>
                </Modal.Footer>
            </Modal>

            <Card>
                <Card.Header><h4 className="card-title">Foto di copertina</h4>
                    {!props.isDisabled && <Button onClick={() => setModalCentered(true)} className="btn btn-primary btn-sm btn-add">
                        <Add variant="Bulk"/><span className='ms-2'>{profile && profile.club.pictures && profile.club.pictures.length > 0 ? 'Sostituisci foto': 'Carica foto' }</span>
                    </Button>}
                </Card.Header>
                <Card.Body>
                    {(!profile || !profile.club.pictures || profile.club.pictures.length === 0) && 
                        (<div className="m-5 text-center">Nessuna foto di copertina presente</div>)
                    }
                    {profile && profile.club.pictures && profile.club.pictures.length > 0 && 
                        (<DynamicLogo logoUrl={profile.club.pictures[0].uri} altText="Copertina Struttura Sportiva" />)
                    }
                    {!props.isDisabled && profile && profile.club.pictures && profile.club.pictures.length > 0 && 
                        (<Button onClick={deleteTitle} className="btn btn-warning btn-sm btn-add mt-2">
                            <Trash variant="Bulk"/><span className='ms-2'>Elimina la foto</span>
                        </Button>)
                    }
                </Card.Body>
            </Card>
        </>
    )
}

export default FormFoto;