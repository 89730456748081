import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Tab, Nav, Modal, Spinner } from "react-bootstrap";
import { People, Profile, TableDocument, Teacher, Information, Profile2User, Health, MedalStar, DocumentText, Note} from "iconsax-react";
import { Link, useParams } from 'react-router-dom';
import IdsJunior from "../../../../components/Junior/ids_junior";
import NoteJunior from "../../../../components/Junior/note_junior";
import StudiJunior from "../../../../components/Junior/Studi_junior";
import SportJunior from "../../../../components/Junior/Sport_junior";
import ParentiJunior from "../../../../components/Junior/parenti_junior";
import TraguardiJunior from "../../../../components/Junior/traguardi_junior";
import MyApiClient from "../../../../../api-client/MyApiClient";
import DynamicLogo from "../../../DynamicLogoLoader";
import DEFAULT_USER from "../../../../../images/DEFAULT_USER.png";

export const JuniorEdit = () => {

	const { id } = useParams();

	const [loadingShowed, setLoadingShowed] = useState(false);

	const [profile, setProfile] = useState(null);
	const [labelNome, setLabelNome] = useState('');

	const tabData = [
		{
			name: "IDS",
			icon: <Profile size='18' variant='Bulk' />,
			content:<IdsJunior isDisabled={true} profile={profile} />,
		},
        {
			name: "Parenti",
			icon: <Profile2User size='18' variant="Bulk" />,
            content:<ParentiJunior profile={profile}/>,
		},
        {
			name: "Studi",
			icon: <Teacher size='18' />,
            content:<StudiJunior profile={profile}/>,
		},
		{
			name: "Sport",
			icon: <TableDocument size='18' />,
            content:<SportJunior profile={profile}/>,
		},
		{
			name: "Traguardi",
			icon: <MedalStar size='18' variant="Bulk" />,
			content:<TraguardiJunior isDisabled={true} profile={profile}/>,
		},
		/*
		{
			name: "Note private",
			icon: <Note size='18' variant="Bulk" />,
			content:<NoteJunior/>,	
		},
		*/
	];

	useEffect(() => {
        const fetchData = async () => {
            try {
                const tokenDetailsString = sessionStorage.getItem('userDetails');
                if (tokenDetailsString) {
                    const tokenDetails = JSON.parse(tokenDetailsString);
                    const token = tokenDetails.idToken;
                    const apiClient = new MyApiClient();

					setLoadingShowed(true);
                    const [profileData] = await Promise.all([
                        apiClient.getProfiles(token, id)
                    ]);
					setLoadingShowed(false);

					let labelNomeTemp = '';
					if(profileData.junior) {
						labelNomeTemp = profileData.junior.givenName+' '+profileData.junior.familyName;
					}
					setLabelNome(labelNomeTemp);

					setProfile(profileData);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

	const isNotCompleted = profile && profile.junior.profileStatus != 'COMPLETED' && profile.junior.profileStatus != 'ACTIVE';

	return (
		<>
			<Modal className="fade" show={loadingShowed} centered>
				<Modal.Body className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				<div>Caricamento in corso...</div>
				</Modal.Body>
			</Modal>

			<div className="custom-tab-1 mtnegativo2">
				<Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
					<Nav as="ul" className="nav-tabs justify-content-center" style={{ border: 'none' }}>
						{tabData.map((data, i) => (
							<Nav.Item as="li" key={i} className="tabMenu">
								<Nav.Link eventKey={data.name.toLowerCase()}>
									{data.icon}{data.name}
								</Nav.Link>
							</Nav.Item>
						))}
					</Nav>
					<Row className="align-items-start mt-4">
						<div className="col-xl-9">
							<Tab.Content>
								{tabData.map((data, i) => (
									<Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
										{data.content}
									</Tab.Pane>
								))}
							</Tab.Content>
							<div className="d-flex justify-content-end gap-4 formDisabled">
								<Link to={"#"} className="btn btn-dark light mr-4">Indietro</Link>	<Link to="#" className="btn btn-primary ">Salva</Link>

							</div>
						</div>
						<div className="col-xl-3">
							<Card>
								<Card.Body className="text-center">
									{profile && profile.junior.picture && <DynamicLogo logoUrl={profile ? profile.junior.picture : ''} />}
									{(!profile || !profile.junior.picture) && <img src={DEFAULT_USER} style={{width: '100%'}} />}
									{/*<img src={playerfoto} style={{width:'60%'}} className="bd-tondo"/>*/}
									<h4 className="text-start mb-0 mt-4 text-warning">{labelNome}</h4>
									<p className="text-start mt-0 pt-0">Junior</p>
									
									{isNotCompleted && (
										<Link to={'#'} className="btn btn-sm btn-profile mt-2">
											<Information variant="Bulk" /><span className='ms-2'>Profilo non completato</span>
										</Link>
									)}
								</Card.Body>
							</Card>
						</div>
					</Row>

				</Tab.Container>
			</div>

		</>
	)
}
export default JuniorEdit;