import React, { Fragment } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
const options2 = [
    { value: "ADMIN", label: "Administrator" },
    { value: "MKT gestore", label: "Gestore MKT" },
    { value: "gestore shop", label: "Gestore Shop" },
];
const options = [
    { value: "attivo", label: "Attivo" },
    { value: "sospeso", label: "Sospeso" },
];

const StrutturaAddUtente = () => {
    return (
        <Fragment>
            <Form>
                <Card>
                    <Card.Header>
                        <Card.Title>Crea un nuovo utente per il gestionale</Card.Title>

                    </Card.Header>
                    <Card.Body>

                        <Row>
                            <Col className="col-xl-4">
                                <Form.Label>Nome</Form.Label>
                                <Form.Control type="text" placeholder="Inserisci il nome" />
                            </Col>

                            <Col className="col-xl-4">
                                <Form.Label>Cognome</Form.Label>
                                <Form.Control type="text" placeholder="Inserisci il cognome" />
                            </Col>
                            <Col className="col-xl-4">
                                <Form.Label>Seleziona lo status</Form.Label>
                                <Select
                                    defaultValue={options[1]}
                                    options={options}
                                    style={{
                                        lineHeight: "40px",
                                        color: "#fd7e14",
                                        paddingLeft: " 15px",
                                    }
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col className="col-xl-4">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Inserisci email" />
                            </Col>

                            <Col className="col-xl-4">
                                <Form.Label>Nome utente</Form.Label>
                                <Form.Control type="text" placeholder="Inserisci username" />
                            </Col>
                            <Col className="col-xl-4">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="imposta password" />

                            </Col>
                            <Col className="col-xl-4 mt-4">
                                <Form.Label>Seleziona il ruolo</Form.Label>
                                <Select
                                    defaultValue={options2[1]}
                                    options={options2}
                                    style={{
                                        lineHeight: "40px",
                                        color: "#fd7e14",
                                        paddingLeft: " 15px",
                                    }
                                    }
                                />
                            </Col>

                        </Row>
                        <Row>
                            <Col className="col-xl-4 mt-4">
                                <Form.Label>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        value="send"
                                    /> Invia email con i dati account
                                </Form.Label>
                                <Form.Label>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        value="force_password"
                                    /> Forza cambio password
                                </Form.Label>
                            </Col>
                        </Row>


                    </Card.Body>
                </Card >
                <div className="d-flex justify-content-end gap-4">
                    <Link to={"#"} className="btn btn-dark light mr-4">Annulla</Link>	<Link to="#" className="btn btn-primary ">Salva</Link>
                </div>
            </Form>
        </Fragment>

    )
}

export default StrutturaAddUtente