import React, { Fragment, useState } from 'react';
import { Form, Row, Col, Card, } from "react-bootstrap";
import Select from "react-select";
import { Link } from 'react-router-dom';
import { AddSquare, Trash } from 'iconsax-react';
import DatePicker from "react-widgets/DatePicker";
import "react-widgets/styles.css";


const AggiungiPromozione = () => {

	const options = [
		{ value: "Strutture", label: "Strutture" },
		{ value: "Maestri", label: "Mestri" },
		{ value: "Player", label: "Player" },
		{ value: "CDA", label: "CdA" },
		{ value: "Soci", label: "Soci" },
		{ value: "Teams", label: "Teams" },
	];

	const tipologiaSconto = [
		{ value: "percentuale", label: "Percentuale" },
		{ value: "importo-fisso", label: "Importo Fisso" },
	];

	const [selectedOption, setSelectedOption] = useState({ label: "Seleziona...", value: null });

	const [prodotti, setProdotti] = useState([1]);

	const handleAggiungiRiga = () => {
		const nextNumber = Math.max(...prodotti) + 1;
		setProdotti([...prodotti, nextNumber]);
	}

	const handleRimuoviRiga = (index) => {
		const deleteVal = [...prodotti]
		deleteVal.splice(index, 1)
		setProdotti(deleteVal)
	}

	return (
		<Fragment>
			<Card>
				<Card.Header as="h4" className='card-title'>Aggiungi promozione</Card.Header>
				<Card.Body>
					<Form>
						<Row>
							<Col xxl="6" xl="6" lg="6">
								<Form.Label>Nome promozione</Form.Label>
								<Form.Control type="text" placeholder="Inserisci il nome della promozione" />
								<br />
							</Col>
							<Col xxl="6" xl="6" lg="6">
								<Form.Label>Stato della promozione</Form.Label>
								<Select
									defaultValue={""}
									options={options}
									isMulti
									style={{
										lineHeight: "40px",
										color: "#fd7e14",
										paddingLeft: " 15px",
									}
									}
								/>
								<br />
							</Col>
							<Col xxl="12" xl="12" lg="12" xs="12">
								<Form.Label>Prodotti in promozione (non è necessario comprarli tutti insieme):</Form.Label>
								<div className="bg-body p-4">
									{prodotti.map((item, index) => (
										<Row className="pb-4">
											<Col>
												<Form.Label>Nome Prodotto</Form.Label>
												<Select
													defaultValue={selectedOption}
													onChange={setSelectedOption}
													options={options}
													style={{
														lineHeight: "40px",
														color: "#7e7e7e",
														paddingLeft: " 15px",
													}}
												/>
											</Col>
											<Col className="d-flex flex-column justify-content-around col-auto ">
												<Form.Label></Form.Label>
												<div onClick={() => handleRimuoviRiga(index)}><Trash size="24" color="red" variant="Bulk" /></div>
											</Col>
										</Row>

									))}
									<div className="bg-white text-center p-1" onClick={handleAggiungiRiga}>Aggiungi  <AddSquare size="24" color="#737B8B" variant="Bulk" /></div>
								</div>
								<br />
							</Col>

							<Col xxl="6" xl="6" lg="6">
								<Form.Label>Data inizio disponibilità</Form.Label>
								<DatePicker
									defaultValue={new Date()}
									valueFormat={{ dateStyle: "medium" }}
								/>
								<br />
							</Col>

							<Col xxl="6" xl="6" lg="6">
								<Form.Label>Data fine disponibilità</Form.Label>
								<DatePicker
									defaultValue={new Date()}
									valueFormat={{ dateStyle: "medium" }}
								/>
								<br />
							</Col>

							<Col xxl="6" xl="6" lg="6">
								<Form.Label>Tipologia di sconto</Form.Label>
								<Select
									defaultValue={selectedOption}
									onChange={setSelectedOption}
									options={tipologiaSconto}
									style={{
										lineHeight: "40px",
										color: "#7e7e7e",
										paddingLeft: " 15px",
									}}
								/>
								<br />
							</Col>

							<Col xxl="6" xl="6" lg="6">
								<Form.Label>Sconto da applicare</Form.Label>
								<Form.Control type="number" placeholder="Es. 5" />
								<br />
							</Col>

						</Row>
					</Form>
				</Card.Body>
			</Card>

			<div className="d-flex justify-content-end gap-4 mt-4">
				<Link to={"/struttura/prodotti/promozioni"} className="btn btn-dark light mr-4">Annulla</Link> <Link to="#" className="btn btn-primary ">Salva</Link>
			</div>
		</Fragment>
	);
};

export default AggiungiPromozione