import React, { Component, useEffect, useState } from 'react';
import { Form, Button, ButtonGroup, Dropdown, SplitButton, Modal, Row, Col, Card, Table, Badge, ProgressBar, textarea } from "react-bootstrap";

const FormIDSMaestro = (props) => {

    const [formData, setFormData] = useState({
        email: '',
        phoneNumber: '',
        sdi: '',
        taxCode: '',
        description: ''
    });

    useEffect(() => {
        if (props.profile && props.profile.idsGuru) {
            let playerData = props.profile;

            if(playerData && playerData.idsGuru) {
                let formData = {};

                // console.log(playerData.idsGuru);

                formData.email = playerData.idsGuru.email ? playerData.idsGuru.email : '';
                formData.phoneNumber = playerData.idsGuru.phoneNumber ? playerData.idsGuru.phoneNumber : '';
                formData.sdi = playerData.idsGuru.sdi ? playerData.idsGuru.sdi : '';
                formData.taxCode = playerData.idsGuru.taxCode ? playerData.idsGuru.taxCode : '';
                formData.description = playerData.idsGuru.description ? playerData.idsGuru.description : '';
                
                setFormData(formData);
            }
        }
		
    }, [props]);

    return (
        <>
            <Card>
                <Card.Header><h4 className="card-title">Informazioni</h4></Card.Header>
                <Card.Body>
                    <Form>
                        <Row>
                            <Col className="col-xl-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" value={formData.email} {...props.isDisabled && { disabled: true }} />
                            </Col>
                            <Col className="col-xl-3">
                                <Form.Label>Numero di telefono</Form.Label>
                                <Form.Control type="text" value={formData.phoneNumber} {...props.isDisabled && { disabled: true }} />
                            </Col>
                            <Col className="col-xl-3">
                                <Form.Label>SDI</Form.Label>
                                <Form.Control type="email" value={formData.sdi} {...props.isDisabled && { disabled: true }} />
                            </Col>
                            <Col className="col-xl-3">
                                <Form.Label>Partita IVA</Form.Label>
                                <Form.Control type="tel" value={formData.taxCode} {...props.isDisabled && { disabled: true }} />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col className="col-xl-12">
                                <Form.Label>Descrizione</Form.Label>
                                <Form.Control as="textarea" value={formData.description} {...props.isDisabled && { disabled: true }}/>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
           
        </>
    )
}

export default FormIDSMaestro