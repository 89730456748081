import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { Profile, TableDocument, Health, MedalStar, Teacher, DocumentText, Profile2User, MoneySend, People, Calendar, Information, Coin, ArrangeHorizontal } from "iconsax-react";
import { Tab, Nav, Button } from "react-bootstrap";
import { Row, Col, Card, Table } from "react-bootstrap";

import FotoProfiloMaestro from "../../../images/foto-profilo-maestro.png";

const IlMioProfilo = () => {

    const tabData = [
		{
			name: "IDS",
			icon: <Profile size='18' variant='Bulk' />,
			content: 1,
		},
		{
			name: "Dati sportivi",
			icon: <TableDocument size='18' />,
			content: 1
		},
		{
			name: "Dati sanitari",
			icon: <Health size='18' />,
			content: 1,
		},

		{
			name: "Forma fisica",
			icon: <Health size='18' />,
			content:1,
		},
		{
			name: "Traguardi",
			icon: <MedalStar size='18' />,
			content:1,
		},
		{
			name: "Studi",
			icon: <Teacher size='18' />,
			content:1,
		},
		{
			name: "Documenti",
			icon: <DocumentText size='18' />,
			content:1,
		},
		{
			name: "Iscrizioni",
			icon: <DocumentText size='18' />,
			content:1,
		},
		{
			name: "Junior",
			icon: <Profile2User size='18' />,
			content:1,
		},
		{
			name: "Team",
			icon: <People size='18' />,
			content:1,
		},
		{
			name: "FitCoin",
			icon: <Coin size='18' />,
			content:1,
		},
	];
    
    return(
        <Fragment>
            			{/* <!-- Nav tabs --> */}
			<div className="custom-tab-1 mtnegativo2">
				<Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
					<Nav as="ul" className="nav-tabs justify-content-center" style={{ border: 'none' }}>
						{tabData.map((data, i) => (
							<Nav.Item as="li" key={i} className="tabMenu">
								<Nav.Link eventKey={data.name.toLowerCase()}>
									{data.icon}{data.name}
								</Nav.Link>
							</Nav.Item>
						))}
					</Nav>
					<Row className="align-items-start mt-4">
						<div className="col-xl-9">
							<Tab.Content>
								{tabData.map((data, i) => (
									<Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
										{data.content}
									</Tab.Pane>
								))}
							</Tab.Content>
							<div className="d-flex justify-content-end gap-4">
								<Link to={"#"} className="btn btn-dark light mr-4">Indietro</Link>	<Link to="#" className="btn btn-primary ">Salva</Link>

							</div>
						</div>
						<div className="col-xl-3">
							<Card>
								<Card.Body className="text-center">
									<img src={FotoProfiloMaestro} alt="profileImage" className="img-fluid" />
									<h4 className="text-center mb-0">La Sportiva</h4>
									<p className="text-center mt-0 pt-0">Associazione Sportiva Dilettanti</p>
                                    <div className="d-grid gap-2">
                                    <Link to={'#'} className="btn btn-xs light btn-visualizza-profilo-maestro">
									<ArrangeHorizontal variant="Bulk" /><span className='ms-2'>Visualizza Profilo Maestro</span></Link>
									<Link to={'#'} className="btn btn-sm btn-profile mt-2">
									<Information variant="Bulk" /><span className='ms-2'>Profilo non completato</span></Link>
                                    </div>
								</Card.Body>
							</Card>
						</div>
					</Row>

				</Tab.Container>
			</div>
        </Fragment>
    )
}
export default IlMioProfilo;