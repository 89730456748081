import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {ReactComponent as LogoSportID} from "../../../images/logo-nero-sportid.svg";
import DEFAULT_STRUTTURA from "../../../images/DEFAULT_STRUTTURA.png";

export function  NavMenuToggle(){
	setTimeout(()=>{	
		let mainwrapper = document.querySelector("#main-wrapper");
		if(mainwrapper.classList.contains('menu-toggle')){
			mainwrapper.classList.remove("menu-toggle");
		}else{
			mainwrapper.classList.add("menu-toggle");
		}
	},200);
}

const NavHader = () => {
  
  const [toggle, setToggle] = useState(false);
  const userType = sessionStorage.getItem('userType');

  const [displayLogo, setDisplayLogo] = useState("");

  useEffect(() => {

    const tokenDetailsString = sessionStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    if(tokenDetails.logo) {
      setDisplayLogo(tokenDetails.logo);
    } else {
      setDisplayLogo(DEFAULT_STRUTTURA);
    }

  }, []);

  return (
    <div className="nav-header">
      
      <Link to="/dashboard" className="brand-logo" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Fragment>
          {userType === 'superadmin' && <LogoSportID />}
          {displayLogo && <img src={displayLogo} height="100%" alt="" />}
        </Fragment>
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          NavMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;